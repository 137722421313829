import style from "./StakeLpLagacy.module.scss";

import { useState } from "react";
import StakeLpLagacyStake from "./StakeLpLagacyStake";
import StakeLpLagacyUnstake from "./StakeLpLagacyUnstake";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../libs/contexts";
import TxBoxView from "../../../common/TxBoxView/TxBoxView";
import PageHeader from "../../../common/PageHeader/PageHeader";
import StepBar from "../common/StepBar";
import { releaseAll } from "../../../../libs/api/terraswap_liquidity/execution";
import LineDummy from "../../../common/LineDummy/LineDummy";
import { autoStakeLp } from "../../../../libs/api/astroport_liquidity/execution";
import { PATHS } from "../../../../App";
import { useWallet } from "@terra-money/wallet-provider";

export enum StakeLpLagacyMode {
    step1,
    step2,
}

export default function StakeLpLagacy(props: any) {
    const [mode, setMode] = useState(StakeLpLagacyMode.step1);
    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);

    function releasePressed(balance: {
        lp: string;
        staked: string;
        pending: string;
    }) {
        if (wallet) {
            setHash({
                type: "TerraswapUnstake",
                isPending: true,
            });

            releaseAll(balance, wallet.terraAddress)
                .then((r) => {
                    return post(r);
                })
                .then((response) => {
                    setHash({
                        type: "TerraswapUnstake",
                        response: response,
                        redirect: undefined,
                        callback: () => {
                            setMode(StakeLpLagacyMode.step2);
                        },
                    });
                })
                .catch((error) => {
                    setHash({
                        type: "TerraswapUnstake",
                        error: error,
                        redirect: undefined,
                    });
                });
        }
    }

    function stakeLpAutoPressed(token: string, uusd: string) {
        setHash({
            type: "AstroStakeAuto",
            isPending: true,
        });

        autoStakeLp(wallet!.terraAddress, token, uusd)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "AstroStakeAuto",
                    response: response,
                    redirect: PATHS.governance,
                });
            })
            .catch((error) => {
                setHash({
                    type: "AstroStakeAuto",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={style.container}>
            <PageHeader title="Stake" docs="" subtitle={"Change Astroport"} />
            <TxBoxView
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
            >
                <div className={style.header}>
                    <div className={style.icon} />
                    <div className={style.text1}>
                        {
                            "1. Withdraw LP for TerraSwap > 2. Staking for Astroport"
                        }
                    </div>
                    <div className={style.text2}>
                        {
                            "Astroport, the LP reward that was provided to Staking Wallet of Terraswap Liquidity Pool"
                        }
                        <br />
                        {
                            "Switch to Liquidity Pool Staking Wallet. (Look closely)"
                        }
                        <br />

                        {
                            "LP Staking User in the existing Pool needs to switch Pool to receive LP rewards."
                        }
                    </div>
                </div>
                <StepBar
                    title1={"1. Withdraw LP for Terraswap"}
                    title2={"2. Staking for Astroport"}
                    selected={mode}
                    setSelected={setMode}
                />

                <LineDummy horizontal={true} height={2} />

                {mode === StakeLpLagacyMode.step1 ? (
                    <StakeLpLagacyUnstake releasePressed={releasePressed} />
                ) : (
                    <StakeLpLagacyStake
                        stakeLpAutoPressed={stakeLpAutoPressed}
                    />
                )}
            </TxBoxView>
        </div>
    );
}
