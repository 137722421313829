import style from "./MainSection1.module.scss";

import DashboardChartHeader from "../common/DashboardChartHeader/DashboardChartHeader";
import PriceHeader from "../common/PriceHeader/PriceHeader";
import { useEffect, useRef } from "react";
import { circulatingSupply } from "../../libs/api/rest";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { refreshState, walletState } from "../../libs/contexts";
import LineDummy from "../common/LineDummy/LineDummy";

const cellMeta = {
    title: "Circulating Supply",
    tooltip: "",
    symbol: "VKR",
    items: [
        {
            title: "LP",
            color: "#ffe646",
        },
        {
            title: "Gov",
            color: "#a37e30",
        },
        {
            title: "Reward Pool",
            color: "#4e461b",
        },
        {
            title: "Other",
            color: "#626051",
        },
    ],
};

export default function MainSection1() {
    const refresh = useRecoilValue(refreshState);
    const wallet = useRecoilValue(walletState);
    const isLoading = useRef(false);

    const [value, setValue] = useState({
        total: "0",
        values: ["0", "0", "0", "0"],
    });

    const [selected, setSelected] = useState("");

    useEffect(() => {
        if (isLoading.current) {
            return;
        }
        isLoading.current = true;
        circulatingSupply(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setValue({
                    total: r.total,
                    values: [r.lp, r.gov, r.deposit, r.others],
                });
                isLoading.current = false;
            })
            .catch((e) => {
                isLoading.current = false;
            });
    }, [wallet, refresh]);

    function chartSelected(selectLabel: string) {
        setSelected(selectLabel);
    }

    return (
        <div className={style.container}>
            <div className={style.chart_container}>
                <PriceHeader isDashboard={true} />
            </div>
            <LineDummy horizontal={true} />
            <div className={style.header_container}>
                <DashboardChartHeader meta={cellMeta} value={value} selected={selected} />
            </div>
            <BarChart value={value} chartSelected={chartSelected} />
        </div>
    );
}

function BarChart(props: {
    value: {
        total: string;
        values: string[];
    };
    chartSelected: (selectLabel: string) => void;
}) {
    let sum = 0;

    for (let i = 0; i < props.value.values.length; i++) {
        sum += parseFloat(props.value.values[i]);
    }

    if (sum === 0) {
        return <div />;
    }


    return (
        <div className={style.bar_chart_container}>
            {props.value.values.map((item, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            flex: parseFloat(item) / sum,
                            backgroundColor: cellMeta.items[index].color,
                        }}
                        onMouseOver={() => {
                            props.chartSelected(cellMeta.items[index].title);
                        }}
                        onMouseOut={() => {
                            props.chartSelected("");
                        }}
                        className={style.bar_chart_item}
                    />
                );
            })}
        </div>
    );
}
