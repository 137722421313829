import { fixed, min, multiply } from "../Math";
import { getLcdClient } from "./api";
import { Denom } from "@terra-money/terra.js";

async function getTaxRate(): Promise<string> {
    const r = await getLcdClient().treasury.taxRate();
    return r.toString();
}

async function getTaxCap(denom: Denom): Promise<string> {
    const r = await getLcdClient().treasury.taxCap(denom);

    return r.amount.toString();
}

export async function calculateTax(
    amount: string,
    denom: Denom
): Promise<string> {
    if (denom === "uluna") {
        return "0";
    }

    const taxRate = await getTaxRate();
    const taxCap = await getTaxCap(denom);
    const tax = fixed(min(multiply(amount, taxRate), taxCap), 0);

    return tax;
}
