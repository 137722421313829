import { useEffect, useState } from "react";
import { campaignSummary } from "../../../libs/api/campaign/query";
import { PATHS } from "../../../App";
import { isWhitelistedCampaign } from "../../../libs/api/rest";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
import { useRef } from "react";
import { environment } from "../../../libs/api/api";

import BaseCampaignDetail from "./Base/BaseCampaignDetail";
import LunartCampaignDetail from "./Lunart/LunartCampaignDetail";
import FanfuryCampaignDetail from "./Fanfury/FanfuryCampaignDetail";
import TalisCampaignDetail from "./Talis/TalisCampaignDetail";
import SmartStakeCampaignDetail from "./SmartStake/SmartStakeCampaignDetail";

export const parameter = "vkr";

export default function CampaignDetail(props: any) {
    const wallet = useRecoilValue(walletState);
    const [campaignAddress, setCampaignAddress] = useState("");

    const [summary, setSummary] = useState<CampaignSummary | undefined>(
        undefined
    );
    const isLoading = useRef(false);

    useEffect(() => {
        const campaignAddress = props.match.params.campaignAddress;

        if (!campaignAddress) {
            props.history.replace(PATHS.campaigns);
            return;
        }

        setCampaignAddress(campaignAddress);
    }, [props.history, props.match.params]);

    useEffect(() => {
        if (isLoading.current || campaignAddress === "") {
            return;
        }

        isLoading.current = true;
        isWhitelistedCampaign(campaignAddress).then((r) => {
            if (r) {
                campaignSummary(
                    campaignAddress,
                    wallet ? wallet.terraAddress : undefined
                )
                    .then((r) => {
                        isLoading.current = false;
                        setSummary(r);
                    })

                    .catch((error) => {
                        isLoading.current = false;
                        props.history.replace(PATHS.campaigns);
                    });
            } else {
                isLoading.current = false;
                props.history.replace(PATHS.campaigns);
            }
        });
    }, [campaignAddress, wallet]);

    if (summary === undefined) {
        return <div />;
    }

    return (
        <div>
            {campaignAddress ===
            environment().contracts.lunart_early.campaign ? (
                <LunartCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                    earlybird={true}
                />
            ) : campaignAddress ===
              environment().contracts.lunart_public.campaign ? (
                <LunartCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                    earlybird={false}
                />
            ) : campaignAddress ===
              environment().contracts.fanfury_early.campaign ? (
                <FanfuryCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                    earlybird={true}
                    refresh={() => {
                        campaignSummary(
                            campaignAddress,
                            wallet ? wallet.terraAddress : undefined
                        )
                            .then((r) => {
                                setSummary(r);
                            })
                            .catch((e) => {});
                    }}
                />
            ) : campaignAddress ===
              environment().contracts.fanfury_public.campaign ? (
                <FanfuryCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                    earlybird={false}
                    refresh={() => {
                        campaignSummary(
                            campaignAddress,
                            wallet ? wallet.terraAddress : undefined
                        )
                            .then((r) => {
                                setSummary(r);
                            })
                            .catch((e) => {});
                    }}
                />
            ) : campaignAddress ===
              environment().contracts.talis_early.campaign ? (
                <TalisCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                    earlybird={true}
                    refresh={() => {
                        campaignSummary(
                            campaignAddress,
                            wallet ? wallet.terraAddress : undefined
                        )
                            .then((r) => {
                                setSummary(r);
                            })
                            .catch((e) => {});
                    }}
                />
            ) : campaignAddress ===
              environment().contracts.talis_public.campaign ? (
                <TalisCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                    earlybird={false}
                    refresh={() => {
                        campaignSummary(
                            campaignAddress,
                            wallet ? wallet.terraAddress : undefined
                        )
                            .then((r) => {
                                setSummary(r);
                            })
                            .catch((e) => {});
                    }}
                />
            ) : "https://event.vkr.link/smartstake" === summary.config.url ? (
                <SmartStakeCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                />
            ) : (
                <BaseCampaignDetail
                    campaignAddress={campaignAddress}
                    summary={summary}
                />
            )}
        </div>
    );
}
