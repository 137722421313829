import style from "./ProducerSetting.module.scss";

import PageHeader from "../../common/PageHeader/PageHeader";
import { PATHS } from "../../../App";
import { useState } from "react";
import { useEffect } from "react";
import { environment } from "../../../libs/api/api";
import { txQualifierCampaign } from "../../../libs/api/campaign/qualification/query";

export default function ProducerSetting(props: any) {
    const [campaignAddress, setCampaignAddress] = useState("");
    const [summary, setSummary] = useState<CampaignSummary | undefined>(
        undefined
    );

    const [registeredInfo, setRegisteredInfo] = useState<
        ResponseTxQualifierCampaign | undefined
    >(undefined);

    useEffect(() => {
        const state: any = props.history.location.state;
        if (!state || !state.campaignAddress || !state.summary) {
            props.history.replace(PATHS.producer);
            return;
        }
        setCampaignAddress(state.campaignAddress);
        setSummary(state.summary);
    }, [props.history]);

    useEffect(() => {
        txQualifierCampaign(campaignAddress)
            .then((r) => {
                console.log(r);
                setRegisteredInfo(r);
            })
            .catch((e) => {});
    }, [campaignAddress]);

    if (summary === undefined) {
        return <div />;
    }

    return (
        <div className={style.container}>
            <PageHeader title={"Campaign Manager"} docs={""} />

            <div className={style.card_container}>
                <Card
                    title="Set Campaign"
                    pressed={() => {
                        props.history.push(PATHS.producerSettingGeneral, {
                            campaignAddress: campaignAddress,
                            summary: summary,
                        });
                    }}
                />

                <Card
                    title="Set Qualifier Contract"
                    pressed={() => {
                        props.history.push(PATHS.producerSettingQualification, {
                            campaignAddress: campaignAddress,
                            summary: summary,
                        });
                    }}
                />
                {summary.config.qualifier ===
                    environment().contracts.tx_qualifier &&
                registeredInfo === undefined ? (
                    <Card
                        title="Register campaign to qualifier"
                        pressed={() => {
                            props.history.push(
                                PATHS.producerSettingRegisterQualifier,
                                {
                                    campaignAddress: campaignAddress,
                                    summary: summary,
                                }
                            );
                        }}
                    />
                ) : (
                    <div />
                )}

                {summary.config.qualifier ===
                    environment().contracts.tx_qualifier &&
                registeredInfo === undefined ? (
                    <Card
                        title="Register campaign to qualifier"
                        pressed={() => {
                            props.history.push(
                                PATHS.producerSettingRegisterQualifier,
                                {
                                    campaignAddress: campaignAddress,
                                    summary: summary,
                                }
                            );
                        }}
                    />
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
}

function Card(props: { title: string; pressed: () => void }) {
    return (
        <div className={style.card} onClick={props.pressed}>
            <div className={style.col1}>
                <div className={style.text1}>{props.title}</div>
            </div>
            <div className={style.col2}>
                <div className={style.icon} />
            </div>
        </div>
    );
}
