import style from "./Airdrop.module.scss";
import PageHeader from "../common/PageHeader/PageHeader";
import { useState } from "react";
import { useEffect } from "react";

import TxBoxView from "../common/TxBoxView/TxBoxView";
import {
    airdropClaim,
    airdropList,
    airdropVpList,
    vpGenesisAirdropClaim,
} from "../../libs/api/rest";

import { useWallet } from "@terra-money/wallet-provider";

import { useRecoilState, useRecoilValue } from "recoil";
import { airdropState, vpAirdropState, walletState } from "../../libs/contexts";
import { saveAirdrop, saveVpAirdrop } from "../../libs/storage";
import AirdropItem from "./AirdropItem";
import EmptyView3 from "../common/EmptyView/EmptyView3";
import TitleLabel from "../common/Labels/TitleLabel";

export default function Airdrop() {
    const { post } = useWallet();
    const [hash, setHash] = useState<HashResult | undefined>(undefined);
    const [vpList, setVpList] = useRecoilState(vpAirdropState);
    const [list, setList] = useRecoilState(airdropState);

    const wallet = useRecoilValue(walletState);

    useEffect(() => {
        if (wallet) {
            airdropList(wallet.terraAddress)
                .then((r) => {
                    setList(r);
                })
                .catch((e) => {});
            airdropVpList(wallet.terraAddress)
                .then((r) => {
                    setVpList(r);
                })
                .catch((e) => {});
        }
    }, [wallet]);

    function claimVpGenesis(item: AirdropItem) {
        if (!wallet) {
            return;
        }

        setHash({
            type: "VPAirdrop",
            isPending: true,
        });

        vpGenesisAirdropClaim(item, wallet.terraAddress)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "VPAirdrop",
                    response: response,
                    redirect: undefined,
                    callback: (success) => {
                        if (success) {
                            saveVpAirdrop(wallet.terraAddress, item.stage);
                            airdropVpList(wallet.terraAddress)
                                .then((r) => {
                                    setVpList(r);
                                })
                                .catch((e) => {});
                        }
                    },
                });
            })
            .catch((error) => {
                setHash({
                    type: "VPAirdrop",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function claim(item: AirdropItem) {
        if (!wallet) {
            return;
        }

        setHash({
            type: "Airdrop",
            isPending: true,
        });

        airdropClaim(item, wallet.terraAddress)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "Airdrop",
                    response: response,
                    redirect: undefined,
                    callback: (success) => {
                        if (success) {
                            saveAirdrop(wallet.terraAddress, item.stage);
                            airdropList(wallet.terraAddress)
                                .then((r) => {
                                    setList(r);
                                })
                                .catch((e) => {});
                        }
                    },
                });
            })
            .catch((error) => {
                setHash({
                    type: "Airdrop",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={style.container}>
            <PageHeader title="Airdrop" docs="" />

            {list.length + vpList.length === 0 ? (
                <EmptyView3 title={"All Claimed"} />
            ) : (
                <TxBoxView
                    transparent={true}
                    hash={hash}
                    clearHash={() => {
                        setHash(undefined);
                    }}
                >
                    <div className={style.box}>
                        {vpList.map((item, index) => {
                            return (
                                <AirdropItem
                                    key={index}
                                    item={item}
                                    symbol={"VP"}
                                    pressed={(item) => {
                                        claimVpGenesis(item);
                                    }}
                                    isLast={index === vpList.length - 1}
                                />
                            );
                        })}

                        {list.map((item, index) => {
                            return (
                                <AirdropItem
                                    key={index}
                                    item={item}
                                    symbol={"VKR"}
                                    pressed={(item) => {
                                        claim(item);
                                    }}
                                    isLast={index === list.length - 1}
                                />
                            );
                        })}
                    </div>
                </TxBoxView>
            )}
        </div>
    );
}
