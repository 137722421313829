import { useState } from "react";
import style from "./ProducerCreate.module.scss";
import PageHeader from "../../common/PageHeader/PageHeader";
import TitleLabel from "../../common/Labels/TitleLabel";
import ConfirmButton from "../../common/ConfirmButton/ConfirmButton";
import TxBoxView from "../../common/TxBoxView/TxBoxView";
import InputField from "../../common/InputField/InputField";
import InputFieldMulti from "../../common/InputField/InputFieldMulti";
import { useEffect } from "react";

import { PATHS } from "../../../App";
import * as Utils from "../../../libs/utils";
import {
    addressValidate,
    blockPerDay,
    environment,
} from "../../../libs/api/api";
import { createCampaign } from "../../../libs/api/campaign/execution";
import { multiply } from "../../../libs/Math";
import TxInfoView from "../../common/TxInfoView/TxInfoView";
import LineDummy from "../../common/LineDummy/LineDummy";
import InputFieldIconCombo from "../../common/InputField/InputFieldIconCombo";
import TokenAddPopup from "./TokenAddPopup";
import { useWallet } from "@terra-money/wallet-provider";
import InputFieldDeposit from "../../common/InputField/InputFieldDeposit";
import ProducerQualification from "./ProducerQualification";
import { isWhitelistedCreator } from "../../../libs/api/rest";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
import { getFeeNormal } from "../../../libs/api/fee";
import ComboBox from "../../common/ComboBox/ComboBox";
import { getSecretKey } from "../../../libs/api/campaign/qualification/rest";

export default function ProducerCreate(props: any) {
    const { post } = useWallet();

    useEffect(() => {
        getSecretKey("Aw1W/FvtPnCeLyqFFWmPIwt827tgygQDstDQbUloaC3e")
            .then((r) => {
                console.log("r");
                console.log(r);
            })
            .catch((e) => {
                console.log("e");
                console.log(e);
            });
    }, []);

    const [hash, setHash] = useState<HashResult | undefined>(undefined);
    const wallet = useRecoilValue(walletState);
    const [showTokenAddPopup, setShowTokenAddPopup] = useState(false);

    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow = showTokenAddPopup ? "hidden" : "auto";
    }, [showTokenAddPopup]);

    useEffect(() => {
        isWhitelistedCreator(wallet ? wallet.terraAddress : undefined).then(
            (r) => {
                if (!r) {
                    props.history.replace(PATHS.producer);
                }
            }
        );
    }, [props.history, wallet]);

    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [desc, setDesc] = useState("");
    const [parameterKey, setParameterKey] = useState("");

    const [currencyIndex, setCurrencyIndex] = useState(1);
    const [currencyList, setCurrencyList] = useState([
        {
            title: "+ Add",
            value: "add",
        },
        {
            title: "VKR",
            value: environment().contracts.token,
        },
        {
            title: "UST",
            value: "uusd",
        },
    ]);

    const [reward0, setReward0] = useState("");
    const [reward1, setReward1] = useState("");

    const [depositOn, setDepositOn] = useState(false);
    const [deposit, setDeposit] = useState("");
    const [depositUnlockPeriodDay, setDepositUnlockPeriodDay] = useState("");

    const [qualification, setQualification] = useState("");
    const [qualificationDesc, setQualificationDesc] = useState("");

    const [
        participationRewardDistributionSchedule,
        setParticipationRewardDistributionSchedule,
    ] = useState(
        JSON.stringify(
            JSON.parse('[{"startDay":1, "endDay":1, "amount":"10.000000"}]'),
            undefined,
            4
        )
    );
    const [referralRewardLockPeriodDay, setReferralRewardLockPeriodDay] =
        useState("");
    const [vpBurnAmount, setVpBurnAmount] = useState("");

    const [valid, setValid] = useState(false);

    useEffect(() => {
        if (!depositOn) {
            setDeposit("");
            setDepositUnlockPeriodDay("");
        }
    }, [depositOn]);

    useEffect(() => {
        if (qualification && !addressValidate(qualification)) {
            setValid(false);
            return;
        }

        if (
            name &&
            url &&
            desc &&
            participationRewardDistributionSchedule &&
            reward0 &&
            reward1 &&
            parameterKey
        ) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [
        name,
        url,
        desc,
        participationRewardDistributionSchedule,
        reward0,
        reward1,
        parameterKey,

        qualification,
    ]);

    function createPressed() {
        let native: string | undefined = undefined;
        let token: string | undefined = undefined;

        const currency = currencyList[currencyIndex];

        if (Utils.isNativeToken(currency.value)) {
            native = currencyList[currencyIndex].value;
        } else {
            token = currencyList[currencyIndex].value;
        }

        const rewards = [
            multiply(reward0, 1000000),
            multiply(reward1, 1000000),
        ];

        setHash({
            type: "CreateCampaign",
            isPending: true,
        });

        createCampaign(
            wallet!.terraAddress,
            name,
            url,
            desc,
            {
                native: native,
                token: token,
            },
            parameterKey,

            rewards,
            depositOn && deposit ? multiply(deposit, 1000000) : "0",
            depositOn && depositUnlockPeriodDay
                ? depositUnlockPeriodDay
                : undefined,
            participationRewardDistributionSchedule,
            referralRewardLockPeriodDay
                ? parseInt(referralRewardLockPeriodDay) * blockPerDay
                : 0,
            vpBurnAmount ? multiply(vpBurnAmount, 1000000) : "0",
            qualification ? qualification : undefined,
            qualificationDesc ? qualificationDesc : undefined
        )
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "CreateCampaign",
                    response: response,
                    redirect: PATHS.producer,
                });
            })
            .catch((error) => {
                setHash({
                    type: "CreateCampaign",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={style.container}>
            <PageHeader title={"Create a Campaign"} docs={""} />

            <TxBoxView
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
                transparent={true}
            >
                <div className={style.inner}>
                    <TitleLabel
                        size={20}
                        fontWeight={"light"}
                        text={"General Setting"}
                        color={"white"}
                    />

                    <div style={{ height: "20px" }} />

                    <InputField
                        tooltip={"Title of Campaign"}
                        title="Title"
                        type="text"
                        value={name}
                        onChanged={setName}
                    />
                    <InputField
                        title="Site URL"
                        type="text"
                        value={url}
                        tooltip={"Campaign Page URL"}
                        onChanged={setUrl}
                    />
                    <InputFieldMulti
                        title="Description"
                        tooltip={
                            "Campaign introduction, user manual, etc. that are made by the creator"
                        }
                        placeholder=""
                        value={desc}
                        onChanged={setDesc}
                    />

                    <InputField
                        title="Parameter Key"
                        type="text"
                        value={parameterKey}
                        tooltip={
                            "Query parameter key in Campaign Site URL for referral"
                        }
                        onChanged={(v) => {
                            setParameterKey(v.trim());
                        }}
                    />

                    <div style={{ marginTop: "40px" }} />
                    <TitleLabel
                        size={20}
                        text="Reward Setting"
                        color={"white"}
                        fontWeight="light"
                    />

                    <div style={{ marginTop: "20px" }} />

                    <InputFieldIconCombo
                        title="Participation Reward"
                        value={""}
                        decimal={
                            currencyList[currencyIndex].value === "uusd" ? 3 : 6
                        }
                        denom={currencyList[currencyIndex].value}
                        placeholder="_"
                        tooltip={
                            "The amount distributed upon participation is provided as a reward token"
                        }
                        onChanged={(v) => {}}
                        combo={{
                            selected: currencyIndex,
                            setSelected: (v) => {
                                if (v === 0) {
                                    setShowTokenAddPopup(true);
                                } else {
                                    setCurrencyIndex(v);
                                }
                            },
                            list: currencyList.map((item) => {
                                return item.title;
                            }),
                        }}
                    />

                    <InputFieldMulti
                        title=""
                        value={participationRewardDistributionSchedule}
                        placeholder=""
                        onChanged={(v) => {
                            setParticipationRewardDistributionSchedule(v);
                        }}
                    />

                    <div style={{ marginTop: "10px" }} />

                    <div className={style.row1}>
                        <TitleLabel
                            tooltip={
                                "This is the scheme that upon successful referrals (up to 2 degrees), rewards will be distributed to the referral link owner"
                            }
                            text={"Sharing Reward Scheme"}
                            color={"#786f64"}
                        />
                        <div className={style.col1}>
                            <InputField
                                title=""
                                type="amount"
                                value={reward0}
                                symbol={"VKR"}
                                onChanged={setReward0}
                            />
                            <div className={style.dash} />
                            <InputField
                                title=""
                                type="amount"
                                value={reward1}
                                symbol={"VKR"}
                                onChanged={setReward1}
                            />
                        </div>
                    </div>

                    <InputField
                        title=""
                        type={"amount"}
                        decimal={6}
                        value={referralRewardLockPeriodDay}
                        symbol={"Days"}
                        placeholder={"Unlock Period"}
                        onChanged={(v) => {
                            setReferralRewardLockPeriodDay(
                                v.replaceAll(".", "")
                            );
                        }}
                    />

                    <div style={{ marginTop: "30px" }} />

                    <InputFieldDeposit
                        depositOn={depositOn}
                        setDepositOn={setDepositOn}
                        deposit={deposit}
                        setDeposit={setDeposit}
                        depositUnlockPeriodDay={depositUnlockPeriodDay}
                        setDepositUnlockPeriodDay={setDepositUnlockPeriodDay}
                    />

                    <InputField
                        title="VP Burn Amount"
                        type={"amount"}
                        decimal={6}
                        value={vpBurnAmount}
                        symbol={"VP"}
                        placeholder={"0"}
                        onChanged={(v) => {
                            setVpBurnAmount(v);
                        }}
                    />

                    <LineDummy
                        horizontal={true}
                        marginTop={30}
                        marginBottom={40}
                    />

                    <TitleLabel
                        size={20}
                        text="Set Qualifier Contract"
                        symbol={"(optional)"}
                        color={"white"}
                        fontWeight="light"
                    />

                    <div style={{ height: "20px" }} />

                    <ProducerQualification
                        contract={qualification}
                        setContract={setQualification}
                        desc={qualificationDesc}
                        setDesc={setQualificationDesc}
                    />

                    <div style={{ height: "30px" }} />
                    <InfoView />

                    <ConfirmButton
                        title="Create"
                        enable={valid}
                        onClick={createPressed}
                    />
                </div>
            </TxBoxView>

            {showTokenAddPopup ? (
                <TokenAddPopup
                    showTokenAddPopup={showTokenAddPopup}
                    setShowTokenAddPopup={setShowTokenAddPopup}
                    add={(info: { title: string; value: string }) => {
                        let l = currencyList.slice();
                        l.push(info);
                        setCurrencyList(l);
                        setCurrencyIndex(l.length - 1);
                    }}
                />
            ) : (
                <div />
            )}
        </div>
    );
}

function InfoView() {
    const [fee, setFee] = useState("0");

    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => {});
    }, []);

    return <TxInfoView fee={fee} />;
}
