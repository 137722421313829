import { useEffect, useState } from "react";
import style from "./ProducerDepositWithdraw.module.scss";
import TxBoxView from "../../common/TxBoxView/TxBoxView";
import TabBox from "../../common/TabBox/TabBox";
import ProducerDeposit from "./ProducerDeposit";
import ProducerWithdraw from "./ProducerWithdraw";
import TitleLabel from "../../common/Labels/TitleLabel";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
import { PATHS } from "../../../App";
import {
    campaignPoolDeposit,
    campaignPoolWithdraw,
} from "../../../libs/api/campaign/execution";
import TokenAddPopup from "../Create/TokenAddPopup";
import { useWallet } from "@terra-money/wallet-provider";
import { environment } from "../../../libs/api/api";

export default function ProducerDepositWithdraw(props: any) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);
    const [showTokenAddPopup, setShowTokenAddPopup] = useState(false);
    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow = showTokenAddPopup ? "hidden" : "auto";
    }, [showTokenAddPopup]);

    const [currencyIndex, setCurrencyIndex] = useState(1);
    const [currencyList, setCurrencyList] = useState([
        {
            title: "+ Add",
            value: "add",
        },
        {
            title: "VKR",
            value: environment().contracts.token,
        },
        {
            title: "UST",
            value: "uusd",
        },
    ]);

    const [campaignAddress, setCampaignAddress] = useState("");
    const [summary, setSummary] = useState<CampaignSummary | undefined>(
        undefined
    );

    const [tabIndex, setTabIndex] = useState(0);

    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    useEffect(() => {
        const state: any = props.history.location.state;

        if (!state || !state.summary || !state.campaignAddress) {
            props.history.replace(PATHS.producer);
            return;
        }
        setCampaignAddress(state.campaignAddress);
        setSummary(state.summary);
    }, [props.history]);

    function depositPressed(
        amount1: string,
        amount1Denom: string,
        amount2: string
    ) {
        setHash({
            type: "CampaignDeposit",
            isPending: true,
        });

        campaignPoolDeposit(
            wallet!.terraAddress,
            campaignAddress,
            amount1,
            amount1Denom,
            amount2
        )
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "CampaignDeposit",
                    response: response,
                    redirect: PATHS.producerDetail + campaignAddress,
                });
            })
            .catch((error) => {
                setHash({
                    type: "CampaignDeposit",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function withdrawPressed(amount: string, denom: string) {
        setHash({
            type: "CampaignWithdraw",
            isPending: true,
        });

        campaignPoolWithdraw(
            wallet!.terraAddress,
            campaignAddress,
            amount,
            denom
        )
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "CampaignWithdraw",
                    response: response,
                    redirect: PATHS.producerDetail + campaignAddress,
                });
            })
            .catch((error) => {
                setHash({
                    type: "CampaignWithdraw",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    if (summary === undefined) {
        return <div />;
    }

    return (
        <div className={style.container}>
            <div className={style.title}>
                <TitleLabel
                    text="Pool Balance"
                    color="white"
                    size={20}
                    fontWeight="light"
                />
            </div>

            <div className={style.subtitle}>
                <TitleLabel text="Campaign:" color="#786f64" />
                &nbsp;
                <TitleLabel text={summary.config.title} fontWeight="light" />
            </div>

            <TxBoxView
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
                transparent={true}
            >
                <div className={style.inner_container}>
                    <TabBox
                        titleLeft={"Add"}
                        titleRight={"Remove"}
                        tabIndex={tabIndex}
                        tabChanged={setTabIndex}
                    />
                    {tabIndex === 0 ? (
                        <ProducerDeposit
                            campaignAddress={campaignAddress}
                            summary={summary}
                            depositPressed={depositPressed}
                        />
                    ) : (
                        <ProducerWithdraw
                            campaignAddress={campaignAddress}
                            summary={summary}
                            withdrawPressed={withdrawPressed}
                            currencyIndex={currencyIndex}
                            currencyList={currencyList}
                            setCurrencyIndex={setCurrencyIndex}
                            showAddPopup={() => {
                                setShowTokenAddPopup(true);
                            }}
                        />
                    )}
                </div>
            </TxBoxView>

            {showTokenAddPopup ? (
                <TokenAddPopup
                    showTokenAddPopup={showTokenAddPopup}
                    setShowTokenAddPopup={setShowTokenAddPopup}
                    add={(info: { title: string; value: string }) => {
                        let l = currencyList.slice();
                        l.push(info);
                        setCurrencyList(l);
                        setCurrencyIndex(l.length - 1);
                    }}
                />
            ) : (
                <div />
            )}
        </div>
    );
}
