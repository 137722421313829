import { CreateTxOptions } from "@terra-money/terra.js";

import { isZero } from "../../Math";
import { environment, wasmExecutes } from "../api";

export async function releaseAll(
    balance: {
        lp: string;
        staked: string;
        pending: string;
    },
    address: string
): Promise<CreateTxOptions> {
    //unstake lp
    //lp send [stake contact] => [lp contract]

    let exes: WasmExecute[] = [];

    if (!isZero(balance.staked)) {
        //lp staking 분해
        exes.push({
            contract: environment().contracts.terraswap.staking,
            msg: {
                unbond: {
                    amount: balance.staked,
                },
            },
            coin: undefined,
        });

        exes.push({
            contract: environment().contracts.terraswap.lp,
            msg: {
                send: {
                    contract: environment().contracts.terraswap.pair,
                    amount: balance.staked,
                    msg: btoa(
                        JSON.stringify({
                            withdraw_liquidity: {},
                        })
                    ),
                },
            },
            coin: undefined,
        });
    }

    if (!isZero(balance.pending)) {
        //claim
        exes.push({
            contract: environment().contracts.terraswap.staking,
            msg: { withdraw: {} },
            coin: undefined,
        });
    }

    if (!isZero(balance.lp)) {
        //lp토큰 분해
        exes.push({
            contract: environment().contracts.terraswap.lp,
            msg: {
                send: {
                    contract: environment().contracts.terraswap.pair,
                    amount: balance.lp,
                    msg: btoa(
                        JSON.stringify({
                            withdraw_liquidity: {},
                        })
                    ),
                },
            },
            coin: undefined,
        });
    }

    return await wasmExecutes(address, exes);
}
