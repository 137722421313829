import { useState } from "react";
import style from "./FanfuryCampaignItem.module.scss";
import * as Utils from "../../../libs/utils";
import { PATHS } from "../../../App";
import TitleLabel from "../../common/Labels/TitleLabel";
import { useEffect } from "react";
import {
    campaignSummary,
    fanfuryRequirement,
} from "../../../libs/api/campaign/query";
import Skeleton from "react-loading-skeleton";
import { divide, leftLessThanRight, minus, multiply } from "../../../libs/Math";
import { Link } from "react-router-dom";

import classNames from "classnames/bind";
import { useRecoilState, useRecoilValue } from "recoil";
import { blockState, walletState } from "../../../libs/contexts";
import DetailButton from "../../common/DetailButton/DetailButton";
import { environment } from "../../../libs/api/api";

const cx = classNames.bind(style);

export default function FanfuryCampaignItem(props: {
    info: CampaignListItem;
    isEarlybird: boolean;
}) {
    const block = useRecoilValue(blockState);
    const wallet = useRecoilValue(walletState);
    const [summary, setSummary] = useState<CampaignSummary | undefined>(
        undefined
    );
    const [requirement, setRequirement] = useState<
        FanfuryRequirement | undefined
    >(undefined);

    useEffect(() => {
        if (props.info.campaignAddress) {
            campaignSummary(
                props.info.campaignAddress,
                wallet ? wallet.terraAddress : undefined
            )
                .then((r) => {
                    setSummary(r);
                    return fanfuryRequirement(props.isEarlybird);
                })
                .then((r) => {
                    setRequirement(r);
                })
                .catch((e) => {});
        }
    }, [props.info, wallet, props.isEarlybird]);

    const isActive = summary ? summary.state.is_active : false;

    const [open, setOpen] = useState(false);
    const [leftBlock, setLeftBlock] = useState(0);

    useEffect(() => {
        if (requirement) {
            if (
                summary &&
                summary.state.is_active &&
                requirement.start_block >= block.block
            ) {
                setOpen(false);
                setLeftBlock(requirement.start_block - block.block);
            } else {
                setOpen(true);
            }
        }
    }, [requirement, block, summary]);

    return (
        <Link
            className={cx(style.campaigns_item, {
                disabled: !isActive,
            })}
            to={PATHS.campaignsDetail + props.info.campaignAddress}
        >
            <IconView isEarlybird={props.isEarlybird} isActive={isActive} />

            <div className={style.dummy}>
                <div className={style.dummy1} />
                <div className={style.dummy2} />
            </div>

            <div className={style.contents}>
                {summary ? (
                    <div className={style.campaign_title}>
                        {summary.config.title}
                    </div>
                ) : (
                    <Skeleton width={100} height={18} />
                )}

                <div className={style.box}>
                    {!open ? (
                        <NotOpenView
                            earlybird={props.isEarlybird}
                            leftBlock={leftBlock}
                        />
                    ) : (
                        <OpenView
                            isEarlybird={props.isEarlybird}
                            summary={summary}
                        />
                    )}
                </div>

                <div className={style.detail_button}>
                    <div className={style.text}>{"Details"}</div>
                    <div className={style.arrow} />
                </div>
            </div>
        </Link>
    );
}

function IconView(props: { isEarlybird: boolean; isActive: boolean }) {
    return (
        <div className={style.icon_container}>
            <div className={cx(style.icon, { public: !props.isEarlybird })}>
                {props.isActive === false ? (
                    <div className={style.closed}>
                        <div className={style.text}>{"CLOSED"}</div>
                    </div>
                ) : (
                    <div />
                )}
            </div>
            <div className={style.titles}>
                <div className={style.text1}>{"$FURY"}</div>
                <div className={style.text2}>{"FanFury"}</div>
            </div>
        </div>
    );
}

function NotOpenView(props: { earlybird: boolean; leftBlock: number }) {
    const startTime = props.earlybird
        ? environment().contracts.fanfury_early.startTimeSeconds * 1000
        : environment().contracts.fanfury_public.startTimeSeconds * 1000;

    return (
        <div className={style.yet}>
            <div
                className={cx(style.opendate, {
                    highlight: props.leftBlock > 100,
                })}
            >
                {props.leftBlock <= 100
                    ? "Open: " + Utils.dateFormat10(startTime)
                    : "Open: " + Utils.dateFormat11(startTime)}
            </div>
            <div className={style.text_remainig}>{"Blocks Remaining"}</div>
            <div className={style.block_left}>
                <div
                    className={cx(style.text1, {
                        highlight: props.leftBlock <= 100,
                    })}
                >
                    {Utils.getDecimal(
                        multiply(props.leftBlock, 1000000),
                        true
                    ) + " Blocks"}
                </div>
            </div>
        </div>
    );
}

function OpenView(props: { isEarlybird: boolean; summary?: CampaignSummary }) {
    const [totalDistribution, setTotalDistribution] = useState("0");
    const [percent, setPercent] = useState("0");
    const [price, setPrice] = useState("0");
    const [isActive, setActive] = useState(false);

    useEffect(() => {
        if (props.isEarlybird) {
            const info = environment().contracts.fanfury_early;
            setTotalDistribution(info.total_distribution);
            setPrice(info.ustprice);
        } else {
            const info = environment().contracts.fanfury_public;
            setTotalDistribution(info.total_distribution);
            setPrice(info.ustprice);
        }
    }, [props.isEarlybird]);

    useEffect(() => {
        if (props.summary) {
            const state = props.summary.state;
            const cumulative = state.cumulative_participation_reward_amount;
            const percent = multiply(
                divide(cumulative, totalDistribution),
                100
            );
            setPercent(percent);
            setActive(state.is_active);
        }
    }, [props.summary, totalDistribution]);

    return (
        <div className={style.open}>
            {isActive ? (
                <div
                    className={cx(style.onsale, {
                        disabled: !leftLessThanRight(percent, 80),
                    })}
                >
                    {leftLessThanRight(percent, 80)
                        ? "ON SALE"
                        : "Total Distribution"}
                </div>
            ) : (
                <div />
            )}

            {leftLessThanRight(percent, 80) ? (
                // < 80%
                <div className={style.total_distribution}>
                    <div className={style.text1}>{"Total Distribution : "}</div>
                    <div className={style.text2}>
                        {Utils.getDecimal(totalDistribution, true) + " MAX"}
                    </div>
                </div>
            ) : (
                // >= 80%
                <div className={style.graph}>
                    <div className={style.info}>
                        <div className={style.text1}>
                            {Utils.getPercentFormat(divide(percent, 100)) + "%"}
                        </div>
                        <div className={style.text2}>
                            {Utils.getDecimal(totalDistribution, true) + " MAX"}
                        </div>
                    </div>

                    <div className={style.bar_container}>
                        <div
                            className={style.bar}
                            style={{ width: percent + "%" }}
                        />
                    </div>
                </div>
            )}

            <div className={style.block_left}>
                <div className={style.text1}>{"SALE PRICE"}</div>
                <div className={style.text2}>{price + " UST"}</div>
                <div className={style.text3}>{"(1 $Fury)"}</div>
            </div>
        </div>
    );
}
