import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { mypageCampaignInfo } from "../../libs/api/rest";
import { walletState } from "../../libs/contexts";
import TitleLabel from "../common/Labels/TitleLabel";
import { SymbolIcon3 } from "../common/SymbolIcon/SymbolIcon";
import style from "./MyRewardsCampaignItem.module.scss";
import * as Utils from "../../libs/utils";
import { PATHS } from "../../App";
import { Link } from "react-router-dom";
import EmptyView from "../common/EmptyView/EmptyView";
import { LinkButton } from "../common/NormalButton/NormalButton";

export default function MyRewardsCampaignItem(props: {
    title: string;
    list: string[];
    ipc: boolean;
    addTotalInfo: (
        campaign: string,
        claimed: string,
        claimable: string,
        locked: string
    ) => void;
}) {
    return (
        <div className={style.container}>
            {props.title === "IPC" ? (
                <TitleLabel
                    tooltip="List of Participated IPC"
                    text={props.title}
                    size={16}
                    color={"#fff"}
                    fontWeight={"light"}
                />
            ) : (
                <TitleLabel
                    tooltip="List of Participated Campaigns"
                    text={props.title}
                    size={16}
                    color={"#fff"}
                    fontWeight={"light"}
                />
            )}
            {!props.list.length ? (
                <div className={style.empty_box}>
                    <EmptyView title="Empty" />
                </div>
            ) : (
                <div className={style.box}>
                    <div className={style.title_container}>
                        <div className={style.dummy_margin} />
                        <div className={style.title_item}>
                            <TitleLabel
                                text={props.title}
                                size={12}
                                color={"#fff"}
                                opacity={0.5}
                                fontWeight={"light"}
                            />
                        </div>
                        <div className={style.title_item}>
                            <TitleLabel
                                text={"Claimed"}
                                size={12}
                                color={"#fff"}
                                opacity={0.5}
                                fontWeight={"light"}
                            />
                        </div>
                        <div className={style.title_item}>
                            <TitleLabel
                                text={"Locked"}
                                size={12}
                                color={"#fff"}
                                opacity={0.5}
                                fontWeight={"light"}
                            />
                            <div className={style.icon} />
                        </div>
                        <div className={style.title_item}>
                            <TitleLabel
                                text={"Claimable"}
                                size={12}
                                color={"#fff"}
                                opacity={0.5}
                                fontWeight={"light"}
                            />
                        </div>
                        <div className={style.button_dummy} />
                        <div className={style.dummy_margin} />
                    </div>
                    <div className={style.separator} />

                    {props.list.map((item, index) => {
                        return (
                            <Item
                                campaignsAddress={item}
                                ipc={props.ipc}
                                addTotalInfo={props.addTotalInfo}
                                isLast={index === props.list.length - 1}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
}

function Item(props: {
    campaignsAddress: string;
    ipc: boolean;
    addTotalInfo: (
        campaign: string,
        claimed: string,
        claimable: string,
        locked: string
    ) => void;
    isLast: boolean;
}) {
    const wallet = useRecoilValue(walletState);
    const link = PATHS.campaignsDetail + props.campaignsAddress;

    const [item, setItem] = useState<MyPageCampaignInfo | undefined>(undefined);

    useEffect(() => {
        if (wallet) {
            mypageCampaignInfo(props.campaignsAddress, wallet.terraAddress)
                .then((r) => {
                    setItem(r);
                    props.addTotalInfo(
                        props.campaignsAddress,
                        r.claimed.uusd,
                        r.claimable.uusd,
                        r.locked.uusd
                    );
                })
                .catch((e) => {});
        }
    }, [props.campaignsAddress, wallet]);

    return (
        <div className={style.item_container}>
            <div className={style.inner_container}>
                <div className={style.dummy_margin} />
                <div className={style.title_item}>
                    <div className={style.symbol_icon}>
                        {item ? (
                            <SymbolIcon3 denom={item.denom} isActive={true} />
                        ) : (
                            <div />
                        )}
                    </div>

                    <div className={style.title}>
                        <TitleLabel
                            text={item ? item.title : undefined}
                            size={12}
                            color={"#fff"}
                            fontWeight={"light"}
                            skeleton={{ width: 100 }}
                        />
                    </div>
                </div>
                <div className={style.title_item}>
                    <TitleLabel
                        text={
                            item
                                ? Utils.getDecimal(
                                      props.ipc
                                          ? item.claimed.tokenAmount
                                          : item.claimed.uusd,
                                      true,
                                      props.ipc ? item.claimed.decimal : 3
                                  )
                                : undefined
                        }
                        size={12}
                        color={"#fff"}
                        fontWeight={"light"}
                        symbol={item ? (props.ipc ? item.symbol : "UST") : ""}
                        skeleton={{ width: 100 }}
                    />
                </div>
                <div className={style.title_item}>
                    <TitleLabel
                        text={
                            item
                                ? Utils.getDecimal(
                                      props.ipc
                                          ? item.locked.tokenAmount
                                          : item.locked.uusd,
                                      true,
                                      props.ipc ? item.locked.decimal : 3
                                  )
                                : undefined
                        }
                        size={12}
                        color={"#f93660"}
                        fontWeight={"light"}
                        symbol={item ? (props.ipc ? item.symbol : "UST") : ""}
                        skeleton={{ width: 100 }}
                    />
                </div>
                <div className={style.title_item}>
                    <TitleLabel
                        text={
                            item
                                ? Utils.getDecimal(
                                      props.ipc
                                          ? item.claimable.tokenAmount
                                          : item.claimable.uusd,
                                      true,
                                      props.ipc ? item.claimable.decimal : 3
                                  )
                                : undefined
                        }
                        size={12}
                        color={"#ffe646"}
                        fontWeight={"light"}
                        symbol={item ? (props.ipc ? item.symbol : "UST") : ""}
                        skeleton={{ width: 100 }}
                    />
                </div>
                <LinkButton link={link} />
                <div className={style.dummy_margin} />
            </div>
            {!props.isLast ? <div className={style.row_line} /> : <div />}
        </div>
    );
}
