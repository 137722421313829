import { useEffect, useState } from "react";
import style from "./StakeLpCard.module.scss";
import {
    queryLpEstimateParams,
    stakeLpInfo,
    unstakeLpEstimate,
} from "../../libs/api/astroport_liquidity/query";
import * as Utils from "../../libs/utils";

import TitleLabel from "../common/Labels/TitleLabel";
import LineDummy from "../common/LineDummy/LineDummy";
import { ClaimButton, StakeButton } from "../common/NormalButton/NormalButton";
import { isZero, leftGreaterThanRight, multiply, plus } from "../../libs/Math";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../App";
import Skeleton from "react-loading-skeleton";
import { queryPrice, queryTokenBalance } from "../../libs/api/trade/query";
import { environment } from "../../libs/api/api";
import { useRecoilValue } from "recoil";
import { walletState } from "../../libs/contexts";
import classNames from "classnames/bind";
import { terraswapStakerInfo } from "../../libs/api/terraswap_liquidity/query";
const cx = classNames.bind(style);

export default function StakeLpCard(props: { claimPressed: () => void }) {
    const wallet = useRecoilValue(walletState);
    const history = useHistory();
    const [info, setInfo] = useState<
        | {
              apr: string;
              terraswapBalance: string;
              terraswapLpBalance: string;
              lpBalance: string;
              stakedAmount: string;
              pending: {
                  vkr: string;
                  astro: string;
              };
          }
        | undefined
    >(undefined);

    const [estimateLp, setEstimateLp] = useState("0");
    const [estimateStaked, setEstimateStaked] = useState("0");
    const symbol = "LP";

    useEffect(() => {
        load()
            .then((r) => {
                setEstimateLp(r.estimateLp);
                setEstimateStaked(r.estimateStaked);
                setInfo(r.info);
            })
            .catch((e) => {});
    }, [wallet]);

    async function load() {
        const info = await stakeLpInfo(
            wallet ? wallet.terraAddress : undefined
        );

        const terraswapLpBalance = wallet
            ? (
                  await queryTokenBalance(
                      environment().contracts.terraswap.lp,
                      wallet.terraAddress
                  )
              ).amount
            : "0";
        const terraswapStaked = wallet
            ? await terraswapStakerInfo(wallet.terraAddress)
            : undefined;
        let terraswapStakedAmount = "0";
        let terraswapStakedPending = "0";
        if (terraswapStaked) {
            terraswapStakedAmount = terraswapStaked.bond_amount;
            terraswapStakedPending = terraswapStaked.pending_reward;
        }

        const lpBalance = wallet
            ? (
                  await queryTokenBalance(
                      environment().contracts.astroport.lp,
                      wallet.terraAddress
                  )
              ).amount
            : "0";
        const price = await queryPrice(environment().contracts.token);
        const estimateParam = await queryLpEstimateParams();
        const estimated1 = await unstakeLpEstimate(
            //todo
            estimateParam.pools,
            lpBalance,
            estimateParam.lp_total_supply
        );
        const estimated2 = await unstakeLpEstimate(
            estimateParam.pools,
            info.staked,
            estimateParam.lp_total_supply
        );

        return {
            info: {
                apr: info.apr,
                terraswapBalance: plus(
                    terraswapStakedPending,
                    plus(terraswapLpBalance, terraswapStakedAmount)
                ),
                terraswapLpBalance: plus(
                    terraswapLpBalance,
                    terraswapStakedAmount
                ),
                lpBalance: lpBalance,
                stakedAmount: info.staked,
                pending: {
                    astro: info.pending, //todo
                    vkr: info.pending_on_proxy,
                },
            },
            estimateLp: plus(
                estimated1.uusd,
                multiply(estimated1.token, price)
            ),
            estimateStaked: plus(
                estimated2.uusd,
                multiply(estimated2.token, price)
            ),
        };
    }

    function balanceAvailable() {
        if (info) {
            return (
                leftGreaterThanRight(info.stakedAmount, 0) ||
                leftGreaterThanRight(info.lpBalance, 0)
            );
        } else {
            return false;
        }
    }

    function rewardAvailable() {
        if (info) {
            return (
                leftGreaterThanRight(info.pending.astro, 0) ||
                leftGreaterThanRight(info.pending.vkr, 0)
            );
        } else {
            return false;
        }
    }

    if (!info) {
        return (
            <div className={style.stake_info_container}>
                <div className={style.inner}>
                    <div className={style.not_info}>
                        <div className={style.title}>{"Liquidity"}</div>
                        <div className={style.info_wrapper}>
                            <div className={style.info}>
                                <Skeleton width={200} height={25} />
                            </div>
                        </div>
                        <div className={style.icon}>
                            <div className={style.icon1} />
                        </div>

                        <div className={style.info_line_container}>
                            <LineDummy
                                horizontal={true}
                                marginTop={0}
                                marginBottom={0}
                            />
                        </div>

                        <div className={style.stake_button}>
                            <Skeleton width={250} height={48} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={style.stake_info_container}>
            <div className={style.inner}>
                {leftGreaterThanRight(info.stakedAmount, 0) ? (
                    <div className={style.staked_container}>
                        <div className={style.icon}>
                            <div className={style.icon1} />
                        </div>
                        <div className={style.title_container}>
                            <div className={style.title}>{"Liquidity"}</div>
                            <div className={style.apr_container}>
                                <div className={style.apr}>{"APR"}</div>
                                <TitleLabel
                                    size={20}
                                    color={"#ffe646"}
                                    text={Utils.getPercentFormat(info.apr)}
                                    symbol={"%"}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={style.stake_container}>
                        <div className={style.title}>{"Liquidity"}</div>
                        <div className={style.apr_container}>
                            <div className={style.apr}>{"APR"}</div>
                            <TitleLabel
                                size={20}
                                color={"#ffe646"}
                                text={Utils.getPercentFormat(info.apr)}
                                symbol={"%"}
                            />
                        </div>
                        <div className={style.icon}>
                            <div className={style.icon1} />
                        </div>
                    </div>
                )}

                {balanceAvailable() ? (
                    <BalanceView
                        lpBalance={info.lpBalance}
                        estimateLp={estimateLp}
                        stakedAmount={info.stakedAmount}
                        estimateStaked={estimateStaked}
                        symbol={symbol}
                    />
                ) : (
                    <div />
                )}

                {rewardAvailable() ? (
                    <div className={style.reward_box}>
                        <div className={style.reward}>
                            <TitleLabel
                                text="Reward"
                                color={"rgba(255, 255, 255, 0.5)"}
                                fontWeight={"light"}
                            />
                            <div style={{ height: "8px" }} />
                            <div className={style.reward_content}>
                                <TitleLabel
                                    text={Utils.getDecimal(
                                        info.pending.vkr,
                                        true
                                    )}
                                    size={16}
                                    color={"#ffe646"}
                                    symbol={"VKR + "}
                                />
                                &nbsp;
                                <TitleLabel
                                    text={Utils.getDecimal(
                                        info.pending.astro,
                                        true
                                    )}
                                    size={16}
                                    color={"#ffe646"}
                                    symbol={"ASTRO"}
                                />
                            </div>
                        </div>

                        <div className={style.button}>
                            <ClaimButton
                                title={"Claim"}
                                disabled={
                                    isZero(info.pending.astro) &&
                                    isZero(info.pending.vkr)
                                }
                                textHighlight={
                                    isZero(info.pending.vkr) ||
                                    isZero(info.pending.astro)
                                }
                                selected={false}
                                onClick={props.claimPressed}
                            />
                        </div>
                    </div>
                ) : (
                    <div />
                )}

                <div
                    className={cx(style.line_container, {
                        staked:
                            leftGreaterThanRight(info.stakedAmount, 0) === true,
                    })}
                >
                    <LineDummy
                        horizontal={true}
                        marginTop={69}
                        marginBottom={0}
                    />
                </div>

                {leftGreaterThanRight(info.stakedAmount, 0) ||
                leftGreaterThanRight(info.lpBalance, 0) ? (
                    <div className={style.stake_buttons}>
                        <StakeButton
                            title={"Stake"}
                            isStaked={true}
                            onClick={() => {
                                history.push(PATHS.stakeLp);
                            }}
                        />
                        <div className={style.dummy} />
                        <StakeButton
                            title={"Unstake"}
                            isStaked={true}
                            onClick={() => {
                                history.push(PATHS.stakeLp + "#unstake");
                            }}
                        />
                    </div>
                ) : (
                    <div className={style.stake_button}>
                        <StakeButton
                            title={"Stake"}
                            isStaked={false}
                            onClick={() => {
                                history.push(PATHS.stakeLp);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

function BalanceView(props: {
    lpBalance: string;
    estimateLp: string;
    stakedAmount: string;
    estimateStaked: string;
    symbol: string;
}) {
    if (isZero(props.lpBalance)) {
        return (
            <div className={style.info_wrapper}>
                <div className={cx(style.info, { highlighted: false })}>
                    <div className={style.title}>{"My Staked Amount"}</div>
                    <div className={style.amount_container}>
                        <div className={style.icon} />
                        <TitleLabel
                            size={24}
                            text={Utils.getDecimal(props.stakedAmount, true)}
                            color={"white"}
                            symbol={props.symbol}
                        />
                    </div>

                    <div style={{ height: "2px" }} />
                    <TitleLabel
                        size={12}
                        text={Utils.getDecimal(props.estimateStaked, true, 3)}
                        color={"rgba(255, 255, 255, 0.3)"}
                        symbol={"UST"}
                        fontWeight={"light"}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div className={style.info_wrapper}>
                <div className={cx(style.info, { highlighted: true })}>
                    <div className={style.title}>{"Stakable Amount"}</div>
                    <TitleLabel
                        size={24}
                        text={Utils.getDecimal(props.lpBalance, true)}
                        color={"white"}
                        symbol={props.symbol}
                    />
                    <div style={{ height: "4px" }} />
                    <TitleLabel
                        size={12}
                        text={Utils.getDecimal(props.estimateLp, true, 3)}
                        color={"#5a5a5c"}
                        symbol={"UST"}
                    />
                </div>
                <div className={style.dummy} />
                <div className={cx(style.info, { highlighted: true })}>
                    <div className={style.title}>{"Staked Amount"}</div>
                    <TitleLabel
                        size={24}
                        text={Utils.getDecimal(props.stakedAmount, true)}
                        color={"white"}
                        symbol={props.symbol}
                    />
                    <div style={{ height: "4px" }} />
                    <TitleLabel
                        size={12}
                        text={Utils.getDecimal(props.estimateStaked, true, 3)}
                        color={"#5a5a5c"}
                        symbol={"UST"}
                    />
                </div>
            </div>
        );
    }
}
