import { useState } from "react";
import style from "./ProducerSettingRegisterQualifier.module.scss";
import { updateQualification } from "../../../../libs/api/campaign/execution";
import { addressValidate, getPublicKey } from "../../../../libs/api/api";
import { PATHS } from "../../../../App";
import TxBoxView from "../../../common/TxBoxView/TxBoxView";
import PageHeader from "../../../common/PageHeader/PageHeader";
import { useEffect } from "react";

import { useConnectedWallet, useWallet } from "@terra-money/wallet-provider";
import ProducerQualification from "../../Create/ProducerQualification";
import ProducerSettingFormFooter from "./ProducerSettingFormFooter";
import { walletState } from "../../../../libs/contexts";
import { useRecoilValue } from "recoil";
import { txQualifierCampaign } from "../../../../libs/api/campaign/qualification/query";
import { registerCampaign } from "../../../../libs/api/campaign/qualification/execution";
import InputField from "../../../common/InputField/InputField";
import { campaignSummary } from "../../../../libs/api/campaign/query";
import { useHistory } from "react-router-dom";

export default function ProducerSettingRegisterQualifier(props: any) {
    const { post } = useWallet();
    const history = useHistory();

    const wallet = useRecoilValue(walletState);
    const [campaignAddress, setCampaignAddress] = useState("");
    const [summary, setSummary] = useState<CampaignSummary | undefined>(
        undefined
    );

    const [registeredInfo, setRegisteredInfo] = useState<
        ResponseTxQualifierCampaign | undefined
    >(undefined);

    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    const [publicKey, setPublicKey] = useState("");
    const [creatorPublicKey, setCreatorPublicKey] = useState("");

    useEffect(() => {
        const state: any = props.history.location.state;

        if (!state || !state.summary || !state.campaignAddress) {
            props.history.replace(PATHS.producer);
            return;
        }

        setCampaignAddress(state.campaignAddress);
        setSummary(state.summary);
    }, [props.history]);

    useEffect(() => {
        txQualifierCampaign(campaignAddress)
            .then((r) => {
                console.log(r);
                setRegisteredInfo(r);
            })
            .catch((e) => {});
    }, [campaignAddress]);

    useEffect(() => {
        if (summary && wallet) {
            getPublicKey(summary.config.creator)
                .then((r) => {
                    setCreatorPublicKey(r);
                })
                .catch((e) => {});

            getPublicKey(wallet.terraAddress)
                .then((r) => {
                    setPublicKey(r);
                })
                .catch((e) => {});
        }
    }, [summary, wallet]);

    useEffect(() => {
        if (registeredInfo) {
            history.replace(PATHS.producerDetail + campaignAddress);
        }
    }, [registeredInfo]);

    function savePressed() {
        setHash({
            type: "ProducerRegisterQualifier",
            isPending: true,
        });

        registerCampaign(wallet!.terraAddress, campaignAddress)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "ProducerRegisterQualifier",
                    response: response,
                    redirect: PATHS.producerDetail + campaignAddress,
                });
            })
            .catch((error) => {
                setHash({
                    type: "ProducerRegisterQualifier",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={style.container}>
            <PageHeader title="Register campaign to qualifier" docs="" />

            <div className={style.campaign_title}>
                <div className={style.title1}>{"Campaign:"}</div>
                &nbsp;
                <div className={style.title2}>
                    {summary ? summary.config.title : ""}
                </div>
            </div>

            <TxBoxView
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
                transparent={true}
            >
                <div className={style.inner}>
                    <InputField
                        title="User Address"
                        type="text"
                        value={wallet ? wallet.terraAddress : ""}
                        onChanged={(v) => {}}
                        disabled={true}
                    />

                    <InputField
                        title="User Public Key"
                        type="text"
                        value={creatorPublicKey}
                        onChanged={(v) => {}}
                        disabled={true}
                    />

                    <InputField
                        title="Creator Public Key"
                        type="text"
                        value={publicKey}
                        onChanged={(v) => {}}
                        disabled={true}
                        error={
                            creatorPublicKey !== publicKey
                                ? "Mismatched Public Key"
                                : undefined
                        }
                    />

                    <div style={{ height: "40px" }} />

                    <ProducerSettingFormFooter
                        enable={
                            creatorPublicKey !== "" &&
                            creatorPublicKey === publicKey
                        }
                        pressed={savePressed}
                    />
                </div>
            </TxBoxView>
        </div>
    );
}
