import style from "./EmptyView3.module.scss";

export default function EmptyView3(props: { title: string }) {
    return (
        <div className={style.container}>
            <div className={style.icon} />
            <div className={style.text}>{props.title}</div>
        </div>
    );
}
