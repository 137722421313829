import BigNumber from "bignumber.js";
import moment from "moment";
import {
    divide,
    fixed,
    isNumber,
    leftGreaterThanRight,
    multiply,
} from "./Math";
import { environment } from "./api/api";

export function splitAmountByDot(
    amount: string,
    decimal = 6
): AmountSplitModel {
    const n = getDecimal(amount, true, decimal).split(".");

    return {
        num1: n[0],
        num2: n.length > 1 ? n[1] : undefined,
    };
}

export function amountDivide(amount: string): {
    amount: string;
    denom: string;
} {
    let index = -1;
    for (let i = 0; i < amount.length; i++) {
        const c = amount[i];
        if (isNaN(parseInt(c))) {
            index = i;
            break;
        }
    }

    return {
        amount: amount.substring(0, index),
        denom: amount.substring(index),
    };
}

export function getDecimal(n: string, comma: boolean = false, decimal = 6) {
    let d = 1;
    for (let i = 0; i < Math.max(decimal, 6); i++) {
        d *= 10;
    }

    const result = new BigNumber(fixed(divide(n, d), decimal)).toString();

    if (comma) {
        return new BigNumber(result).toFormat();
    } else {
        return result;
    }
}

export function getFormat(n: string) {
    let denom = "";

    let result = new BigNumber(0);
    const r = new BigNumber(divide(n, 1_000_000));

    if (r.isGreaterThanOrEqualTo(1_000_000_000_000)) {
        result = r.dividedBy(1_000_000_000_000);
        denom = "T";
    } else if (r.isGreaterThanOrEqualTo(1_000_000_000)) {
        result = r.dividedBy(1_000_000_000);
        denom = "B";
    } else if (r.isGreaterThanOrEqualTo(1_000_000)) {
        result = r.dividedBy(1_000_000);
        denom = "M";
    } else if (r.isGreaterThanOrEqualTo(1_000)) {
        result = r.dividedBy(1_000);
        denom = "K";
    } else {
        result = r;
    }

    return new BigNumber(result.toFixed(2, 1)).toFormat() + denom;
}

export function getPercentFormat(n: string, showPlus: boolean = false) {
    //input 0~0.1

    const value = new BigNumber(new BigNumber(multiply(n, 100)).toFixed(2, 1));

    if (value.isNaN()) {
        return "0";
    }

    if (showPlus && leftGreaterThanRight(n, 0)) {
        return "+" + value.toFormat();
    } else {
        return value.toFormat();
    }
}

export function dateFormat1(time: number) {
    return moment(time).format("YYYY.MM.DD");
}

export function dateFormat2(time: number) {
    return moment(time).format("YYYY.MM.DD HH:mm");
}

export function dateFormat5(time: number) {
    return moment(time / 1000).format("MMM DD, hh:mm A");
}
export function dateFormat6(time: number) {
    return moment(time).format("MMM DD, YYYY, hh:mm:ss A");
}
export function dateFormat7(time: number) {
    return moment(time).format("MMM.DD. HH:mm");
}

export function dateFormat8(time: number) {
    return moment(time).format("MMM.DD.YY");
}

export function dateFormat9(time: number) {
    return moment(time).format("DD");
}

export function dateFormat10(time: number) {
    return moment(time).format("MMM Do").toUpperCase();
}

export function dateFormat11(time: number) {
    return moment(time).utc().format("MMM Do UTC HH:mm").toUpperCase();
}

export function showUrl(address: string) {
    window.open(address, "_blank");
}

export function showFinderTx(tx: string) {
    window.open(environment().finder.tx + tx, "_blank");
}

export function showFinderAccount(address: string) {
    window.open(environment().finder.account + address, "_blank");
}

export function isNativeToken(denom: string) {
    return denom.startsWith("u");
}

export function calcLeftDays(endTime: number): number {
    const endDay =
        moment(endTime).format("YYYY-MM-DD") + "T00:00:00.000000000Z";
    const today =
        moment(new Date().getTime()).format("YYYY-MM-DD") +
        "T00:00:00.000000000Z";
    const e = Date.parse(endDay);
    const t = Date.parse(today);

    const remainDays = Math.max(e - t, 0) / (60 * 60 * 24 * 1000);
    return remainDays;
}

export function copyString(s: string) {
    navigator.clipboard.writeText(s);
}

export function inputFieldValidate(
    v: string,
    decimal: number
): string | undefined {
    let value = v;
    let dot = 0;
    for (let i = 0; i < value.length; i++) {
        const c = value.charAt(i);
        if (c === ".") {
            dot += 1;
        }
    }

    if (dot > 1) {
        return undefined;
    }

    const n2 = value.replaceAll(".", "");
    if (!isNumber(n2)) {
        return undefined;
    }

    let splits = value.split(".");
    if (splits.length === 2 && splits[1].length > decimal) {
        splits[1] = splits[1].substring(0, decimal);
        value = splits[0] + "." + splits[1];
    }

    return value;
}
