import { useState } from "react";
import style from "./ProducerSettingGeneral.module.scss";
import { PATHS } from "../../../../App";
import TxBoxView from "../../../common/TxBoxView/TxBoxView";
import PageHeader from "../../../common/PageHeader/PageHeader";

import { useEffect } from "react";

import { useWallet } from "@terra-money/wallet-provider";
import InputField from "../../../common/InputField/InputField";
import InputFieldMulti from "../../../common/InputField/InputFieldMulti";
import { updateCampaignInfo } from "../../../../libs/api/campaign/execution";
import { divide, isZero, multiply } from "../../../../libs/Math";
import TitleLabel from "../../../common/Labels/TitleLabel";
import ProducerSettingFormFooter from "./ProducerSettingFormFooter";
import InputFieldDeposit from "../../../common/InputField/InputFieldDeposit";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../libs/contexts";

export default function ProducerSettingGeneral(props: any) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);
    const [campaignAddress, setCampaignAddress] = useState("");
    const [summary, setSummary] = useState<CampaignSummary | undefined>(
        undefined
    );

    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    //change description
    const [title, setTitle] = useState("");
    const [url, setUrl] = useState("");
    const [desc, setDesc] = useState("");
    const [parameterKey, setParameterKey] = useState("");

    const [depositOn, setDepositOn] = useState(false);
    const [deposit, setDeposit] = useState("");
    const [depositUnlockPeriodDay, setDepositUnlockPeriodDay] = useState("");

    const [pReward, setPReward] = useState("");
    const [reward0, setReward0] = useState("");
    const [reward1, setReward1] = useState("");

    const [pRewardLockPeriodDay, setPRewardLockPeriodDay] = useState("");
    const [rRewardLockPeriodDay, setRRewardLockPeriodDay] = useState("");

    const [valid, setValid] = useState(false);

    useEffect(() => {
        const state: any = props.history.location.state;

        if (!state || !state.summary || !state.campaignAddress) {
            props.history.replace(PATHS.producer);
            return;
        }
        setCampaignAddress(state.campaignAddress);
        setSummary(state.summary);
    }, [props.history]);

    useEffect(() => {
        if (summary && summary.state.is_pending) {
            if (
                desc &&
                url &&
                title &&
                pReward &&
                reward0 &&
                reward1 &&
                parameterKey
            ) {
                setValid(true);
            } else {
                setValid(false);
            }
        } else {
            if (desc && title) {
                setValid(true);
            } else {
                setValid(false);
            }
        }
    }, [
        summary,
        title,
        parameterKey,
        desc,
        url,
        deposit,
        pReward,
        reward0,
        reward1,
    ]);

    useEffect(() => {
        if (summary) {
            const config = summary.config;

            setTitle(config.title);
            if (summary.state.is_pending) {
                setUrl(config.url);
            }
            setPRewardLockPeriodDay(
                summary.reward.participation.lock_day.toString()
            );
            setRRewardLockPeriodDay(
                summary.reward.referral.lock_day.toString()
            );
            setDesc(config.description);
            setParameterKey(config.parameter_key);
            setDepositUnlockPeriodDay(
                config.deposit_lock_period_day.toString()
            );

            const c = divide(config.deposit.amount, 1000000);

            if (isZero(c)) {
                setDepositOn(false);
            } else {
                setDepositOn(true);
            }
            setDeposit(divide(config.deposit.amount, 1000000));

            const reward = summary.reward;
            setPReward(divide(reward.participation.amount, 1000000));

            setReward0(divide(reward.referral.amounts[0], 1000000));
            setReward1(divide(reward.referral.amounts[1], 1000000));
        }
    }, [summary]);

    useEffect(() => {
        if (!depositOn) {
            setDeposit("");
            setDepositUnlockPeriodDay("");
        }
    }, [depositOn]);

    function savePressed() {
        setHash({
            type: "ChangeCampaignDesc",
            isPending: true,
        });

        updateCampaignInfo(
            wallet!.terraAddress,
            summary && summary.state.is_pending ? true : false,
            campaignAddress,
            title,
            desc,
            url,
            parameterKey,
            depositOn && deposit ? multiply(deposit, 1000000) : undefined,
            depositOn && depositUnlockPeriodDay
                ? depositUnlockPeriodDay
                : undefined,
            pReward ? multiply(pReward, 1000000) : undefined,
            reward0 && reward1
                ? [multiply(reward0, 1000000), multiply(reward1, 1000000)]
                : undefined,
            pRewardLockPeriodDay ? pRewardLockPeriodDay : undefined,
            rRewardLockPeriodDay ? rRewardLockPeriodDay : undefined
        )
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                //todo parse
                setHash({
                    type: "ChangeCampaignDesc",
                    response: response,
                    redirect: PATHS.producerDetail + campaignAddress,
                });
            })
            .catch((error) => {
                setHash({
                    type: "ChangeCampaignDesc",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    if (!summary) {
        return <div />;
    }

    return (
        <div className={style.container}>
            <PageHeader title="Set Campaign" docs="" />

            <div className={style.campaign_title}>
                <div className={style.title1}>{"Campaign:"}</div>
                &nbsp;
                <div className={style.title2}>{summary.config.title}</div>
            </div>

            <TxBoxView
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
                transparent={true}
            >
                <div className={style.inner}>
                    <InputField
                        title="Title"
                        type="text"
                        tooltip="Title of Campaign"
                        value={title}
                        onChanged={setTitle}
                    />
                    {summary.state.is_pending ? (
                        <InputField
                            title="Site URL"
                            type="text"
                            tooltip={"Campaign Page URL"}
                            value={url}
                            onChanged={setUrl}
                        />
                    ) : (
                        <div />
                    )}

                    {summary.state.is_pending ? (
                        <InputField
                            title="Parameter Key"
                            type="text"
                            tooltip={
                                "Query parameter key in Campaign Site URL for referral"
                            }
                            value={parameterKey}
                            onChanged={setParameterKey}
                        />
                    ) : (
                        <div />
                    )}

                    <InputFieldMulti
                        size={14}
                        title="Description"
                        placeholder={""}
                        tooltip={
                            "Campaign introduction, user manual, etc. that are made by the creator"
                        }
                        value={desc}
                        onChanged={setDesc}
                    />

                    {summary.state.is_pending ? (
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <div style={{ marginTop: "40px" }} />
                            <TitleLabel
                                size={20}
                                text="Reward Setting"
                                color={"white"}
                                fontWeight="light"
                            />

                            <div style={{ marginTop: "20px" }} />

                            <InputField
                                title="Participation Reward"
                                type={"amount"}
                                value={pReward}
                                decimal={
                                    summary.reward.participation.denom ===
                                    "uusd"
                                        ? 3
                                        : 6
                                }
                                symbol={summary.reward.participation.symbol}
                                tooltip={
                                    "The amount distributed upon participation is provided as a reward token"
                                }
                                onChanged={(v) => {
                                    setPReward(v);
                                    // setPReward(Utils.getD v);
                                }}
                            />

                            <InputField
                                title={""}
                                type={"amount"}
                                decimal={6}
                                value={pRewardLockPeriodDay}
                                symbol={"Days"}
                                placeholder={"Unlock Period"}
                                onChanged={(v) => {
                                    setPRewardLockPeriodDay(
                                        v.replaceAll(".", "")
                                    );
                                }}
                            />

                            <div style={{ marginTop: "10px" }} />

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: 1,
                                }}
                            >
                                <TitleLabel
                                    tooltip={
                                        "This is the scheme that upon successful referrals (up to 2 degrees), rewards will be distributed to the referral link owner"
                                    }
                                    text={"Sharing Reward Scheme"}
                                    color={"#786f64"}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <InputField
                                        title=""
                                        type="amount"
                                        value={reward0}
                                        symbol={"VKR"}
                                        onChanged={setReward0}
                                    />
                                    <div
                                        style={{
                                            width: "13px",
                                            height: "1px",
                                            marginLeft: "8px",
                                            marginRight: "8px",
                                            marginBottom: "20px",
                                            backgroundColor: "white",
                                            opacity: 0.3,
                                        }}
                                    />
                                    <InputField
                                        title=""
                                        type="amount"
                                        value={reward1}
                                        symbol={"VKR"}
                                        onChanged={setReward1}
                                    />
                                </div>
                            </div>
                            <InputField
                                title={""}
                                type={"amount"}
                                decimal={6}
                                value={rRewardLockPeriodDay}
                                symbol={"Days"}
                                placeholder={"Unlock Period"}
                                onChanged={(v) => {
                                    setRRewardLockPeriodDay(
                                        v.replaceAll(".", "")
                                    );
                                }}
                            />
                        </div>
                    ) : (
                        <div />
                    )}

                    <InputFieldDeposit
                        depositOn={depositOn}
                        setDepositOn={setDepositOn}
                        deposit={deposit}
                        setDeposit={setDeposit}
                        depositUnlockPeriodDay={depositUnlockPeriodDay}
                        setDepositUnlockPeriodDay={setDepositUnlockPeriodDay}
                    />

                    <div style={{ height: "40px" }} />

                    <ProducerSettingFormFooter
                        enable={valid}
                        pressed={savePressed}
                    />
                </div>
            </TxBoxView>
        </div>
    );
}
