import {
    multiply,
    plus,
} from "../../Math";
import { environment, wasmQuery } from "../api";

import { queryBalances, queryPrice } from "../trade/query";
import { govState } from "../governance/query";
import * as astroport from "../astroport_liquidity/query";

export async function myTotalValue(
    address: string | undefined
): Promise<MyPageValue> {
    const vkrPrice = await queryPrice(environment().contracts.token);
    const balances = await queryBalances(address ? address : "");

    const gov = await govState(address);
    const govUusd = multiply(gov.staked, vkrPrice);

    const lp = await astroport.stakeLpInfo(address);
    const pools = await astroport.queryLpEstimateParams();
    const lpInfo = await astroport.unstakeLpEstimate(
        pools.pools,
        lp.staked,
        pools.lp_total_supply
    );
    const lpUusd = plus(lpInfo.uusd, multiply(lpInfo.token, vkrPrice));
    const holdingUusd = multiply(balances.token, vkrPrice);

    const total = plus(plus(plus(balances.uusd, holdingUusd), govUusd), lpUusd);

    return {
        total: total,
        balance: {
            token: balances.token,
            tokenUusd: holdingUusd,
            uusd: balances.uusd,
        },
        lp: {
            amount: lp.staked,
            uusd: lpUusd,
        },
        gov: {
            amount: gov.staked,
            uusd: govUusd,
        },
    };
}
