import style from "./AirdropItem.module.scss";
import * as Utils from "../../libs/utils";

import TitleLabel from "../common/Labels/TitleLabel";
import { NormalButton } from "../common/NormalButton/NormalButton";

export default function AirdropItem(props: {
    item: AirdropItem;
    symbol: string;
    pressed: (item: AirdropItem) => void;
    isLast: boolean;
}) {
    return (
        <div className={style.container}>
            <div className={style.row}>
                <div className={style.content}>
                    <TitleLabel
                        size={16}
                        color={"#c8c8c8"}
                        text={Utils.getDecimal(
                            props.item.airdropAmount,
                            true,
                            6
                        )}
                        symbol={props.symbol}
                        fontWeight="light"
                    />
                </div>

                <div className={style.button}>
                    <NormalButton
                        title={"Claim"}
                        onClick={() => {
                            props.pressed(props.item);
                        }}
                    />
                </div>
            </div>
            {!props.isLast ? (
                <div className={style.dummy}>
                    <div className={style.dummy1} />
                    <div className={style.dummy2} />
                </div>
            ) : (
                <div />
            )}
        </div>
    );
}
