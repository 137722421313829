import style from "./SymbolIcon.module.scss";

import defaultIcon from "../../../resources/symbols/logo_default.gif";
import vkrIcon from "../../../resources/symbols/vkr.svg";
import lunaIcon from "../../../resources/symbols/luna.svg";
import ustIcon from "../../../resources/symbols/ust.svg";
import lunartIcon from "../../../resources/logod-arts-main2.svg";
import { useEffect } from "react";
import { useState } from "react";
import { environment, findSymbolIcon } from "../../../libs/api/api";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function SymbolIcon(props: {
    denom?: string;
    isActive: boolean | undefined;
}) {
    const [icon, setIcon] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (!props.denom) {
            setIcon(defaultIcon);
        } else if (props.denom === environment().contracts.token) {
            setIcon(vkrIcon);
        } else if (props.denom === "uusd") {
            setIcon(ustIcon);
        } else if (props.denom === "ukrw") {
            setIcon(defaultIcon);
        } else if (props.denom === "uluna") {
            setIcon(lunaIcon);
        } else {
            findSymbolIcon(props.denom)
                .then((icon) => {
                    if (icon) {
                        setIcon(icon);
                    } else {
                        setIcon(defaultIcon);
                    }
                })
                .catch((e) => {
                    setIcon(defaultIcon);
                });
        }
    }, [props.denom]);

    return (
        <div className={style.container}>
            {props.isActive ? (
                <div>
                    {icon ? (
                        <div
                            className={cx(style.icon, {
                                pylon:
                                    props.denom ===
                                    "terra1kcthelkax4j9x8d3ny6sdag0qmxxynl3qtcrpy",
                            })}
                        >
                            <img className={style.icon_ico} src={icon} alt="" />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            ) : (
                <div>
                    <div className={style.activefalse}>
                        <div className={style.closed}>{"CLOSED"}</div>
                    </div>
                    {icon ? (
                        <div
                            className={cx(style.icon, {
                                pylon:
                                    props.denom ===
                                    "terra1kcthelkax4j9x8d3ny6sdag0qmxxynl3qtcrpy",
                            })}
                        >
                            <img className={style.icon_ico} src={icon} alt="" />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            )}
        </div>
    );
}

// for campaignmain mobile size
export function SymbolIcon2(props: {
    denom?: string;
    isActive: boolean | undefined;
}) {
    const [icon, setIcon] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (!props.denom) {
            setIcon(defaultIcon);
        } else if (props.denom === environment().contracts.token) {
            setIcon(vkrIcon);
        } else if (props.denom === "uusd") {
            setIcon(ustIcon);
        } else if (props.denom === "ukrw") {
            setIcon(defaultIcon);
        } else if (props.denom === "uluna") {
            setIcon(lunaIcon);
        } else {
            findSymbolIcon(props.denom)
                .then((icon) => {
                    if (icon) {
                        setIcon(icon);
                    } else {
                        setIcon(defaultIcon);
                    }
                })
                .catch((e) => {
                    setIcon(defaultIcon);
                });
        }
    }, [props.denom]);

    return (
        <div className={style.container2}>
            {props.isActive ? (
                <div>
                    {icon ? (
                        <div
                            className={cx(style.icon, {
                                pylon:
                                    props.denom ===
                                    "terra1kcthelkax4j9x8d3ny6sdag0qmxxynl3qtcrpy",
                            })}
                        >
                            <img className={style.icon_ico} src={icon} alt="" />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            ) : (
                <div>
                    <div className={style.activefalse}>
                        <div className={style.closed}>{"CLOSED"}</div>
                    </div>
                    {icon ? (
                        <div
                            className={cx(style.icon, {
                                pylon:
                                    props.denom ===
                                    "terra1kcthelkax4j9x8d3ny6sdag0qmxxynl3qtcrpy",
                            })}
                        >
                            <img className={style.icon_ico} src={icon} alt="" />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            )}
        </div>
    );
}
export function SymbolIcon3(props: {
    denom?: string;
    isActive: boolean | undefined;
}) {
    const [icon, setIcon] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (!props.denom) {
            setIcon(defaultIcon);
        } else if (props.denom === environment().contracts.token) {
            setIcon(vkrIcon);
        } else if (props.denom === "uusd") {
            setIcon(ustIcon);
        } else if (props.denom === "ukrw") {
            setIcon(defaultIcon);
        } else if (props.denom === "uluna") {
            setIcon(lunaIcon);
        } else if (props.denom === "terra1g0pm8xm5c2dq4qtv8j9a80hg4mhe5ndy8qad07"
            || props.denom === "terra19f22ldtkhx7gse5l5qzncv84w6rkfrgl3vegq7") {
            setIcon(lunartIcon);
        } else {
            findSymbolIcon(props.denom)
                .then((icon) => {
                    if (icon) {
                        setIcon(icon);
                    } else {
                        setIcon(defaultIcon);
                    }
                })
                .catch((e) => {
                    setIcon(defaultIcon);
                });
        }
    }, [props.denom]);

    return (
        <div className={style.container3}>
            {props.isActive ? (
                <div>
                    {icon ? (
                        <div
                            className={cx(style.icon, {
                                pylon:
                                    props.denom ===
                                    "terra1kcthelkax4j9x8d3ny6sdag0qmxxynl3qtcrpy",
                                lunart:
                                    props.denom ===
                                    "terra19f22ldtkhx7gse5l5qzncv84w6rkfrgl3vegq7"
                                    || props.denom ===
                                    "terra1g0pm8xm5c2dq4qtv8j9a80hg4mhe5ndy8qad07",
                            })}
                        >
                            <img className={cx(style.icon_ico, {
                                lunart:
                                    props.denom ===
                                    "terra19f22ldtkhx7gse5l5qzncv84w6rkfrgl3vegq7"
                                    || props.denom ===
                                    "terra1g0pm8xm5c2dq4qtv8j9a80hg4mhe5ndy8qad07",
                            })} src={icon} alt="" />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            ) : (
                <div>
                    <div className={style.activefalse}>
                        <div className={style.closed}>{"CLOSED"}</div>
                    </div>
                    {icon ? (
                        <div
                            className={cx(style.icon, {
                                pylon:
                                    props.denom ===
                                    "terra1kcthelkax4j9x8d3ny6sdag0qmxxynl3qtcrpy",
                            })}
                        >
                            <img className={style.icon_ico} src={icon} alt="" />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            )}
        </div>
    );
}
