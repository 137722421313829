import { Coins } from "@terra-money/terra.js";
import { fixed, isZero } from "../../../Math";
import { getPublicKey, environment, wasmExecutes } from "../../api";
import { campaignSummary } from "../query";
import { getReferrer } from "./query";

export async function registerCampaign(address: string, campaign: string) {
    const publicKey = await getPublicKey(address);

    const exe: WasmExecute = {
        contract: environment().contracts.tx_qualifier,
        msg: {
            register: {
                campaign: campaign,
                public_key: publicKey,
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe]);
}

export async function participateTxCampaign(
    txHash: string,
    actor: string,
    referrer: string | undefined,
    campaign: string,
    signature: string,
    rule_id: string
): Promise<any> {
    const summary = await campaignSummary(campaign, actor);

    let decompressedReferrer;
    if (referrer && !referrer.toLowerCase().startsWith("terra1")) {
        //referrer is compressed.
        decompressedReferrer = (await getReferrer(referrer)).address;
    } else {
        decompressedReferrer = referrer;
    }

    let exes: WasmExecute[] = [];
    exes.push({
        contract: environment().contracts.tx_qualifier,
        msg: {
            register_signature: {
                campaign: campaign,
                actor: actor,
                txhash: txHash,
                signature: signature,
                rule_id: rule_id,
            },
        },
        coin: undefined,
    });

    if (!isZero(summary.config.deposit.amount)) {
        exes.push({
            contract: environment().contracts.token,
            msg: {
                send: {
                    contract: campaign,
                    amount: summary.config.deposit.amount,
                    msg: btoa(
                        JSON.stringify({
                            deposit: {},
                        })
                    ),
                },
            },
            coin: undefined,
        });
    }

    if (!isZero(summary.config.vp_burn_amount)) {
        exes.push({
            contract: environment().contracts.vp_token,
            msg: {
                increase_allowance: {
                    spender: campaign,
                    amount: summary.config.vp_burn_amount,
                },
            },
            coin: undefined,
        });
    }

    exes.push({
        contract: campaign,
        msg: {
            participate: {
                actor: actor,
                referrer: decompressedReferrer
                    ? {
                          address: decompressedReferrer,
                      }
                    : undefined,
            },
        },
        coin: undefined,
    });

    return await wasmExecutes(actor, exes);
}

export async function joinCampaignV2(
    campaign: string,
    qualifier: string,
    address: string,
    uusdAmount: string,
    txHash: string,
    signature: string,
    rule_id: string,
    referrer?: string
) {
    const summary = await campaignSummary(campaign, address);

    let decompressedReferrer;
    if (referrer && !referrer.toLowerCase().startsWith("terra1")) {
        //referrer is compressed.
        decompressedReferrer = (await getReferrer(referrer)).address;
    } else {
        decompressedReferrer = referrer;
    }

    const exes: WasmExecute[] = [];

    exes.push({
        contract: qualifier,
        msg: {
            register_signature: {
                actor: address,
                txhash: txHash,
                signature: signature,
                rule_id: rule_id,
            },
        },
        coin: undefined,
    });

    exes.push({
        contract: qualifier,
        msg: {
            prepare: {},
        },
        coin: new Coins(uusdAmount) + "uusd",
    });

    if (!isZero(summary.config.vp_burn_amount)) {
        exes.push({
            contract: environment().contracts.vp_token,
            msg: {
                increase_allowance: {
                    spender: campaign,
                    amount: summary.config.vp_burn_amount,
                },
            },
            coin: undefined,
        });
    }

    if (!isZero(summary.config.deposit.amount)) {
        exes.push({
            contract: environment().contracts.token,
            msg: {
                send: {
                    contract: campaign,
                    amount: summary.config.deposit.amount,
                    msg: btoa(
                        JSON.stringify({
                            deposit: {},
                        })
                    ),
                },
            },
            coin: undefined,
        });
    }

    exes.push({
        contract: campaign,
        msg: {
            participate: {
                actor: address,
                referrer: decompressedReferrer
                    ? {
                          address: decompressedReferrer,
                      }
                    : undefined,
            },
        },
        coin: undefined,
    });

    return await wasmExecutes(address, exes);
}
