import InputField from "../../common/InputField/InputField";
import { useState } from "react";
import GovCreateFormFooter from "./GovCreateFormFooter";
import { useEffect } from "react";
import GovCreateFormHeader from "./GovCreateFormHeader";
import * as Utils from "../../../libs/utils";
import { poll6 } from "../../../libs/api/governance/execution";
import { PATHS } from "../../../App";
import { useWallet } from "@terra-money/wallet-provider";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";

export default function GovCreateForm6(props: {
    setHash: (h: HashResult) => void;
    deposit: string;
}) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);

    const [headerInfo, setHeaderInfo] = useState({
        title: "",
        desc: "",
        link: "",
    });
    const [headerValid, setHeaderValid] = useState(false);

    const [valid, setValid] = useState(false);
    useEffect(() => {
        if (headerValid) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [headerValid]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <GovCreateFormHeader
                setInfo={setHeaderInfo}
                setValid={setHeaderValid}
            />

            <InputField
                title="Proposal Deposit"
                type="amount"
                disabled={true}
                tooltip={
                    "Proposed minimum VKR deposit required for submitting a new poll"
                }
                symbol={"VKR"}
                value={Utils.getDecimal(props.deposit, true)}
                onChanged={(v) => {}}
            />

            <GovCreateFormFooter
                enable={valid}
                pressed={() => {
                    props.setHash({
                        type: "GovPoll6",
                        isPending: true,
                    });

                    poll6(wallet!.terraAddress, headerInfo)
                        .then((r) => {
                            return post(r);
                        })
                        .then((response) => {
                            props.setHash({
                                type: "GovPoll6",
                                response: response,
                                redirect: PATHS.governance,
                            });
                        })
                        .catch((error) => {
                            props.setHash({
                                type: "GovPoll6",
                                error: error,
                                redirect: undefined,
                            });
                        });
                }}
            />
        </div>
    );
}
