import { isZero } from "../../../../../libs/Math";
import { getDecimal } from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./FanfuryInfoPool.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(style);

export default function FanfuryInfoPool(props: { summary: CampaignSummary }) {
    const state = props.summary.state;
    const reward = props.summary.reward;

    const pFiltered = state.pool_balances.filter((item) => {
        return item.denom === reward.participation.denom;
    });
    const pReward = pFiltered.length > 0 ? pFiltered[0].amount : "0";
    const pSymbol = reward.participation.symbol;
    const pDenom = reward.participation.denom;
    const pDecimals = reward.participation.decimals;

    const rFiltered = state.pool_balances.filter((item) => {
        return item.denom === reward.referral.denom;
    });
    const rReward = rFiltered.length > 0 ? rFiltered[0].amount : "0";
    const rSymbol = reward.referral.symbol;
    const rDenom = reward.referral.denom;

    return (
        <div className={style.pool}>
            {props.summary.state.is_active === true ? (
                <div className={style.wavewrapper}>
                    <div className={style.wave}></div>
                    <div className={style.wave}></div>
                </div>
            ) : (
                <div />
            )}
            <div className={style.pooltitle}>
                <div className={style.pooltitle_ico} />
                <div className={style.pooltitle_text}>
                    <TitleLabel
                        fontWeight={"medium"}
                        text={"Campaign Pool"}
                        size={14}
                        color={"#fff"}
                    />
                </div>
            </div>
            <div
                className={cx(style.poolamount, {
                    amountlong: pDenom !== rDenom,
                })}
            >
                <div className={style.poolamount_text}>
                    <div className={style.sub}>
                        <div
                            className={cx(style.text2, {
                                isZero: isZero(pReward),
                            })}
                        >
                            {getDecimal(
                                pReward,
                                true,
                                pDenom === "uusd" ? 3 : pDecimals
                            )}
                            &nbsp;
                            <small>{pSymbol}</small>
                        </div>

                        {pDenom !== rDenom ? (
                            <div className={style.dummy}>
                                &nbsp;<small>{"/"}</small>&nbsp;
                            </div>
                        ) : (
                            <div />
                        )}

                        {pDenom !== rDenom ? (
                            <div
                                className={cx(style.text3, {
                                    isZero: isZero(rReward),
                                })}
                            >
                                {getDecimal(
                                    rReward,
                                    true,
                                    rDenom === "uusd" ? 3 : 6
                                )}
                                &nbsp;
                                <small>{rSymbol}</small>
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
