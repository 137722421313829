import { useState } from "react";
import style from "./CampaignsItem.module.scss";
import * as Utils from "../../../libs/utils";
import { PATHS } from "../../../App";
import TitleLabel from "../../common/Labels/TitleLabel";
import { useEffect } from "react";
import { campaignSummary } from "../../../libs/api/campaign/query";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import classNames from "classnames/bind";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
import { SymbolIcon2 } from "../../common/SymbolIcon/SymbolIcon";
import DetailButton from "../../common/DetailButton/DetailButton";

const cx = classNames.bind(style);

export default function CampaignItem(props: {
    info: CampaignListItem;
    isProducer?: boolean;
}) {
    const wallet = useRecoilValue(walletState);
    const [summary, setSummary] = useState<CampaignSummary | undefined>(
        undefined
    );

    useEffect(() => {
        if (props.info.campaignAddress) {
            campaignSummary(
                props.info.campaignAddress,
                wallet ? wallet.terraAddress : undefined
            )
                .then((r) => {
                    setSummary(r);
                })
                .catch((e) => {});
        }
    }, [props.info, wallet]);

    const state = summary ? summary.state : undefined;
    const config = summary ? summary.config : undefined;
    const reward = summary ? summary.reward : undefined;

    const isActive = state ? state.is_active : false;

    const title = config ? config.title : "";

    const pAmount = reward ? reward.participation.amount : undefined;
    const pSymbol = reward ? reward.participation.symbol : undefined;
    const pDenom = reward ? reward.participation.denom : undefined;
    const pName = reward ? reward.participation.name : undefined;
    const pDecimals = reward ? reward.participation.decimals : 6;

    const rAmount = reward ? reward.referral.amounts : undefined;
    const rSymbol = reward ? reward.referral.symbol : undefined;

    const [hovercheck, setHovercheck] = useState(false);

    return (
        <Link
            className={cx(style.campaigns_item, {
                activefalse: isActive === false,
            })}
            onMouseEnter={() => {
                setHovercheck(true);
            }}
            onMouseLeave={() => {
                setHovercheck(false);
            }}
            to={
                props.isProducer
                    ? PATHS.producerDetail + props.info.campaignAddress
                    : PATHS.campaignsDetail + props.info.campaignAddress
            }
        >
            <div className={style.campaigns_itemwrap} />

            <div className={style.cardlogo}>
                <Cardlogo
                    denom={pDenom}
                    name={pName}
                    symbol={pSymbol}
                    isActive={isActive}
                    hovercheck={hovercheck}
                />
            </div>
            <div className={style.carddummy2} />
            <div className={style.carddummy} />
            <div className={style.cardcontent}>
                <CardContent
                    title={title}
                    isActive={isActive}
                    hovercheck={hovercheck}
                    pAmount={pAmount}
                    pDecimals={pDecimals}
                    pSymbol={pSymbol}
                    rAmount={rAmount}
                    rSymbol={rSymbol}
                />
            </div>
        </Link>
    );
}

function Cardlogo(props: {
    denom: string | undefined;
    name: string | undefined;
    symbol: string | undefined;
    isActive: boolean;
    hovercheck: boolean;
}) {
    return (
        <div className={style.cardlogo_container}>
            <div className={style.cardlogo_img}>
                <SymbolIcon2 denom={props.denom} isActive={props.isActive} />
            </div>
            <div className={style.cardlogo_mobile}>
                <div
                    className={cx(style.cardlogo_symbol, {
                        activefalse: props.isActive === false,
                        hovercheck: props.hovercheck,
                    })}
                >
                    {props.symbol === undefined ? (
                        <Skeleton />
                    ) : (
                        "$" + props.symbol
                    )}
                </div>
                <div
                    className={cx(style.cardlogo_name, {
                        activefalse: props.isActive === false,
                        hovercheck: props.hovercheck,
                    })}
                >
                    {props.name === undefined ? <Skeleton /> : props.name}
                </div>
            </div>
        </div>
    );
}

function CardContent(props: {
    title: string;
    isActive: boolean;
    hovercheck: boolean;
    pAmount: string | undefined;
    pDecimals: number;
    pSymbol: string | undefined;
    rAmount: string[] | undefined;
    rSymbol: string | undefined;
}) {
    const white = "white";
    const white30 = "rgba(255,255,255,0.3)";

    const [color, setColor] = useState(white);

    useEffect(() => {
        if (props.isActive === false && props.hovercheck === false) {
            setColor(white30);
        } else {
            setColor(white);
        }
    }, [props.hovercheck, props.isActive]);

    return (
        <div className={style.cardcontent_container}>
            <div
                className={cx(style.cardcontent_title, {
                    activefalse: props.isActive === false,
                    hovercheck: props.hovercheck,
                })}
            >
                {props.title === undefined ? <Skeleton /> : props.title}
            </div>

            <div className={style.cardcontent_reward}>
                <div className={style.rewardstitle}>
                    <TitleLabel
                        text={"Rewards"}
                        color={!props.isActive ? "rgba(#fff, 0.3)" : "white"}
                        isCampaignfalse={!props.isActive}
                        hovercheck={
                            !props.isActive ? props.hovercheck : undefined
                        }
                    />
                </div>
                <div
                    className={cx(style.rewardsdetail, {
                        activefalse: props.isActive === false,
                        hovercheck: props.hovercheck,
                    })}
                >
                    <div className={style.rewardsdetail_amount}>
                        <div
                            className={cx(style.amount_ico, {
                                activefalse: props.isActive === false,
                                hovercheck: props.hovercheck,
                            })}
                        />
                        <div className={style.amount_reward}>
                            <div className={style.amount_reward_pamount}>
                                <div className={style.pamount}>
                                    <div className={style.pamount_pc}>
                                        <TitleLabel
                                            size={16}
                                            text={
                                                props.pAmount
                                                    ? Utils.getDecimal(
                                                          props.pAmount
                                                              ? props.pAmount
                                                              : "",
                                                          true,
                                                          props.pSymbol ===
                                                              "UST"
                                                              ? 3
                                                              : props.pDecimals
                                                      )
                                                    : undefined
                                            }
                                            color={color}
                                            skeleton={{ width: 50 }}
                                            symbol={
                                                props.pSymbol
                                                    ? props.pSymbol
                                                    : " -"
                                            }
                                        />
                                    </div>
                                    <div className={style.pamount_middle}>
                                        <TitleLabel
                                            size={12}
                                            text={
                                                props.pAmount
                                                    ? Utils.getDecimal(
                                                          props.pAmount
                                                              ? props.pAmount
                                                              : "",
                                                          true,
                                                          props.pSymbol ===
                                                              "UST"
                                                              ? 3
                                                              : props.pDecimals
                                                      )
                                                    : undefined
                                            }
                                            color={color}
                                            skeleton={{ width: 50 }}
                                            symbol={
                                                props.pSymbol
                                                    ? props.pSymbol
                                                    : " -"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.rewardsdetail_dummy} />
                    <div className={style.rewardsdetail_referral}>
                        <div
                            className={cx(style.referral_ico, {
                                activefalse: props.isActive === false,
                                hovercheck: props.hovercheck,
                            })}
                        />
                        <div className={style.referral_reward}>
                            <div className={style.referral_reward_first}>
                                <div className={style.referrals}>
                                    <div className={style.referrals_pc}>
                                        <TitleLabel
                                            size={16}
                                            text={
                                                props.rAmount
                                                    ? Utils.getDecimal(
                                                          props.rAmount[0],
                                                          true,
                                                          props.rSymbol ===
                                                              "UST"
                                                              ? 3
                                                              : 6
                                                      )
                                                    : undefined
                                            }
                                            skeleton={{ width: 30 }}
                                            color={color}
                                            symbol={props.rSymbol + " -"}
                                        />
                                    </div>
                                    <div className={style.referrals_middle}>
                                        <TitleLabel
                                            size={12}
                                            text={
                                                props.rAmount
                                                    ? Utils.getDecimal(
                                                          props.rAmount[0],
                                                          true,
                                                          props.rSymbol ===
                                                              "UST"
                                                              ? 3
                                                              : 6
                                                      )
                                                    : undefined
                                            }
                                            skeleton={{ width: 30 }}
                                            color={color}
                                            symbol={props.rSymbol + " -"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={style.referral_reward_second}>
                                <div className={style.referrals}>
                                    <TitleLabel
                                        size={12}
                                        text={
                                            props.rAmount
                                                ? Utils.getDecimal(
                                                      props.rAmount[1],
                                                      true,
                                                      props.rSymbol === "UST"
                                                          ? 3
                                                          : 6
                                                  )
                                                : undefined
                                        }
                                        skeleton={{ width: 30 }}
                                        color={color}
                                        symbol={props.rSymbol}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.cardcontent_button}>
                <DetailButton hovercheck={props.hovercheck} />
            </div>
        </div>
    );
}
