import style from "./FanfuryParticipationAuto.module.scss";
import classNames from "classnames/bind";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import { useEffect, useRef, useState } from "react";
import ConfirmButton from "../../../../common/ConfirmButton/ConfirmButton";
import { qualificationTxInfo } from "../../../../../libs/api/campaign/qualification/rest";
import { useRecoilValue } from "recoil";
import { balanceState, walletState } from "../../../../../libs/contexts";
import {
    divide,
    fixed,
    leftGreaterThanRight,
    multiply,
} from "../../../../../libs/Math";
import { environment } from "../../../../../libs/api/api";
import { queryTokenBalance } from "../../../../../libs/api/trade/query";
import FanfuryInputFieldTxPaste from "../../../../common/InputField/FanfuryInputFieldTxPaste";
import FanfuryParticipationFooter from "./FanfuryParticipationFooter";
const cx = classNames.bind(style);

export default function FanfuryParticipationAuto(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    txPassed: boolean;
    setTxPassed: (v: boolean) => void;
    txHash: string;
    setTxHash: (t: string) => void;
    hashResult: TxQualificationInfo | undefined;
    setHashResult: (v: TxQualificationInfo | undefined) => void;
    earlybird: boolean;
    requirement?: FanfuryRequirement;
    started: boolean;
    ipcParticipate: (
        token: string,
        referrer: string | undefined,
        txHash: string,
        signature: string,
        ruleId: string
    ) => void;
}) {
    const retryCountDownMax = 20; // will retry 3seconds * 20times
    const wallet = useRecoilValue(walletState);

    const balance = useRecoilValue(balanceState);
    const [vpBalance, setVpBalance] = useState<string>("0");

    const retryCountDown = useRef(0);
    const [loading, setLoading] = useState(false);

    const [error1, setError1] = useState<string | undefined>(undefined);

    const [vkrCheck, setVkrCheck] = useState<boolean>(false);
    const [vpCheck, setVpCheck] = useState<boolean>(false);
    const [ustCheck, setUstCheck] = useState<boolean>(false);

    const [ustAmount, setUstAmount] = useState("");

    useEffect(() => {
        if (!wallet) {
            props.setHashResult(undefined);
        }

        if (wallet !== undefined) {
            queryTokenBalance(
                environment().contracts.vp_token,
                wallet.terraAddress
            )
                .then((r) => {
                    setVpBalance(r.amount);
                })
                .catch((e) => {
                    setVpBalance("0");
                });
        }
    }, [wallet]);

    useEffect(() => {
        if (
            !leftGreaterThanRight(
                props.summary.config.deposit.amount,
                balance.token
            )
        ) {
            setVkrCheck(true);
        } else {
            setVkrCheck(false);
        }

        if (
            !leftGreaterThanRight(
                props.summary.config.vp_burn_amount,
                vpBalance
            )
        ) {
            setVpCheck(true);
        } else {
            setVpCheck(false);
        }
    }, [balance, vpBalance]);

    useEffect(() => {
        if (props.hashResult) {
            if (
                props.hashResult.campaign === props.campaignAddress &&
                props.hashResult.actor ===
                    (wallet ? wallet.terraAddress : "") &&
                props.hashResult.status === "SUCCESS"
            ) {
                setError1(undefined);
                props.setTxPassed(true);
            } else if (props.hashResult.reason) {
                setError1(props.hashResult.reason);
                props.setTxPassed(false);
            } else {
                setError1("Invalid Tx");
                props.setTxPassed(false);
            }
        } else {
            props.setTxHash("");
            props.setTxPassed(false);
        }
    }, [props.hashResult, wallet, props.campaignAddress]);

    useEffect(() => {
        if (error1 !== undefined) {
            setError1(undefined);
        }
    }, [props.txHash]);

    function startCheck() {
        retryCountDown.current -= 1;

        qualificationTxInfo(
            wallet!.terraAddress,
            props.txHash,
            props.campaignAddress
        )
            .then((r) => {
                if (r.status === "SUCCESS") {
                    retryCountDown.current = 0;
                    setLoading(false);
                    props.setHashResult(r);
                } else {
                    if (retryCountDown.current > 0) {
                        setTimeout(() => {
                            startCheck();
                        }, 3000);
                    } else {
                        retryCountDown.current = 0;
                        setLoading(false);
                        props.setHashResult(r);
                    }
                }
            })
            .catch((e) => {
                if (retryCountDown.current > 0) {
                    setTimeout(() => {
                        startCheck();
                    }, 3000);
                } else {
                    setLoading(false);
                    setError1("Invalid Tx");
                }
            });
    }

    return (
        <div className={style.container}>
            <TitleLabel text={"Input TX"} fontWeight="light" size={16} />

            <div style={{ height: "6px" }} />

            <FanfuryInputFieldTxPaste
                value={props.txHash}
                passed={props.txPassed}
                setPassed={(v) => {
                    props.setHashResult(undefined);
                }}
                onChanged={props.setTxHash}
                error={error1}
            />

            {props.txPassed === true && props.hashResult ? (
                <div className={style.signature_box}>
                    <div className={style.box1}>
                        <div
                            className={cx(style.icon, {
                                referrer:
                                    props.hashResult.referrer !== undefined,
                            })}
                        />
                        <div className={style.text}>
                            {props.hashResult.referrer
                                ? "Connected through referral link"
                                : "Connected without referral link"}
                        </div>
                    </div>
                    <div className={style.box2} />
                    <div className={style.box3}>
                        <div className={style.text1}>{"Signature"}</div>
                        <div className={style.text2}>
                            {props.hashResult.encodedSignature}
                        </div>
                    </div>
                </div>
            ) : (
                <div />
            )}

            {props.txPassed === true && props.hashResult ? (
                <FanfuryParticipationFooter
                    summary={props.summary}
                    vkrCheck={vkrCheck}
                    vpCheck={vpCheck}
                    ustCheck={ustCheck}
                    setVkrCheck={setVkrCheck}
                    setVpCheck={setVpCheck}
                    setUstCheck={setUstCheck}
                    tokenBalance={balance.token}
                    ustBalance={balance.uusd}
                    vpBalance={vpBalance}
                    ustAmount={ustAmount}
                    setUstAmount={setUstAmount}
                    requirement={props.requirement}
                    error={error1}
                    earlybird={props.earlybird}
                    started={props.started}
                    hashResult={props.hashResult}
                    txHash={props.txHash}
                    ipcParticipate={props.ipcParticipate}
                />
            ) : (
                <div />
            )}

            {!props.txPassed ? (
                <ConfirmButton
                    title="Check"
                    loading={loading}
                    onClick={() => {
                        retryCountDown.current = retryCountDownMax;
                        setLoading(true);
                        setError1(undefined);
                        startCheck();
                    }}
                    enable={props.txHash !== ""}
                />
            ) : (
                <div />
            )}
        </div>
    );
}
