import { useEffect, useState } from "react";
import { isZero } from "../../../../../libs/Math";
import { getDecimal } from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./TalisInfoDeposit.module.scss";

export default function TalisInfoDeposit(props: { summary: CampaignSummary }) {
    const [depositAmount, setDepositAmount] = useState("0");
    const [depoistSymbol, setDepositSymbol] = useState("");

    useEffect(() => {
        setDepositAmount(props.summary.config.deposit.amount);
        setDepositSymbol(props.summary.config.deposit.symbol);
    }, [props.summary.config.deposit]);

    return (
        <div className={style.container}>
            <div className={style.deposit_title}>
                <TitleLabel
                    fontWeight={"medium"}
                    text={"Campaign Deposit"}
                    size={14}
                    color={"#fff"}
                />
            </div>
            {!isZero(depositAmount) ? (
                <div className={style.deposit_contents}>
                    <div className={style.deposit_contents_amount}>
                        <div className={style.deposit_contents_amount_ico} />
                        <div className={style.deposit_contents_amount_text}>
                            <TitleLabel
                                size={16}
                                text={getDecimal(
                                    depositAmount,
                                    true,
                                    depoistSymbol === "UST" ? 3 : 6
                                )}
                                color={"#fff"}
                                symbol={depoistSymbol}
                            />
                        </div>
                    </div>
                    <div className={style.deposit_contents_dummy} />
                    <div className={style.deposit_contents_day}>
                        <div className={style.deposit_contents_day_text}>
                            {props.summary.config.deposit_lock_period_day.toString() +
                                " Days"}
                        </div>

                        <div className={style.deposit_contents_day_ico} />
                    </div>
                </div>
            ) : (
                <div className={style.deposit_contents}>
                    <div className={style.deposit_contents_none}>
                        <div className={style.deposit_contents_amount_ico} />
                        <TitleLabel size={16} text={"None"} color={"#fff"} />
                    </div>
                </div>
            )}
        </div>
    );
}
