import { useEffect, useState } from "react";
import style from "./StakeLp.module.scss";
import {
    autoUnstakeLp,
    autoStakeLp,
    makeLp,
    stakeLp,
    unstakeLp,
    divideLp,
} from "../../../libs/api/astroport_liquidity/execution";
import {
    queryLpEstimateParams,
    stakeLpInfo,
} from "../../../libs/api/astroport_liquidity/query";
import TxBoxView from "../../common/TxBoxView/TxBoxView";
import { useLocation } from "react-router-dom";
import { PATHS } from "../../../App";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";

import { useWallet } from "@terra-money/wallet-provider";
import StakeLpStake from "./stake/StakeLpStake";
import StakeLpUnstake from "./unstake/StakeLpUnstake";
import SwitchButton from "./common/SwitchButton";
import LineDummy from "../../common/LineDummy/LineDummy";
import StakeLpHeader from "./StakeLpHeader";
import StepBar2 from "./common/StepBar2";
import TitleLabel from "../../common/Labels/TitleLabel";

import classNames from "classnames/bind";
import PageHeader from "../../common/PageHeader/PageHeader";
import TabBox from "../../common/TabBox/TabBox";

const cx = classNames.bind(style);

enum StakeLpMode {
    stake,
    unstake,
}

export enum StakeLpStakeMode {
    auto,
    manual,
}

export enum StakeLpStakeManualMode {
    step1,
    step2,
}

export default function StakeLp(props: any) {
    const { post } = useWallet();

    const wallet = useRecoilValue(walletState);

    const location = useLocation();
    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    const [mode1, setMode1] = useState(StakeLpMode.stake);
    const [mode2, setMode2] = useState(StakeLpStakeMode.auto);
    const [mode3, setMode3] = useState(StakeLpStakeManualMode.step1);

    const [stakeInfo, setStakeInfo] = useState<StakeLpHeaderInfo | undefined>(
        undefined
    );
    const [tabIndex, setTabIndex] = useState(0);
    const [estimateParams, setEstimateParams] = useState<
        ResponseLpEstimate | undefined
    >(undefined);

    useEffect(() => {
        if (location.hash === "#stake") {
            setMode1(StakeLpMode.stake);
        } else if (location.hash === "#unstake") {
            setMode1(StakeLpMode.unstake);
        }
    }, [location]);

    useEffect(() => {
        queryLpEstimateParams()
            .then((r) => {
                setEstimateParams(r);
            })
            .catch((e) => {});
    }, []);

    useEffect(() => {
        stakeLpInfo(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setStakeInfo(r);
            })
            .catch((e) => {});
    }, [wallet, hash]);

    useEffect(() => {
        setMode2(StakeLpStakeMode.auto);
    }, [mode1]);

    useEffect(() => {
        if (mode2 === StakeLpStakeMode.auto) {
            setMode3(StakeLpStakeManualMode.step1);
        }
    }, [mode2]);

    function stakeLpAutoPressed(token: string, uusd: string) {
        setHash({
            type: "AstroStakeAuto",
            isPending: true,
        });

        autoStakeLp(wallet!.terraAddress, token, uusd)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "AstroStakeAuto",
                    response: response,
                    redirect: PATHS.governance,
                });
            })
            .catch((error) => {
                setHash({
                    type: "AstroStakeAuto",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function unstakeLpAutoPressed(amount: string) {
        setHash({
            type: "AstroUnstakeAuto",
            isPending: true,
        });

        autoUnstakeLp(wallet!.terraAddress, amount)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "AstroUnstakeAuto",
                    response: response,
                    redirect: PATHS.governance,
                });
            })
            .catch((error) => {
                setHash({
                    type: "AstroUnstakeAuto",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function makeLpPressed(token: string, uusd: string) {
        setHash({
            type: "AstroMakeLp",
            isPending: true,
        });

        makeLp(wallet!.terraAddress, token, uusd)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "AstroMakeLp",
                    response: response,
                    redirect: undefined,
                    callback: () => {
                        setMode3(StakeLpStakeManualMode.step2);
                    },
                });
            })
            .catch((error) => {
                setHash({
                    type: "AstroMakeLp",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function divideLpPressed(amount: string) {
        setHash({
            type: "AstroDivideLp",
            isPending: true,
        });

        divideLp(wallet!.terraAddress, amount)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "AstroDivideLp",
                    response: response,
                    redirect: PATHS.governance,
                });
            })
            .catch((error) => {
                setHash({
                    type: "AstroDivideLp",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function stakeLpPressed(amount: string) {
        setHash({
            type: "AstroStakeLp",
            isPending: true,
        });

        stakeLp(wallet!.terraAddress, amount)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "AstroStakeLp",
                    response: response,
                    redirect: PATHS.governance,
                });
            })
            .catch((error) => {
                setHash({
                    type: "AstroStakeLp",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function unstakeLpPressed(amount: string) {
        setHash({
            type: "AstroUnstakeLp",
            isPending: true,
        });

        unstakeLp(wallet!.terraAddress, amount)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "AstroUnstakeLp",
                    response: response,
                    redirect: undefined,
                    callback: () => {
                        setMode3(StakeLpStakeManualMode.step2);
                    },
                });
            })
            .catch((error) => {
                setHash({
                    type: "AstroUnstakeLp",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={style.container}>
            {hash !== undefined ? (
                <PageHeader title="Liquidity Stake" docs="" />
            ) : (
                <div />
            )}
            <TxBoxView
                transparent={true}
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
            >
                <StakeLpHeader info={stakeInfo} />

                <PageHeader title="Liquidity Stake" docs="" />

                <div className={style.tradetab}>
                    <TabBox
                        titleLeft="Stake"
                        titleRight="Unstake"
                        tabIndex={tabIndex}
                        tabChanged={(index) => {
                            setTabIndex(index);
                        }}
                    />

                    <div className={style.switch_box}>
                        <SwitchButton
                            titleLeft="Auto"
                            titleRight="Manual"
                            tabIndex={mode2}
                            tabChanged={setMode2}
                        />
                        <div style={{ height: "10px" }} />
                        {mode2 === StakeLpStakeMode.auto ? (
                            <div />
                        ) : (
                            <StepBar2
                                title1={
                                    mode1 === StakeLpMode.stake
                                        ? "1. Provide"
                                        : "1. Unstake"
                                }
                                title2={
                                    mode1 === StakeLpMode.stake
                                        ? "2. Stake"
                                        : "2. Withdraw"
                                }
                                selected={mode3}
                                setSelected={setMode3}
                            />
                        )}

                        {mode2 === StakeLpStakeMode.auto ? (
                            <div />
                        ) : (
                            <div className={style.line_container}>
                                <LineDummy
                                    horizontal={true}
                                    marginTop={0}
                                    marginBottom={0}
                                />
                            </div>
                        )}
                    </div>
                    <div className={style.tradetab_bottom}>
                        {tabIndex === 0 ? (
                            <StakeLpStake
                                mode1={mode2}
                                mode2={mode3}
                                estimateParams={estimateParams}
                                stakeLpAutoPressed={stakeLpAutoPressed}
                                makeLpPressed={makeLpPressed}
                                stakeLpPressed={stakeLpPressed}
                            />
                        ) : (
                            <StakeLpUnstake
                                mode1={mode2}
                                mode2={mode3}
                                estimateParams={estimateParams}
                                unstakeLpAutoPressed={unstakeLpAutoPressed}
                                divideLpPressed={divideLpPressed}
                                unstakeLpPressed={unstakeLpPressed}
                            />
                        )}
                    </div>
                </div>
            </TxBoxView>
        </div>
    );
}
