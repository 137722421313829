import {
    divide,
    fixed,
    leftGreaterThanRight,
    leftLessThanRight,
    multiply,
} from "../../../../../libs/Math";
import { getDecimal } from "../../../../../libs/utils";
import ConfirmButton from "../../../../common/ConfirmButton/ConfirmButton";
import TalisInputField from "../../../../common/InputField/TalisInputField";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import TalisDepositCheck, { ConditionType } from "./TalisDepositCheck";
import style from "./TalisParticipationFooter.module.scss";
import TalisRemaining from "./TalisRemaining";
import TalisVpCheck from "./TalisVpCheck";
import classNames from "classnames/bind";
import { environment } from "../../../../../libs/api/api";
import { useEffect, useState } from "react";
const cx = classNames.bind(style);

export default function TalisParticipationFooter(props: {
    summary: CampaignSummary;
    vpCheck: boolean;
    ustCheck: boolean;
    setVpCheck: (v: boolean) => void;
    setUstCheck: (v: boolean) => void;
    tokenBalance: string;
    ustBalance: string;
    vpBalance: string;
    ustAmount: string;
    setUstAmount: (v: string) => void;
    requirement?: TalisRequirement;
    error: string | undefined;
    earlybird: boolean;
    started: boolean;
    hashResult: TxQualificationInfo | undefined;
    txHash: string;
    ipcParticipate: (
        token: string,
        referrer: string | undefined,
        txHash: string,
        signature: string,
        ruleId: string
    ) => void;
}) {
    const [errorText, setErrorText] = useState<string | undefined>(undefined);

    useEffect(() => {
        setErrorText(
            "Available Swap cap " +
                getDecimal(
                    props.requirement ? props.requirement.min_uusd_amount : "",
                    true,
                    0
                ) +
                " ~ " +
                getDecimal(
                    props.requirement ? props.requirement?.max_uusd_amount : "",
                    true,
                    0
                ) +
                " UST"
        );
    }, [props.requirement]);

    return (
        <div className={style.container}>
            <div className={style.taliscondition}>
                <div className={style.conditiondummy1} />
                <div className={style.conditiondummy2} />
                <div className={style.conditiontitle}>
                    <TitleLabel
                        text={"Participate Condition Check"}
                        fontWeight="light"
                        size={16}
                    />
                </div>

                <div className={style.vpcondition}>
                    <TalisVpCheck
                        status={
                            props.vpCheck
                                ? ConditionType.confirm
                                : ConditionType.deny
                        }
                        statusSub={props.vpCheck ? "" : "You need more VP"}
                        balance={props.vpBalance}
                        text={
                            props.vpCheck
                                ? fixed(
                                      divide(
                                          props.summary.config.vp_burn_amount,
                                          1000000
                                      ),
                                      0
                                  )
                                : getDecimal(props.vpBalance, true, 6)
                        }
                    />
                </div>

                <div className={style.swapcondition}>
                    <div className={style.swapconditiontitle}>
                        <div
                            className={cx(style.swapicon, {
                                enable: !props.ustCheck,
                            })}
                        />
                        {props.ustCheck ? (
                            <div className={style.swaptitle}>{"Swap"}</div>
                        ) : (
                            <div className={style.swaptitlewrap}>
                                <div className={style.swaptitlemain}>
                                    {"Swap"}
                                </div>
                                <div className={style.swaptitlesub}>
                                    {errorText}
                                </div>
                            </div>
                        )}
                    </div>
                    <TalisInputField
                        title=""
                        disabled={false}
                        symbol="UST"
                        value={props.ustAmount}
                        balance={props.ustBalance}
                        onChanged={(v: string) => {
                            if (v === "") {
                                props.setUstCheck(false);
                                setErrorText(
                                    "Available Swap cap " +
                                        getDecimal(
                                            props.requirement
                                                ? props.requirement
                                                      .min_uusd_amount
                                                : "",
                                            true,
                                            0
                                        ) +
                                        " ~ " +
                                        getDecimal(
                                            props.requirement
                                                ? props.requirement
                                                      ?.max_uusd_amount
                                                : "",
                                            true,
                                            0
                                        ) +
                                        " UST"
                                );
                            } else {
                                if (
                                    !leftGreaterThanRight(
                                        multiply(v, "1000000"),
                                        props.ustBalance
                                    )
                                ) {
                                    if (props.requirement !== undefined) {
                                        if (
                                            !leftGreaterThanRight(
                                                multiply(v, "1000000"),
                                                props.requirement
                                                    .max_uusd_amount
                                            ) &&
                                            !leftGreaterThanRight(
                                                props.requirement
                                                    .min_uusd_amount,
                                                multiply(v, "1000000")
                                            )
                                        ) {
                                            props.setUstCheck(true);
                                            setErrorText(undefined);
                                        } else {
                                            props.setUstCheck(false);
                                            setErrorText(
                                                "Available Swap cap " +
                                                    getDecimal(
                                                        props.requirement
                                                            ? props.requirement
                                                                  .min_uusd_amount
                                                            : "",
                                                        true,
                                                        0
                                                    ) +
                                                    " ~ " +
                                                    getDecimal(
                                                        props.requirement
                                                            ? props.requirement
                                                                  ?.max_uusd_amount
                                                            : "",
                                                        true,
                                                        0
                                                    ) +
                                                    " UST"
                                            );
                                        }
                                    }
                                } else {
                                    props.setUstCheck(false);
                                    setErrorText(
                                        "Available Swap cap " +
                                            getDecimal(
                                                props.requirement
                                                    ? props.requirement
                                                          .min_uusd_amount
                                                    : "",
                                                true,
                                                0
                                            ) +
                                            " ~ " +
                                            getDecimal(
                                                props.requirement
                                                    ? props.requirement
                                                          ?.max_uusd_amount
                                                    : "",
                                                true,
                                                0
                                            ) +
                                            " UST"
                                    );
                                }
                            }
                            props.setUstAmount(v);
                        }}
                        error={props.error ? props.error : undefined}
                        autofocus={true}
                        errorText={errorText}
                    />
                </div>

                <div className={style.participationdummy} />

                {props.started ? (
                    <div className={style.participation_button_container}>
                        {props.requirement &&
                        props.ustAmount !== "" &&
                        !leftGreaterThanRight(
                            props.requirement.min_uusd_amount,
                            multiply(props.ustAmount, 1000000)
                        ) &&
                        !leftLessThanRight(
                            props.requirement.max_uusd_amount,
                            multiply(props.ustAmount, 1000000)
                        ) ? (
                            <div className={style.estimate_container}>
                                <div className={style.text}>
                                    {getDecimal(
                                        multiply(
                                            divide(
                                                props.ustAmount,
                                                props.earlybird
                                                    ? environment().contracts
                                                          .talis_early.ustprice
                                                    : environment().contracts
                                                          .talis_public.ustprice
                                            ),
                                            1000000
                                        ),
                                        true
                                    ) + " TALIS"}
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}

                        <div className={style.participationbuttonwrap}>
                            <ConfirmButton
                                title="Participation"
                                onClick={() =>
                                    props.ipcParticipate(
                                        props.ustAmount,
                                        props.hashResult
                                            ? props.hashResult.referrer
                                            : "",
                                        props.txHash,
                                        props.hashResult
                                            ? props.hashResult.encodedSignature
                                            : "",
                                        props.hashResult
                                            ? props.hashResult.ruleId
                                            : ""
                                    )
                                }
                                enable={props.vpCheck && props.ustCheck}
                            />
                        </div>
                    </div>
                ) : (
                    <div className={style.remaining}>
                        <TalisRemaining
                            summary={props.summary}
                            earlybird={props.earlybird}
                            requirement={props.requirement}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
