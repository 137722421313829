import { useEffect, useState } from "react";
import TitleLabel from "../common/Labels/TitleLabel";
import style from "./MyClaimable.module.scss";
import * as Utils from "../../libs/utils";
import { govState } from "../../libs/api/governance/query";
import { useRecoilValue } from "recoil";
import { walletState } from "../../libs/contexts";
import { stakeLpInfo } from "../../libs/api/astroport_liquidity/query";
import { campaignClaimableInfo } from "../../libs/api/rest";
import { isZero, multiply, plus } from "../../libs/Math";
import { queryPrice } from "../../libs/api/trade/query";
import { environment } from "../../libs/api/api";
import PageHeader from "../common/PageHeader/PageHeader";
import { ClaimButton } from "../common/NormalButton/NormalButton";

export default function MyClaimable(props: {
    claimPressed: (hasVp: boolean) => void;
}) {
    const wallet = useRecoilValue(walletState);
    const [vpReward, setVpReward] = useState("0");
    const [lpVkrReward, setLpVkrReward] = useState("0");
    const [lpAstroReward, setAstroLpReward] = useState("0");

    const [depositLocked, setDepositLocked] = useState<string | undefined>(
        undefined
    );
    const [depositReward, setDepositReward] = useState<string | undefined>(
        undefined
    );

    const [lpVkr, setLpVkr] = useState<AmountSplitModel | undefined>(undefined);
    const [lpAst, setLpAst] = useState<AmountSplitModel | undefined>(undefined);
    const [vp, setVp] = useState<AmountSplitModel | undefined>(undefined);

    const [loadedTotal, setLoadedTotal] = useState(false);
    const [totalClaimable, setTotalClaimable] = useState("0");
    const [totalClaimableSplit, setTotalClaimableSplit] = useState<
        AmountSplitModel | undefined
    >(undefined);

    useEffect(() => {
        govState(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setVpReward(r.pending);
                setVp(Utils.splitAmountByDot(r.pending));
            })
            .catch((e) => {});
        stakeLpInfo(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setLpVkrReward(r.pending_on_proxy);
                setAstroLpReward(r.pending);

                setLpVkr(Utils.splitAmountByDot(r.pending_on_proxy));
                setLpAst(Utils.splitAmountByDot(r.pending));
            })
            .catch((e) => {});

        campaignClaimableInfo(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setDepositLocked(r.deposit.locked);
                setDepositReward(r.deposit.claimable);

                setLoadedTotal(true);
            })
            .catch((e) => {});
    }, [wallet]);

    useEffect(() => {
        let sum = "0";
        load()
            .then((r) => {
                sum = plus(sum, multiply(depositReward ?? "0", r.vkrPrice));
                sum = plus(sum, multiply(lpVkrReward, r.vkrPrice));
                sum = plus(sum, multiply(lpAstroReward, r.astPrice));

                setTotalClaimable(sum);
                setTotalClaimableSplit(Utils.splitAmountByDot(sum, 3));
            })
            .catch((e) => {});
    }, [wallet, vpReward, lpVkrReward, lpAstroReward, depositReward]);

    async function load() {
        const vkrPrice = await queryPrice(environment().contracts.token);
        const astPrice = await queryPrice(
            environment().contracts.astroport.astroToken
        );

        return {
            vkrPrice: vkrPrice,
            astPrice: astPrice,
        };
    }

    return (
        <div className={style.container}>
            <PageHeader title="Claimable Assets" docs="" />
            <div className={style.total_container_box}>
                <div className={style.total_container}>
                    <div className={style.text}>
                        <TitleLabel
                            text={"My Total Claimable"}
                            color={"#fff"}
                            tooltip={
                                "Total Value of all Claimable asets excluding rewards (Lp Staking Rewards + Lp astro port reward + Locked & Unlocked Campaign Deposit)"
                            }
                            fontWeight={"light"}
                        />
                        <div style={{ height: 5 }} />
                        <TitleLabel
                            size={30}
                            text={
                                loadedTotal && totalClaimableSplit
                                    ? totalClaimableSplit.num1
                                    : undefined
                            }
                            color={"white"}
                            symbol={
                                loadedTotal &&
                                totalClaimableSplit &&
                                totalClaimableSplit.num2
                                    ? "." + totalClaimableSplit.num2 + " UST"
                                    : "UST"
                            }
                            skeleton={{ width: 100 }}
                        />
                    </div>
                    <div className={style.button}>
                        <ClaimButton
                            title={"Claim All"}
                            disabled={
                                (isZero(totalClaimable) && isZero(vpReward)) ||
                                !loadedTotal
                            }
                            textHighlight={
                                isZero(totalClaimable) && loadedTotal
                            }
                            selected={false}
                            onClick={() => {
                                if (wallet) {
                                    props.claimPressed(!isZero(vpReward));
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={style.container_box}>
                <div className={style.lp_container}>
                    <div className={style.text}>
                        <TitleLabel
                            text={"LP"}
                            color={"#fff"}
                            fontWeight={"light"}
                        />
                        <div style={{ height: 11 }} />

                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={lpVkr ? lpVkr.num1 : undefined}
                            color={"#ffffff"}
                            symbol={
                                lpVkr && lpVkr.num2
                                    ? "." + lpVkr.num2 + " VKR"
                                    : "VKR"
                            }
                            skeleton={{ width: 100 }}
                        />

                        <div style={{ height: 12 }} />

                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={lpAst ? lpAst.num1 : undefined}
                            color={"#ffffff"}
                            symbol={
                                lpAst && lpAst.num2
                                    ? "." + lpAst.num2 + " ASTRO"
                                    : "ASTRO"
                            }
                            skeleton={{ width: 100 }}
                        />
                    </div>
                </div>

                <div className={style.line} />

                <div className={style.deposit_container}>
                    <div className={style.text}>
                        <TitleLabel
                            text={"Campaign Deposit"}
                            color={"#fff"}
                            fontWeight={"light"}
                        />
                        <div style={{ height: 7 }} />
                        <div className={style.deposit}>
                            <TitleLabel
                                text={
                                    depositLocked
                                        ? Utils.getDecimal(depositLocked, true)
                                        : undefined
                                }
                                size={12}
                                color={"#f93660"}
                                symbol={"VKR Locked"}
                                fontWeight={"light"}
                                skeleton={{ width: 100 }}
                            />
                            <div className={style.icon} />
                        </div>
                        <div style={{ height: 8 }} />
                        <TitleLabel
                            text={
                                depositReward
                                    ? Utils.getDecimal(depositReward, true)
                                    : undefined
                            }
                            size={16}
                            color={"#ffffff"}
                            symbol={"VKR"}
                            skeleton={{ width: 100 }}
                        />
                    </div>
                </div>

                <div className={style.line} />

                <div className={style.vp_container}>
                    <div className={style.text}>
                        <TitleLabel
                            text={"VP"}
                            color={"#fff"}
                            fontWeight={"light"}
                            tooltip={
                                "VP is not worth of UST value. Valkyrian Pass is non-exchangeable, non-refundable and it cannot be transferred to another wallet"
                            }
                        />
                        <div style={{ height: 12 }} />

                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={vp ? vp.num1 : undefined}
                            color={"#ffffff"}
                            symbol={
                                vp && vp.num2 ? "." + vp.num2 + " VP" : "VP"
                            }
                            skeleton={{ width: 100 }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
