import { useState } from "react";
import style from "./Campaigns.module.scss";
import * as Api from "../../libs/api/rest";
import MoreButton from "../common/MoreButton/MoreButton";
import { useEffect } from "react";
import { useRef } from "react";
import CampaignItem from "./CampaignItem/CampaignsItem";
import { useCallback } from "react";
import TxBoxView from "../common/TxBoxView/TxBoxView";

import { campaignAllClaim } from "../../libs/api/campaign/execution";
import { useWallet } from "@terra-money/wallet-provider";

import TitleLabel from "../common/Labels/TitleLabel";
import { useRecoilValue } from "recoil";
import { walletState } from "../../libs/contexts";
import classNames from "classnames/bind";
import ComingSoon from "./Comingsoon/ComingSoon";
import Myreward from "./Myreward/Myreward";
import { environment } from "../../libs/api/api";
import LunartCampaignItem from "./CampaignItem/LunartCampaignItem";
import FanfuryCampaignItem from "./CampaignItem/FanfuryCampaignItem";
import TalisCampaignItem from "./CampaignItem/TalisCampaignItem";
import Banner from "./Banner/Banner";

const cx = classNames.bind(style);

export default function Campaigns(props: any) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);

    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    const loading = useRef(false);
    const cursor1 = useRef<string | undefined>(undefined);
    const cursor2 = useRef<string | undefined>(undefined);
    const cursor3 = useRef<string | undefined>(undefined);

    const loaded1 = useRef(false);
    const loaded2 = useRef(false);
    const loaded3 = useRef(false);

    const [hasNext, setHasNext] = useState(true);
    const [campaignList, setCampaignList] = useState<CampaignListItem[]>([]);

    const load = useCallback(() => {
        if (loading.current || !hasNext) {
            return;
        }

        loading.current = true;

        let old = campaignList.slice();

        if (!loaded1.current) {
            Api.campaignAllListIpc(cursor1.current)
                .then((r) => {
                    if (loaded1.current === false) {
                        r.items.forEach((item) => {
                            old.push(item);
                        });
                    }

                    cursor1.current = r.nextCursor;

                    if (r.hasNext) {
                        setHasNext(r.hasNext);
                        setCampaignList(old);
                        loading.current = false;
                    } else {
                        loaded1.current = true;
                        Api.campaignAllList(cursor2.current)
                            .then((res) => {
                                if (loaded2.current === false) {
                                    res.items.forEach((item) => {
                                        old.push(item);
                                    });
                                }

                                cursor2.current = res.nextCursor;

                                if (res.hasNext) {
                                    setHasNext(res.hasNext);
                                    setCampaignList(old);
                                    loading.current = false;
                                } else {
                                    loaded2.current = true;
                                    Api.campaignAllListInactive(cursor3.current)
                                        .then((res2) => {
                                            if (loaded3.current === false) {
                                                res2.items.forEach((item) => {
                                                    old.push(item);
                                                });
                                            }

                                            cursor3.current = res2.nextCursor;
                                            setHasNext(res2.hasNext);
                                            setCampaignList(old);
                                            loading.current = false;

                                            if (res2.hasNext) {
                                                //todo 나중에 뭔가 더 생기면 위 구조 따를거.
                                            } else {
                                                loaded3.current = true;
                                            }
                                        })
                                        .catch((e) => {
                                            setHasNext(false);
                                            loading.current = false;
                                        });
                                }
                            })
                            .catch((e) => {
                                setHasNext(false);
                                loading.current = false;
                            });
                    }
                })
                .catch((e) => {
                    setHasNext(false);
                    loading.current = false;
                });
        }
        // loaded1.current === true
        else {
            if (!loaded2.current) {
                Api.campaignAllList(cursor2.current)
                    .then((res) => {
                        if (loaded2.current === false) {
                            res.items.forEach((item) => {
                                old.push(item);
                            });
                        }

                        cursor2.current = res.nextCursor;

                        if (res.hasNext) {
                            setHasNext(res.hasNext);
                            setCampaignList(old);
                            loading.current = false;
                        } else {
                            loaded2.current = true;
                            Api.campaignAllListInactive(cursor3.current)
                                .then((res2) => {
                                    if (loaded3.current === false) {
                                        res2.items.forEach((item) => {
                                            old.push(item);
                                        });
                                    }

                                    cursor3.current = res2.nextCursor;
                                    setHasNext(res2.hasNext);
                                    setCampaignList(old);
                                    loading.current = false;

                                    if (res2.hasNext) {
                                        //todo 나중에 뭔가 더 생기면 위 구조 따를거.
                                    } else {
                                        loaded3.current = true;
                                    }
                                })
                                .catch((e) => {
                                    setHasNext(false);
                                    loading.current = false;
                                });
                        }
                    })
                    .catch((e) => {
                        setHasNext(false);
                        loading.current = false;
                    });
            }
            // loaded2.current === true
            else {
                Api.campaignAllListInactive(cursor3.current)
                    .then((res2) => {
                        if (loaded3.current === false) {
                            res2.items.forEach((item) => {
                                old.push(item);
                            });
                        }

                        cursor3.current = res2.nextCursor;
                        setHasNext(res2.hasNext);
                        setCampaignList(old);
                        loading.current = false;

                        if (res2.hasNext) {
                            //todo 나중에 뭔가 더 생기면 위 구조 따를거.
                        } else {
                            loaded3.current = true;
                        }
                    })
                    .catch((e) => {
                        setHasNext(false);
                        loading.current = false;
                    });
            }
        }
    }, [hasNext, campaignList]);

    useEffect(() => {
        load();
    }, []);

    function claimAllPressed(list: string[]) {
        if (wallet) {
            setHash({
                type: "CampaignAllClaim",
                isPending: true,
            });

            campaignAllClaim(list, wallet.terraAddress)
                .then((r) => {
                    return post(r);
                })
                .then((response) => {
                    setHash({
                        type: "CampaignAllClaim",
                        response: response,
                        redirect: undefined,
                    });
                })
                .catch((error) => {
                    setHash({
                        type: "CampaignAllClaim",
                        error: error,
                        redirect: undefined,
                    });
                });
        }
    }

    return (
        <div className={style.wrap}>
            <div className={cx(style.container, { hash: hash !== undefined })}>
                <TxBoxView
                    hash={hash}
                    clearHash={() => {
                        setHash(undefined);
                    }}
                    transparent={true}
                >
                    {/* V.1 Header
                    <Header claimAllPressed={claimAllPressed} />
                    <Header /> */}

                    {/* <Banner /> */}

                    {wallet ? (
                        <div className={style.filter_container}>
                            <TitleLabel
                                size={20}
                                fontWeight={"light"}
                                color={"#fff"}
                                text={"My Rewards"}
                            />
                        </div>
                    ) : (
                        <div />
                    )}

                    {wallet ? (
                        <div className={style.myreward}>
                            <Myreward claimAllPressed={claimAllPressed} />
                        </div>
                    ) : (
                        <div />
                    )}

                    <div
                        className={style.filter_container}
                        style={{ marginTop: "40px" }}
                    >
                        <TitleLabel
                            size={20}
                            fontWeight={"light"}
                            color={"#fff"}
                            text={"Coming Soon"}
                        />
                        <div style={{ flex: 1 }} />
                    </div>
                    <div className={style.comingsoon}>
                        <ComingSoon />
                    </div>
                    <div className={style.filter_container}>
                        <TitleLabel
                            size={20}
                            fontWeight={"light"}
                            color={"#fff"}
                            text={"Campaign List"}
                        />
                    </div>
                    <div className={style.item_container}>
                        {campaignList.map((item, index) => {
                            if (
                                item.campaignAddress ===
                                environment().contracts.lunart_early.campaign
                            ) {
                                return (
                                    <LunartCampaignItem
                                        key={index}
                                        info={item}
                                        isEarlybird={true}
                                    />
                                );
                            } else if (
                                item.campaignAddress ===
                                environment().contracts.lunart_public.campaign
                            ) {
                                return (
                                    <LunartCampaignItem
                                        key={index}
                                        info={item}
                                        isEarlybird={false}
                                    />
                                );
                            } else if (
                                item.campaignAddress ===
                                environment().contracts.fanfury_early.campaign
                            ) {
                                return (
                                    <FanfuryCampaignItem
                                        key={index}
                                        info={item}
                                        isEarlybird={true}
                                    />
                                );
                            } else if (
                                item.campaignAddress ===
                                environment().contracts.fanfury_public.campaign
                            ) {
                                return (
                                    <FanfuryCampaignItem
                                        key={index}
                                        info={item}
                                        isEarlybird={false}
                                    />
                                );
                            } else if (
                                item.campaignAddress ===
                                environment().contracts.talis_early.campaign
                            ) {
                                return (
                                    <TalisCampaignItem
                                        key={index}
                                        info={item}
                                        isEarlybird={true}
                                    />
                                );
                            } else if (
                                item.campaignAddress ===
                                environment().contracts.talis_public.campaign
                            ) {
                                return (
                                    <TalisCampaignItem
                                        key={index}
                                        info={item}
                                        isEarlybird={false}
                                    />
                                );
                            } else {
                                return <CampaignItem key={index} info={item} />;
                            }
                        })}
                    </div>
                    <MoreButton
                        isMore={hasNext && campaignList.length > 0}
                        onClick={() => {
                            load();
                        }}
                    />
                    {hasNext && campaignList.length > 0 ? (
                        <div style={{ height: "40px" }} />
                    ) : (
                        <div />
                    )}
                </TxBoxView>
            </div>
        </div>
    );
}
