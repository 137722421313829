import style from "./Myreward.module.scss";

import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
    campaignClaimableInfo,
    campaignClaimableList,
} from "../../../libs/api/rest";
import { walletState } from "../../../libs/contexts";
import { getDecimal } from "../../../libs/utils";
import TitleLabel from "../../common/Labels/TitleLabel";
import { NormalButton } from "../../common/NormalButton/NormalButton";
import sharingIcon from "../../../resources/ico-rewards-02.svg";
import depositIcon from "../../../resources/ico-deposit011.svg";
import participationIcon from "../../../resources/ico-rewards-01.svg";

export default function Myreward(props: {
    claimAllPressed: (list: string[]) => void;
}) {
    const wallet = useRecoilValue(walletState);

    const [info, setInfo] = useState<
        | {
              participation: {
                  locked: string;
                  claimable: string;
              };
              sharing: {
                  locked: string;
                  claimable: string;
              };
              deposit: {
                  locked: string;
                  claimable: string;
              };
          }
        | undefined
    >(undefined);

    const [claimableList, setClaimableList] = useState<string[] | undefined>(
        undefined
    );

    // deposit locked, claimable 둘 중 하나라도 값이 있으면 claimablelist에 담아온다.
    // front단에서 claimable이 0 인 경우를 판별하기 위한 const
    // false : 0 인 상태 , true : claim할 게 있는 상태
    const [isClaimable, setClaimable] = useState(false);

    const p = info ? info.participation : undefined;
    const s = info ? info.sharing : undefined;
    const d = info ? info.deposit : undefined;

    useEffect(() => {
        if (wallet) {
            campaignClaimableInfo(wallet.terraAddress)
                .then((r) => {
                    setInfo(r);
                    if (
                        r.deposit.claimable === "0" &&
                        r.sharing.claimable === "0" &&
                        r.participation.claimable === "0"
                    ) {
                        setClaimable(false);
                    } else {
                        setClaimable(true);
                    }
                })
                .catch((e) => {});

            campaignClaimableList(wallet.terraAddress, true)
                .then((r) => {
                    setClaimableList(r);
                })
                .catch((e) => {});
        }
    }, [wallet]);

    return (
        <div className={style.container_myreward}>
            {/* pc version */}
            <div className={style.container_myreward_amount}>
                <div className={style.amount_title}>
                    <div className={style.amount_title_participation}>
                        <div className={style.amount_title_participation_text}>
                            <TitleLabel
                                size={12}
                                fontWeight={"light"}
                                color={"rgba(255,255,255,0.5)"}
                                text={"Participation Info"}
                            />
                        </div>
                    </div>
                    <div className={style.amount_title_locked}>
                        <div className={style.amount_title_locked_text}>
                            <TitleLabel
                                size={12}
                                fontWeight={"light"}
                                color={"rgba(255,255,255,0.5)"}
                                text={"Locked"}
                            />
                        </div>
                        <div className={style.amount_title_locked_ico} />
                    </div>
                    <div className={style.amount_title_claimable}>
                        <div className={style.amount_title_claimable_text}>
                            <TitleLabel
                                size={12}
                                fontWeight={"light"}
                                color={"rgba(255,255,255,0.5)"}
                                text={"Claimable"}
                            />
                        </div>
                    </div>
                </div>
                <div className={style.amount_dummy} />
                <div className={style.amount_contents}>
                    <AmountDetail
                        title={"Participation"}
                        locked={p?.locked}
                        claimable={p?.claimable}
                        symbol={"UST"}
                        icon={participationIcon}
                    />
                </div>
                <div className={style.amount_dummy} />
                <div className={style.amount_contents}>
                    <AmountDetail
                        title={"Sharing"}
                        locked={s?.locked}
                        claimable={s?.claimable}
                        symbol={"UST"}
                        icon={sharingIcon}
                    />
                </div>
                <div className={style.amount_dummy} />
                <div className={style.amount_contents}>
                    <AmountDetail
                        title={"Deposit"}
                        locked={d?.locked}
                        claimable={d?.claimable}
                        symbol={"VKR"}
                        icon={depositIcon}
                    />
                </div>
            </div>
            <div className={style.container_myreward_dummy} />
            <div className={style.container_myreward_buttonarea}>
                <div className={style.myreward_button}>
                    <NormalButton
                        title={"Claim All Rewards"}
                        disabled={
                            !claimableList ||
                            claimableList.length === 0 ||
                            (claimableList.length !== 0 && !isClaimable)
                        }
                        onClick={() => {
                            if (claimableList) {
                                props.claimAllPressed(claimableList);
                            }
                        }}
                    />
                </div>
            </div>

            {/* mobile version */}
            <div className={style.mobile_container_myreward_amount}>
                <div className={style.mobile_amount_contents}>
                    <MobileAmountDetail
                        title={"Participation"}
                        locked={p?.locked}
                        claimable={p?.claimable}
                        symbol={"UST"}
                        icon={participationIcon}
                    />
                </div>
                <div className={style.mobile_amount_dummy} />
                <div className={style.mobile_amount_contents}>
                    <MobileAmountDetail
                        title={"Sharing"}
                        locked={s?.locked}
                        claimable={s?.claimable}
                        symbol={"UST"}
                        icon={sharingIcon}
                    />
                </div>
                <div className={style.mobile_amount_dummy} />
                <div className={style.mobile_amount_contents}>
                    <MobileAmountDetail
                        title={"Deposit"}
                        locked={d?.locked}
                        claimable={d?.claimable}
                        symbol={"VKR"}
                        icon={depositIcon}
                    />
                </div>
                <div className={style.mobile_amount_dummy} />
            </div>
            <div className={style.mobile_container_myreward_buttonarea}>
                <div className={style.mobile_myreward_button}>
                    <NormalButton
                        title={"Claim All Rewards"}
                        disabled={
                            !claimableList ||
                            claimableList.length === 0 ||
                            (claimableList.length !== 0 && !isClaimable)
                        }
                        onClick={() => {
                            if (claimableList) {
                                props.claimAllPressed(claimableList);
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

function AmountDetail(props: {
    icon: any;
    title: string;
    locked: string | undefined;
    claimable: string | undefined;
    symbol: string;
}) {
    const [lockedValue, setLockedValue] = useState<(string | undefined)[]>([
        undefined,
        undefined,
    ]);
    const [claimableValue, setClaimableValue] = useState<
        (string | undefined)[]
    >([undefined, undefined]);

    useEffect(() => {
        if (props.locked) {
            const splits = getDecimal(props.locked, true).split(".");
            let v1 = splits[0];
            let v2 = splits.length > 1 ? splits[1] : undefined;

            setLockedValue([v1, v2]);
        }
    }, [props.locked]);

    useEffect(() => {
        if (props.claimable) {
            const splits = getDecimal(props.claimable, true).split(".");
            let v1 = splits[0];
            let v2 = splits.length > 1 ? splits[1] : undefined;

            setClaimableValue([v1, v2]);
        }
    }, [props.claimable]);

    return (
        <div className={style.d_container}>
            <div className={style.info}>
                <img className={style.info_ico} src={props.icon} alt="" />
                <div className={style.info_text}>
                    <TitleLabel
                        size={16}
                        fontWeight={"light"}
                        color={"white"}
                        text={props.title}
                    />
                </div>
            </div>
            <div className={style.locked}>
                {props.locked ? (
                    <div className={style.lockedwrap}>
                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={lockedValue[0]}
                            color={"#f93660"}
                            symbol={lockedValue[1] ? undefined : props.symbol}
                        />
                        <div className={style.lockedwrap_second}>
                            <TitleLabel
                                size={12}
                                fontWeight={"medium"}
                                text={
                                    lockedValue[1]
                                        ? "." + lockedValue[1]
                                        : undefined
                                }
                                color={"#f93660"}
                                symbol={
                                    lockedValue[1] ? props.symbol : undefined
                                }
                            />
                        </div>
                    </div>
                ) : (
                    <TitleLabel text={undefined} skeleton={{ width: 100 }} />
                )}
            </div>
            <div className={style.claimable}>
                {props.claimable ? (
                    <div className={style.claimwrap}>
                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={claimableValue[0]}
                            color={"#ffe646"}
                            symbol={
                                claimableValue[1] ? undefined : props.symbol
                            }
                        />

                        <div className={style.claimwrap_second}>
                            <TitleLabel
                                size={12}
                                fontWeight={"medium"}
                                text={
                                    claimableValue[1]
                                        ? "." + claimableValue[1]
                                        : ""
                                }
                                color={"#ffe646"}
                                symbol={
                                    claimableValue[1] ? props.symbol : undefined
                                }
                            />
                        </div>
                    </div>
                ) : (
                    <TitleLabel text={undefined} skeleton={{ width: 100 }} />
                )}
            </div>
        </div>
    );
}

function MobileAmountDetail(props: {
    icon: any;
    title: string;
    locked: string | undefined;
    claimable: string | undefined;
    symbol: string;
}) {
    const [lockedValue, setLockedValue] = useState<(string | undefined)[]>([
        undefined,
        undefined,
    ]);
    const [claimableValue, setClaimableValue] = useState<
        (string | undefined)[]
    >([undefined, undefined]);

    useEffect(() => {
        if (props.locked) {
            const splits = getDecimal(props.locked, true).split(".");
            let v1 = splits[0];
            let v2 = splits.length > 1 ? splits[1] : undefined;

            setLockedValue([v1, v2]);
        }
    }, [props.locked]);

    useEffect(() => {
        if (props.claimable) {
            const splits = getDecimal(props.claimable, true).split(".");
            let v1 = splits[0];
            let v2 = splits.length > 1 ? splits[1] : undefined;

            setClaimableValue([v1, v2]);
        }
    }, [props.claimable]);

    return (
        <div className={style.mobile_d_container}>
            <div className={style.mobile_info}>
                <img
                    className={style.mobile_info_ico}
                    src={props.icon}
                    alt=""
                />
                <div className={style.mobile_info_text}>
                    <TitleLabel
                        size={16}
                        fontWeight={"light"}
                        color={"white"}
                        text={props.title}
                    />
                </div>
            </div>
            <div className={style.mobile_contents}>
                <div className={style.mobile_contents_locked}>
                    <div className={style.mobile_contents_locked_title}>
                        <div className={style.mobile_locked_text}>
                            <TitleLabel
                                size={12}
                                fontWeight={"light"}
                                color={"rgba(255,255,255,0.5)"}
                                text={"Locked"}
                            />
                        </div>
                        <div className={style.mobile_locked_ico} />
                    </div>
                    <div className={style.mobile_contents_locked_amount}>
                        {props.locked ? (
                            <div className={style.mobile_lockedwrap}>
                                <TitleLabel
                                    size={16}
                                    fontWeight={"medium"}
                                    text={lockedValue[0]}
                                    color={"#f93660"}
                                    symbol={
                                        lockedValue[1]
                                            ? undefined
                                            : props.symbol
                                    }
                                />
                                <div className={style.mobile_lockedwrap_second}>
                                    <TitleLabel
                                        size={12}
                                        fontWeight={"medium"}
                                        text={
                                            lockedValue[1]
                                                ? "." + lockedValue[1]
                                                : undefined
                                        }
                                        color={"#f93660"}
                                        symbol={
                                            lockedValue[1]
                                                ? props.symbol
                                                : undefined
                                        }
                                    />
                                </div>
                            </div>
                        ) : (
                            <TitleLabel
                                text={undefined}
                                skeleton={{ width: 100 }}
                            />
                        )}
                    </div>
                </div>
                <div className={style.mobile_contents_dummy} />
                <div className={style.mobile_contents_claimable}>
                    <div className={style.mobile_contents_claimable_title}>
                        <div className={style.mobile_claimable_text}>
                            <TitleLabel
                                size={12}
                                fontWeight={"light"}
                                color={"rgba(255,255,255,0.5)"}
                                text={"Claimable"}
                            />
                        </div>
                    </div>
                    <div className={style.mobile_contents_claimable_amount}>
                        <div className={style.mobile_contents_claimable_text}>
                            {props.claimable ? (
                                <div className={style.mobile_claimwrap}>
                                    <TitleLabel
                                        size={16}
                                        fontWeight={"medium"}
                                        text={claimableValue[0]}
                                        color={"#ffe646"}
                                        symbol={
                                            claimableValue[1]
                                                ? undefined
                                                : props.symbol
                                        }
                                    />

                                    <div
                                        className={
                                            style.mobile_claimwrap_second
                                        }
                                    >
                                        <TitleLabel
                                            size={12}
                                            fontWeight={"medium"}
                                            text={
                                                claimableValue[1]
                                                    ? "." + claimableValue[1]
                                                    : undefined
                                            }
                                            color={"#ffe646"}
                                            symbol={
                                                claimableValue[1]
                                                    ? props.symbol
                                                    : undefined
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <TitleLabel
                                    text={undefined}
                                    skeleton={{ width: 100 }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
