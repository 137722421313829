import style from "./StakeLpStake.module.scss";
import StakeLpStakeAuto from "./StakeLpStakeAuto";
import StakeLpStakeManual from "./StakeLpStakeManual";
import { StakeLpStakeManualMode, StakeLpStakeMode } from "../StakeLp";

export default function StakeLpStake(props: {
    mode1: StakeLpStakeMode;
    mode2: StakeLpStakeManualMode;
    estimateParams?: ResponseLpEstimate;
    stakeLpAutoPressed: (token: string, uusd: string) => void;
    makeLpPressed: (token: string, uusd: string) => void;
    stakeLpPressed: (amount: string) => void;
}) {
    return (
        <div className={style.container}>
            {props.mode1 === StakeLpStakeMode.auto ? (
                <StakeLpStakeAuto
                    estimateParams={props.estimateParams}
                    stakeLpAutoPressed={props.stakeLpAutoPressed}
                />
            ) : (
                <StakeLpStakeManual
                    mode={props.mode2}
                    estimateParams={props.estimateParams}
                    makeLpPressed={props.makeLpPressed}
                    stakeLpPressed={props.stakeLpPressed}
                />
            )}
        </div>
    );
}
