import { useWallet } from "@terra-money/wallet-provider";
import style from "./TestnetBadge.module.scss";

import { useEffect, useState } from "react";

export default function TestnetBadge() {
    const { status, network } = useWallet();
    const [mainnet, setMainnet] = useState(true);
    useEffect(() => {
        if (
            network.name.toLowerCase() === "mainnet" ||
            network.name.toLowerCase() === "classic"
        ) {
            setMainnet(true);
        } else {
            setMainnet(false);
        }
    }, [status, network]);

    if (mainnet) {
        return <div />;
    } else {
        return <div className={style.testnet_badge}>{"Testnet"}</div>;
    }
}
