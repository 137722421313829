import { useEffect, useState } from "react";
import * as Utils from "../../libs/utils";
import style from "./Trade.module.scss";
import {
    tokenSimulate,
    tokenReverseSimulate,
} from "../../libs/api/trade/query";
import InputField from "../common/InputField/InputField";
import ConfirmButton from "../common/ConfirmButton/ConfirmButton";
import {
    divide,
    isNumber,
    isZero,
    leftGreaterThanRight,
    multiply,
} from "../../libs/Math";
import TxInfoView from "../common/TxInfoView/TxInfoView";
import { useRecoilValue } from "recoil";
import { spreadState } from "../../libs/contexts";
import SpreadView from "./TradeSpread";
import { getFeeNormal } from "../../libs/api/fee";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function TradeSell(props: {
    balance: { token: string; uusd: string };
    sellPressed: (amount: string, spread: number) => void;
}) {
    const spread = useRecoilValue(spreadState);

    const [token, setToken] = useState("");
    const [uusd, setUusd] = useState("");
    const [simulate, setSimulate] = useState<ResponseSimulate | undefined>(
        undefined
    );

    const [error1, setError1] = useState<string | undefined>(undefined);
    const [error2, setError2] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (
            leftGreaterThanRight(token, Utils.getDecimal(props.balance.token))
        ) {
            setError1("Insufficient balance");
        } else if (isZero(token)) {
            setError1("Amount must be greater than 0");
        } else {
            setError1(undefined);
        }
    }, [token, props.balance.token]);

    useEffect(() => {
        simulate1(token, spread.spreadValue);
    }, [spread]);

    function simulate1(token: string, spread: number) {
        if (token) {
            tokenSimulate(false, multiply(token, 1000000), spread)
                .then((r: ResponseSimulate) => {
                    setSimulate(r);
                    setUusd(Utils.getDecimal(r.to, false, 3));
                    setError2(undefined);
                })
                .catch((e) => {});
        }
    }

    return (
        <div className={style.trade_inner_container}>
            <div className={style.from}>
                <InputField
                    title={"From"}
                    type="amount"
                    balance={props.balance.token}
                    symbol={"VKR"}
                    onChanged={(v: string) => {
                        setToken(v);
                        if (v) {
                            simulate1(v, spread.spreadValue);
                        } else {
                            setUusd("");
                        }
                    }}
                    value={token}
                    error={error1}
                    autofocus={true}
                    showSymbolIcon={true}
                />
            </div>

            {/* <div className={style.trade_exchangeicon} /> */}
            <div
                className={cx(style.arrow, {
                    error: error1,
                })}
            />

            <div className={style.to}>
                <InputField
                    title={"To"}
                    type="amount"
                    symbol={"UST"}
                    decimal={3}
                    onChanged={(v: string) => {
                        setUusd(v);
                        if (v) {
                            tokenReverseSimulate(
                                false,
                                multiply(v, 1000000),
                                spread.spreadValue
                            )
                                .then((r: ResponseSimulate) => {
                                    setSimulate(r);
                                    setToken(Utils.getDecimal(r.to));
                                    setError2(undefined);
                                })
                                .catch((e) => {
                                    setError2("Simulation failed");
                                });
                        } else {
                            setToken("");
                        }
                    }}
                    value={uusd}
                    error={error2}
                    showSymbolIcon={true}
                />
            </div>

            <SpreadView />

            {token && simulate ? (
                <InfoView simulate={simulate} isTrade={true} />
            ) : (
                <div />
            )}

            <div className={style.spreaddummy} />

            <div className={style.createbutton}>
                <div
                    className={cx(style.button, {
                        enable2: !error1 && !error2 && token !== "",
                    })}
                    onClick={() => {
                        if (!error1 && !error2 && token !== "") {
                            props.sellPressed(
                                multiply(token, 1000000),
                                spread.spreadValue
                            );
                        }
                    }}
                >
                    {"SELL"}
                </div>
            </div>

            {/* <ConfirmButton
                title="SELL"
                enable={!error1 && !error2 && token !== ""}
                isBuy={false}
                onClick={() => {
                    props.sellPressed(
                        multiply(token, 1000000),
                        spread.spreadValue
                    );
                }}
            /> */}
        </div>
    );
}

function InfoView(props: { simulate: ResponseSimulate; isTrade?: boolean }) {
    const spread = useRecoilValue(spreadState);
    const [fee, setFee] = useState("0");

    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => {});
    }, []);

    const [price, setPrice] = useState("0");
    useEffect(() => {
        let price = divide(1, props.simulate.rate);

        if (!isNumber(price)) {
            price = "0";
        }
        const p = multiply(price, 1000000);

        setPrice(Utils.getDecimal(p, true, 3));
    }, [props.simulate]);

    const [list, setList] = useState<any[]>([]);

    useEffect(() => {
        let list: any[] = [];

        list.push({
            title: "Estimated Price",
            body: price,
            symbol: " UST per VKR",
        });

        list.push({
            title: "Minimum Received",
            body: Utils.getDecimal(props.simulate.minimumReceive, true, 3),
            symbol: "UST",
        });

        list.push({
            title: "Trading Fee",
            body: Utils.getDecimal(props.simulate.tradingFee, true, 3),
            symbol: "UST",
        });

        setList(list);
    }, [props, fee, price, spread]);

    return <TxInfoView list={list} fee={fee} />;
}
