import { useEffect, useState } from "react";
import * as Utils from "../../../libs/utils";
import style from "./ProducerWithdraw.module.scss";
import InputField from "../../common/InputField/InputField";
import ConfirmButton from "../../common/ConfirmButton/ConfirmButton";
import { campaignManagerConfig } from "../../../libs/api/campaign/query";
import {
    queryNativeBalance,
    queryTokenBalance,
} from "../../../libs/api/trade/query";
import {
    divide,
    fixed,
    isZero,
    leftGreaterThanRight,
    minus,
    multiply,
    plus,
} from "../../../libs/Math";
import TxInfoView from "../../common/TxInfoView/TxInfoView";
import { calculateTax } from "../../../libs/api/tax";
import { getFeeNormal } from "../../../libs/api/fee";

export default function ProducerWithdraw(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    currencyIndex: number;
    setCurrencyIndex: (n: number) => void;
    currencyList: { title: string; value: string }[];
    showAddPopup: () => void;
    withdrawPressed: (amount: string, denom: string) => void;
}) {
    const [withdrawFee, setWithdrawFee] = useState<number | undefined>(
        undefined
    );
    const [balance, setBalance] = useState("0");

    const [denom, setDenom] = useState("");
    const [symbol, setSymbol] = useState("");
    const [amount, setAmount] = useState("");

    const [error1, setError1] = useState<string | undefined>(undefined);

    useEffect(() => {
        const currency = props.currencyList[props.currencyIndex];

        setDenom(currency.value);
        setSymbol(currency.title);
        setAmount("");

        const pDenom = props.summary.reward.participation.denom;
        const rDenom = props.summary.reward.referral.denom;

        if (currency.value === pDenom) {
            const filtered = props.summary.state.pool_balances.filter(
                (item) => {
                    return item.denom === pDenom;
                }
            );

            let balance = "0";
            filtered.forEach((item) => {
                balance = plus(balance, item.amount);
            });

            setBalance(balance);
        } else if (currency.value === rDenom) {
            const filtered = props.summary.state.pool_balances.filter(
                (item) => {
                    return item.denom === rDenom;
                }
            );

            let balance = "0";
            filtered.forEach((item) => {
                balance = plus(balance, item.amount);
            });

            setBalance(balance);
        } else if (!Utils.isNativeToken(currency.value)) {
            queryTokenBalance(currency.value, props.campaignAddress)
                .then((r) => {
                    setBalance(r.amount);
                })
                .catch((e) => {
                    setBalance("0");
                });
        } else {
            queryNativeBalance(currency.value, props.campaignAddress)
                .then((r) => {
                    setBalance(r.amount);
                })
                .catch((e) => {
                    setBalance("0");
                });
        }
    }, [
        props.currencyIndex,
        props.campaignAddress,
        props.currencyList,
        props.summary,
    ]);

    useEffect(() => {
        const bn = multiply(amount, 1000000);
        if (leftGreaterThanRight(bn, balance)) {
            setError1("Insufficient balance");
        } else if (isZero(amount)) {
            setError1("Amount must be greater than 0");
        } else {
            setError1(undefined);
        }
    }, [amount, balance]);

    useEffect(() => {
        campaignManagerConfig()
            .then((r) => {
                setWithdrawFee(r.remove_pool_fee_rate);
            })
            .catch((e) => {});
    }, []);

    return (
        <div className={style.container}>
            <InputField
                title={"Amount"}
                type="amount"
                balance={symbol ? balance : undefined}
                symbol={symbol}
                decimal={denom === "uusd" ? 3 : 6}
                onChanged={setAmount}
                value={amount}
                error={error1}
                combo={{
                    selected: props.currencyIndex,
                    setSelected: (v) => {
                        if (v === 0) {
                            props.showAddPopup();
                        } else {
                            props.setCurrencyIndex(v);
                        }
                    },
                    list: props.currencyList.map((item) => {
                        return item.title;
                    }),
                }}
            />

            <InputField
                title={"Withdraw Address"}
                type="text"
                disabled={true}
                onChanged={(v: string) => {}}
                value={props.summary.config.creator}
                error={undefined}
            />

            {amount && symbol && withdrawFee ? (
                <InfoView
                    amount={amount}
                    denom={denom}
                    symbol={symbol}
                    withdrawFee={withdrawFee}
                    isPending={props.summary.state.is_pending}
                />
            ) : (
                <div />
            )}

            <ConfirmButton
                title="Remove from Reward Pool"
                enable={!error1 && amount !== "" && withdrawFee !== undefined}
                onClick={() => {
                    props.withdrawPressed(
                        multiply(amount, 1000000),
                        props.currencyList[props.currencyIndex].value
                    );
                }}
            />
        </div>
    );
}

function InfoView(props: {
    amount: string;
    denom: string;
    symbol: string;
    withdrawFee: number;
    isPending: boolean;
}) {
    const [fee, setFee] = useState("0");

    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => {});
    }, []);

    const [balance, setBalance] = useState("0");
    const [withdrawFee, setWithdrawFee] = useState("0");
    useEffect(() => {
        const amount = multiply(props.amount, 1000000);

        if (Utils.isNativeToken(props.denom)) {
            if (!props.isPending) {
                const divided = divide(amount, 100 + props.withdrawFee * 100);
                const receive = fixed(multiply(divided, 100), 0);

                calculateTax(receive, props.denom)
                    .then((tax) => {
                        const r = minus(receive, tax);
                        setBalance(fixed(r, 0));
                        setWithdrawFee(fixed(minus(amount, r), 0));
                    })
                    .catch((e) => {});
            } else {
                calculateTax(amount, props.denom)
                    .then((tax) => {
                        setBalance(fixed(minus(amount, tax), 0));
                        setWithdrawFee(
                            fixed(minus(amount, minus(amount, tax)), 0)
                        );
                    })
                    .catch((e) => {});
            }
        } else {
            if (!props.isPending) {
                const divided = divide(amount, 100 + props.withdrawFee * 100);
                setBalance(fixed(multiply(divided, 100), 0));
                setWithdrawFee(
                    fixed(multiply(divided, props.withdrawFee * 100), 0)
                );
            } else {
                setBalance(fixed(amount, 0));
                setWithdrawFee("0");
            }
        }
    }, [props]);

    if (leftGreaterThanRight(withdrawFee, 0)) {
        const list = [
            {
                title: "Withdraw Balance",
                body: Utils.getDecimal(
                    balance,
                    true,
                    props.denom === "uusd" ? 3 : 6
                ),
                symbol: props.symbol,
            },
            {
                title: "Withdraw Fee",
                body: Utils.getDecimal(
                    withdrawFee,
                    true,
                    props.denom === "uusd" ? 3 : 6
                ),
                symbol: props.symbol,
            },
        ];

        return <TxInfoView list={list} fee={fee} />;
    } else {
        const list = [
            {
                title: "Withdraw Balance",
                body: Utils.getDecimal(
                    balance,
                    true,
                    props.denom === "uusd" ? 3 : 6
                ),
                symbol: props.symbol,
            },
        ];

        return <TxInfoView list={list} fee={fee} />;
    }
}
