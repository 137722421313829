import { useEffect, useState } from "react";

import style from "./ProducerDeposit.module.scss";
import * as Utils from "../../../libs/utils";
import ConfirmButton from "../../common/ConfirmButton/ConfirmButton";
import InputField from "../../common/InputField/InputField";
import {
    queryNativeBalance,
    queryPrice,
    queryTokenBalance,
} from "../../../libs/api/trade/query";
import {
    divide,
    isZero,
    leftGreaterThanRight,
    leftLessThanRight,
    multiply,
    plus,
} from "../../../libs/Math";
import TxInfoView from "../../common/TxInfoView/TxInfoView";
import Warning from "../../common/Warning/Warning";
import { campaignManagerConfig } from "../../../libs/api/campaign/query";
import TitleLabel from "../../common/Labels/TitleLabel";
import CampaignCell4 from "../../common/CampaignComponents/CampaignCell4";

import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
import { getFeeNormal } from "../../../libs/api/fee";

export default function ProducerDeposit(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    depositPressed: (
        amount: string,
        amount1Denom: string,
        denom: string
    ) => void;
}) {
    const wallet = useRecoilValue(walletState);

    const [error1, setError1] = useState<string | undefined>(undefined);
    const [error2, setError2] = useState<string | undefined>(undefined);

    const [contractBalance1, setContractBalance1] = useState("0");
    const [contractBalance2, setContractBalance2] = useState("0");

    const [quorum, setQuorum] = useState(20);
    const [depositFeeRate, setDepositFeeRate] = useState(0);

    const [balance1, setBalance1] = useState("0");
    const [denom1, setDenom1] = useState("");
    const [symbol1, setSymbol1] = useState("");
    const [price1, setPrice1] = useState("0");
    const [estimated1, setEstimated1] = useState("0");

    const [balance2, setBalance2] = useState("0");
    const [denom2, setDenom2] = useState("");
    const [symbol2, setSymbol2] = useState("");
    const [price2, setPrice2] = useState("0");
    const [estimated2, setEstimated2] = useState("0");

    const [amount1, setAmount1] = useState("");
    const [amount2, setAmount2] = useState("");

    useEffect(() => {
        campaignManagerConfig()
            .then((r) => {
                const q = (r.add_pool_min_referral_reward_rate ?? 0) * 100;
                setQuorum(q);

                setDepositFeeRate(r.add_pool_fee_rate ?? 0);
            })
            .catch((e) => {});

        const reward = props.summary.reward;
        setDenom1(reward.participation.denom);
        setSymbol1(reward.participation.symbol);
        setDenom2(reward.referral.denom);
        setSymbol2(reward.referral.symbol);

        const pFiltered = props.summary.state.pool_balances.filter((item) => {
            return item.denom === props.summary.reward.participation.denom;
        });
        setContractBalance1(pFiltered.length > 0 ? pFiltered[0].amount : "0");
        const rFiltered = props.summary.state.pool_balances.filter((item) => {
            return item.denom === props.summary.reward.referral.denom;
        });
        setContractBalance2(rFiltered.length > 0 ? rFiltered[0].amount : "0");

        const denom1 = reward.participation.denom;
        const isNative1 = Utils.isNativeToken(denom1);

        queryPrice(denom1)
            .then((r) => {
                setPrice1(r);
            })
            .catch((e) => {});

        if (!isNative1) {
            if (wallet) {
                queryTokenBalance(denom1, wallet.terraAddress)
                    .then((r) => {
                        setBalance1(r.amount);
                    })
                    .catch((e) => {});
            }
        } else {
            if (wallet) {
                queryNativeBalance(denom1, wallet.terraAddress)
                    .then((r) => {
                        setBalance1(r.amount);
                    })
                    .catch((e) => {});
            }
        }

        const denom2 = reward.referral.denom;
        const isNative2 = Utils.isNativeToken(denom2);

        queryPrice(denom2)
            .then((r) => {
                setPrice2(r);
            })
            .catch((e) => {});
        if (!isNative2) {
            if (wallet) {
                queryTokenBalance(denom2, wallet.terraAddress)
                    .then((r) => {
                        setBalance2(r.amount);
                    })
                    .catch((e) => {});
            }
        } else {
            if (wallet) {
                queryNativeBalance(denom2, wallet.terraAddress)
                    .then((r) => {
                        setBalance2(r.amount);
                    })
                    .catch((e) => {});
            }
        }
    }, [props.summary, wallet]);

    useEffect(() => {
        if (amount1) {
            setEstimated1(multiply(price1, multiply(amount1, 1000000)));
        } else {
            setEstimated1("0");
        }
    }, [amount1, price1]);

    useEffect(() => {
        if (amount2) {
            setEstimated2(multiply(price2, multiply(amount2, 1000000)));
        } else {
            setEstimated2("0");
        }
    }, [amount2, price2]);

    useEffect(() => {
        if (!amount1) {
            return;
        }
        if (
            leftGreaterThanRight(
                amount1,
                Utils.getDecimal(balance1, false, denom1 === "uusd" ? 3 : 6)
            )
        ) {
            setError1("Insufficient balance");
        } else if (isZero(amount1)) {
            setError1("Amount must be greater than 0");
        } else {
            setError1(undefined);
        }
    }, [amount1, balance1]);

    useEffect(() => {
        if (!amount2) {
            return;
        }
        if (leftGreaterThanRight(amount2, Utils.getDecimal(balance2))) {
            setError2("Insufficient balance");
        } else if (isZero(amount2)) {
            setError2("Amount must be greater than 0");
        } else if (
            leftLessThanRight(
                divide(estimated2, estimated1),
                divide(quorum, 100)
            )
        ) {
            // setError2("Check quorum");
            setError2(undefined);
        } else {
            setError2(undefined);
        }
    }, [amount2, balance2, estimated1, estimated2, quorum]);

    return (
        <div className={style.container}>
            <div className={style.balance}>
                <CampaignCell4
                    pReward={contractBalance1}
                    pDenom={denom1}
                    pSymbol={symbol1}
                    rReward={contractBalance2}
                    rDenom={denom2}
                    rSymbol={symbol2}
                    skeleton={false}
                />
            </div>
            <div className={style.row0}>
                <div className={style.col1}>
                    <InputField
                        title={"Participation"}
                        type="amount"
                        decimal={denom1 === "uusd" ? 3 : 6}
                        balance={balance1}
                        symbol={symbol1}
                        onChanged={(v: string) => {
                            setAmount1(v);
                        }}
                        value={amount1}

                        // error={error1}
                    />

                    <div className={style.label}>
                        <TitleLabel
                            size={12}
                            fontWeight={"light"}
                            text={Utils.getDecimal(estimated1, true, 3)}
                            symbol={"UST:"}
                            color={"#646464"}
                        />
                        &nbsp;
                        <TitleLabel
                            size={12}
                            text={Utils.getPercentFormat(
                                divide(estimated1, plus(estimated1, estimated2))
                            )}
                            symbol={"%"}
                            color={"white"}
                        />
                    </div>
                </div>
                <div className={style.icon} />

                <div className={style.col1}>
                    <InputField
                        title={"Sharing"}
                        type="amount"
                        decimal={denom2 === "uusd" ? 3 : 6}
                        balance={balance2}
                        symbol={symbol2}
                        onChanged={(v: string) => {
                            setAmount2(v);
                        }}
                        value={amount2}
                        error={error2}
                    />
                    <div className={style.label}>
                        <TitleLabel
                            size={12}
                            fontWeight={"light"}
                            text={Utils.getDecimal(estimated2, true, 3)}
                            symbol={"UST:"}
                            color={"#646464"}
                        />
                        &nbsp;
                        <TitleLabel
                            size={12}
                            text={Utils.getPercentFormat(
                                divide(estimated2, plus(estimated1, estimated2))
                            )}
                            symbol={"%"}
                            color={"white"}
                        />
                    </div>
                </div>
            </div>

            <Slider uusd1={estimated1} uusd2={estimated2} quorum={quorum} />

            <div style={{ height: "40px" }} />
            <Warning
                msg={
                    "At the time of deposit, the UST ratio of the Refral pool must be at least " +
                    Utils.getPercentFormat(divide(quorum, 100)) +
                    "%. Deposit via Contract Wallet address cannot be used as a Reward."
                }
            />
            <div style={{ height: "40px" }} />
            <InfoView
                uusd1={estimated1}
                uusd2={estimated2}
                price={price2}
                depositFeeRate={depositFeeRate}
            />

            <ConfirmButton
                title="Add to Reward Pool"
                enable={!error1 && !error2 && amount1 !== "" && amount2 !== ""}
                onClick={() => {
                    props.depositPressed(
                        multiply(amount1, 1000000),
                        props.summary.reward.participation.denom,
                        multiply(amount2, 1000000)
                    );
                }}
            />
        </div>
    );
}

function InfoView(props: {
    uusd1: string;
    uusd2: string;
    price: string;
    depositFeeRate: number;
}) {
    const [fee, setFee] = useState("0");

    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => {});
    }, []);

    const total = parseFloat(plus(props.uusd1, props.uusd2));
    const depositFee = multiply(
        divide(total, props.price),
        props.depositFeeRate
    );

    const list = [
        {
            title: "Add Fee",
            body: Utils.getDecimal(depositFee, true),
            symbol: "VKR",
        },
    ];

    return <TxInfoView list={list} fee={fee} />;
}

function Slider(props: {
    uusd1: string;
    uusd2: string;

    quorum: number;
}) {
    const total = parseFloat(plus(props.uusd1, props.uusd2));
    const sliderRate = parseFloat(divide(props.uusd1, total)) * 100;

    const color = 100 - sliderRate >= props.quorum ? "#435bd1" : "#ff0000";

    return (
        <div className={style.row1}>
            <div className={style.title_container}>
                <TitleLabel
                    text={
                        "Particitaion " +
                        Utils.getPercentFormat(divide(sliderRate, 100)) +
                        "%"
                    }
                    size={14}
                    color={"#786f64"}
                />
                <TitleLabel
                    text={
                        "Sharing " +
                        Utils.getPercentFormat(divide(100 - sliderRate, 100)) +
                        "%"
                    }
                    size={14}
                    color={color}
                />
            </div>
            <div
                className={style.bg1}
                style={{
                    backgroundColor: color,
                }}
            >
                <div
                    className={style.rate}
                    style={{ width: sliderRate + "%" }}
                />
            </div>
        </div>
    );
}
