import style from "./DashboardChartCell2.module.scss";
import * as Utils from "../../../libs/utils";

import TitleLabel from "../Labels/TitleLabel";
import { useEffect, useState } from "react";

export default function DashboardChartCell2(props: {
    color: any;
    title: string;
    percent: string;
    body: string;
    symbol: string;
    uusd?: string;
    select?: boolean;
}) {
    const percent = isNaN(parseFloat(props.percent)) ? "0" : props.percent;

    const [token, setToken] = useState<AmountSplitModel | undefined>(undefined);

    useEffect(() => {
        setToken(Utils.splitAmountByDot(props.body, 3));
    }, [props]);

    return (
        <div className={style.container}>
            <div className={style.title}>
                <div
                    className={style.icon}
                    style={{ backgroundColor: props.color }}
                />
                {props.select ? (
                    <TitleLabel
                        text={props.title + " " + percent}
                        size={12}
                        fontWeight="light"
                        color={"#ffe646"}
                        symbol={"%"}
                    />
                ) : (
                    <TitleLabel
                        text={props.title + " " + percent}
                        size={12}
                        fontWeight="light"
                        color={"#fff"}
                        symbol={"%"}
                    />
                )}
            </div>
            <div className={style.num}>
                {props.select ? (
                    <TitleLabel
                        text={token ? token.num1 : undefined}
                        size={16}
                        fontWeight="bold"
                        color="#ffe646"
                        symbol={
                            token && token.num2
                                ? "." + token.num2 + " " + props.symbol
                                : props.symbol
                        }
                    />
                ) : (
                    <TitleLabel
                        text={token ? token.num1 : undefined}
                        size={16}
                        fontWeight="bold"
                        color="#fff"
                        symbol={
                            token && token.num2
                                ? "." + token.num2 + " " + props.symbol
                                : props.symbol
                        }
                    />
                )}

                <div className={style.dummy} />
                {props.uusd ? (
                    <div className={style.uusdamount}>
                        <div className={style.special}>&asymp;</div>
                        <TitleLabel
                            text={Utils.getDecimal(props.uusd, true, 3)}
                            size={12}
                            fontWeight="light"
                            color="#fff"
                            opacity={0.3}
                            symbol={"UST"}
                        />
                    </div>
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
}
