import ConfirmButton from "../../common/ConfirmButton/ConfirmButton";
import TxInfoView from "../../common/TxInfoView/TxInfoView";
import { useEffect, useState } from "react";
import { getFeeNormal } from "../../../libs/api/fee";
import LineDummy from "../../common/LineDummy/LineDummy";

export default function GovCreateFormFooter(props: {
    enable: boolean;
    pressed: () => void;
}) {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <InfoView />

            {/* <div className={style.line_container}> */}
            <LineDummy horizontal={true} marginTop={10} marginBottom={10} />
            {/* </div> */}

            <ConfirmButton
                title="Submit"
                enable={props.enable}
                onClick={props.pressed}
            />
        </div>
    );
}

function InfoView() {
    const [fee, setFee] = useState("0");

    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => { });
    }, []);

    return <TxInfoView fee={fee} />;
}
