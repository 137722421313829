import { useEffect, useState } from "react";
import * as Utils from "../../../../libs/utils";
import style from "./StakeLpUnstake.module.scss";
import {
    astroportDepositAmount,
    unstakeLpEstimate,
} from "../../../../libs/api/astroport_liquidity/query";
import InputField from "../../../common/InputField/InputField";
import ConfirmButton from "../../../common/ConfirmButton/ConfirmButton";

import {
    fixed,
    isZero,
    leftGreaterThanRight,
    multiply,
} from "../../../../libs/Math";
import TxInfoView from "../../../common/TxInfoView/TxInfoView";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../libs/contexts";
import { getFeeNormal } from "../../../../libs/api/fee";
import LineDummy from "../../../common/LineDummy/LineDummy";

export default function StakeLpUnstakeAuto(props: {
    estimateParams?: ResponseLpEstimate;
    unstakeLpAutoPressed: (amount: string) => void;
}) {
    const wallet = useRecoilValue(walletState);
    const [balance, setBalance] = useState("0");

    const [token, setToken] = useState("");
    const [error1, setError1] = useState<string | undefined>(undefined);
    const [lpEstimated, setLpEstimated] = useState({
        token: "",
        uusd: "",
    });

    useEffect(() => {
        if (wallet) {
            astroportDepositAmount(wallet.terraAddress)
                .then((r) => {
                    setBalance(r);
                })
                .catch((e) => {
                    setBalance("0");
                });
        }
    }, [wallet]);

    useEffect(() => {
        if (leftGreaterThanRight(token, Utils.getDecimal(balance))) {
            setError1("Insufficient balance");
        } else if (isZero(token)) {
            setError1("Amount must be greater than 0");
        } else {
            setError1(undefined);
        }
    }, [token, balance]);

    useEffect(() => {
        if (token && props.estimateParams) {
            unstakeLpEstimate(
                {
                    token: props.estimateParams.pools.token,
                    uusd: props.estimateParams.pools.uusd,
                },
                multiply(token, 1000000),
                props.estimateParams.lp_total_supply
            )
                .then((r) => {
                    setLpEstimated(r);
                })
                .catch((e) => { });
        } else {
            setLpEstimated({
                token: "0",
                uusd: "0",
            });
        }
    }, [token, props.estimateParams]);

    return (
        <div className={style.inner_container}>
            <InputField
                title={"VKR-UST LP"}
                type="amount"
                balance={balance}
                symbol={"LP"}
                onChanged={(v: string) => {
                    setToken(v);
                }}
                value={token}
                error={error1}
                autofocus={true}
                showSymbolIcon={true}
            />

            <div className={style.line_container}>
                <LineDummy horizontal={true} marginTop={15} marginBottom={0} />
            </div>

            <div className={style.exchangeicon} />
            {/* <div style={{ height: "80px" }} /> */}

            <div className={style.row}>
                {/* <div style={{ flex: 1 }}> */}
                <InputField
                    title={"Output VKR"}
                    type="amount"
                    symbol={"VKR"}
                    disabled={true}
                    onChanged={(v: string) => { }}
                    value={Utils.getDecimal(lpEstimated.token, true)}
                    error={undefined}
                    showSymbolIcon={true}
                />
                {/* </div> */}

                <div className={style.icon} />

                <InputField
                    title={"Output UST"}
                    type="amount"
                    symbol={"UST"}
                    disabled={true}
                    onChanged={(v: string) => { }}
                    value={Utils.getDecimal(lpEstimated.uusd, true, 3)}
                    error={undefined}
                    showSymbolIcon={true}
                />
                {/* </div> */}
            </div>

            {token ? <InfoView /> : <div />}

            <div className={style.spreaddummy} />

            <ConfirmButton
                title={"Withdraw"}
                enable={!error1 && token !== ""}
                onClick={() => {
                    props.unstakeLpAutoPressed(
                        fixed(multiply(token, 1000000), 0)
                    );
                }}
            />
        </div>
    );
}

function InfoView() {
    const [fee, setFee] = useState("0");
    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => { });
    }, []);

    return <TxInfoView fee={fee} />;
}
