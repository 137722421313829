import ConfirmButton from "../../../common/ConfirmButton/ConfirmButton";
import TxInfoView from "../../../common/TxInfoView/TxInfoView";
import { useEffect, useState } from "react";
import { getFeeNormal } from "../../../../libs/api/fee";

export default function ProducerSettingFormFooter(props: {
    enable: boolean;
    pressed: () => void;
}) {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <InfoView />

            <ConfirmButton
                title="Change Status"
                enable={props.enable}
                onClick={props.pressed}
            />
        </div>
    );
}

function InfoView() {
    const [fee, setFee] = useState("0");

    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => {});
    }, []);

    return <TxInfoView fee={fee} />;
}
