import { useState } from "react";
import style from "./Producer.module.scss";
import * as Api from "../../libs/api/rest";
import PageHeader from "../common/PageHeader/PageHeader";
import { PATHS } from "../../App";
import { MoreButton2 } from "../common/MoreButton/MoreButton";
import { NormalButton } from "../common/NormalButton/NormalButton";
import { useEffect } from "react";
import { useRef } from "react";
import EmptyView from "../common/EmptyView/EmptyView";
import CampaignItem from "../Campaigns/CampaignItem/CampaignsItem";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { walletState } from "../../libs/contexts";
import TitleLabel from "../common/Labels/TitleLabel";

export default function Producer(props: any) {
    const history = useHistory();
    const wallet = useRecoilValue(walletState);
    const [connected, setConnected] = useState(false);

    const loading = useRef(false);
    const cursor = useRef<string | undefined>(undefined);
    const [hasNext, setHasNext] = useState(true);

    const [campaignList, setCampaignList] = useState<CampaignListItem[]>([]);

    function load(clear?: boolean) {
        if (clear) {
            cursor.current = undefined;
        }

        if (loading.current || (!clear && !hasNext)) {
            return;
        }

        loading.current = true;

        Api.producerList(cursor.current, wallet!.terraAddress)
            .then((r) => {
                let old: CampaignListItem[] = [];

                if (!clear) {
                    old = campaignList.slice();
                }

                r.items.forEach((item) => {
                    old.push(item);
                });

                cursor.current = r.nextCursor;
                setHasNext(r.hasNext);
                setCampaignList(old);

                loading.current = false;
            })
            .catch((e) => {
                setHasNext(false);
                loading.current = false;
            });
    }

    useEffect(() => {
        setConnected(wallet ? true : false);

        if (!wallet) {
            return;
        }

        Api.isWhitelistedCreator(wallet ? wallet.terraAddress : undefined).then(
            (r) => {
                if (r) {
                    load(true);
                } else {
                    history.replace(PATHS.home);
                }
            }
        );
    }, [wallet]);

    if (!connected) {
        return <div />;
    }

    return (
        <div className={style.container}>
            <div className={style.title}>
                <TitleLabel
                    text="Creator"
                    size={20}
                    fontWeight="light"
                    color={"white"}
                />
            </div>

            <div className={style.create_button}>
                <div className={style.button}>
                    <NormalButton
                        title="+ Create a Campaign"
                        onClick={() => {
                            props.history.push(PATHS.producerCreate);
                        }}
                    />
                </div>
            </div>

            {campaignList.length === 0 && !hasNext ? (
                <EmptyView title="No Existing Campaigns" />
            ) : (
                <div className={style.list_container}>
                    {campaignList.map((item, index) => {
                        return (
                            <CampaignItem
                                key={index}
                                isProducer={true}
                                info={item}
                            />
                        );
                    })}
                </div>
            )}

            <MoreButton2
                isMore={hasNext && campaignList.length > 0}
                onClick={() => {
                    load();
                }}
            />
        </div>
    );
}
