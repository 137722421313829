import InputField from "../../common/InputField/InputField";
import { useState } from "react";
import GovCreateFormFooter from "./GovCreateFormFooter";
import { useEffect } from "react";
import GovCreateFormHeader from "./GovCreateFormHeader";
import { addressValidate } from "../../../libs/api/api";
import * as Utils from "../../../libs/utils";
import { poll5 } from "../../../libs/api/governance/execution";
import { PATHS } from "../../../App";
import { fixed, leftGreaterThanRight, multiply } from "../../../libs/Math";
import { useWallet } from "@terra-money/wallet-provider";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";

export default function GovCreateForm5(props: {
    setHash: (h: HashResult) => void;
    deposit: string;
}) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);
    const [headerInfo, setHeaderInfo] = useState({
        title: "",
        desc: "",
        link: "",
    });
    const [headerValid, setHeaderValid] = useState(false);

    const [recipient, setRecipient] = useState("");
    const [amount, setAmount] = useState("");

    const [valid, setValid] = useState(false);
    useEffect(() => {
        if (
            addressValidate(recipient) &&
            amount &&
            leftGreaterThanRight(amount, 0) &&
            headerValid
        ) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [recipient, amount, headerValid]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <GovCreateFormHeader
                setInfo={setHeaderInfo}
                setValid={setHeaderValid}
            />

            <InputField
                title="Recipient"
                type="text"
                disabled={false}
                tooltip="Recipient terra address of grant"
                value={recipient}
                onChanged={setRecipient}
                error={
                    recipient && !addressValidate(recipient)
                        ? "Invalid address"
                        : undefined
                }
            />

            <InputField
                title="Amount"
                type="amount"
                disabled={false}
                tooltip="Amount of funding requested"
                symbol={"VKR"}
                value={amount}
                onChanged={setAmount}
            />

            <InputField
                title="Proposal Deposit"
                type="amount"
                tooltip={
                    "Proposed minimum VKR deposit required for submitting a new poll"
                }
                disabled={true}
                symbol={"VKR"}
                value={Utils.getDecimal(props.deposit, true)}
                onChanged={(v) => {}}
            />

            <GovCreateFormFooter
                enable={valid}
                pressed={() => {
                    props.setHash({
                        type: "GovPoll5",
                        isPending: true,
                    });

                    poll5(
                        wallet!.terraAddress,
                        headerInfo,
                        recipient,
                        fixed(multiply(amount, 1000000), 0)
                    )
                        .then((r) => {
                            return post(r);
                        })
                        .then((response) => {
                            props.setHash({
                                type: "GovPoll5",
                                response: response,
                                redirect: PATHS.governance,
                            });
                        })
                        .catch((error) => {
                            props.setHash({
                                type: "GovPoll5",
                                error: error,
                                redirect: undefined,
                            });
                        });
                }}
            />
        </div>
    );
}
