import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";

import { useSetRecoilState } from "recoil";
import { SkeletonTheme } from "react-loading-skeleton";

import Header from "./views/Header/Header";
import Main from "./views/main/Main";
import Stake from "./views/stake/Stake";

import "./App.scss";
import Trade from "./views/Trade/Trade";
import ConnectWay from "./views/ConnectWay/ConnectWay";

import Campaigns from "./views/Campaigns/Campaigns";
import CampaignDetail from "./views/Campaigns/detail/CampaignDetail";

import Footer from "./views/Footer/Footer";

import GovernancePollDetail from "./views/Governance/GovernancePollDetail";
import GovernanceVote from "./views/Governance/GovernanceVote";
import GovernanceCreate from "./views/Governance/GovernanceCreate";
import GovernanceCreateForm from "./views/Governance/CreateForm/GovernanceCreateForm";

import ScrollToTop from "./libs/ScrollToTop";

import StakeGovernance from "./views/stake/gov/StakeGovernance";
import StakeLp from "./views/stake/lp/StakeLp";
import History from "./views/History/History";

import Producer from "./views/Producer/Producer";
import ProducerCreate from "./views/Producer/Create/ProducerCreate";
import ProducerSetting from "./views/Producer/Setting/ProducerSetting";
import ProducerDepositWithdraw from "./views/Producer/DepositWithdraw/ProducerDepositWithdraw";
import ProducerDetail from "./views/Producer/detail/ProducerDetail";
import ProducerSettingQualification from "./views/Producer/Setting/SettingForm/ProducerSettingQualification";
import ProducerSettingGeneral from "./views/Producer/Setting/SettingForm/ProducerSettingGeneral";
import ProducerSettingActivation from "./views/Producer/Setting/SettingForm/ProducerSettingActivation";

import { setNetwork } from "./libs/storage";
import { useEffect, useState } from "react";
import { walletState } from "./libs/contexts";
import Airdrop from "./views/Airdrop/Airdrop";
import {
    useConnectedWallet,
    useWallet,
    WalletStatus,
} from "@terra-money/wallet-provider";
import StakeLpLagacy from "./views/stake/lp/lagacy/StakeLpLagacy";
import Vp from "./views/VP/VPMain";
import Mypage from "./views/Mypage/Mypage";
import ProducerSettingRegisterQualifier from "./views/Producer/Setting/SettingForm/ProducerSettingRegisterQualifier";
import { getLcdClient } from "./libs/api/api";
import ConfirmButton from "./views/common/ConfirmButton/ConfirmButton";
import { YellowButton } from "./views/common/NormalButton/NormalButton";

export const PATHS = {
    home: "/",
    airdrop: "/airdrop",

    // stake: "/stake",
    stakeLp: "/stake/lp",
    stakeGov: "/stake/gov",
    stakeLagacy: "/stake/terraswap",

    trade: "/trade",
    connect: "/connect",
    campaigns: "/campaigns",
    campaignsDetail: "/campaigns/",

    history: "/history",

    governance: "/governance",
    governancePollDetail: "/governance/poll/",
    governanceVote: "/governance/vote",
    governanceCreate: "/governance/create",
    governanceCreateForm: "/governance/create_form",

    mypage: "/mypage",

    producer: "/creator",
    producerDetail: "/creator/campaigns/",
    producerCreate: "/creator/create",

    producerSetting: "/creator/setting",

    producerSettingQualification: "/creator/setting/qualification",
    producerSettingGeneral: "/creator/setting/general",
    producerSettingActivation: "/creator/setting/activate",
    producerSettingRegisterQualifier: "/creator/setting/register_qualifier",

    producerDepositWithdraw: "/creator/dw",

    vp: "/vp",
};

export default function App() {
    const { status, network, disconnect } = useWallet();
    const connectedWallet = useConnectedWallet();
    const [initialized, setInitialized] = useState(false);
    const setWallet = useSetRecoilState(walletState);

    const [isV2, setV2] = useState(false);
    const [closed, setClosed] = useState(false);

    useEffect(() => {
        if (status !== WalletStatus.INITIALIZING) {
            setNetwork(network);

            const obj = connectedWallet
                ? { terraAddress: connectedWallet.terraAddress }
                : undefined;
            setWallet(obj);
            setInitialized(true);
        } else {
            setInitialized(false);
        }
    }, [status, network, connectedWallet, setWallet]);

    useEffect(() => {
        if (initialized) {
            if (
                network.chainID === "columbus-5" ||
                network.chainID === "bombay-12"
            ) {
                setV2(false);
            } else {
                setV2(true);
            }
        }
    }, [initialized, network]);

    if (network.name === "mainnet" && connectedWallet !== undefined) {
        disconnect();
        window.location.replace("https://app.valkyrieprotocol.com/");
        return <div />;
    }

    if (!initialized) {
        return <div />;
    }

    return (
        <SkeletonTheme
            color="rgba(63,70,87, 0.4)"
            highlightColor="rgba(175,188,212,0.4)"
        >
            <Router>
                <ScrollToTop />
                <div className="app_container">
                    <Header />
                    <div className="bg_container">
                        <Switch>
                            <Route
                                exact
                                path={PATHS.home}
                                render={(props: any) => {
                                    return <Main {...props} />;
                                }}
                            />

                            <Route
                                exact
                                path={PATHS.airdrop}
                                render={(props: any) => {
                                    return <Airdrop {...props} />;
                                }}
                            />

                            <Route
                                exact={true}
                                path={PATHS.governance}
                                render={(props: any) => {
                                    return <Stake {...props} />;
                                }}
                            />
                            <Route
                                exact={true}
                                path={PATHS.stakeLp}
                                render={(props: any) => {
                                    return <StakeLp {...props} />;
                                }}
                            />
                            <Route
                                exact={true}
                                path={PATHS.stakeGov}
                                render={(props: any) => {
                                    return <StakeGovernance {...props} />;
                                }}
                            />
                            <Route
                                exact={true}
                                path={PATHS.stakeLagacy}
                                render={(props: any) => {
                                    return <StakeLpLagacy {...props} />;
                                }}
                            />
                            <Route
                                path={PATHS.trade}
                                render={(props: any) => {
                                    return <Trade {...props} />;
                                }}
                            />
                            <Route
                                path={PATHS.connect}
                                render={(props: any) => {
                                    return <ConnectWay {...props} />;
                                }}
                            />
                            <Route
                                exact={true}
                                path={PATHS.campaigns}
                                render={(props: any) => {
                                    return <Campaigns {...props} />;
                                }}
                            />
                            <Route
                                exact={true}
                                path={
                                    PATHS.campaignsDetail + ":campaignAddress"
                                }
                                render={(props: any) => {
                                    return <CampaignDetail {...props} />;
                                }}
                            />

                            <Route
                                exact={true}
                                path={PATHS.history}
                                render={(props: any) => {
                                    return <History {...props} />;
                                }}
                            />
                            <Route
                                exact={true}
                                path={PATHS.governanceCreate}
                                render={(props: any) => {
                                    return <GovernanceCreate {...props} />;
                                }}
                            />
                            <Route
                                exact={true}
                                path={PATHS.governanceCreateForm}
                                render={(props: any) => {
                                    return <GovernanceCreateForm {...props} />;
                                }}
                            />

                            <Route
                                exact={true}
                                path={PATHS.producer}
                                render={(props: any) => {
                                    return <Producer {...props} />;
                                }}
                            />
                            <Route
                                exact={true}
                                path={PATHS.producerCreate}
                                render={(props: any) => {
                                    return <ProducerCreate {...props} />;
                                }}
                            />
                            <Route
                                exact={true}
                                path={PATHS.producerSetting}
                                render={(props: any) => {
                                    return <ProducerSetting {...props} />;
                                }}
                            />

                            <Route
                                exact={true}
                                path={PATHS.producerSettingQualification}
                                render={(props: any) => {
                                    return (
                                        <ProducerSettingQualification
                                            {...props}
                                        />
                                    );
                                }}
                            />

                            <Route
                                exact={true}
                                path={PATHS.producerSettingRegisterQualifier}
                                render={(props: any) => {
                                    return (
                                        <ProducerSettingRegisterQualifier
                                            {...props}
                                        />
                                    );
                                }}
                            />

                            <Route
                                exact={true}
                                path={PATHS.producerSettingGeneral}
                                render={(props: any) => {
                                    return (
                                        <ProducerSettingGeneral {...props} />
                                    );
                                }}
                            />

                            <Route
                                exact={true}
                                path={PATHS.producerSettingActivation}
                                render={(props: any) => {
                                    return (
                                        <ProducerSettingActivation {...props} />
                                    );
                                }}
                            />

                            <Route
                                exact={true}
                                path={PATHS.producerDepositWithdraw}
                                render={(props: any) => {
                                    return (
                                        <ProducerDepositWithdraw {...props} />
                                    );
                                }}
                            />

                            <Route
                                path={PATHS.producerDetail + ":campaignAddress"}
                                render={(props: any) => {
                                    return <ProducerDetail {...props} />;
                                }}
                            />
                            <Route
                                path={PATHS.governancePollDetail + ":pollId"}
                                render={(props: any) => {
                                    return <GovernancePollDetail {...props} />;
                                }}
                            />

                            <Route
                                exact={true}
                                path={PATHS.governanceVote}
                                render={(props: any) => {
                                    return <GovernanceVote {...props} />;
                                }}
                            />

                            <Route
                                exact={true}
                                path={PATHS.vp}
                                render={(props: any) => {
                                    return <Vp {...props} />;
                                }}
                            />

                            <Route
                                exact={true}
                                path={PATHS.mypage}
                                render={(props: any) => {
                                    return <Mypage {...props} />;
                                }}
                            />

                            <Redirect from="/*" to={PATHS.home} />
                        </Switch>
                    </div>
                    <Footer />
                    {isV2 && !closed ? (
                        <div className={"v2_popup_container"}>
                            <div className="v2_popup">
                                <div className="v2_text1">{"Guide"}</div>
                                <div className="v2_text2">
                                    {
                                        "Currently we only support “classic” network in this website."
                                    }
                                    <br />
                                    <br />
                                    {
                                        'Change website URL to "https://app.valkyrieprotocol.com/"'
                                    }
                                </div>

                                <div className="v2_button">
                                    <YellowButton
                                        title={"Check"}
                                        onClick={() => {
                                            setClosed(true);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            </Router>
        </SkeletonTheme>
    );
}
