import { useEffect, useState } from "react";
import {
    divide,
    equalTo,
    isZero,
    leftGreaterThanRight,
    minus,
    multiply,
} from "../../../../../../libs/Math";
import { getDecimal } from "../../../../../../libs/utils";
import TitleLabel from "../../../../../common/Labels/TitleLabel";
import style from "./IPCDetailTitle.module.scss";
import classNames from "classnames/bind";
import { environment } from "../../../../../../libs/api/api";

const cx = classNames.bind(style);

export default function IPCDetailTitle(props: {
    earlybird: boolean;
    summary: CampaignSummary;
    started: boolean;
}) {
    const state = props.summary.state;
    const reward = props.summary.reward;

    const pFiltered = state.pool_balances.filter((item) => {
        return item.denom === reward.participation.denom;
    });
    const pReward = pFiltered.length > 0 ? pFiltered[0].amount : "0";

    const [amountsTotal, setAmountsTotal] = useState("0");
    const [amountsNow, setAmountsNow] = useState("0");
    const [amountsPercent, setAmountsPercent] = useState("0");

    useEffect(() => {
        let total = "0";
        if (props.earlybird) {
            total = environment().contracts.fanfury_early.total_distribution;
        } else {
            total = environment().contracts.fanfury_public.total_distribution;
        }

        setAmountsTotal(total);
    }, [props.earlybird]);

    useEffect(() => {
        setAmountsNow(minus(amountsTotal, pReward));
    }, [pReward, amountsTotal]);

    useEffect(() => {
        if (!isZero(amountsTotal)) {
            setAmountsPercent(
                multiply(divide(amountsNow, amountsTotal), "100")
            );
        }
    }, [amountsNow, amountsTotal]);

    return (
        <div
            className={cx(style.container, {
                beforeparticipate: !leftGreaterThanRight(amountsPercent, "80"),
            })}
        >
            <div
                className={cx(style.containerwrap, {
                    beforeparticipate: !leftGreaterThanRight(
                        amountsPercent,
                        "80"
                    ),
                })}
            >
                <div
                    className={cx(style.logofield, {
                        beforeparticipate: !leftGreaterThanRight(
                            amountsPercent,
                            "80"
                        ),
                    })}
                >
                    <div
                        className={cx(style.logofield_ico, {
                            earlybird: props.earlybird,
                            beforeparticipate: !leftGreaterThanRight(
                                amountsPercent,
                                "80"
                            ),
                            beforeparticipate_earlybird:
                                !leftGreaterThanRight(amountsPercent, "80") &&
                                props.earlybird,
                        })}
                    />
                    {props.summary.state.is_active === true ? (
                        <div />
                    ) : (
                        <div className={style.logoclosed}>{"CLOSED"}</div>
                    )}
                </div>
                <div className={style.contentsfield}>
                    <div
                        className={cx(style.titles, {
                            beforeparticipate: !leftGreaterThanRight(
                                amountsPercent,
                                "80"
                            ),
                        })}
                    >
                        <div
                            className={cx(style.title, {
                                earlybird: props.earlybird,
                            })}
                        >
                            {props.summary.config.title}
                        </div>
                        <div className={style.symbol}>
                            <TitleLabel
                                fontWeight={"bold"}
                                text={"$FURY"}
                                size={20}
                                color={"#fff"}
                            />
                        </div>
                        <div className={style.name}>
                            <TitleLabel
                                fontWeight={"medium"}
                                text={"FanFury"}
                                size={12}
                                color={"#fff"}
                            />
                        </div>
                    </div>
                    {!leftGreaterThanRight(amountsPercent, "80") ? (
                        <div />
                    ) : (
                        <div className={style.distribute}>
                            <div className={style.title}>
                                <TitleLabel
                                    fontWeight={"medium"}
                                    text={"Total Distribution"}
                                    size={14}
                                    color={"#fff"}
                                />
                            </div>
                            <div className={style.amounts}>
                                <div className={style.amounts_now}>
                                    <TitleLabel
                                        fontWeight={"light"}
                                        text={
                                            getDecimal(amountsNow, true, 6) +
                                            " (" +
                                            getDecimal(
                                                multiply(
                                                    amountsPercent,
                                                    "1000000"
                                                ),
                                                false,
                                                2
                                            ) +
                                            "%)"
                                        }
                                        size={14}
                                        color={"rgba(142,47,245,0.7)"}
                                    />
                                </div>
                                <div className={style.amounts_total}>
                                    <div className={style.amounts_total_text}>
                                        <TitleLabel
                                            fontWeight={"light"}
                                            text={
                                                getDecimal(
                                                    amountsTotal,
                                                    true,
                                                    6
                                                ) + " MAX"
                                            }
                                            size={14}
                                            color={"#c8c8c8"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={style.progressbar}>
                                <div
                                    className={style.progressbar_now}
                                    style={{ width: amountsPercent + "%" }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {!leftGreaterThanRight(amountsPercent, "80") ? (
                <div />
            ) : (
                <div className={style.mobile_distribute}>
                    <div className={style.title}>
                        <TitleLabel
                            fontWeight={"medium"}
                            text={"Total Distribution"}
                            size={14}
                            color={"#fff"}
                        />
                    </div>
                    <div className={style.amounts}>
                        <div className={style.amounts_now}>
                            <TitleLabel
                                fontWeight={"light"}
                                text={
                                    getDecimal(
                                        multiply(amountsPercent, "1000000"),
                                        false,
                                        2
                                    ) + "%"
                                }
                                size={14}
                                color={"rgba(142,47,245,0.7)"}
                            />
                        </div>
                        <div className={style.amounts_total}>
                            <div className={style.amounts_total_text}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        getDecimal(amountsTotal, true, 6) +
                                        " MAX"
                                    }
                                    size={14}
                                    color={"#c8c8c8"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.progressbar}>
                        <div
                            className={style.progressbar_now}
                            style={{ width: amountsPercent + "%" }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
