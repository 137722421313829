import style from "./SymbolDenom.module.scss";

import defaultIcon from "../../../resources/symbols/logo_default.gif";
import fanfuryIpc from "../../../resources/logod-fan-e.png";
import talisIpc from "../../../resources/Talis/logo-talis-e.png";
import lunartIpc from "../../../resources/logod-arts-e.png";
import staderIcon from "../../../resources/logo-stader.svg";
import lunApesIcon from "../../../resources/logo-lun-apes.png";
import { useEffect } from "react";
import { useState } from "react";
import { environment, findSymbolIconDenom } from "../../../libs/api/api";
import TitleLabel from "../../common/Labels/TitleLabel";

import classNames from "classnames/bind";
const cx = classNames.bind(style);

export default function SymbolDenom(props: {
    name: string;
    denom?: string;
    ipc: boolean;
}) {
    const [icon, setIcon] = useState<any | undefined>(undefined);

    useEffect(() => {
        if (!props.denom) {
            if (props.name === "Stader") {
                setIcon(staderIcon);
            } else if (props.name === "Planet of LunApes") {
                setIcon(lunApesIcon);
            } else {
                setIcon(defaultIcon);
            }
        } else {
            if (props.ipc) {
                if (props.denom === "FURY") {
                    setIcon(fanfuryIpc);
                } else if (props.denom === "TALIS") {
                    setIcon(talisIpc);
                } else if (props.denom === "lunart") {
                    setIcon(lunartIpc);
                } else {
                    setIcon(defaultIcon);
                }
            } else {
                findSymbolIconDenom(props.denom)
                    .then((icon) => {
                        if (icon) {
                            setIcon(icon);
                        } else {
                            setIcon(defaultIcon);
                        }
                    })
                    .catch((e) => {
                        setIcon(defaultIcon);
                    });
            }
        }
    }, [props.denom, props.ipc]);

    return (
        <div className={style.container}>
            <div>
                {icon && props.ipc ? (
                    <IpcComponent icon={icon} ipc={props.ipc} />
                ) : (
                    <IpcComponent icon={icon} ipc={props.ipc} />
                )}
            </div>
            <div
                className={cx(style.text_label, {
                    ipc_label: props.ipc === true,
                })}
            >
                {props.name.length < 12 ? (
                    <TitleLabel
                        size={16}
                        text={props.name}
                        color={"white"}
                        fontWeight={"bold"}
                        opacity={0.5}
                    />
                ) : (
                    <div className={style.multiple_label}>
                        <TitleLabel
                            size={13}
                            text={props.name}
                            color={"white"}
                            fontWeight={"bold"}
                            opacity={0.5}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

function IpcComponent(props: { icon: any; ipc: boolean }) {
    if (props.ipc) {
        return <img className={style.ipc} src={props.icon} alt="" />;
    } else {
        return (
            <div className={style.icon_container}>
                <div className={style.iconwrap} />
                <img className={style.icon} src={props.icon} alt="" />
            </div>
        );
    }
}
