import { PATHS } from "../../../App";

import { useState } from "react";
import { useEffect } from "react";

import { campaignSummary } from "../../../libs/api/campaign/query";
import { isWhitelistedCreator } from "../../../libs/api/rest";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
import BaseCampaignDetail from "./Base/BaseCampaignDetail";

export default function ProducerDetail(props: any) {
    const wallet = useRecoilValue(walletState);
    const [campaignAddress, setCampaignAddress] = useState("");
    const [summary, setSummary] = useState<CampaignSummary | undefined>(
        undefined
    );

    useEffect(() => {
        if (!wallet) {
            return;
        }

        const campaignAddress = props.match.params.campaignAddress;
        setCampaignAddress(campaignAddress);
        if (!campaignAddress) {
            props.history.replace(PATHS.producer);
            return;
        }

        isWhitelistedCreator(wallet ? wallet.terraAddress : undefined).then(
            (r) => {
                if (r) {
                    campaignSummary(
                        campaignAddress,
                        wallet ? wallet.terraAddress : undefined
                    )
                        .then((r) => {
                            if (r.config.admin !== wallet?.terraAddress) {
                                props.history.replace(PATHS.producer);
                            } else {
                                setSummary(r);
                            }
                        })
                        .catch((error) => {
                            props.history.replace(PATHS.producer);
                        });
                } else {
                    props.history.replace(PATHS.producer);
                }
            }
        );
    }, [props.history, props.match.params, wallet]);

    if (summary === undefined) {
        return <div />;
    }

    return (
        <div>
            <BaseCampaignDetail
                campaignAddress={campaignAddress}
                summary={summary}
            />
        </div>
    );
}
