import { useEffect, useState } from "react";
import * as Utils from "../../../../libs/utils";
import style from "./StakeLpStake.module.scss";
import { queryTokenBalance } from "../../../../libs/api/trade/query";
import { environment } from "../../../../libs/api/api";
import InputField from "../../../common/InputField/InputField";
import ConfirmButton from "../../../common/ConfirmButton/ConfirmButton";

import {
    fixed,
    isZero,
    leftGreaterThanRight,
    multiply,
} from "../../../../libs/Math";
import TxInfoView from "../../../common/TxInfoView/TxInfoView";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../libs/contexts";
import { getFeeNormal } from "../../../../libs/api/fee";

export default function StakeLpStakeManual2(props: {
    stakeLpPressed: (amount: string) => void;
}) {
    const wallet = useRecoilValue(walletState);

    const [balance, setBalance] = useState("0");
    const [token, setToken] = useState("");
    const [error1, setError1] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (wallet) {
            queryTokenBalance(
                environment().contracts.astroport.lp,
                wallet.terraAddress
            )
                .then((r) => {
                    setBalance(r.amount);
                })
                .catch((e) => {
                    setBalance("0");
                });
        }
    }, [wallet]);

    useEffect(() => {
        if (leftGreaterThanRight(token, Utils.getDecimal(balance))) {
            setError1("Insufficient balance");
        } else if (isZero(token)) {
            setError1("Amount must be greater than 0");
        } else {
            setError1(undefined);
        }
    }, [token, balance]);

    return (
        <div className={style.inner_container}>
            <InputField
                title={"VKR-UST LP"}
                type="amount"
                balance={balance}
                symbol={"LP"}
                onChanged={(v: string) => {
                    setToken(v);
                }}
                value={token}
                error={error1}
                autofocus={true}
                showSymbolIcon={true}
            />

            {token ? <InfoView /> : <div />}

            <ConfirmButton
                title={"Stake"}
                enable={!error1 && token !== ""}
                onClick={() => {
                    props.stakeLpPressed(fixed(multiply(token, 1000000), 0));
                }}
            />
        </div>
    );
}

function InfoView() {
    const [fee, setFee] = useState("0");
    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => { });
    }, []);

    return <TxInfoView fee={fee} />;
}
