import { Coins, Fee } from "@terra-money/terra.js";
import axios from "axios";
import { fixed, multiply, plus } from "../Math";
import { environment } from "./api";

export async function getFeeHigh(tax: string = "0"): Promise<Fee> {
    const denom = "uusd";
    const gas = 3000000;

    return await getFeeRaw(denom, gas, tax);
}

export async function getFeeNormal(tax: string = "0"): Promise<Fee> {
    const denom = "uusd";
    const gas = 1500000;

    return await getFeeRaw(denom, gas, tax);
}

export async function getFeeLow(
    multiplier: number,
    tax: string = "0"
): Promise<Fee> {
    const denom = "uusd";
    const gas = 300000 * multiplier;

    return await getFeeRaw(denom, gas, tax);
}

async function getFeeRaw(
    denom: string,
    gas: number,
    tax: string = "0"
): Promise<Fee> {
    const price = await gasPrice(denom);
    const amount = fixed(multiply(gas, price), 0);

    return new Fee(gas, new Coins(fixed(plus(amount, tax), 0) + denom));
}

async function gasPrice(denom: string): Promise<number> {
    let gas: { amount: number; denom: string }[] = [];

    const list = (
        await axios.get("v1/txs/gas_prices", {
            baseURL: environment().fcd,
            headers: {
                "Content-Type": "application/json",
            },
        })
    ).data;

    for (let i = 0; i < Object.keys(list).length; i++) {
        const key = Object.keys(list)[i];
        const value: number = list[key];
        gas.push({
            amount: value,
            denom: key,
        });
    }

    const filtered = gas.filter((item) => {
        return item.denom.toLowerCase() === denom.toLowerCase();
    });

    return filtered.length > 0 ? filtered[0].amount : 0.5;
}
