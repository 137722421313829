import { useEffect, useState } from "react";

import style from "./Trade.module.scss";
import {
    tokenSimulate,
    tokenReverseSimulate,
} from "../../libs/api/trade/query";
import * as Utils from "../../libs/utils";

import ConfirmButton from "../common/ConfirmButton/ConfirmButton";

import InputField from "../common/InputField/InputField";

import {
    isNumber,
    isZero,
    leftGreaterThanRight,
    leftLessThanRight,
    multiply,
} from "../../libs/Math";
import TxInfoView from "../common/TxInfoView/TxInfoView";
import { useRecoilValue } from "recoil";
import { spreadState } from "../../libs/contexts";
import SpreadView from "./TradeSpread";
import { calculateTax } from "../../libs/api/tax";
import { getFeeNormal } from "../../libs/api/fee";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function TradeBuy(props: {
    balance: { token: string; uusd: string };
    buyPressed: (amount: string, spread: number) => void;
}) {
    const spread = useRecoilValue(spreadState);

    const [uusd, setUusd] = useState("");
    const [token, setToken] = useState("");
    const [simulate, setSimulate] = useState<ResponseSimulate | undefined>(
        undefined
    );

    const [error1, setError1] = useState<string | undefined>(undefined);
    const [error2, setError2] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (
            leftGreaterThanRight(
                uusd,
                Utils.getDecimal(props.balance.uusd, false, 3)
            )
        ) {
            setError1("Insufficient balance");
        } else if (isZero(uusd)) {
            setError1("Amount must be greater than 0");
        } else if (leftLessThanRight(uusd, 0.001)) {
            setError1("Amount must be within 3 decimal points");
        } else {
            setError1(undefined);
        }
    }, [uusd, props.balance.uusd]);

    useEffect(() => {
        simulate1(uusd, spread.spreadValue);
    }, [spread]);

    function simulate1(uusd: string, spread: number) {
        if (uusd) {
            tokenSimulate(true, multiply(uusd, 1000000), spread)
                .then((r: ResponseSimulate) => {
                    setSimulate(r);
                    setToken(Utils.getDecimal(r.to));
                    setError2(undefined);
                })
                .catch((e) => {});
        }
    }

    return (
        <div className={style.trade_inner_container}>
            <div className={style.from}>
                <InputField
                    title="From"
                    type="amount"
                    // disabled={false}
                    symbol="UST"
                    value={uusd}
                    decimal={3}
                    balance={props.balance.uusd}
                    onChanged={(v: string) => {
                        setUusd(v);
                        if (v) {
                            simulate1(v, spread.spreadValue);
                        } else {
                            setToken("");
                        }
                    }}
                    error={error1}
                    autofocus={true}
                    showSymbolIcon={true}
                />
            </div>
            <div
                className={cx(style.arrow, {
                    error: error1,
                })}
            />

            <div className={style.to}>
                <InputField
                    title="To"
                    type="amount"
                    // disabled={false}
                    symbol={"VKR"}
                    value={token}
                    onChanged={(v: string) => {
                        setToken(v);
                        if (v) {
                            tokenReverseSimulate(
                                true,
                                multiply(v, 1000000),
                                spread.spreadValue
                            )
                                .then((r: ResponseSimulate) => {
                                    setSimulate(r);
                                    setUusd(Utils.getDecimal(r.to, false, 3));
                                    setError2(undefined);
                                })
                                .catch((e) => {
                                    setError2("Simulation failed");
                                });
                        } else {
                            setUusd("");
                        }
                    }}
                    error={error2}
                    showSymbolIcon={true}
                />
            </div>

            <SpreadView />

            {uusd && simulate ? (
                <InfoView uusd={uusd} simulate={simulate} isTrade={true} />
            ) : (
                <div />
            )}

            <div className={style.spreaddummy} />

            {/* <ConfirmButton
                title="BUY"
                enable={!error1 && !error2 && uusd !== ""}
                isBuy={true}
                onClick={() => {
                    props.buyPressed(
                        multiply(uusd, 1000000),
                        spread.spreadValue
                    );
                }}
            /> */}

            <div className={style.createbutton}>
                <div
                    className={cx(style.button, {
                        enable: !error1 && !error2 && uusd !== "",
                    })}
                    onClick={() => {
                        if (!error1 && !error2 && uusd !== "") {
                            props.buyPressed(
                                multiply(uusd, 1000000),
                                spread.spreadValue
                            );
                        }
                    }}
                >
                    {"BUY"}
                </div>
            </div>
        </div>
    );
}

function InfoView(props: {
    uusd: string;
    simulate: ResponseSimulate;
    isTrade?: boolean;
}) {
    const spread = useRecoilValue(spreadState);
    const [fee, setFee] = useState("0");

    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => {});
    }, []);

    const [price, setPrice] = useState("0");
    const [tax, setTax] = useState("0");

    useEffect(() => {
        const price = multiply(props.simulate.rate, 1000000);
        setPrice(Utils.getDecimal(isNumber(price) ? price : "0", true, 3));

        calculateTax(multiply(props.uusd, 1000000), "uusd")
            .then((r) => {
                setTax(r);
            })
            .catch((e) => {});
    }, [props]);

    const [list, setList] = useState<any[]>([]);

    useEffect(() => {
        let list: any[] = [];

        list.push({
            title: "Estimated Price",
            body: price,
            symbol: " UST per VKR",
        });

        list.push({
            title: "Minimum Received",
            body: Utils.getDecimal(props.simulate.minimumReceive, true),
            symbol: "VKR",
        });

        list.push({
            title: "Trading Fee",
            body: Utils.getDecimal(props.simulate.tradingFee, true, 3),
            symbol: "VKR",
        });

        setList(list);
    }, [props.simulate, tax, price, spread]);

    return <TxInfoView list={list} fee={fee} tax={tax} />;
}
