import React, { useEffect, useState } from "react";
import style from "./ComingSoon.module.scss";
import SymbolDenom from "./SymbolDenom";

import classNames from "classnames/bind";
const cx = classNames.bind(style);

export default function ComingSoon() {
    const list = [
        { name: "Fanfury", denom: "FURY", ipc: true },
        { name: "Talis", denom: "TALIS", ipc: true },
        { name: "Terra World", denom: "TWD", ipc: false },
        { name: "Planet of LunApes", ipc: false },
    ];

    const SLIDE_GAP = 50;
    const SLIDE_MOVING_UNIT = 210;
    const IMG_WIDTH = 160;
    const imgQuantity = list.length;
    const slideWidth =
        IMG_WIDTH * imgQuantity + imgQuantity * SLIDE_GAP + SLIDE_GAP;
    const hiddenedSlideWidth =
        slideWidth - window.innerWidth + SLIDE_MOVING_UNIT;
    const [slideSpot, setSlideSpot] = useState(0);
    const [slideEnd, setSlideEnd] = useState(list.length);
    const [arrow, setArrow] = useState(true);

    const handlePrevBtn = () => {
        //
        if (Math.abs(slideSpot) < SLIDE_MOVING_UNIT) {
            setSlideSpot(0);
        } else if (hiddenedSlideWidth - Math.abs(slideSpot) === 0) {
            setSlideSpot(slideSpot + IMG_WIDTH - 10);
        } else {
            setSlideSpot(slideSpot + SLIDE_MOVING_UNIT);
        }
    };

    const handleNextBtn = () => {
        if (hiddenedSlideWidth - Math.abs(slideSpot) < SLIDE_MOVING_UNIT) {
            setSlideSpot(
                slideSpot - (hiddenedSlideWidth - Math.abs(slideSpot))
            );
            setSlideEnd(slideSpot - (hiddenedSlideWidth - Math.abs(slideSpot)));
        } else {
            setSlideSpot(slideSpot - SLIDE_MOVING_UNIT);
        }
    };
    useEffect(() => {
        if (hiddenedSlideWidth > -150 && imgQuantity > 1) {
            setArrow(true);
        } else if (imgQuantity === 1) {
            setArrow(false);
        } else {
            setArrow(false);
        }
    }, [arrow]);

    return (
        <div className={style.list_container}>
            {arrow ? (
                <PrevArrow
                    handlePrevBtn={handlePrevBtn}
                    slideSpot={slideSpot}
                />
            ) : (
                <div />
            )}

            <div className={cx("slider_background", "left")} />

            <div
                className={style.slider_wrapper}
                style={{ width: `${slideWidth}px` }}
            >
                <ul className={style.slider_ul}>
                    <div
                        style={{ transform: `translateX(${slideSpot}px)` }}
                        className={style.slider_container}
                    >
                        {list.map(function (list) {
                            return (
                                <Card
                                    name={list.name}
                                    denom={list.denom}
                                    ipc={list.ipc}
                                />
                            );
                        })}
                    </div>
                </ul>
            </div>

            <div className={cx("slider_background", "right")} />
            {arrow ? (
                <NextArrow
                    handleNextBtn={handleNextBtn}
                    slideSpot={slideSpot}
                    slideEnd={slideEnd}
                />
            ) : (
                <div />
            )}
        </div>
    );
}

function Card(props: { name: string; denom?: string; ipc: boolean }) {
    const [color, setColor] = useState("default_card");
    const [link, setLink] = useState("");
    const [hover, setHover] = useState(false);

    useEffect(() => {
        if (props.ipc && props.denom) {
            if (props.denom === "FURY") {
                setColor("fanfury_card");
            } else if (props.denom === "TALIS") {
                setColor("talis_card");
            } else if (props.denom === "lunart") {
                setColor("lunart_card");
            } else {
                setColor("default_card");
            }
        } else {
            setColor("default_card");
        }
    }, [props.ipc]);

    useEffect(() => {
        if (props.name === "Fanfury") {
            setLink("https://fury.fan");
        } else if (props.name === "Talis") {
            setLink("https://talis.art/");
        } else if (props.name === "Stader") {
            setLink("https://staderlabs.com");
        } else if (props.name === "Terra World") {
            setLink("https://terraoffice.world");
        } else {
            setLink("");
        }
    }, [props.name]);

    return (
        <li className={style.slider_li}>
            <div className={style.card_container}>
                <div className={cx("card_item_color", color)} />
                <div className={style.card_item}>
                    <SymbolDenom
                        name={props.name}
                        denom={props.denom}
                        ipc={props.ipc}
                    />
                    {link ? (
                        <a
                            className={style.card_link}
                            href={link}
                            target="_blank"
                            onMouseOver={() => setHover(true)}
                            onMouseOut={() => setHover(false)}
                        >
                            <div className={style.link_container}>
                                <div className={style.link_background} />
                                {hover ? (
                                    <div className={style.hover_link_button} />
                                ) : (
                                    <div
                                        className={style.unhover_link_button}
                                    />
                                )}
                            </div>
                        </a>
                    ) : (
                        <div />
                    )}
                </div>
            </div>
        </li>
    );
}

function PrevArrow(props: {
    handlePrevBtn: React.MouseEventHandler<HTMLDivElement>;
    slideSpot: number;
}) {
    if (props.slideSpot) {
        return (
            <div
                className={cx("slider_arrow", "left")}
                onClick={props.handlePrevBtn}
            >
                <div className={cx("slider_arrow_icon", "on")} />
            </div>
        );
    } else {
        return (
            <div
                className={cx("slider_arrow", "left")}
                onClick={props.handlePrevBtn}
            >
                <div className={cx("slider_arrow_icon", "off")} />
            </div>
        );
    }
}

function NextArrow(props: {
    handleNextBtn: React.MouseEventHandler<HTMLDivElement>;
    slideSpot: number;
    slideEnd: number;
}) {
    if (props.slideSpot !== props.slideEnd) {
        return (
            <div
                className={cx("slider_arrow", "right")}
                onClick={props.handleNextBtn}
            >
                <div className={cx("slider_arrow_icon", "on")} />
            </div>
        );
    } else {
        return (
            <div
                className={cx("slider_arrow", "right")}
                onClick={props.handleNextBtn}
            >
                <div className={cx("slider_arrow_icon", "off")} />
            </div>
        );
    }
}
