import { useEffect, useState } from "react";
import * as Utils from "../../../libs/utils";
import style from "./StakeGovernance.module.scss";

import InputField from "../../common/InputField/InputField";
import ConfirmButton from "../../common/ConfirmButton/ConfirmButton";

import { isZero, leftGreaterThanRight, multiply } from "../../../libs/Math";
import TxInfoView from "../../common/TxInfoView/TxInfoView";
import { getFeeNormal } from "../../../libs/api/fee";

export default function StakeGovernancepStake(props: {
    token: string;
    pressed: (amount: string) => void;
}) {
    const [token, setToken] = useState("");
    const [error1, setError1] = useState<string | undefined>(undefined);

    useEffect(() => {
        setToken("");
    }, []);

    useEffect(() => {
        if (leftGreaterThanRight(token, Utils.getDecimal(props.token))) {
            setError1("Insufficient balance");
        } else if (isZero(token)) {
            setError1("Amount must be greater than 0");
        } else {
            setError1(undefined);
        }
    }, [token, props.token]);

    return (
        <div className={style.inner_container}>
            <InputField
                title={"Amount"}
                type="amount"
                balance={props.token}
                symbol={"VKR"}
                onChanged={(v: string) => {
                    setToken(v);
                }}
                value={token}
                error={error1}
                autofocus={true}
                showSymbolIcon={true}
            />

            <InfoView />

            <div className={style.spreaddummy} />

            <ConfirmButton
                title={"Stake"}
                enable={!error1 && token !== ""}
                onClick={() => {
                    props.pressed(multiply(token, 1000000));
                }}
            />
        </div>
    );
}

function InfoView() {
    const [fee, setFee] = useState("0");

    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => { });
    }, []);

    return <TxInfoView fee={fee} />;
}
