import style from "./MainSection3.module.scss";
import * as Utils from "../../libs/utils";
import TitleLabel from "../common/Labels/TitleLabel";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { poolValue } from "../../libs/api/rest";
import { refreshState } from "../../libs/contexts";
import { useRecoilValue } from "recoil";

export default function MainSection3() {
    const refresh = useRecoilValue(refreshState);
    const [value, setValue] = useState("0");
    const isLoading = useRef(false);

    useEffect(() => {
        if (isLoading.current) {
            return;
        }
        isLoading.current = true;

        poolValue("ADDED")
            .then((r) => {
                setValue(r);
                isLoading.current = false;
            })
            .catch((e) => {
                isLoading.current = false;
            });
    }, [refresh]);

    return (
        <div className={style.container}>
            <div className={style.inner_container}>
                <TitleLabel
                    tooltip={
                        "The total cumulative reward pool value that was captured in the protocol through all time"
                    }
                    text={"Cumulative Campaign Rewards"}
                    color={"#fff"}
                />
                <div className={style.value}>
                    <TitleLabel
                        size={30}
                        text={Utils.getFormat(value)}
                        color={"white"}
                        symbol={"UST"}
                    />
                </div>
            </div>
        </div>
    );
}
