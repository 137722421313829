import style from "./DashboardChartHeader2.module.scss";
import * as Utils from "../../../libs/utils";

import TitleLabel from "../Labels/TitleLabel";
import DashboardChartCell2 from "./DashboardChartCell2";
import { useEffect, useState } from "react";
import { divide, fixed, multiply, plus } from "../../../libs/Math";

export default function DashboardChartHeader2(props: {
    meta: {
        title: string;
        tooltip: string;
        symbol: string;
        items: {
            title: string;
            color: string;
        }[];
    };
    value: MyPageValue | undefined;
    selected: string;
}) {
    const [total, setTotal] = useState<AmountSplitModel | undefined>(undefined);

    useEffect(() => {
        if (props.value) {
            setTotal(Utils.splitAmountByDot(props.value.total, 3));
        }
    }, [props]);

    return (
        <div className={style.container}>
            <div className={style.row0}>
                <TitleLabel
                    text={props.meta.title}
                    color={"#fff"}
                    tooltip={props.meta.tooltip}
                    fontWeight={"light"}
                />
                <div style={{ height: "8px" }} />
                <TitleLabel
                    size={30}
                    text={total ? total.num1 : undefined}
                    color={"white"}
                    symbol={
                        total && total.num2
                            ? "." + total.num2 + " " + props.meta.symbol
                            : props.meta.symbol
                    }
                />
            </div>

            <div className={style.row1}>
                <DashboardChartCell2
                    color={props.meta.items[0].color}
                    title={props.meta.items[0].title}
                    percent={
                        props.value
                            ? fixed(
                                  multiply(
                                      divide(
                                          Utils.getDecimal(
                                              props.value.balance.uusd,
                                              false,
                                              3
                                          ),
                                          Utils.getDecimal(
                                              props.value.total,
                                              false,
                                              3
                                          )
                                      ),
                                      100
                                  ),
                                  2
                              )
                            : "0"
                    }
                    body={props.value ? props.value.balance.uusd : "0"}
                    symbol={props.meta.symbol}
                    select={props.meta.items[0].title === props.selected ? true : false}
                />

                <div className={style.dummy} />

                <DashboardChartCell2
                    color={props.meta.items[1].color}
                    title={props.meta.items[1].title}
                    body={props.value ? props.value.gov.amount : "0"}
                    percent={
                        props.value
                            ? fixed(
                                  multiply(
                                      divide(
                                          Utils.getDecimal(
                                              props.value.gov.uusd,
                                              false,
                                              3
                                          ),
                                          Utils.getDecimal(
                                              props.value.total,
                                              false,
                                              3
                                          )
                                      ),
                                      100
                                  ),
                                  2
                              )
                            : "0"
                    }
                    symbol={"VKR"}
                    uusd={props.value ? props.value.gov.uusd : "0"}
                    select={props.meta.items[1].title === props.selected ? true : false}
                />
            </div>

            <div className={style.row2}>
                <DashboardChartCell2
                    color={props.meta.items[2].color}
                    title={props.meta.items[2].title}
                    percent={
                        props.value
                            ? fixed(
                                  multiply(
                                      divide(
                                          Utils.getDecimal(
                                              props.value.balance.tokenUusd,
                                              false,
                                              3
                                          ),
                                          Utils.getDecimal(
                                              props.value.total,
                                              false,
                                              3
                                          )
                                      ),
                                      100
                                  ),
                                  2
                              )
                            : "0"
                    }
                    body={props.value ? props.value.balance.token : "0"}
                    symbol={"VKR"}
                    uusd={props.value ? props.value.balance.tokenUusd : "0"}
                    select={props.meta.items[2].title === props.selected ? true : false}
                />

                <div className={style.dummy} />

                <DashboardChartCell2
                    color={props.meta.items[3].color}
                    title={props.meta.items[3].title}
                    percent={
                        props.value
                            ? fixed(
                                  multiply(
                                      divide(
                                          Utils.getDecimal(
                                              props.value.lp.uusd,
                                              false,
                                              3
                                          ),
                                          Utils.getDecimal(
                                              props.value.total,
                                              false,
                                              3
                                          )
                                      ),
                                      100
                                  ),
                                  2
                              )
                            : "0"
                    }
                    body={props.value ? props.value.lp.amount : "0"}
                    symbol={"LP"}
                    uusd={props.value ? props.value.lp.uusd : "0"}
                    select={props.meta.items[3].title === props.selected ? true : false}
                />
            </div>
        </div>
    );
}
