import { useState } from "react";
import style from "./LunartCampaignItem.module.scss";
import * as Utils from "../../../libs/utils";
import { PATHS } from "../../../App";
import TitleLabel from "../../common/Labels/TitleLabel";
import { useEffect } from "react";
import { campaignSummary } from "../../../libs/api/campaign/query";
import Skeleton from "react-loading-skeleton";
import { divide, minus, multiply } from "../../../libs/Math";
import { Link } from "react-router-dom";

import classNames from "classnames/bind";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";
import DetailButton from "../../common/DetailButton/DetailButton";

const cx = classNames.bind(style);

export default function LunartCampaignItem(props: {
    info: CampaignListItem;
    isEarlybird: boolean;
}) {
    const wallet = useRecoilValue(walletState);
    const [summary, setSummary] = useState<CampaignSummary | undefined>(
        undefined
    );

    useEffect(() => {
        if (props.info.campaignAddress) {
            campaignSummary(
                props.info.campaignAddress,
                wallet ? wallet.terraAddress : undefined
            )
                .then((r) => {
                    setSummary(r);
                })
                .catch((e) => {});
        }
    }, [props.info, wallet]);

    const state = summary ? summary.state : undefined;
    const config = summary ? summary.config : undefined;
    const reward = summary ? summary.reward : undefined;

    const isActive = state ? state.is_active : false;

    const title = config ? config.title : "";

    const pFiltered = state
        ? state.pool_balances.filter((item) => {
              return (
                  item.denom ===
                  (reward ? reward.participation.denom : "unknown")
              );
          })
        : [];

    const cummulativePReward = state
        ? state.cumulative_participation_reward_amount
        : "0";
    const pReward = pFiltered.length > 0 ? pFiltered[0].amount : "0";
    const pSymbol = reward ? reward.participation.symbol : undefined;
    const pName = reward ? reward.participation.name : undefined;

    const [hovercheck, setHovercheck] = useState(false);

    return (
        <Link
            className={cx(style.campaigns_item, {
                activefalse: false,
                isIpc: isActive === true,
                isIpcactivefalse: isActive === false,
            })}
            onMouseEnter={() => {
                setHovercheck(true);
            }}
            onMouseLeave={() => {
                setHovercheck(false);
            }}
            to={PATHS.campaignsDetail + props.info.campaignAddress}
        >
            <div className={style.campaigns_itemwrap} />

            <div className={style.cardlogo}>
                <Cardlogo
                    name={pName}
                    symbol={pSymbol}
                    isActive={isActive}
                    hovercheck={hovercheck}
                />
            </div>

            <div className={style.carddummy2} />
            <div className={style.carddummy} />

            <div className={style.cardcontent}>
                <IpcContent
                    title={title}
                    isActive={isActive}
                    hovercheck={hovercheck}
                    cummulativePReward={cummulativePReward}
                    pReward={pReward}
                    isEarlybird={props.isEarlybird}
                />
            </div>
        </Link>
    );
}

function Cardlogo(props: {
    name: string | undefined;
    symbol: string | undefined;
    isActive: boolean;
    hovercheck: boolean;
}) {
    return (
        <div className={style.cardlogo_container}>
            <div className={style.cardlogo_img}>
                <div className={style.ipc_cardlogo}>
                    {props.isActive === false ? (
                        <div className={style.activefalse}>
                            <div className={style.closed}>{"CLOSED"}</div>
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            </div>
            <div className={style.cardlogo_mobile}>
                <div
                    className={cx(style.cardlogo_symbol, {
                        activefalse: props.isActive === false,
                        hovercheck: props.hovercheck,
                    })}
                >
                    {props.symbol === undefined ? (
                        <Skeleton />
                    ) : (
                        "$" + props.symbol
                    )}
                </div>
                <div
                    className={cx(style.cardlogo_name, {
                        activefalse: props.isActive === false,
                        hovercheck: props.hovercheck,
                    })}
                >
                    {props.name === undefined ? <Skeleton /> : props.name}
                </div>
            </div>
        </div>
    );
}

function IpcContent(props: {
    title: string;
    isActive: boolean;
    hovercheck: boolean;
    cummulativePReward: string;
    pReward: string;
    isEarlybird: boolean;
}) {
    const white = "rgba(255, 255, 255)";
    const white70 = "rgba(255,255,255, 0.7)";
    const white30 = "rgba(255,255,255, 0.3)";

    const total = props.isEarlybird ? "25000000000000" : "15000000000000";

    const [ratio, setRatio] = useState("");

    useEffect(() => {
        setRatio(multiply(divide(props.cummulativePReward, total), "100"));
    }, [props.cummulativePReward, total]);

    return (
        <div className={style.cardcontent_container}>
            <div
                className={cx(style.cardcontent_title, {
                    activefalse: props.isActive === false,
                    hovercheck: props.hovercheck,
                })}
            >
                {props.title === undefined ? <Skeleton /> : props.title}
            </div>

            <div className={style.cardcontent_distribute}>
                <div className={style.distributetitle}>
                    <TitleLabel
                        text={"Total Distribution"}
                        // tooltip={"Rewards"}
                        color={!props.isActive ? "rgba(#fff, 0.3)" : "white"}
                        isCampaignfalse={!props.isActive}
                        hovercheck={
                            !props.isActive ? props.hovercheck : undefined
                        }
                    />
                </div>
                <div className={style.distributedetail}>
                    <div className={style.distributedetail_amounts}>
                        <div className={style.amounts_now}>
                            <TitleLabel
                                size={14}
                                fontWeight={"medium"}
                                text={
                                    Utils.getDecimal(
                                        multiply(ratio, "1000000"),
                                        false,
                                        2
                                    ) + "%"
                                }
                                color={
                                    props.isActive
                                        ? "#f29077"
                                        : props.hovercheck
                                        ? "rgba(255,255,255)"
                                        : white30
                                }
                            />
                        </div>
                        <div className={style.amounts_max}>
                            <div className={style.amounts_max_text}>
                                {" "}
                                <TitleLabel
                                    size={14}
                                    fontWeight={"medium"}
                                    text={
                                        Utils.getDecimal(total, true, 6) +
                                        " MAX"
                                    }
                                    color={
                                        props.isActive
                                            ? white70
                                            : props.hovercheck
                                            ? white
                                            : white30
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.distributedetail_bar}>
                        {props.isActive === true ? (
                            <div
                                className={style.distributedetail_bar_now}
                                style={{ width: ratio + "%" }}
                            />
                        ) : (
                            <div />
                        )}
                    </div>
                </div>
            </div>

            <div className={style.cardcontent_button}>
                <DetailButton hovercheck={props.hovercheck} />
            </div>
        </div>
    );
}
