import { environment, wasmQuery } from "../../api";

export async function txQualifierCampaign(
    campaignAddress: string
): Promise<ResponseTxQualifierCampaign> {
    return wasmQuery(environment().contracts.tx_qualifier, {
        campaign: {
            campaign: campaignAddress,
        },
    });
}

export async function getReferrer(compressed: string): Promise<{
    compressed: string;
    address: string;
}> {
    return await wasmQuery(environment().contracts.tx_qualifier, {
        get_referrer: {
            compressed: compressed,
        },
    });
}
