import TitleLabel from "../../../../../common/Labels/TitleLabel";
import style from "./IPCGuide.module.scss";

export default function IPCGuide(props: { earlybird: boolean }) {
    return (
        <div className={style.container}>
            <div className={style.title}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Guide"}
                    size={20}
                    color={"#fff"}
                />
            </div>
            <div className={style.guidewrap}>
                <div className={style.box}>
                    <div className={style.boxwrap}>
                        <div className={style.boxwrap_title}>
                            <TitleLabel
                                fontWeight={"medium"}
                                text={"LunArt IPC Key Notice"}
                                size={18}
                                color={"#fff"}
                            />
                        </div>
                        <div className={style.boxwrap_contents_text}>
                            <div className={style.boxwrap_contetns_text1}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "- Purchased token lock-up period (4 weeks)"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />
                            </div>
                            <div className={style.boxwrap_contetns_text1}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "- 10% of purchased $ARTS will released after 4weeks of lock-up period"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />
                            </div>
                            <div className={style.boxwrap_contetns_text1}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "- Rest of 90% of purchased $ARTS released with 6months linear vesting"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.boxwrap_contents_more}>
                        <a
                            href="https://medium.com/@valkyrie-protocol/lunart-ipc-details-dfe652b0172e"
                            target="_blank"
                            className={style.boxwrap_contents_more_text}
                        >
                            <TitleLabel
                                fontWeight={"light"}
                                text={"more"}
                                size={12}
                                color={"#fff"}
                            />
                        </a>
                    </div>
                </div>
                <div className={style.detail}>
                    <div className={style.detail_swap}>
                        <div className={style.detail_swap_title}>
                            <div className={style.detail_swap_title_ico} />
                            <div className={style.detail_swap_title_text}>
                                <TitleLabel
                                    fontWeight={"medium"}
                                    text={"Swap"}
                                    size={16}
                                    color={"#fff"}
                                />
                            </div>
                        </div>
                        <div className={style.detailwrap}>
                            <div className={style.detail_swap_rate}>
                                <div className={style.detail_swap_rate_title}>
                                    <TitleLabel
                                        fontWeight={"light"}
                                        text={"Rate"}
                                        size={14}
                                        color={"#fff"}
                                    />
                                </div>
                                <div
                                    className={style.detail_swap_rate_contents}
                                >
                                    <div className={style.rate_ust}>
                                        <div className={style.rate_ust_ico} />
                                        <div className={style.rate_ust_amount}>
                                            <TitleLabel
                                                fontWeight={"medium"}
                                                text={"1"}
                                                size={18}
                                                color={"#f29077"}
                                                symbol={"ARTS"}
                                            />
                                        </div>
                                    </div>
                                    <div className={style.rate_dummy} />
                                    <div className={style.rate_token}>
                                        <div className={style.rate_token_ico} />
                                        <div
                                            className={style.rate_token_amount}
                                        >
                                            <TitleLabel
                                                fontWeight={"medium"}
                                                text={"0.018"}
                                                size={18}
                                                color={"#fff"}
                                                symbol={"UST"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.detail_swap_available}>
                                <div
                                    className={
                                        style.detail_swap_available_title
                                    }
                                >
                                    <TitleLabel
                                        fontWeight={"light"}
                                        text={"Available"}
                                        size={14}
                                        color={"#fff"}
                                    />
                                </div>
                                <div
                                    className={style.detail_swap_available_wrap}
                                >
                                    <div
                                        className={
                                            style.detail_swap_available_contents
                                        }
                                    >
                                        <div className={style.available_max}>
                                            <TitleLabel
                                                fontWeight={"medium"}
                                                text={"Max"}
                                                size={16}
                                                color={"#f29077"}
                                            />
                                        </div>
                                        <div
                                            className={
                                                style.available_maxamount
                                            }
                                        >
                                            {props.earlybird ? (
                                                <TitleLabel
                                                    fontWeight={"medium"}
                                                    text={"2,500"}
                                                    size={16}
                                                    color={"#fff"}
                                                    symbol={"UST"}
                                                />
                                            ) : (
                                                <TitleLabel
                                                    fontWeight={"medium"}
                                                    text={"500"}
                                                    size={16}
                                                    color={"#fff"}
                                                    symbol={"UST"}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
