import TitleLabel from "../../../../../common/Labels/TitleLabel";
import style from "./IPCAlert.module.scss";

export default function IPCAlert(props: { earlybird: boolean }) {
    return (
        <div className={style.container}>
            <div className={style.wrap}>
                <div className={style.sub}>
                    {props.earlybird ? (
                        <TitleLabel
                            size={14}
                            fontWeight={"light"}
                            text={"VP EXCLUSIVE Open"}
                            color={"#fff"}
                        />
                    ) : (
                        <TitleLabel
                            size={14}
                            fontWeight={"light"}
                            text={"Public Open"}
                            color={"#fff"}
                        />
                    )}
                </div>
                <div className={style.content}>
                    {props.earlybird ? (
                        <TitleLabel
                            size={20}
                            text={"FEB 21ST UTC 13:00"}
                            color={"#ffe646"}
                        />
                    ) : (
                        <TitleLabel
                            size={20}
                            text={"FEB 22ND UTC 13:00"}
                            color={"#ffe646"}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
