import { multiply } from "../../../../../libs/Math";
import { getDecimal } from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./TalisInfo.module.scss";
import TalisInfoVp from "./TalisInfoVp";
import TalisInfoDeposit from "./TalisInfoDeposit";
import TalisInfoSharing from "./TalisInfoSharing";
import TalisInfoParticipation from "./TalisInfoParticipation";
import TalisInfoPool from "./TalisInfoPool";

export default function TalisInfo(props: {
    campaignAddress: string;
    summary: CampaignSummary;
}) {
    return (
        <div className={style.container}>
            <div className={style.header}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Info"}
                    size={20}
                    color={"#fff"}
                />

                <div className={style.rewards_partamount_sub}>
                    <div className={style.sub}>
                        <div className={style.date}>
                            <div className={style.participants}>
                                <div className={style.icon1} />
                                <div className={style.text}>
                                    {getDecimal(
                                        multiply(
                                            props.summary.state
                                                .participation_count,
                                            1000000
                                        ),
                                        true,
                                        0
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.contents}>
                <TalisInfoPool summary={props.summary} />

                <div className={style.rewards}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Rewards"}
                        size={16}
                        color={"#fff"}
                    />

                    <div className={style.rewardsdetail}>
                        <TalisInfoParticipation
                            summary={props.summary}
                            campaignAddress={props.campaignAddress}
                        />
                        <TalisInfoSharing summary={props.summary} />
                    </div>
                </div>
                <div className={style.condition}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Condition"}
                        size={16}
                        color={"#fff"}
                    />
                    <div className={style.conditiondetail}>
                        <TalisInfoDeposit summary={props.summary} />

                        <TalisInfoVp summary={props.summary} />
                    </div>
                </div>
            </div>
        </div>
    );
}
