import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./TalisCampaignAbout.module.scss";

export default function TalisCampaignAbout() {
    return (
        <div className={style.container}>
            <div className={style.title}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"About"}
                    size={20}
                    color={"#fff"}
                />
            </div>
            <div className={style.contents}>
                <a
                    className={style.home}
                    href="https://talis.art/"
                    target="_blank"
                />
                <a
                    className={style.twitter}
                    href="https://talis.art/"
                    target="_blank"
                />
                <a
                    className={style.discord}
                    href="https://talis.art/"
                    target="_blank"
                />
                <a
                    className={style.telegram}
                    href="https://talis.art/"
                    target="_blank"
                />
                {/* <a
                    className={style.mail}
                    href="https://lunartnft.medium.com/lunart-community-driven-nft-protocol-artist-launchpad-114b94008df5"
                    target="_blank"
                /> */}
            </div>
        </div>
    );
}
