export type LocalStorage<T> = [T, (value: T | ((val: T) => T)) => void];

export function loadVpAirdrop(wallet: string): number[] {
    const loaded: any | undefined = load("airdropvp_" + wallet);
    if (loaded) {
        return loaded;
    } else {
        return [];
    }
}

export function saveVpAirdrop(wallet: string, stage: number) {
    const loaded = loadAirdrop(wallet);

    if (!loaded.includes(stage)) {
        loaded.push(stage);
        save("airdropvp_" + wallet, loaded);
    }
}

export function loadAirdrop(wallet: string): number[] {
    const loaded: any | undefined = load("airdrop_" + wallet);
    if (loaded) {
        return loaded;
    } else {
        return [];
    }
}

export function saveAirdrop(wallet: string, stage: number) {
    const loaded = loadAirdrop(wallet);

    if (!loaded.includes(stage)) {
        loaded.push(stage);
        save("airdrop_" + wallet, loaded);
    }
}

export const SPREAD_STEP_4 = 0.001 / 2;
export const SPREAD_DEFAULT = 0.01 / 2; // 1%
export const SPREAD_STEP_1 = 0.05 / 2;
export const SPREAD_STEP_2 = 0.1 / 2;
export const SPREAD_STEP_3 = 0.25 / 2;
export const SPREAD_STEP_5 = 0.5 / 2;
// export const SPREAD_STEP_UNLIMIT = -1;

export function saveSpread(value: { spreadValue: number }) {
    save("spread", value);
}

export function loadSpread(): {
    spreadValue: number;
} {
    const df = {
        spreadValue: SPREAD_DEFAULT,
    };

    return load("spread") ?? df;
}

export function getNetwork(): ExtNetworkConfig | undefined {
    return load("network");
}

export function setNetwork(value: ExtNetworkConfig) {
    save("network", value);
}

export function getVpCampaignReferre(): { referrer: string } | undefined {
    return load("vp_c_ref");
}

export function setVpCampaignReferrer(value: { referrer: string }) {
    save("vp_c_ref", value);
}

function load(key: string): any | undefined {
    try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : undefined;
    } catch (error) {
        return undefined;
    }
}

function save(key: string, value: any) {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {}
}

function clear(key: string) {
    try {
        window.localStorage.removeItem(key);
    } catch (error) {}
}
