import axios from "axios";
import { environment, wasmExecutes } from "../api";
import { getReferrer } from "../campaign/qualification/query";

export async function VPBurn(
    campaignActive: boolean,
    address: string,
    amount: string,
    referrer?: string
) {
    let exes: WasmExecute[] = [];
    exes.push({
        contract: environment().contracts.token,
        msg: {
            send: {
                contract: environment().contracts.vp_token,
                amount: amount,
                msg: btoa(
                    JSON.stringify({
                        mint: {},
                    })
                ),
            },
        },
        coin: undefined,
    });

    if (campaignActive) {
        let decompressedReferrer;
        if (referrer && !referrer.toLowerCase().startsWith("terra1")) {
            //referrer is compressed.
            decompressedReferrer = (await getReferrer(referrer)).address;
        } else {
            decompressedReferrer = referrer;
        }

        exes.push({
            contract: environment().contracts.tx_qualifier,
            msg: {
                valkyrie_meta: {
                    campaign: environment().contracts.vpburncampaign,
                    referrer: decompressedReferrer,
                },
            },
            coin: undefined,
        });
    }

    return await wasmExecutes(address, exes);
}

async function qualificationPost(address: string, body: any) {
    return (
        await axios.post(address, body, {
            baseURL: environment().qualification_api,
            headers: {
                "Content-Type": "application/json",
            },
        })
    ).data;
}

export async function postTx(txHash: string, actor: string, referrer?: string) {
    let decompressedReferrer;
    if (referrer && !referrer.toLowerCase().startsWith("terra1")) {
        //referrer is compressed.
        decompressedReferrer = (await getReferrer(referrer)).address;
    } else {
        decompressedReferrer = referrer;
    }

    const response: {
        status: string;
        reason?: string;
        encodedSignature?: string;
        actor: string;
        referrer?: string;
        campaign: string;
        transactionHash: string;
        ruleId: string;
    } = (
        await qualificationPost("qualification/transaction", {
            transactionHash: txHash,
            actor: actor,
            referrer: decompressedReferrer,
            campaign: environment().contracts.vpburncampaign,
        })
    ).body;

    return response;
}
