import { useEffect, useState } from "react";
import * as Utils from "../../../../libs/utils";
import style from "./StakeLpStake.module.scss";
import { stakeLpEstimate } from "../../../../libs/api/astroport_liquidity/query";
import { queryPrice } from "../../../../libs/api/trade/query";
import { environment } from "../../../../libs/api/api";
import InputField from "../../../common/InputField/InputField";
import ConfirmButton from "../../../common/ConfirmButton/ConfirmButton";

import {
    divide,
    fixed,
    isNumber,
    isZero,
    leftGreaterThanRight,
    multiply,
} from "../../../../libs/Math";
import TxInfoView from "../../../common/TxInfoView/TxInfoView";
import { useRecoilValue } from "recoil";
import { balanceState } from "../../../../libs/contexts";
import { calculateTax } from "../../../../libs/api/tax";
import { getFeeHigh } from "../../../../libs/api/fee";
import LineDummy from "../../../common/LineDummy/LineDummy";

export default function StakeLpStakeAuto(props: {
    estimateParams?: ResponseLpEstimate;
    stakeLpAutoPressed: (token: string, uusd: string) => void;
}) {
    const balance = useRecoilValue(balanceState);

    const [price, setPrice] = useState("0");
    const [token, setToken] = useState("");
    const [uusd, setUusd] = useState("");

    const [error1, setError1] = useState<string | undefined>(undefined);
    const [error2, setError2] = useState<string | undefined>(undefined);
    const [lpEstimated, setLpEstimated] = useState("0");

    useEffect(() => {
        if (leftGreaterThanRight(token, Utils.getDecimal(balance.token))) {
            setError1("Insufficient balance");
        } else if (isZero(token)) {
            setError1("Amount must be greater than 0");
        } else {
            setError1(undefined);
        }
    }, [token, balance.token]);

    useEffect(() => {
        if (leftGreaterThanRight(uusd, Utils.getDecimal(balance.uusd))) {
            setError2("Insufficient balance");
        } else if (isZero(uusd)) {
            setError2("Amount must be greater than 0");
        } else {
            setError2(undefined);
        }
    }, [uusd, balance.uusd]);

    useEffect(() => {
        if (token && uusd && props.estimateParams) {
            stakeLpEstimate(
                {
                    token: props.estimateParams.pools.token,
                    uusd: props.estimateParams.pools.uusd,
                },
                {
                    token: multiply(token, 1000000),
                    uusd: multiply(uusd, 1000000),
                },
                props.estimateParams.lp_total_supply
            )
                .then((r) => {
                    setLpEstimated(r);
                })
                .catch((e) => { });
        } else {
            setLpEstimated("");
        }
    }, [token, uusd, props.estimateParams]);

    return (
        <div className={style.inner_container}>
            <div className={style.row}>
                <InputField
                    title={"Input VKR"}
                    type="amount"
                    balance={balance.token}
                    symbol={"VKR"}
                    onChanged={(v: string) => {
                        setToken(v);

                        if (v) {
                            queryPrice(environment().contracts.token)
                                .then((price) => {
                                    setPrice(price);
                                    const uusd = multiply(price, v);
                                    setUusd(
                                        !isNumber(uusd) ? "" : fixed(uusd, 3)
                                    );
                                })
                                .catch((e) => { });
                        } else {
                            setUusd("");
                        }
                    }}
                    value={token}
                    error={error1}
                    autofocus={true}
                    showSymbolIcon={true}
                />

                <div className={style.icon} />

                <InputField
                    title={"Input UST"}
                    type="amount"
                    decimal={3}
                    balance={balance.uusd}
                    symbol={"UST"}
                    onChanged={(v: string) => {
                        setUusd(v);
                        if (v) {
                            queryPrice(environment().contracts.token)
                                .then((price) => {
                                    setPrice(price);
                                    const token = divide(v, price);
                                    setToken(
                                        !isNumber(token) ? "" : fixed(token, 6)
                                    );
                                })
                                .catch((e) => { });
                        } else {
                            setToken("");
                        }
                    }}
                    value={uusd}
                    error={error2}
                    showSymbolIcon={true}
                />
            </div>

            <div className={style.line_container}>
                <LineDummy horizontal={true} marginTop={15} marginBottom={0} />
            </div>

            <div className={style.exchangeicon} />

            <InputField
                title={"VKR-UST LP"}
                type="amount"
                symbol={"LP"}
                onChanged={(v: string) => { }}
                value={lpEstimated ? Utils.getDecimal(lpEstimated, true) : ""}
                error={undefined}
                disabled={true}
                showSymbolIcon={true}
            />

            {token ? <InfoView price={price} uusd={uusd} /> : <div />}

            <div className={style.spreaddummy} />

            <ConfirmButton
                title={"Provide"}
                enable={!error1 && !error2 && token !== ""}
                onClick={() => {
                    props.stakeLpAutoPressed(
                        fixed(multiply(token, 1000000), 0),
                        fixed(multiply(uusd, 1000000), 0)
                    );
                }}
            />
        </div>
    );
}

function InfoView(props: { price: string; uusd: string }) {
    const [fee, setFee] = useState("0");

    useEffect(() => {
        getFeeHigh()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => { });
    }, []);

    const [tax, setTax] = useState("0");

    useEffect(() => {
        calculateTax(multiply(props.uusd, 1000000), "uusd")
            .then((r) => {
                setTax(r);
            })
            .catch((e) => { });
    }, [props.uusd]);

    const list = [
        {
            title: "Estimated Price",
            body: Utils.getDecimal(multiply(props.price, 1000000), true, 3),
            symbol: " UST per VKR",
        },
    ];

    return <TxInfoView list={list} fee={fee} tax={tax} />;
}
