import { lpApr } from "../rest";
import {
    divide,
    fixed,
    isZero,
    min,
    minus,
    multiply,
    plus,
    sqrt,
} from "../../Math";
import { environment, wasmQuery } from "../api";

import { queryNativeBalance, queryTokenBalance } from "../trade/query";
import { calculateTax } from "../tax";

export async function queryLpEstimateParams(): Promise<ResponseLpEstimate> {
    const balances = await queryNativeBalance(
        "uusd",
        environment().contracts.astroport.pair
    );
    const uusd = balances.amount;

    const response1 = await wasmQuery(environment().contracts.token, {
        balance: {
            address: environment().contracts.astroport.pair,
        },
    });

    const response2 = await wasmQuery(environment().contracts.astroport.lp, {
        token_info: {},
    });

    const token = response1.balance;
    const lp_total_supply = response2.total_supply;

    return {
        pools: {
            uusd: uusd,
            token: token,
        },
        lp_total_supply: lp_total_supply,
    };
}

export async function stakeLpEstimate(
    pools: {
        token: string;
        uusd: string;
    },
    deposits: {
        token: string;
        uusd: string;
    },
    lp_total_supply: string
): Promise<string> {
    let tax1 = await calculateTax(deposits.uusd, "uusd");
    let tax2 = await calculateTax(minus(deposits.uusd, tax1), "uusd");

    //user => lp contract
    //lp contract => staking contract
    //sent 2times.

    const tax = plus(tax1, tax2);
    let estimate = "0";

    if (isZero(lp_total_supply)) {
        estimate = sqrt(multiply(deposits.token, deposits.uusd));
    } else {
        const v1 = divide(multiply(deposits.uusd, lp_total_supply), pools.uusd);
        const v2 = divide(
            multiply(deposits.token, lp_total_supply),
            pools.token
        );

        estimate = min(v1, v2);
    }

    return fixed(minus(estimate, tax), 6);
}

export async function unstakeLpEstimate(
    pools: {
        token: string;
        uusd: string;
    },
    amount: string,
    lp_total_supply: string
): Promise<{
    token: string;
    uusd: string;
}> {
    const shareRatio = divide(amount, lp_total_supply);

    const token = multiply(pools.token, shareRatio);
    const uusd = multiply(pools.uusd, shareRatio);

    return {
        token: fixed(token, 0),
        uusd: fixed(uusd, 0),
    };
}

export async function astroportDepositAmount(address: string): Promise<string> {
    return await wasmQuery(environment().contracts.astroport.generator, {
        deposit: {
            lp_token: environment().contracts.astroport.lp,
            user: address,
        },
    });
}

async function astroportRewardInfo(
    address: string
): Promise<ResponseAstroportRewardInfo> {
    return await wasmQuery(environment().contracts.astroport.generator, {
        pending_token: {
            lp_token: environment().contracts.astroport.lp,
            user: address,
        },
    });
}

async function lpStakingState(): Promise<ResponseLpStakeStateInfo> {
    return await wasmQuery(environment().contracts.astroport.staking, {
        state: {},
    });
}

export async function stakeLpInfo(
    address: string | undefined
): Promise<StakeLpHeaderInfo> {
    const apr = await lpApr();
    const state = await lpStakingState();

    let balance = "0";
    let bond_amount = "0";
    let pending = "0";
    let pending_on_proxy = "0";

    if (address) {
        balance = (await queryTokenBalance(undefined, address)).amount;
        const info = await astroportRewardInfo(address);
        bond_amount = await astroportDepositAmount(address);
        pending = info.pending;
        pending_on_proxy = info.pending_on_proxy ?? "0";
    }

    return {
        apr: apr,
        totalStaked: state.total_bond_amount,
        stakable: balance,
        staked: bond_amount,
        unstakable: bond_amount,
        pending: pending,
        pending_on_proxy: pending_on_proxy,
    };
}
