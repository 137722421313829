import * as Utils from "../../../libs/utils";
import style from "./InputField.module.scss";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import ComboBox from "../ComboBox/ComboBox";
import TitleLabel from "../Labels/TitleLabel";
import TooltipIcon from "../TooltipIcon/TooltipIcon";
import BigNumber from "bignumber.js";

const cx = classNames.bind(style);

export default function InputField(props: {
    title: string;
    type: "amount" | "text";
    decimal?: number;
    balanceTitle?: string;
    balance?: string;
    disabled?: boolean;
    tooltip?: string;
    symbol?: string;
    placeholder?: string;
    autofocus?: boolean;

    value: string;
    onChanged: (v: string) => void;
    error?: string;
    combo?: {
        selected: number;
        setSelected: (index: number) => void;
        list: string[];
    };
    showSymbolIcon?: boolean;
}) {
    return (
        <div className={style.container}>
            <div className={style.title_container}>
                {props.tooltip ? (
                    <div className={style.title_container_v2}>
                        <TitleLabel
                            text={props.title}
                            color={"#fff"}
                            size={14}
                            fontWeight={"medium"}
                        />
                        <div style={{ display: "flex", marginLeft: "4px" }}>
                            <TooltipIcon tooltip={props.tooltip} />
                        </div>
                    </div>
                ) : (
                    <TitleLabel
                        text={props.title}
                        color={"#fff"}
                        size={14}
                        fontWeight={"medium"}
                    />
                )}

                <div style={{ flex: 1 }} />

                <BalanceView
                    balanceTitle={props.balanceTitle}
                    balance={props.balance}
                    symbol={props.symbol}
                    decimal={props.decimal ?? 6}
                    disabled={props.disabled}
                    onChanged={props.onChanged}
                />
            </div>

            <div
                className={cx(style.body_container, {
                    error: props.error,
                })}
            >
                {props.showSymbolIcon ? (
                    <div className={style.inputico}>
                        {props.symbol === "VKR" ? (
                            <div className={style.inputico_vkr} />
                        ) : props.symbol === "UST" ? (
                            <div className={style.inputico_ust} />
                        ) : props.symbol === "VP" ? (
                            <div className={style.inputico_vp} />
                        ) : props.symbol === "LP" ? (
                            <div className={style.inputico_lp} />
                        ) : (
                            <div />
                        )}
                    </div>
                ) : (
                    <div />
                )}

                <input
                    className={cx(
                        style.field,

                        {
                            error: props.error && !props.disabled,
                        },
                        {
                            disabled: props.disabled,
                        },
                        {
                            combo: props.combo !== undefined,
                        },
                        {
                            showSymbolIcon: props.showSymbolIcon === true,
                        }
                    )}
                    maxLength={props.type === "amount" ? 40 : 100}
                    placeholder={
                        props.placeholder
                            ? props.placeholder
                            : props.type === "amount"
                            ? "0.000"
                            : undefined
                    }
                    disabled={props.disabled}
                    value={props.value}
                    onChange={(e) => {
                        let value = e.target.value;

                        if (props.type === "text") {
                            try {
                                //check base64 encodable
                                btoa(value);
                                props.onChanged(value);
                            } catch (e) {
                                //can't
                            }
                        } else {
                            if (value) {
                                const validated = Utils.inputFieldValidate(
                                    value,
                                    props.decimal ?? 6
                                );
                                if (validated) {
                                    props.onChanged(validated);
                                }
                            } else {
                                //빈값일때
                                props.onChanged(value);
                            }
                        }
                    }}
                    autoFocus={
                        window.innerWidth < 1000
                            ? false
                            : props.autofocus ?? false
                    }
                    type={props.type === "amount" ? "tel" : props.type}
                    inputMode={props.type === "amount" ? "decimal" : "text"}
                />

                {props.combo ? (
                    <ComboView value={props.combo!} />
                ) : (
                    <SymbolView symbol={props.symbol} />
                )}
            </div>

            <ErrorView title={props.error} />
        </div>
    );
}

function BalanceView(props: {
    balance: string | undefined;
    balanceTitle?: string;
    decimal: number;
    symbol?: string;
    disabled?: boolean;
    onChanged: (balance: string) => void;
}) {
    if (!props.balance) {
        return <div />;
    }

    return (
        <div className={style.balance_container}>
            <div className={style.title}>
                <TitleLabel
                    text={props.balanceTitle ? props.balanceTitle : "Balance:"}
                    color="#fff"
                    size={14}
                    fontWeight={"light"}
                />
            </div>
            &nbsp;
            <div
                className={cx(style.value, {
                    disabled: props.disabled,
                })}
                onClick={() => {
                    if (!props.disabled) {
                        props.onChanged(
                            Utils.getDecimal(
                                props.balance!,
                                false,
                                props.decimal
                            )
                        );
                    }
                }}
            >
                <TitleLabel
                    text={new BigNumber(
                        Utils.getDecimal(props.balance, false, props.decimal)
                    ).toFormat()}
                    symbol={props.symbol}
                    underline={props.disabled ? false : true}
                    size={14}
                    fontWeight={"light"}
                />
            </div>
        </div>
    );
}

function SymbolView(props: { symbol: string | undefined }) {
    if (!props.symbol) {
        return <div />;
    }

    return <div className={style.symbol}>{props.symbol}</div>;
}

function ErrorView(props: { title: string | undefined }) {
    if (!props.title) {
        return <div style={{ height: "30px" }} />;
    }

    return (
        <div className={style.error_container}>
            <div className={style.error_text}>{props.title}</div>
            <div style={{ flex: 1 }} />
        </div>
    );
}

function ComboView(props: {
    value: {
        selected: number;
        setSelected: (index: number) => void;
        list: string[];
    };
}) {
    const [showCombo, setShowCombo] = useState(false);

    return (
        <div
            className={cx(style.combobox)}
            onClick={() => {
                setShowCombo(true);
            }}
        >
            <div className={style.dummy} />
            <div style={{ flex: 1 }} />
            <div className={style.symbol}>
                {props.value.list[props.value.selected]}
            </div>
            <div className={style.icon} />

            <ComboBox
                showCombo={showCombo}
                setShowCombo={setShowCombo}
                selected={props.value.selected}
                setSelected={(v) => {
                    props.value.setSelected(v);
                }}
                list={props.value.list.map((item, index) => {
                    return {
                        value: index.toString(),
                        display: props.value.list[index],
                    };
                })}
            />
        </div>
    );
}
