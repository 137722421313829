import style from "./FanfuryInputFieldTxPaste.module.scss";
import classNames from "classnames/bind";
import TitleLabel from "../Labels/TitleLabel";

const cx = classNames.bind(style);

export default function FanfuryInputFieldTxPaste(props: {
    autofocus?: boolean;
    value: string;
    onChanged: (v: string) => void;
    passed: boolean;
    setPassed: (v: boolean) => void;
    error?: string;
    disabled?: boolean;
}) {
    return (
        <div className={style.container}>
            <div className={style.title_container}>
                <div
                    className={cx(style.tooltipicon, { passed: props.passed })}
                />

                <TitleLabel
                    text={
                        props.passed
                            ? "It's connected normally"
                            : "Please enter the TX that came out after the campaign action was executed."
                    }
                    color={props.passed ? "#55f559" : "#fff"}
                    size={12}
                    fontWeight={"medium"}
                />

                <div style={{ flex: 1 }} />
            </div>

            <div
                className={cx(style.body_container, {
                    error: props.error,
                })}
            >
                <input
                    className={cx(
                        style.field,
                        {
                            error: props.error,
                        },
                        {
                            disabled: props.passed || props.disabled,
                        }
                    )}
                    maxLength={999}
                    disabled={props.passed || props.disabled}
                    value={props.value}
                    onChange={(e) => {
                        let value = e.target.value;

                        try {
                            //check base64 encodable
                            btoa(value);
                            props.onChanged(value);
                        } catch (e) {
                            //can't
                        }
                    }}
                    autoFocus={
                        window.innerWidth < 1000
                            ? false
                            : props.autofocus ?? false
                    }
                    type={"text"}
                    inputMode={"text"}
                />

                {props.passed ? (
                    <div className={style.reset_container}>
                        <div className={style.dummy} />
                        <div
                            className={style.resetbutton}
                            onClick={() => {
                                props.setPassed(false);
                            }}
                        >
                            {"Reset"}
                        </div>
                    </div>
                ) : (
                    <div
                        className={style.pastebutton}
                        onClick={() => {
                            if (!props.disabled) {
                                navigator.clipboard
                                    .readText()
                                    .then((r) => {
                                        props.onChanged(r);
                                    })
                                    .catch((e) => {});
                            }
                        }}
                    >
                        {"Paste"}
                    </div>
                )}
            </div>

            <ErrorView title={props.error} />
        </div>
    );
}

function ErrorView(props: { title: string | undefined }) {
    if (!props.title) {
        return <div style={{ height: "30px" }} />;
    }

    return (
        <div className={style.error_container}>
            <div className={style.error_text}>{props.title}</div>
            <div style={{ flex: 1 }} />
        </div>
    );
}
