import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { environment } from "../../../../../libs/api/api";
import { blockState } from "../../../../../libs/contexts";
import { dateFormat11 } from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./TalisStandbyBanner.module.scss";

export default function TalisStandbyBanner(props: {
    earlybird: boolean;
    requirement?: TalisRequirement;
}) {
    const block = useRecoilValue(blockState);
    const [time, setTime] = useState(0);
    const [leftBlock, setLeftBlock] = useState(999999);

    useEffect(() => {
        let time = 0;
        if (props.earlybird) {
            time = environment().contracts.talis_early.startTimeSeconds;
        } else {
            time = environment().contracts.talis_public.startTimeSeconds;
        }
        setTime(time * 1000);
    }, [props.earlybird]);

    useEffect(() => {
        if (props.requirement) {
            setLeftBlock(props.requirement.start_block - block.block);
        }
    }, [props.requirement, block]);

    return (
        <div className={style.container}>
            <div className={style.wrap}>
                <div className={style.sub}>
                    {props.earlybird ? (
                        <TitleLabel
                            size={14}
                            fontWeight={"light"}
                            text={"VP EXCLUSIVE Open"}
                            color={"#fff"}
                        />
                    ) : (
                        <TitleLabel
                            size={14}
                            fontWeight={"light"}
                            text={"Public Open"}
                            color={"#fff"}
                        />
                    )}
                </div>
                <div className={style.content}>
                    <TitleLabel
                        size={20}
                        text={
                            leftBlock <= 100
                                ? leftBlock + " Blocks left"
                                : dateFormat11(time)
                        }
                        color={"#ffe646"}
                    />
                </div>
            </div>
        </div>
    );
}
