import { useEffect, useState } from "react";
import PageHeader from "../common/PageHeader/PageHeader";
import * as Utils from "../../libs/utils";
import style from "./GovernanceVote.module.scss";
import TxBoxView from "../common/TxBoxView/TxBoxView";
import InputField from "../common/InputField/InputField";
import Warning from "../common/Warning/Warning";
import ConfirmButton from "../common/ConfirmButton/ConfirmButton";

import { PATHS } from "../../App";
import { pollVote } from "../../libs/api/governance/execution";
import { govState } from "../../libs/api/governance/query";
import { fixed, isZero, leftGreaterThanRight, multiply } from "../../libs/Math";
import TxInfoView from "../common/TxInfoView/TxInfoView";
import { useWallet } from "@terra-money/wallet-provider";
import { useRecoilValue } from "recoil";
import { walletState } from "../../libs/contexts";
import { getFeeNormal } from "../../libs/api/fee";
import TitleLabel from "../common/Labels/TitleLabel";
import classNames from "classnames/bind";
import LineDummy from "../common/LineDummy/LineDummy";

const cx = classNames.bind(style);

export default function GovernanceVote(props: any) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);
    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    const [staked, setStaked] = useState("0");
    const [token, setToken] = useState("");
    const [selected, setSelected] = useState(-1);

    const [pollId, setPollId] = useState<number | undefined>(undefined);
    const [voteTitle, setVoteTitle] = useState("");
    const [rate, setRate] = useState({
        yes: "0",
        no: "0",
        abstain: "0",
    });

    const [error1, setError1] = useState<string | undefined>(undefined);

    useEffect(() => {
        const state: any = props.history.location.state;
        if (!state || !state.info) {
            props.history.replace(PATHS.governance);
            return;
        }

        const info = state.info as PollInfo;

        setVoteTitle(info.info.title);
        setPollId(info.info.id);

        setRate({
            yes: info.votes.display.yes,
            no: info.votes.display.no,
            abstain: info.votes.display.abstain,
        });
    }, [props.history]);

    useEffect(() => {
        govState(wallet ? wallet.terraAddress : undefined)
            .then((r) => {
                setStaked(r.staked);
            })
            .catch((e) => {});
    }, [wallet]);

    useEffect(() => {
        if (leftGreaterThanRight(token, Utils.getDecimal(staked))) {
            setError1("Insufficient balance");
        } else if (isZero(token)) {
            setError1("Amount must be greater than 0");
        } else {
            setError1(undefined);
        }
    }, [token, staked]);

    function votePressed() {
        if (!pollId || !wallet) {
            return;
        }

        setHash({
            type: "GovVote",
            isPending: true,
        });

        pollVote(
            wallet.terraAddress,
            pollId,
            selected === 0 ? "yes" : selected === 1 ? "no" : "abstain",
            fixed(multiply(token, 1000000), 0)
        )
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "GovVote",
                    response: response,
                    redirect: PATHS.governancePollDetail + pollId,
                });
            })
            .catch((error) => {
                setHash({
                    type: "GovVote",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    if (pollId === undefined) {
        return <div />;
    }

    return (
        <div className={style.governance_detail_container}>
            <div style={{ height: "40px" }} />
            <TitleLabel
                text="Vote"
                color={"#ffffff"}
                fontWeight={"light"}
                size={20}
            />
            <div style={{ height: "20px" }} />
            <div
                className={cx(style.inner_container, {
                    hash: hash,
                })}
            >
                <TxBoxView
                    transparent={true}
                    hash={hash}
                    clearHash={() => {
                        setHash(undefined);
                    }}
                >
                    <TitleLabel text={voteTitle} color={"#ffffff"} size={20} />
                    <div className={style.buttons}>
                        <div
                            className={cx("button", "yes", {
                                selected: selected === 0,
                            })}
                            onClick={() => {
                                setSelected(0);
                            }}
                        >
                            {"YES"}
                        </div>
                        <div className={style.dummy} />
                        <div
                            className={cx("button", "no", {
                                selected: selected === 1,
                            })}
                            onClick={() => {
                                setSelected(1);
                            }}
                        >
                            {"NO"}
                        </div>
                        <div className={style.dummy} />
                        <div
                            className={cx("button", "abstain", {
                                selected: selected === 2,
                            })}
                            onClick={() => {
                                setSelected(2);
                            }}
                        >
                            {"ABSTAIN"}
                        </div>
                    </div>
                    <InputField
                        title="Amount"
                        balance={staked}
                        type={"amount"}
                        symbol="VKR"
                        value={token}
                        onChanged={setToken}
                        error={error1}
                    />

                    <div style={{ height: "20px" }} />

                    {token ? (
                        <div className={style.warning_container}>
                            <InfoView />
                            <div style={{ marginTop: "-30px" }} />
                            <Warning
                                msg="Vote cannot be changed after submission. Staked VKR used to vote in polls are locked and cannot be withdrawn until the poll finishes."
                                borderHidden={true}
                            />
                        </div>
                    ) : (
                        <div />
                    )}

                    <div style={{ height: "40px" }} />

                    <LineDummy horizontal={true} />

                    <ConfirmButton
                        title={"Submit"}
                        enable={!error1 && token !== "" && selected >= 0}
                        onClick={votePressed}
                    />
                </TxBoxView>
            </div>
        </div>
    );
}

function InfoView() {
    const [fee, setFee] = useState("0");

    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => {});
    }, []);

    return <TxInfoView fee={fee} />;
}
