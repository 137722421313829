import {
    Coins,
    LCDClient,
    MsgExecuteContract,
    TxInfo,
    Fee,
    Coin,
    AccAddress,
    CreateTxOptions,
    Account,
} from "@terra-money/terra.js";
import axios from "axios";

import { getNetwork } from "../storage";
import { divide, plus } from "../Math";
import * as Utils from "../utils";
import { NetworkInfo } from "@terra-money/wallet-provider";
import { calculateTax } from "./tax";
import { getFeeNormal } from "./fee";

export const blockPerDay = 12758;

export enum NetworkType {
    mainnet,
    testnet,
    localterra,
}

export const WebLinks = {
    github: "https://github.com/valkyrieprotocol",
    twitter: "https://twitter.com/valkyrie_money",
    forum: "https://forum.valkyrieprotocol.com",
    telegram: "https://t.me/valkyrie_protocol",
    discord: "",
    medium: "https://medium.com/@valkyrie-protocol",
};

export const DocsLinks = {
    campaign: "https://docs.valkyrieprotocol.com/webapp/campaign#campaign-main",
    trade: "https://docs.valkyrieprotocol.com/webapp/trade#trade",
    stake: "https://docs.valkyrieprotocol.com/webapp/stake",
    governance:
        "https://docs.valkyrieprotocol.com/webapp/governance#governance-main",
};

export function isMainnet(info: NetworkInfo | undefined): NetworkType {
    const n = info ? info : getNetwork() ?? network.mainnet;

    if (
        n.name.toLowerCase().includes("mainnet") ||
        n.name.toLowerCase().includes("classic")
    ) {
        return NetworkType.mainnet;
    } else if (n.name.toLowerCase().includes("testnet")) {
        return NetworkType.testnet;
    } else {
        return NetworkType.localterra;
    }
}

export const network = {
    mainnet: {
        name: "mainnet",
        chainID: "columbus-5",
        lcd: "https://columbus-lcd.terra.dev",
    },
    testnet: {
        name: "testnet",
        chainID: "bombay-12",
        lcd: "https://bombay-lcd.terra.dev",
    },
    local: {
        name: "localterra",
        chainID: "localterra",
        lcd: "https://internal-fcd.valkyrieprotocol.com",
    },
};

export function environment() {
    if (isMainnet(undefined) === NetworkType.mainnet) {
        return {
            fcd: "https://columbus-fcd.terra.dev",
            mantle: "https://hive.terra.dev/graphql",
            api: "https://api.valkyrieprotocol.com",
            qualification_api: "https://qualification-api.valkyrieprotocol.com",

            finder: {
                tx: "https://finder.terra.money/mainnet/tx/",
                account: "https://finder.terra.money/mainnet/address/",
            },
            contracts: {
                governance: "terra1w6xf64nlmy3fevmmypx6w2fa34ue74hlye3chk",
                token: "terra1dy9kmlm4anr92e42mrkjwzyvfqwz66un00rwr5",
                campaignManager: "terra18s9khg4ugpv4hyy0ed7jxc6lmq5pcyyh9djakz",
                airdrop: "terra1s5ww3afj9ym9k5ceu5m3xmea0t9tl7fmh7r40h",
                tx_qualifier: "terra10x2qusnxecp0al2lasz39wghzn5nr6glh924ug",

                astroport: {
                    //todo
                    pair: "terra15s2wgdeqhuc4gfg7sfjyaep5cch38mwtzmwqrx", //pool
                    lp: "terra1lw36qqz72mxajrfgkv24lahudq3ehmkpc305yc",
                    generator: "terra1zgrx9jjqrfye8swykfgmd6hpde60j0nszzupp9",
                    staking: "terra1wjc6zd6ue5sqmyucdu8erxj5cdf783tqle6dja",
                    astroPair: "terra1l7xu2rl3c7qmtx3r5sd2tz25glf6jh8ul7aag7",
                    astroToken: "terra1xj49zyqrwpv5k928jwfpfy2ha668nwdgkwlrg3",
                },
                terraswap: {
                    pair: "terra1e59utusv5rspqsu8t37h5w887d9rdykljedxw0",
                    lp: "terra17fysmcl52xjrs8ldswhz7n6mt37r9cmpcguack",
                    staking: "terra1ude6ggsvwrhefw2dqjh4j6r7fdmu9nk6nf2z32",
                },
                vp_token: "terra1v6khrawqqa6pqhwa0p6a5dt2a2g05rnum5mtcy",
                airdrop_vp_genesis:
                    "terra1677a796735gqu82nv9agy45q549lafs4n2xswe",

                lunart_early: {
                    campaign: "terra1mcwc6th7kj0tfkjfnje9msk6s9hrq99xwmjq2x",
                    qualifier: "terra1jn5sgf8u2cfsmtrk72nwqtc2cf486wx4q5zzzg",
                },

                lunart_public: {
                    campaign: "terra1pq9x0f5pfp5yhkx8njqtdqpdqvzemd57xpy5fr",
                    qualifier: "terra1h7e3wlq076gpjwj3gd05kfl9mt9z7zeusyfhhv",
                },
                fanfury_early: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "2940000000000",
                    ustprice: "0.05",
                    startTimeSeconds: 1651060800,
                },

                fanfury_public: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "1260000000000",
                    ustprice: "0.05",
                    startTimeSeconds: 1651147200,
                },
                talis_early: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "50000000000000",
                    ustprice: "0.012",
                    startTimeSeconds: 1652612400,
                },

                talis_public: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "25000000000000",
                    ustprice: "0.012",
                    startTimeSeconds: 1652698800,
                },
                stader: {
                    campaign: "",
                    qualifier: "",
                },
                vpburncampaign: "terra10unjnnh8hllcuetpxeg576rt4jt8fxks0m8kkw",
            },
            assets: {
                tokens: "https://assets.terra.money/cw20/tokens.json",
                pairs: "https://assets.terra.money/cw20/pairs.json",
            },
        };
    } else if (isMainnet(undefined) === NetworkType.testnet) {
        return {
            fcd: "https://bombay-fcd.terra.dev",
            mantle: "https://testnet-hive.terra.dev/graphql",
            api: "https://api.testnet.valkyrieprotocol.com",
            qualification_api:
                "https://qualification-api.testnet.valkyrieprotocol.com",

            finder: {
                tx: "https://finder.terra.money/testnet/tx/",
                account: "https://finder.terra.money/testnet/address/",
            },
            contracts: {
                governance: "terra102jsr0c2d5nhaa8vmjw0nerphw0s2cs70tz9a3",
                token: "terra1a8hskrwnccq0v7gq3n24nraaqt7yevzy005uf5",
                campaignManager: "terra1zx8pcw9t43u4xnjz4648mz7v6mzt2gpsekt23j",
                airdrop: "terra1ewvvj536al328pg6rrf6r2hlxm0jv0rll6rwdq",
                tx_qualifier: "terra1drrx23azt2c5mtz5udhu5hqdg95plun5rvypzt",

                astroport: {
                    pair: "terra168jy6ej8rm2kf8wtygavpqq5fpc5ewq39xe36a",
                    lp: "terra1qrpflfyte76cvxdj8tftj2qvat47aus38h58p4",
                    generator: "terra1gjm7d9nmewn27qzrvqyhda8zsfl40aya7tvaw5",
                    staking: "terra1q4uk5vh7km9dtnlwtzlpmtzrtvf9yzzst46tl7",
                    astroPair: "terra1ec0fnjk2u6mms05xyyrte44jfdgdaqnx0upesr",
                    astroToken: "terra1jqcw39c42mf7ngq4drgggakk3ymljgd3r5c3r5",
                    //generator proxy terra1zg79e3hvg27uzatfzrrrjns7qz6saj5kmsv596
                },
                terraswap: {
                    pair: "terra1wqlehpdy5yu787lnk5k8c2zf0eyvc9rt8ek0x3",
                    lp: "terra1gwktz6stdrr377e4nmqu49es8e73pxwx7qejew",
                    staking: "terra1pemp0pyupazlfye0cuxn0cz93mu02p8m5uep3k",
                },
                vp_token: "terra1n0909008f4h6kjf9jp8upe45f0hncwqwwxyck9",
                airdrop_vp_genesis:
                    "terra15q2fsnrn8zf9pxy4duxz7e304z2nc7jtxjmp56",
                lunart_early: {
                    campaign: "terra1fumzchsxqhem7hrf6ezna47hsh6rw6gj7j8pjg",
                    qualifier: "terra1zaaajg6fh6vvuhx8ze5jgk8pk5mrhhwe8fngmx",
                },

                lunart_public: {
                    campaign: "terra1ys2w54u0sqrseqy5dcjl5nmnw8h8pp475wg2xz",
                    qualifier: "terra1cjq00huhj25nuek0k4wnltr7a9usy2r2vwenm2",
                },
                fanfury_early: {
                    campaign: "terra18jcdvstmze80kfcq3egc3a9sqy4n7p7nwl8sdp",
                    qualifier: "terra1ac40le3za2hza5a0pf24ygm8gxcnmcn88s57qq",
                    total_distribution: "2940000000000",
                    ustprice: "0.05",
                    startTimeSeconds: 1651060800,
                    // depositvkr: "1500000000",
                    // usedvp: "100000000",
                },

                fanfury_public: {
                    campaign: "terra1ux4rhwmhp460jf6qgaaprxtp2fuj0xyec3szud",
                    qualifier: "terra14ev5asdp0f8vkvq0hmt34lz2l3p4s0e0twllgg",
                    total_distribution: "1260000000000",
                    ustprice: "0.05",
                    startTimeSeconds: 1651147200,
                    // depositvkr: "600000000",
                    // usedvp: "15000000",
                },
                talis_early: {
                    campaign: "terra16drcmcv7atk47lu96lf9gezvt2wv4aejlqc7j0",
                    qualifier: "terra166254nll3zx5qe7eutsdmcfedhz09halw6ef2f",
                    total_distribution: "50000000000000",
                    ustprice: "0.012",
                    startTimeSeconds: 1652612400,
                    // depositvkr: "1500000000",
                    // usedvp: "100000000",
                },

                talis_public: {
                    campaign: "terra1huxhlrku59yzhg7q3yqmufvrrll07zpdvv5y3j",
                    qualifier: "terra1y6mmudtgd944z928g9dhw026n6q9stvq78j0vp",
                    total_distribution: "25000000000000",
                    ustprice: "0.012",
                    startTimeSeconds: 1652698800,
                    // depositvkr: "600000000",
                    // usedvp: "15000000",
                },
                stader: {
                    campaign: "terra15de3a2axdzl8x75jd5ekvhgt2kjqdm8wjyl5yl",
                    qualifier: "terra1ludm772k9tgs0wdqpmzwhf34fm62j9k0tdymtx",
                },
                vpburncampaign: "terra1z83udrav3gzg5rd8g07yrlfzj22qx9lv5jn9q2",
            },
            assets: {
                tokens: "https://assets.terra.money/cw20/tokens.json",
                pairs: "https://assets.terra.money/cw20/pairs.json",
            },
        };
    } else {
        return {
            fcd: "https://internal-fcd.valkyrieprotocol.com",
            mantle: "https://internal-mantle.valkyrieprotocol.com/graphql",
            api: "https://api.dev.valkyrieprotocol.com",
            qualification_api:
                "https://qualification-api.internal.valkyrieprotocol.com",

            finder: {
                tx: "https://finder.terra.money/testnet/tx/",
                account: "https://finder.terra.money/testnet/address/",
            },
            contracts: {
                governance: "terra1ywaaadu2yvxvrqatvc00ekcxdqh8a9ntme4g6z",
                token: "terra1vs2vuks65rq7xj78mwtvn7vvnm2gn7adjlr002",
                campaignManager: "terra18luftlqdlhe6he3p4xedx5a6vqxd8dywjlu7kh",
                airdrop: "terra1flfesrahstklfv5j554f4a89heuxhcudptlc0k",
                tx_qualifier: "terra1a8ahr94hgd7v04m5mw4g8sm8678q0dup4r5fw5",

                astroport: {
                    //todo
                    // vesting: "terra1sg3dycj0ng5r0u673s67ulp6kqwlg2e9hk55uc",
                    pair: "terra17p9rca8w29rwh0ngm3yjuvun57jn2uuc4k9ak2",
                    lp: "terra1rszs2gq8mcyfj2p4e4ae6pyacrpdr7wz4hvmzc",
                    generator: "terra1yeg76hz6cs9sghmcac30k0t7el6l0k9wa9u7ee",
                    staking: "terra1nalx6xq2z4mz8utr7aqjax9mfdqae4q7yarn3e",
                    astroPair: "",
                    astroToken: "terra184j55rvkcfxf63kml8xehj24nuekf6p6n6s54l",
                },
                terraswap: {
                    pair: "terra15nahnssd0cn2xgd7ju6tec34ekmzgm89409px7",
                    lp: "terra19n7rdc3djhctsrnf2l042ugk0y9gml3twyzp8y",
                    staking: "terra1gwjy6w3365lxxjxjgmetskmd0v7fyfjtrl59aw",
                },
                vp_token: "terra150g4en2kgezy84ueq88ddmjpkt6c0d2hlljth4",
                airdrop_vp_genesis:
                    "terra1lgcu92rmq2gfaz00tgn666gggq0d8cpgqaxspm",
                lunart_early: {
                    campaign: "",
                    qualifier: "terra12clk7ta6cl5yxckps6v93w847fvnckmdttnuns",
                },
                lunart_public: {
                    campaign: "",
                    qualifier: "terra1maar3g638gscv466cq523ahsh7vj0m0r4q0fxh",
                },
                fanfury_early: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "2940000000000",
                    ustprice: "0.05",
                    startTimeSeconds: 1651060800,
                    // depositvkr: "1500000000",
                    // usedvp: "100000000",
                },

                fanfury_public: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "1260000000000",
                    ustprice: "0.05",
                    startTimeSeconds: 1651060800,
                    // depositvkr: "600000000",
                    // usedvp: "15000000",
                },
                talis_early: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "50000000000000",
                    ustprice: "0.012",
                    startTimeSeconds: 1651060800,
                    // depositvkr: "1500000000",
                    // usedvp: "100000000",
                },

                talis_public: {
                    campaign: "",
                    qualifier: "",
                    total_distribution: "25000000000000",
                    ustprice: "0.012",
                    startTimeSeconds: 1651147200,
                    // depositvkr: "600000000",
                    // usedvp: "15000000",
                },
                stader: {
                    campaign: "",
                    qualifier: "",
                },
                vpburncampaign: "",
            },
            assets: {
                tokens: "https://assets.terra.money/cw20/tokens.json",
                pairs: "https://assets.terra.money/cw20/pairs.json",
            },
        };
    }
}

let lcdClient: LCDClient | undefined = undefined;
export function getLcdClient(): LCDClient {
    if (lcdClient === undefined) {
        const n = getNetwork() ?? network.mainnet;

        // lcdClient = new LCDClient({
        //     URL: n.lcd,
        //     chainID: n.chainID,
        // });

        lcdClient = new LCDClient({
            URL:
                n.name === "mainnet" ? "https://columbus-lcd.terra.dev" : n.lcd,
            chainID: n.chainID,
            // isClassic: true,
        });
    }
    return lcdClient;
}

export async function latestBlock(): Promise<{
    height: number;
    time: number;
}> {
    return await blockInfo();
}

export async function getPublicKey(address: string): Promise<string> {
    const account = await getLcdClient().auth.accountInfo(address);
    let json = JSON.parse(account.toJSON());
    let publicKey = json.pub_key.key;

    return publicKey;
}

async function blockInfo(height?: number) {
    const blockInfo = (await getLcdClient().tendermint.blockInfo(height)).block;
    return {
        height: parseInt(blockInfo.header.height),
        time: Date.parse(blockInfo.header.time),
    };
}

export async function blockTime(
    latest: { height: number; time: number },
    height: number
): Promise<number> {
    const latestHeight = latest.height;

    if (height === latestHeight) {
        return latest.time;
    } else if (height < latestHeight) {
        return (await blockInfo(height)).time;
    } else {
        const block = latest.height - 100000;
        const genesis = await blockInfo(Math.max(block, 2));

        const genesisHeight = genesis.height;
        const genesisTime = genesis.time;
        const latestTime = latest.time;
        const timePerBlock =
            (latestTime - genesisTime) / (latestHeight - genesisHeight);

        return (height - latestHeight) * timePerBlock + latestTime;
    }
}

export function addressValidate(address: string) {
    return AccAddress.validate(address);
}

export async function bankBalance(address: string): Promise<Coins> {
    return getLcdClient()
        .bank.balance(address)
        .then((r) => {
            return r[0];
        })
        .catch((e) => {
            return new Coins();
        });
}

export async function getTxInfo(hash: string): Promise<TxInfo> {
    return await getLcdClient().tx.txInfo(hash);
}

export async function tokenInfo(contract: string): Promise<ResponseTokenInfo> {
    return await wasmQuery(contract, {
        token_info: {},
    });
}

export async function getSymbol(denom: string): Promise<string> {
    if (Utils.isNativeToken(denom)) {
        if (denom.toLowerCase() === "uluna") {
            return "Luna";
        } else {
            return denom.substring(1, denom.length - 1).toUpperCase() + "T";
        }
    } else if (denom === environment().contracts.token) {
        return "VKR";
    } else {
        return (
            await tokenInfo(denom)
                .then((r) => {
                    return r;
                })
                .catch((e) => {
                    return {
                        symbol: "Token",
                    };
                })
        ).symbol;
    }
}

export async function wasmQuery(contract: string, obj: any): Promise<any> {
    const r = await getLcdClient().wasm.contractQuery(contract, obj);
    return r;
}

export async function wasmExecutes(
    address: string,
    msgs: WasmExecute[],
    memo: string | undefined = undefined,
    manualFee?: Fee
): Promise<CreateTxOptions> {
    // console.log("executes: " + JSON.stringify(msgs));
    const exes: MsgExecuteContract[] = msgs.map((item) => {
        const coins = item.coin ? new Coins(item.coin) : undefined;

        return new MsgExecuteContract(address, item.contract, item.msg, coins);
    });

    const fee = await getFeeNormal("0");

    return {
        msgs: exes,
        memo: memo,
        fee: manualFee ? manualFee : fee,
    };
}

export async function exchangeRates() {
    const r = await getLcdClient().oracle.exchangeRates();
    let list = r.map((item: any) => {
        return {
            amount: item.amount,
            denom: item.denom,
        };
    });

    list.push({
        denom: "uluna",
        amount: "1",
    });

    let map: Map<string, string> = new Map();
    list.forEach((item) => {
        map.set(item.denom, item.amount);
    });

    return map;
}

export async function nativePrice(denom: string): Promise<string | undefined> {
    const r = await exchangeRates();
    const uusd = r.get("uusd");
    const target = r.get(denom);
    if (!uusd || !target) {
        return undefined;
    }

    const rate = divide(target, uusd.toString());
    const price = divide(1, rate);
    return price;
}

export async function getBlockTime(height: number): Promise<number> {
    if (height === 5701178) {
        return Date.parse("2021-12-16T17:36:01.865915753Z");
    } else if (height === 5564809) {
        return Date.parse("2021-12-06T00:30:47.067644193Z");
    } else if (height === 5535205) {
        return Date.parse("2021-12-03T15:55:15.600051774Z");
    } else if (height === 5134580) {
        return Date.parse("2021-11-01T23:23:55.370543028Z");
    }

    const latest = await latestBlock();
    return await blockTime(latest, height);
}

export async function findSymbolIcon(
    denom: string
): Promise<string | undefined> {
    const assets = (await axios.get(environment().assets.tokens)).data;
    const tokens =
        isMainnet(undefined) === NetworkType.mainnet
            ? assets.mainnet
            : assets.testnet;

    for (let i = 0; i < Object.keys(tokens).length; i++) {
        const key = Object.keys(tokens)[i];
        if (key === denom) {
            const value: {
                protocol: string;
                symbol: string;
                token: string;
                icon: string;
            } = tokens[key];
            return value.icon;
        }
    }
    return undefined;
}

export async function findSymbolIconDenom(
    denom: string
): Promise<string | undefined> {
    const assets = (await axios.get(environment().assets.tokens)).data;
    const tokens =
        isMainnet(undefined) === NetworkType.mainnet
            ? assets.mainnet
            : assets.testnet;

    for (let i = 0; i < Object.keys(tokens).length; i++) {
        const key = Object.keys(tokens)[i];

        if (tokens[key].symbol === denom) {
            const value: {
                protocol: string;
                symbol: string;
                token: string;
                icon: string;
            } = tokens[key];
            return value.icon;
        }
    }
    return undefined;
}
