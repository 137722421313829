import style from "./InputFieldMulti.module.scss";
import classNames from "classnames/bind";
import TitleLabel from "../Labels/TitleLabel";
import TooltipIcon from "../TooltipIcon/TooltipIcon";
import { propSatisfies } from "ramda";

const cx = classNames.bind(style);

export default function InputFieldMulti(props: {
    title: string;
    fieldRef?: any;
    placeholder: string;
    maxLength?: number;
    size?: number;
    disabled?: boolean;
    tooltip?: string;
    value: string;
    onChanged: (v: string) => void;
}) {
    return (
        <div className={style.container}>
            <div className={style.title_container}>
                {props.tooltip ? (
                    <div className={style.title_container_v2}>
                        <TitleLabel
                            text={props.title}
                            color={"#fff"}
                            size={14}
                            fontWeight={"medium"}
                        />
                        <div style={{ display: "flex", marginLeft: "4px" }}>
                            <TooltipIcon tooltip={props.tooltip} />
                        </div>
                    </div>
                ) : (
                    <TitleLabel text={props.title} color={"white"} />
                )}

                <div style={{ flex: 1 }} />

                {props.maxLength ? (
                    <div className={style.length}>
                        {"( "}
                        <b>{props.value.length}</b>
                        {"/" + props.maxLength + " Max)"}
                    </div>
                ) : (
                    <div />
                )}
            </div>

            <div className={cx(style.body)}>
                <textarea
                    ref={props.fieldRef}
                    className={cx(
                        style.field,

                        {
                            disabled: props.disabled,
                        }
                    )}
                    maxLength={props.maxLength}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    value={props.value}
                    onChange={(e) => {
                        let value = e.target.value;

                        try {
                            //check base64 encodable
                            btoa(value);
                            if (
                                props.maxLength &&
                                value.length > props.maxLength
                            ) {
                                props.onChanged(value.substring(0, 300));
                            } else {
                                props.onChanged(value);
                            }
                        } catch (e) {
                            //can't
                        }
                    }}
                    style={{
                        fontSize: props.size ? props.size + "px" : "18px",
                    }}
                />
            </div>
        </div>
    );
}
