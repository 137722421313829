import { leftGreaterThanRight } from "../../Math";
import { environment, wasmExecutes } from "../api";
import {
    campaignDeposit,
    campaignSummary,
    lunartCampaignParticipation,
    refineCampaignDeposit,
} from "../campaign/query";
import { getFeeHigh } from "../fee";
import { campaignClaimableList } from "../rest";

export async function claimCampaignRewards(address: string) {
    let exes: WasmExecute[] = [];

    const lunartEarly = environment().contracts.lunart_early.campaign;
    const lunartPublic = environment().contracts.lunart_public.campaign;

    const campaigns = await campaignClaimableList(address, true, undefined);
    for (let i = 0; i < campaigns.length; i++) {
        const campaign = campaigns[i];
        if (campaign === lunartEarly || campaign === lunartPublic) {
            //lunart
            let p = await lunartCampaignParticipation(campaign, address);
            if (leftGreaterThanRight(p.participation_reward.unlocked, 0)) {
                exes.push({
                    contract: campaign,
                    msg: {
                        claim_participation_reward: {},
                    },
                    coin: undefined,
                });
            }

            if (leftGreaterThanRight(p.referral_reward.unlocked, 0)) {
                exes.push({
                    contract: campaign,
                    msg: {
                        claim_referral_reward: {},
                    },
                    coin: undefined,
                });
            }
        } else {
            const summary = await campaignSummary(campaign, address);
            const p = summary.participation;

            const unlockedParticipationReward = p
                ? p.participation_reward.unlocked
                : "0";
            const unlockedReferralReward = p ? p.referral_reward.unlocked : "0";

            if (leftGreaterThanRight(unlockedParticipationReward, 0)) {
                exes.push({
                    contract: campaign,
                    msg: {
                        claim_participation_reward: {},
                    },
                    coin: undefined,
                });
            }

            if (leftGreaterThanRight(unlockedReferralReward, 0)) {
                exes.push({
                    contract: campaign,
                    msg: {
                        claim_referral_reward: {},
                    },
                    coin: undefined,
                });
            }
        }
    }

    const fee = await getFeeHigh();
    return await wasmExecutes(address, exes, undefined, fee);
}

export async function claimOtherRewards(address: string, hasVp: boolean) {
    //lp
    let exes: WasmExecute[] = [];
    exes.push({
        contract: environment().contracts.astroport.generator,
        msg: {
            withdraw: {
                lp_token: environment().contracts.astroport.lp,
                amount: "0",
            },
        },
        coin: undefined,
    });

    //deposit

    const campaigns = await campaignClaimableList(address, true, undefined);
    for (let i = 0; i < campaigns.length; i++) {
        const campaign = campaigns[i];
        const deposit = await campaignDeposit(campaign, address);
        const refined = await refineCampaignDeposit(deposit);
        if (leftGreaterThanRight(refined.claimable, 0)) {
            exes.push({
                contract: campaign,
                msg: {
                    withdraw: {
                        amount: refined.claimable,
                    },
                },
                coin: undefined,
            });
        }
    }


    //vp
    if (hasVp) {
        exes.push({
            contract: environment().contracts.governance,
            msg: {
                ticket_claim: {},
            },
            coin: undefined,
        });
    }

    const fee = await getFeeHigh();
    return await wasmExecutes(address, exes, undefined, fee);
}
