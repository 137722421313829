import style from "./Main.module.scss";
import PageHeader from "../common/PageHeader/PageHeader";

import MainSection1 from "./MainSection1";
import MainSection2 from "./MainSection2";

import MainSection3 from "./MainSection3";
import MainSection4 from "./MainSection4";
import MainSectionBurned from "./MainSectionBurned";

export default function Main() {
    return (
        <div className={style.container}>
            <PageHeader title="Overview" docs="" />

            <div className={style.row1_container}>
                <div className={style.row1}>
                    <div className={style.section1_container}>
                        <MainSection1 />
                    </div>
                    <div className={style.section2_container}>
                        <MainSectionBurned />
                        <MainSection2 />
                    </div>
                </div>
            </div>

            <PageHeader title="Campaign Info" docs="" />

            <div className={style.row2_container}>
                <MainSection3 />
                <div style={{ height: "40px" }} />
                <MainSection4 />
            </div>
        </div>
    );
}
