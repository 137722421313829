import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./TalisParticipation.module.scss";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import TalisParticipationAuto from "./TalisParticipationAuto";
import TalisParticipationManual from "./TalisParticipationManual";

const cx = classNames.bind(style);
export default function TalisParticipation(props: {
    summary: CampaignSummary;
    earlybird: boolean;
    campaignAddress: string;
    requirement?: TalisRequirement;
    started: boolean;
    ipcParticipate: (
        token: string,
        referrer: string | undefined,
        txHash: string,
        signature: string,
        ruleId: string
    ) => void;
}) {
    const [txPassed, setTxPassed] = useState(false);
    const [txHash, setTxHash] = useState("");

    const [hashResult, setHashResult] = useState<
        TxQualificationInfo | undefined
    >(undefined);

    const [auto, setAuto] = useState(true);

    useEffect(() => {
        setTxPassed(false);
        setTxHash("");
        setHashResult(undefined);
    }, [auto]);

    return (
        <div className={style.container}>
            <div className={cx(style.dummy, { isIpc: true })} />
            {props.earlybird === true ? (
                <div className={style.title}>
                    <div className={style.titlewrap}>
                        <TitleLabel
                            fontWeight={"light"}
                            text={"Participation"}
                            size={20}
                            color={"#fff"}
                        />
                        <div className={style.title_sub}>
                            <TitleLabel
                                fontWeight={"light"}
                                text={"( VP EXCLUSIVE )"}
                                size={16}
                                color={"#fff"}
                            />
                        </div>
                    </div>

                    <div
                        className={style.change}
                        onClick={() => {
                            setAuto(!auto);
                        }}
                    >
                        {auto ? "Manual" : "Auto"}
                    </div>
                </div>
            ) : (
                <div className={style.title}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Participation"}
                        size={20}
                        color={"#fff"}
                    />
                    <div
                        className={style.change}
                        onClick={() => {
                            setAuto(!auto);
                        }}
                    >
                        {auto ? "Manual" : "Auto"}
                    </div>
                </div>
            )}

            <div className={style.box}>
                {props.summary.state.is_active ? (
                    <div>
                        <div className={style.gotosite_container}>
                            <div style={{ flex: 1 }} />
                            <a
                                className={style.gotosite}
                                href={props.summary.config.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className={style.text}>{"Go to Site"}</div>
                                <div className={style.arrowicon} />
                            </a>
                        </div>

                        {auto ? (
                            <TalisParticipationAuto
                                campaignAddress={props.campaignAddress}
                                summary={props.summary}
                                txPassed={txPassed}
                                setTxPassed={setTxPassed}
                                txHash={txHash}
                                setTxHash={setTxHash}
                                hashResult={hashResult}
                                setHashResult={setHashResult}
                                earlybird={props.earlybird}
                                requirement={props.requirement}
                                started={props.started}
                                ipcParticipate={props.ipcParticipate}
                            />
                        ) : (
                            <TalisParticipationManual
                                campaignAddress={props.campaignAddress}
                                summary={props.summary}
                                setTxPassed={setTxPassed}
                                txHash={txHash}
                                setTxHash={setTxHash}
                                setHashResult={setHashResult}
                                hashResult={hashResult}
                                earlybird={props.earlybird}
                                requirement={props.requirement}
                                started={props.started}
                                ipcParticipate={props.ipcParticipate}
                                txPassed={txPassed}
                            />
                        )}
                    </div>
                ) : (
                    <div className={style.contents}>
                        <ParticipationCheckNot summary={props.summary} />
                    </div>
                )}
            </div>
        </div>
    );
}

function ParticipationCheckNot(props: { summary: CampaignSummary }) {
    return (
        <div className={style.particontainer3}>
            <div className={style.comingsoon}>
                <div className={style.textwrap}>
                    {props.summary.config.title + " is Closed"}
                </div>
            </div>
        </div>
    );
}
