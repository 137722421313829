import InputField from "../../common/InputField/InputField";
import { useState } from "react";
import GovCreateFormFooter from "./GovCreateFormFooter";
import { useEffect } from "react";

import GovCreateFormHeader from "./GovCreateFormHeader";
import * as Utils from "../../../libs/utils";
import { poll3 } from "../../../libs/api/governance/execution";
import {
    govTicketConfig,
    pollConfig,
} from "../../../libs/api/governance/query";
import { PATHS } from "../../../App";
import {
    divide,
    fixed,
    leftGreaterThanRight,
    leftLessThanRight,
    multiply,
} from "../../../libs/Math";
import { useWallet } from "@terra-money/wallet-provider";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../libs/contexts";

export default function GovCreateForm3(props: {
    setHash: (h: HashResult) => void;
    deposit: string;
}) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);
    const [headerInfo, setHeaderInfo] = useState({
        title: "",
        desc: "",
        link: "",
    });
    const [headerValid, setHeaderValid] = useState(false);

    const [quorum, setQuorum] = useState("");
    const [error1, setError1] = useState<string | undefined>("");
    const [threshold, setThreshold] = useState("");
    const [error2, setError2] = useState<string | undefined>("");
    const [votingPeriod, setVotingPeriod] = useState("");
    const [snapshotPeriod, setSnapshotPeriod] = useState("");
    const [timelockPeriod, setTimelockPeriod] = useState("");
    const [proposalDeposit, setProposalDeposit] = useState("");
    const [ticketPeriod, setTicketPeroid] = useState("");
    const [ticketAmount, setTicketAmount] = useState("");

    const [quorumPlaceholder, setQuorumPlaceholder] = useState("");
    const [thresholdPlaceholder, setThresholdPlaceholder] = useState("");
    const [votingPeriodPlaceholder, setVotingPeriodPlaceholder] = useState("");
    const [snapshotPeriodPlaceholder, setSnapshotPeriodPlaceholder] =
        useState("");
    const [timelockPeriodPlaceholder, setTimelockPeriodPlaceholder] =
        useState("");
    const [proposalDepositPlaceholder, setProposalDepositPlaceholder] =
        useState("");
    const [ticketPeriodPlaceholder, setTicketPeroidPlaceholder] = useState("");
    const [ticketAmountPlaceholder, setTicketAmountPlaceholder] = useState("");

    const [valid, setValid] = useState(false);

    useEffect(() => {
        pollConfig()
            .then((r) => {
                setQuorumPlaceholder(multiply(r.quorum, 100));
                setThresholdPlaceholder(multiply(r.threshold, 100));
                setVotingPeriodPlaceholder(r.voting_period.toString());
                setSnapshotPeriodPlaceholder(r.snapshot_period.toString());
                setTimelockPeriodPlaceholder(
                    r.execution_delay_period.toString()
                );
                setProposalDepositPlaceholder(
                    Utils.getDecimal(r.proposal_deposit)
                );
            })
            .catch((e) => {});

        govTicketConfig()
            .then((r) => {
                setTicketPeroidPlaceholder(
                    r.ticket_distribute_period.toString()
                );
                setTicketAmountPlaceholder(
                    Utils.getDecimal(r.ticket_distribute_amount, false, 6)
                );
            })
            .catch((e) => {});
    }, []);

    useEffect(() => {
        if (!quorum) {
            setError1(undefined);
        } else {
            if (
                leftGreaterThanRight(quorum, 0) &&
                leftLessThanRight(quorum, 100)
            ) {
                setError1(undefined);
            } else {
                setError1("range is 0~100");
            }
        }
    }, [quorum]);

    useEffect(() => {
        if (!threshold) {
            setError2(undefined);
        } else {
            if (
                leftGreaterThanRight(threshold, 0) &&
                leftLessThanRight(threshold, 100)
            ) {
                setError2(undefined);
            } else {
                setError2("range is 0~100");
            }
        }
    }, [threshold]);

    useEffect(() => {
        if (!error1 && !error2 && headerValid) {
            setValid(true);
        } else {
            setValid(false);
        }
    }, [error1, error2, headerValid]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <GovCreateFormHeader
                setInfo={setHeaderInfo}
                setValid={setHeaderValid}
            />
            <InputField
                title="Quorum"
                type="amount"
                disabled={false}
                tooltip="Proposed minimum voter turnout required for a poll to pass"
                symbol="%"
                value={quorum}
                placeholder={quorumPlaceholder}
                onChanged={setQuorum}
                error={error1}
            />

            <InputField
                title="Vote Threshold"
                type="amount"
                disabled={false}
                tooltip="Proposed minimum percentage of yes votes required for a poll to pass"
                symbol="%"
                value={threshold}
                placeholder={thresholdPlaceholder}
                onChanged={setThreshold}
                error={error2}
            />

            <InputField
                title="Voting Period"
                type="amount"
                disabled={false}
                tooltip="Proposed voting period for polls"
                symbol="blocks"
                value={votingPeriod}
                placeholder={votingPeriodPlaceholder}
                onChanged={(v) => {
                    setVotingPeriod(v.replaceAll(".", ""));
                }}
            />

            <InputField
                title="Snapshot Period"
                type="amount"
                disabled={false}
                tooltip="Proposed time window before poll end in which the staked VKR amount can be snapshotted"
                symbol="blocks"
                value={snapshotPeriod}
                placeholder={snapshotPeriodPlaceholder}
                onChanged={(v) => {
                    setSnapshotPeriod(v.replaceAll(".", ""));
                }}
            />

            <InputField
                title="Execution delay Period"
                type="amount"
                disabled={false}
                tooltip="Proposed time delay (number of blocks) required after a poll pass before executing changes"
                symbol="blocks"
                value={timelockPeriod}
                placeholder={timelockPeriodPlaceholder}
                onChanged={(v) => {
                    setTimelockPeriod(v.replaceAll(".", ""));
                }}
            />

            <InputField
                title="Proposal Deposit"
                type="amount"
                disabled={false}
                tooltip="Proposed minimum VKR deposit required for submitting a new poll"
                symbol={"VKR"}
                value={proposalDeposit}
                placeholder={proposalDepositPlaceholder}
                onChanged={setProposalDeposit}
            />

            <InputField
                title="Ticket Distribution Period"
                type="amount"
                disabled={false}
                tooltip=""
                symbol="blocks"
                value={ticketPeriod}
                placeholder={ticketPeriodPlaceholder}
                onChanged={(v) => {
                    setTicketPeroid(v.replaceAll(".", ""));
                }}
            />

            <InputField
                title="Ticket Distribution Amount"
                type="amount"
                disabled={false}
                tooltip=""
                symbol="tVKR"
                value={ticketAmount}
                placeholder={ticketAmountPlaceholder}
                onChanged={(v) => {
                    setTicketAmount(v);
                }}
            />

            <InputField
                title="Proposal Deposit"
                type="amount"
                tooltip={
                    "Proposed minimum VKR deposit required for submitting a new poll"
                }
                disabled={true}
                symbol={"VKR"}
                value={Utils.getDecimal(props.deposit, true)}
                onChanged={(v) => {}}
            />

            <GovCreateFormFooter
                enable={valid}
                pressed={() => {
                    props.setHash({
                        type: "GovPoll3",
                        isPending: true,
                    });

                    poll3(
                        wallet!.terraAddress,
                        headerInfo,
                        quorum
                            ? parseFloat(fixed(divide(quorum, 100), 6))
                            : undefined,
                        threshold
                            ? parseFloat(fixed(divide(threshold, 100), 6))
                            : undefined,
                        votingPeriod ? parseInt(votingPeriod) : undefined,
                        snapshotPeriod ? parseInt(snapshotPeriod) : undefined,
                        timelockPeriod ? parseInt(timelockPeriod) : undefined,
                        proposalDeposit
                            ? fixed(multiply(proposalDeposit, 1000000), 0)
                            : undefined,
                        ticketPeriod ? parseInt(ticketPeriod) : undefined,
                        ticketAmount
                            ? fixed(multiply(ticketAmount, 1000000), 0)
                            : undefined
                    )
                        .then((r) => {
                            return post(r);
                        })
                        .then((response) => {
                            props.setHash({
                                type: "GovPoll3",
                                response: response,
                                redirect: PATHS.governance,
                            });
                        })
                        .catch((error) => {
                            props.setHash({
                                type: "GovPoll3",
                                error: error,
                                redirect: undefined,
                            });
                        });
                }}
            />
        </div>
    );
}
