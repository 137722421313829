import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./LunartParticipation.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);
export default function LunartParticipation(props: {
    summary: CampaignSummary;
    earlybird: boolean;
}) {
    return (
        <div className={style.container}>
            <div className={cx(style.dummy, { isIpc: true })} />
            {props.earlybird === true ? (
                <div className={style.title}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Participation"}
                        size={20}
                        color={"#fff"}
                    />
                    <div className={style.title_sub}>
                        <TitleLabel
                            fontWeight={"light"}
                            text={"( VP EXCLUSIVE )"}
                            size={16}
                            color={"#fff"}
                        />
                    </div>
                </div>
            ) : (
                <div className={style.title}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Participation"}
                        size={20}
                        color={"#fff"}
                    />
                </div>
            )}

            <div className={style.contents}>
                <TxcheckBox summary={props.summary} />
            </div>
        </div>
    );
}

export function TxcheckBox(props: { summary: CampaignSummary }) {
    return (
        <div className={cx(style.txwrap)}>
            <div
                className={cx(style.txbg_footer, {
                    isIpc: true,
                })}
            />

            <div className={style.txcontainer}>
                <ParticipationCheckNot summary={props.summary} />
            </div>
        </div>
    );
}

function ParticipationCheckNot(props: { summary: CampaignSummary }) {
    return (
        <div className={style.particontainer3}>
            <div className={style.comingsoon}>
                <div className={style.textwrap}>
                    {props.summary.config.title + " is Closed"}
                </div>
            </div>
        </div>
    );
}
