import { useEffect, useState } from "react";
import { getBlockTime } from "../../../../../libs/api/api";
import {
    divide,
    isZero,
    leftGreaterThanRight,
    minus,
    multiply,
    plus,
} from "../../../../../libs/Math";
import {
    dateFormat8,
    getDecimal,
    splitAmountByDot,
} from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import ExplainPopup from "../ExplainPopup";
import style from "./TalisReward.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function TalisReward(props: {
    summary: CampaignSummary;
    claimParticipationReward: () => void;
}) {
    const [blockdate, setBlockdate] = useState("");

    const [nowPercent, setNowPercent] = useState(0);
    const [nowClaimedPercent, setNowClaimedPercent] = useState(0);

    const [claimableDisplayValue, setClaimableDisplayValue] = useState<
        AmountSplitModel | undefined
    >(undefined);

    const [tenPercentBlock, setTenPercentBlock] = useState(99999999);
    const [state, setState] = useState({
        claimed: "0",
        total: "0",
        locked: "0",
        unlocked: "0,",
    });

    useEffect(() => {
        const participation = props.summary.participation;
        if (participation) {
            const reward = participation.participation_reward;
            const claimed = minus(
                minus(reward.total, reward.locked),
                reward.unlocked
            );

            setTenPercentBlock(reward.locked_list[0].end!);

            setState({
                claimed: claimed,
                total: reward.total,
                locked: reward.locked,
                unlocked: reward.unlocked,
            });
        }
    }, [props.summary]);

    useEffect(() => {
        setClaimableDisplayValue(splitAmountByDot(state.unlocked));

        if (
            // block over 10 % check
            leftGreaterThanRight(
                divide(state.total, "10"),
                plus(state.unlocked, state.claimed)
            )
        ) {
            setNowPercent(0);
        } else {
            setNowPercent(
                Number(
                    multiply(
                        divide(
                            plus(state.claimed, state.unlocked),
                            state.total
                        ),
                        "100"
                    )
                )
            );

            setNowClaimedPercent(
                Number(multiply(divide(state.claimed, state.total), "100"))
            );
        }
    }, [state]);

    useEffect(() => {
        getBlockTime(tenPercentBlock)
            .then((r) => {
                setBlockdate(dateFormat8(r));
            })
            .catch((e) => {});
    }, [tenPercentBlock]);

    const [showExplainPopup, setShowExplainPopup] = useState(-1);

    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow = showExplainPopup !== -1 ? "hidden" : "auto";
    }, [showExplainPopup]);

    if (!props.summary.participation) {
        return <div />;
    }

    return (
        <div className={style.container}>
            <div className={style.title}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"My Talis"}
                    size={20}
                    color={"#fff"}
                />
            </div>
            <div className={style.contentswrap}>
                <div className={style.contentsbg_footer} />
                <div className={style.contents}>
                    <div className={style.contents_amount}>
                        <div className={style.contents_amount_text}>
                            <div className={style.contents_amount_text_title}>
                                <div className={style.totalreceive}>
                                    <TitleLabel
                                        size={14}
                                        color={"#fff"}
                                        text={"My Total Tokens"}
                                        fontWeight={"medium"}
                                    />
                                </div>
                                <div
                                    className={style.totalreceive_amount}
                                    onClick={() => {
                                        setShowExplainPopup(1);
                                    }}
                                >
                                    <TitleLabel
                                        size={14}
                                        color={"#a500ff"}
                                        text={
                                            getDecimal(state.total, true, 6) +
                                            " " +
                                            props.summary.reward.participation
                                                .symbol
                                        }
                                        fontWeight={"light"}
                                        underline={true}
                                    />
                                </div>
                            </div>
                            <div className={style.contents_amount_text_sub}>
                                <TitleLabel
                                    size={12}
                                    color={"#fff"}
                                    text={"Claimable"}
                                    fontWeight={"light"}
                                />
                            </div>
                            {!isZero(state.unlocked) ? (
                                <div
                                    className={
                                        style.contents_amount_text_amount
                                    }
                                >
                                    <TitleLabel
                                        size={28}
                                        color={"#ffe646"}
                                        text={
                                            claimableDisplayValue
                                                ? claimableDisplayValue.num1
                                                : undefined
                                        }
                                        fontWeight={"bold"}
                                        symbol={
                                            claimableDisplayValue &&
                                            claimableDisplayValue.num2
                                                ? "." +
                                                  claimableDisplayValue.num2 +
                                                  " " +
                                                  props.summary.reward
                                                      .participation.symbol
                                                : props.summary.reward
                                                      .participation.symbol
                                        }
                                    />
                                </div>
                            ) : (
                                <div
                                    className={
                                        style.contents_amount_text_amount
                                    }
                                >
                                    <TitleLabel
                                        size={28}
                                        color={"rgba(255,255,255,0.3)"}
                                        text={"0"}
                                        fontWeight={"bold"}
                                        symbol={
                                            props.summary.reward.participation
                                                .symbol
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className={style.contents_amount_buttonfield}>
                            <div
                                className={cx(style.contents_amount_button, {
                                    enabled: !isZero(state.unlocked),
                                })}
                                onClick={() => {
                                    if (!isZero(state.unlocked)) {
                                        props.claimParticipationReward();
                                    }
                                }}
                            >
                                {"Claim"}
                            </div>
                        </div>
                    </div>
                    <div className={style.contents_bar}>
                        {nowPercent < 10 ? (
                            <div className={style.contents_bar_date}>
                                <div className={style.contents_bar_date_text}>
                                    <TitleLabel
                                        size={12}
                                        color={"#fff"}
                                        text={
                                            blockdate +
                                            " (Block: " +
                                            tenPercentBlock +
                                            ")"
                                        }
                                        fontWeight={"light"}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className={style.contents_bar_date}></div>
                        )}

                        <div className={style.contents_bar_progress}>
                            <div
                                className={cx(style.progressbar_now, {
                                    full: nowPercent === 100,
                                })}
                                style={{ width: nowPercent + "%" }}
                            >
                                <div
                                    className={cx(style.progressbar_claimed, {
                                        full: nowPercent === 100,
                                    })}
                                    style={{ width: nowClaimedPercent + "%" }}
                                />
                            </div>
                            {nowPercent < 10 ? (
                                <div
                                    className={style.progressbar_point}
                                    style={{ left: 10 + "%" }}
                                />
                            ) : (
                                <div />
                            )}
                        </div>
                        <div className={style.contents_bar_amount}>
                            <TitleLabel
                                size={12}
                                color={"#fff"}
                                text={
                                    "Claimed: " +
                                    getDecimal(state.claimed, true, 6) +
                                    " " +
                                    props.summary.reward.participation.symbol
                                }
                                fontWeight={"light"}
                            />
                        </div>
                    </div>
                    <div className={style.myreward_dummy} />
                    <div
                        className={cx(style.mobile_button, {
                            enabled: !isZero(state.unlocked),
                        })}
                        onClick={() => {
                            if (!isZero(state.unlocked)) {
                                props.claimParticipationReward();
                            }
                        }}
                    >
                        {"Claim"}
                    </div>
                </div>
            </div>

            {showExplainPopup !== -1 ? (
                <ExplainPopup
                    show={showExplainPopup}
                    setShow={setShowExplainPopup}
                />
            ) : (
                <div />
            )}
        </div>
    );
}
