import style from "./FanfuryParticipationManual.module.scss";

import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { balanceState, walletState } from "../../../../../libs/contexts";
import FanfuryInputFieldPaste from "../../../../common/InputField/FanfuryInputFieldPaste";
import FanfuryParticipationFooter from "./FanfuryParticipationFooter";
import { queryTokenBalance } from "../../../../../libs/api/trade/query";
import { environment } from "../../../../../libs/api/api";
import { leftGreaterThanRight } from "../../../../../libs/Math";

export default function FanfuryParticipationManual(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    setTxPassed: (v: boolean) => void;
    txHash: string;
    setTxHash: (t: string) => void;
    setHashResult: (v: TxQualificationInfo | undefined) => void;
    hashResult: TxQualificationInfo | undefined;
    earlybird: boolean;
    requirement?: FanfuryRequirement;
    started: boolean;
    ipcParticipate: (
        token: string,
        referrer: string | undefined,
        txHash: string,
        signature: string,
        ruleId: string
    ) => void;
    txPassed: boolean;
}) {
    const wallet = useRecoilValue(walletState);

    const [signature, setSignature] = useState("");
    const [referrer, setReferrer] = useState("");
    const [ruleID, setRuleID] = useState("");

    const balance = useRecoilValue(balanceState);
    const [vpBalance, setVpBalance] = useState<string>("0");

    const [error1, setError1] = useState<string | undefined>(undefined);

    const [vkrCheck, setVkrCheck] = useState<boolean>(false);
    const [vpCheck, setVpCheck] = useState<boolean>(false);
    const [ustCheck, setUstCheck] = useState<boolean>(false);

    const [ustAmount, setUstAmount] = useState("");

    useEffect(() => {
        if (!wallet) {
            props.setHashResult(undefined);
        }

        if (wallet !== undefined) {
            queryTokenBalance(
                environment().contracts.vp_token,
                wallet.terraAddress
            )
                .then((r) => {
                    setVpBalance(r.amount);
                })
                .catch((e) => {
                    setVpBalance("0");
                });
        }
    }, [wallet]);

    useEffect(() => {
        if (
            !leftGreaterThanRight(
                props.summary.config.deposit.amount,
                balance.token
            )
        ) {
            setVkrCheck(true);
        } else {
            setVkrCheck(false);
        }

        if (
            !leftGreaterThanRight(
                props.summary.config.vp_burn_amount,
                vpBalance
            )
        ) {
            setVpCheck(true);
        } else {
            setVpCheck(false);
        }
    }, [balance, vpBalance]);

    useEffect(() => {
        if (props.hashResult) {
            if (
                props.hashResult.campaign === props.campaignAddress &&
                props.hashResult.actor ===
                    (wallet ? wallet.terraAddress : "") &&
                props.hashResult.status === "SUCCESS"
            ) {
                setError1(undefined);
                props.setTxPassed(true);
            } else if (props.hashResult.reason) {
                setError1(props.hashResult.reason);
                props.setTxPassed(false);
            } else {
                setError1("Invalid Tx");
                props.setTxPassed(false);
            }
        } else {
            props.setTxHash("");
            props.setTxPassed(false);
        }
    }, [props.hashResult, wallet, props.campaignAddress]);

    useEffect(() => {
        if (error1 !== undefined) {
            setError1(undefined);
        }
    }, [props.txHash]);

    useEffect(() => {
        if (!wallet) {
            props.setHashResult(undefined);
        }
    }, [wallet]);

    useEffect(() => {
        props.setTxPassed(true);
    }, []);

    useEffect(() => {
        let hashResult: TxQualificationInfo = {
            status: "SUCCESS",
            reason: undefined,
            participationCode: "",
            campaign: props.campaignAddress,
            actor: wallet ? wallet.terraAddress : "",
            referrer: referrer ? referrer : undefined,
            encodedSignature: signature,
            ruleId: ruleID,
            transactionHash: props.txHash,
        };
        props.setHashResult(hashResult);
        props.setTxPassed(true);
    }, [signature, referrer, ruleID, props.txHash, props.campaignAddress]);

    return (
        <div className={style.container}>
            <div style={{ height: "6px" }} />

            <FanfuryInputFieldPaste
                title={"Tx"}
                value={props.txHash}
                onChanged={props.setTxHash}
            />

            <FanfuryInputFieldPaste
                title={"Signature"}
                value={signature}
                onChanged={setSignature}
            />

            <FanfuryInputFieldPaste
                title={"Referrer"}
                value={referrer}
                onChanged={setReferrer}
            />

            <FanfuryInputFieldPaste
                title={"Rule ID"}
                value={ruleID}
                onChanged={setRuleID}
            />

            <FanfuryParticipationFooter
                summary={props.summary}
                vkrCheck={vkrCheck}
                vpCheck={vpCheck}
                ustCheck={ustCheck}
                setVkrCheck={setVkrCheck}
                setVpCheck={setVpCheck}
                setUstCheck={setUstCheck}
                tokenBalance={balance.token}
                ustBalance={balance.uusd}
                vpBalance={vpBalance}
                ustAmount={ustAmount}
                setUstAmount={setUstAmount}
                requirement={props.requirement}
                error={error1}
                earlybird={props.earlybird}
                started={props.started}
                hashResult={props.hashResult}
                txHash={props.txHash}
                ipcParticipate={props.ipcParticipate}
            />
        </div>
    );
}
