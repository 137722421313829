import TitleLabel from "../common/Labels/TitleLabel";
import style from "./MyValue.module.scss";
import { refreshState, walletState } from "../../libs/contexts";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import DashboardChartHeader2 from "../common/DashboardChartHeader/DashboardChartHeader2";
import { queryTokenBalance } from "../../libs/api/trade/query";
import { environment } from "../../libs/api/api";
import * as Utils from "../../libs/utils";
import { PATHS } from "../../App";
import { useHistory } from "react-router-dom";
import CreateVpButton from "../common/CreateVpButton/CreateVpButton";
import LineDummy from "../common/LineDummy/LineDummy";
import PageHeader from "../common/PageHeader/PageHeader";
import { myTotalValue } from "../../libs/api/mypage/query";
import { divide } from "../../libs/Math";
import { Pie } from "@nivo/pie";

const cellMeta = {
    title: "My Total Value",
    tooltip:
        "Total Value of all assets excluding rewards(Holding UST + Holding Value + Total Gov Staked Value + Total Lp Staked Value)",
    symbol: "UST",
    items: [
        {
            title: "UST",
            color: "#a37e30",
        },
        {
            title: "Gov Staked",
            color: "#d5b76b",
        },
        {
            title: "Holdings",
            color: "#a85f10",
        },
        {
            title: "LP Staked",
            color: "#ffe646",
        },
    ],
};

export default function MyValue() {
    const refresh = useRecoilValue(refreshState);
    const wallet = useRecoilValue(walletState);
    const history = useHistory();

    const [value, setValue] = useState<MyPageValue | undefined>(undefined);

    const [myVP, setMyVP] = useState<AmountSplitModel | undefined>(undefined);

    const [selected, setSelected] = useState("");

    useEffect(() => {
        if (wallet) {
            myTotalValue(wallet.terraAddress)
                .then((r) => {
                    setValue(r);
                })
                .catch((e) => {});

            queryTokenBalance(
                environment().contracts.vp_token,
                wallet.terraAddress
            )
                .then((r) => {
                    setMyVP(Utils.splitAmountByDot(r.amount));
                })
                .catch((e) => {});
        }
    }, [wallet, refresh]);

    function chartSelected(selectLabel: string) {
        setSelected(selectLabel);
    }

    return (
        <div className={style.container}>
            <PageHeader title="My Assets" docs="" />
            <div
                className={style.container_box}
                onMouseLeave={() => {
                    chartSelected("");
                }}
            >
                <div className={style.chart}>
                    <div className={style.header_container}>
                        <DashboardChartHeader2
                            meta={cellMeta}
                            value={value}
                            selected={selected}
                        />
                    </div>
                    <Chart
                        value={{
                            total: value ? value.total : "0",
                            values: [
                                value ? value.balance.uusd : "0",
                                value ? value.gov.uusd : "0",
                                value ? value.balance.tokenUusd : "0",
                                value ? value.lp.uusd : "0",
                            ],
                        }}
                        chartSelected={chartSelected}
                    />
                </div>
                <div className={style.line_container}>
                    <LineDummy
                        horizontal={true}
                        marginTop={30}
                        marginBottom={30}
                    />
                </div>

                <div className={style.vp_container}>
                    <div className={style.vp}>
                        <TitleLabel
                            size={14}
                            text={"My Valkyrian Pass"}
                            color={"#ffffff"}
                            fontWeight={"light"}
                            tooltip={""}
                        />
                        <div className={style.staked_wrap}>
                            <TitleLabel
                                size={30}
                                fontWeight={"medium"}
                                text={myVP ? myVP.num1 : undefined}
                                color={"#ffffff"}
                                symbol={
                                    myVP && myVP.num2
                                        ? "." + myVP.num2 + " VP"
                                        : "VP"
                                }
                                skeleton={{ width: 150 }}
                            />
                        </div>
                    </div>
                    <div className={style.required_createvp}>
                        <CreateVpButton rounded={true} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function Chart(props: {
    value: {
        total: string;
        values: string[];
    };
    chartSelected: (selectLabel: string) => void;
}) {
    let datas = props.value.values.map((item) => {
        return parseFloat(item);
    });

    let labels = ["UST", "Gov Staked", "Holdings", "LP Staked"];

    let colors = cellMeta.items.map((item) => {
        return item.color;
    });

    let hoverColors = [
        "rgba(163,126,48,0.8)",
        "rgba(213,183,107,0.8)",
        "rgba(168,95,16,0.8)",
        "rgba(255,230,70,0.8)",
    ];
    const [changeColor, setChangeColor] = useState(-1);

    const data = [
        {
            id: labels[0],
            label: labels[0],
            value: divide(datas[0], props.value.total),
            color: changeColor === 0 ? colors[0] : hoverColors[0],
        },
        {
            id: labels[1],
            label: labels[1],
            value: divide(datas[1], props.value.total),
            color: changeColor === 1 ? colors[1] : hoverColors[1],
        },
        {
            id: labels[2],
            label: labels[2],
            value: divide(datas[2], props.value.total),
            color: changeColor === 2 ? colors[2] : hoverColors[2],
        },
        {
            id: labels[3],
            label: labels[3],
            value: divide(datas[3], props.value.total),
            color: changeColor === 3 ? colors[3] : hoverColors[3],
        },
    ];

    return (
        <div className={style.chart_container}>
            <Pie
                data={data}
                height={180}
                width={180}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                innerRadius={0.5}
                borderWidth={1}
                borderColor={{
                    from: "color",
                    modifiers: [["darker", 0.2]],
                }}
                enableArcLinkLabels={false}
                arcLabel={""}
                animate={true}
                colors={{ datum: "data.color" }}
                theme={{
                    tooltip: {
                        container: {
                            visibility: "hidden",
                            contentVisibility: "hidden",
                        },
                    },
                }}
                onMouseEnter={(data) => {
                    props.chartSelected(data.label.toString());
                    setChangeColor(data.arc.index);
                }}
                onMouseLeave={() => {
                    props.chartSelected("");
                    setChangeColor(-1);
                }}
            />
        </div>
    );
}
