import axios from "axios";
import {
    bankBalance,
    environment,
    getSymbol,
    isMainnet,
    nativePrice,
    NetworkType,
    wasmQuery,
} from "../api";

import { divide, fixed, isZero, minus, multiply } from "../../Math";
import { Coin } from "@terra-money/terra.js";
import { isNativeToken } from "../../utils";

export async function tokenSimulate(
    isBuy: boolean,
    amount: string,
    spread: number //0.01 === 1%
): Promise<ResponseSimulate> {
    const msg = isBuy
        ? {
              simulation: {
                  offer_asset: {
                      info: {
                          native_token: {
                              denom: "uusd",
                          },
                      },
                      amount: fixed(amount, 0),
                  },
              },
          }
        : {
              simulation: {
                  offer_asset: {
                      info: {
                          token: {
                              contract_addr: environment().contracts.token,
                          },
                      },
                      amount: fixed(amount, 0),
                  },
              },
          };

    const simulate: any = await wasmQuery(
        environment().contracts.astroport.pair,
        msg
    );

    const to: string = simulate.return_amount;
    const rate = divide(amount, to); // rate
    const tradingFee: string = simulate.commission_amount;
    const minimumReceive = multiply(to, minus(1, spread));

    return {
        to: to,
        rate: rate,
        tradingFee: tradingFee,
        minimumReceive: minimumReceive.toString(),
    };
}

export async function tokenReverseSimulate(
    isBuy: boolean,
    amount: string,
    spread: number
): Promise<ResponseSimulate> {
    const msg = isBuy
        ? {
              reverse_simulation: {
                  ask_asset: {
                      info: {
                          token: {
                              contract_addr: environment().contracts.token,
                          },
                      },
                      amount: fixed(amount, 0),
                  },
              },
          }
        : {
              reverse_simulation: {
                  ask_asset: {
                      info: {
                          native_token: {
                              denom: "uusd",
                          },
                      },
                      amount: fixed(amount, 0),
                  },
              },
          };

    const reverseSimulate: any = await wasmQuery(
        environment().contracts.astroport.pair,
        msg
    );

    const to: string = reverseSimulate.offer_amount;
    const rate = divide(to, amount); // rate
    const tradingFee: string = reverseSimulate.commission_amount;
    const minimumReceive = multiply(amount, minus(1, spread));

    return {
        to: to,
        rate: rate,
        tradingFee: tradingFee,
        minimumReceive: minimumReceive,
    };
}

export async function queryBalances(address: string) {
    const balance = (await queryNativeBalance("uusd", address)).amount;
    const tokenBalance = (await queryTokenBalance(undefined, address)).amount;
    return {
        uusd: balance,
        token: tokenBalance,
    };
}

export async function queryNativeBalance(
    denom: string | undefined,
    address: string
): Promise<ResponseBalances> {
    const balances = await bankBalance(address);
    const coin: Coin | undefined = balances.get(denom ?? "uusd");
    if (coin) {
        return {
            amount: coin.amount.toString(),
            denom: coin.denom,
            symbol: await getSymbol(coin.denom),
        };
    } else {
        return {
            amount: "0",
            denom: denom ?? "uusd",
            symbol: await getSymbol(denom ?? "uusd"),
        };
    }
}

export async function queryTokenBalance(
    contract: string | undefined,
    address: string
): Promise<ResponseBalances> {
    const denom = contract ?? environment().contracts.token;
    const balance: string = (
        await wasmQuery(denom, {
            balance: {
                address: address,
            },
        })
    ).balance;

    return {
        amount: balance,
        denom: denom,
        symbol: await getSymbol(denom),
    };
}

export async function queryPrice(denom: string): Promise<string> {
    if (isNativeToken(denom)) {
        return await queryNativePrice(denom);
    } else {
        return await queryTokenPrice(denom);
    }
}

async function queryNativePrice(denom: string): Promise<string> {
    const price = await nativePrice(denom);
    return price ?? "0";
}

async function queryTokenPrice(tokenAddress: string): Promise<string> {
    try {
        const env = environment();
        let pairAddress = "";

        if (tokenAddress === env.contracts.token) {
            pairAddress = env.contracts.astroport.pair;
        } else if (tokenAddress === env.contracts.astroport.astroToken) {
            pairAddress = env.contracts.astroport.astroPair;
        } else {
            const assets = (await axios.get(env.assets.pairs)).data;
            const pairs =
                isMainnet(undefined) === NetworkType.mainnet
                    ? assets.mainnet
                    : assets.testnet;

            for (let i = 0; i < Object.keys(pairs).length; i++) {
                const key = Object.keys(pairs)[i];
                const value: string[] = pairs[key];
                if (value.length === 2) {
                    if (
                        value[0] === tokenAddress ||
                        value[1] === tokenAddress
                    ) {
                        pairAddress = key;
                        break;
                    }
                }
            }
        }

        if (pairAddress === "") {
            return "0";
        }

        const pairPool = await pool(pairAddress);
        if (pairPool.assets.length < 2) {
            return "0";
        }

        let token = "0";
        let uusd = "0";

        pairPool.assets.forEach((item) => {
            if (item.denom.toLowerCase() === "uusd") {
                uusd = item.amount;
            } else {
                token = item.amount;
            }
        });

        if (isZero(token)) {
            return "0";
        }

        return fixed(divide(uusd, token), 18);
        //price = uusd / token
    } catch (e) {
        return "0";
    }
}

async function pool(pairContract: string): Promise<ResponsePairPool> {
    const pairPool: {
        assets: {
            info: {
                token?: {
                    contract_addr: string;
                };
                native_token?: {
                    denom: string;
                };
            };
            amount: string;
        }[];
        total_share: string;
    } = await wasmQuery(pairContract, {
        pool: {},
    });

    return {
        assets: pairPool.assets.map((item) => {
            return {
                denom: item.info.native_token
                    ? item.info.native_token.denom
                    : item.info.token!.contract_addr,
                amount: item.amount,
            };
        }),
        total_share: pairPool.total_share,
    };
}
