import { CreateTxOptions } from "@terra-money/terra.js";

import { fixed } from "../../Math";
import { environment, wasmExecutes } from "../api";
import { getFeeHigh } from "../fee";
import { calculateTax } from "../tax";

export async function makeLp(
    address: string,
    token: string,
    uusd: string
): Promise<CreateTxOptions> {
    return await provideLiquidity(address, token, uusd, false);
}

export async function stakeLp(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const lp = fixed(amount, 0);

    const exe1: WasmExecute = {
        contract: environment().contracts.astroport.lp,
        msg: {
            send: {
                contract: environment().contracts.astroport.generator,
                amount: lp,
                msg: btoa(
                    JSON.stringify({
                        deposit: {},
                    })
                ),
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe1]);
}

export async function autoStakeLp(
    address: string,
    token: string,
    uusd: string
): Promise<CreateTxOptions> {
    return await provideLiquidity(address, token, uusd, true);
}

async function provideLiquidity(
    address: string,
    token: string,
    uusd: string,
    auto_stake: boolean
) {
    const t = fixed(token, 0);
    const u = fixed(uusd, 0);

    let exes: WasmExecute[] = [];

    exes.push({
        contract: environment().contracts.token,
        msg: {
            increase_allowance: {
                spender: environment().contracts.astroport.pair,
                amount: t,
            },
        },
        coin: undefined,
    });

    exes.push({
        contract: environment().contracts.astroport.pair,
        msg: {
            provide_liquidity: {
                assets: [
                    {
                        info: {
                            token: {
                                contract_addr: environment().contracts.token,
                            },
                        },
                        amount: t,
                    },
                    {
                        info: {
                            native_token: {
                                denom: "uusd",
                            },
                        },
                        amount: u,
                    },
                ],
                auto_stake: auto_stake,
            },
        },
        coin: u + "uusd",
    });

    if (auto_stake) {
        const tax = await calculateTax(u, "uusd");
        const fee = await getFeeHigh(tax);

        return await wasmExecutes(address, exes, undefined, fee);
    } else {
        return await wasmExecutes(address, exes);
    }
}

export async function unstakeLp(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const lp = fixed(amount, 0);

    const exe: WasmExecute = {
        contract: environment().contracts.astroport.generator,
        msg: {
            withdraw: {
                lp_token: environment().contracts.astroport.lp,
                amount: lp,
            },
        },
        coin: undefined,
    };
    return await wasmExecutes(address, [exe]);
}

export async function divideLp(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const lp = fixed(amount, 0);
    //unstake lp
    //lp send [stake contact] => [lp contract]

    const exe1: WasmExecute = {
        contract: environment().contracts.astroport.lp,
        msg: {
            send: {
                contract: environment().contracts.astroport.pair,
                amount: lp,
                msg: btoa(
                    JSON.stringify({
                        withdraw_liquidity: {},
                    })
                ),
            },
        },
        coin: undefined,
    };

    return await wasmExecutes(address, [exe1]);
}

export async function autoUnstakeLp(
    address: string,
    amount: string
): Promise<CreateTxOptions> {
    const lp = fixed(amount, 0);

    const exe1: WasmExecute = {
        contract: environment().contracts.astroport.generator,
        msg: {
            withdraw: {
                lp_token: environment().contracts.astroport.lp,
                amount: lp,
            },
        },
        coin: undefined,
    };

    const exe2: WasmExecute = {
        contract: environment().contracts.astroport.lp,
        msg: {
            send: {
                contract: environment().contracts.astroport.pair,
                amount: lp,
                msg: btoa(
                    JSON.stringify({
                        withdraw_liquidity: {},
                    })
                ),
            },
        },
        coin: undefined,
    };

    const fee = await getFeeHigh();
    return await wasmExecutes(address, [exe1, exe2], undefined, fee);
}

export async function claimReward(address: string): Promise<CreateTxOptions> {
    return await unstakeLp(address, "0");
}

// export async function registerVesting(
//     address: string
// ): Promise<CreateTxOptions> {
//     const exe2: WasmExecute = {
//         contract: environment().contracts.astroport.astroToken,
//         msg: {
//             send: {
//                 contract: environment().contracts.astroport.vesting,
//                 amount: "63072000000000",
//                 msg: btoa(
//                     JSON.stringify({
//                         register_vesting_accounts: {
//                             vesting_accounts: [
//                                 {
//                                     address:
//                                         environment().contracts.astroport
//                                             .generator,
//                                     schedules: [
//                                         {
//                                             start_point: {
//                                                 time: "1636137000000",
//                                                 amount: "63072000000000",
//                                             },
//                                         },
//                                     ],
//                                 },
//                             ],
//                         },
//                     })
//                 ),
//             },
//         },
//         coin: undefined,
//     };

//     return await wasmExecutes(address, [exe2]);
// }
