import ReactTooltip from "react-tooltip";
import style from "./TooltipIcon.module.scss";
import classNames from "classnames/bind";
import { useEffect, useState } from "react";

const cx = classNames.bind(style);

export default function TooltipIcon(props: {
    tooltip: string;

    isCampaignfalse?: boolean;
    hovercheck?: boolean;
}) {

    const [tooltipPlace, setTooltipPlace] = useState(false);

    function onResize() {
        if (window.innerWidth <= 550) {
            setTooltipPlace(true);
        } else {
            setTooltipPlace(false);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    return (
        <span className={style.container}>
            <a
                data-for="custom-tooltip"
                data-tip={props.tooltip}
                className={cx(style.icon, {
                    campaignfalse: props.isCampaignfalse,
                    hovercheck: props.hovercheck,
                })}
            />

            <ReactTooltip
                id="custom-tooltip"
                className={style.tooltip}
                delayHide={0}
                textColor="#000000"
                backgroundColor="rgba(255, 255, 255, 0.9)"
                border={true}
                multiline={true}
                borderColor={"#fff"}
                effect="solid"
                place={tooltipPlace ? "right" : "top"}
            />
        </span>
    );
}
