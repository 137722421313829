import { useEffect, useState } from "react";
import { divide, isZero, minus, multiply } from "../../../../../../libs/Math";
import { getDecimal } from "../../../../../../libs/utils";
import TitleLabel from "../../../../../common/Labels/TitleLabel";
import style from "./IPCDetailTitle.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function IPCDetailTitle(props: {
    earlybird: boolean;
    refcheck: boolean;
    summary: CampaignSummary;
}) {
    const state = props.summary.state;
    const config = props.summary.config;
    const reward = props.summary.reward;

    const pFiltered = state.pool_balances.filter((item) => {
        return item.denom === reward.participation.denom;
    });
    const pReward = pFiltered.length > 0 ? pFiltered[0].amount : "0";

    const [amountsTotal, setAmountsTotal] = useState("0");
    const [amountsNow, setAmountsNow] = useState("0");
    const [amountsPercent, setAmountsPercent] = useState("");

    const [participated, setParticipated] = useState(false);

    useEffect(() => {
        let total = "0";
        if (props.earlybird) {
            total = "25000000000000";
        } else {
            total = "15000000000000";
        }

        setAmountsTotal(total);
    }, [props.earlybird]);

    useEffect(() => {
        setAmountsNow(minus(amountsTotal, pReward));
    }, [pReward, amountsTotal]);

    useEffect(() => {
        if (!isZero(amountsTotal)) {
            setAmountsPercent(
                multiply(divide(amountsNow, amountsTotal), "100")
            );
        }
    }, [amountsNow, amountsTotal]);

    useEffect(() => {
        if (
            props.summary.participation?.participation_reward.cummulative ===
            undefined
        ) {
            setParticipated(false);
        } else {
            if (
                props.summary.participation.participation_reward.cummulative ===
                "0"
            ) {
                setParticipated(false);
            } else {
                setParticipated(true);
            }
        }
    }, [props.summary]);

    return (
        <div className={style.container}>
            <div className={style.containerwrap}>
                <div className={style.logofield}>
                    <div
                        className={cx(style.logofield_ico, {
                            earlybird: props.earlybird,
                        })}
                    />
                    {props.summary.state.is_active === true ? (
                        <div />
                    ) : (
                        <div className={style.logoclosed}>{"CLOSED"}</div>
                    )}
                </div>
                <div className={style.contentsfield}>
                    <div className={style.titles}>
                        <div
                            className={cx(style.title, {
                                earlybird: props.earlybird,
                            })}
                        >
                            {props.summary.config.title}
                        </div>
                        <div className={style.symbol}>
                            <TitleLabel
                                fontWeight={"bold"}
                                text={"$ARTS"}
                                size={20}
                                color={"#fff"}
                            />
                        </div>
                        <div className={style.name}>
                            <TitleLabel
                                fontWeight={"medium"}
                                text={"LunArt"}
                                size={12}
                                color={"#fff"}
                            />
                        </div>
                    </div>
                    <div className={style.distribute}>
                        <div className={style.title}>
                            <TitleLabel
                                fontWeight={"medium"}
                                text={"Total Distribution"}
                                size={14}
                                color={"#fff"}
                            />
                        </div>
                        <div className={style.amounts}>
                            <div className={style.amounts_now}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        getDecimal(amountsNow, true, 6) +
                                        " (" +
                                        getDecimal(
                                            multiply(amountsPercent, "1000000"),
                                            false,
                                            2
                                        ) +
                                        "%)"
                                    }
                                    size={14}
                                    color={"#f29077"}
                                />
                            </div>
                            <div className={style.amounts_total}>
                                <div className={style.amounts_total_text}>
                                    <TitleLabel
                                        fontWeight={"light"}
                                        text={
                                            getDecimal(amountsTotal, true, 6) +
                                            " MAX"
                                        }
                                        size={14}
                                        color={"#c8c8c8"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={style.progressbar}>
                            <div
                                className={style.progressbar_now}
                                style={{ width: amountsPercent + "%" }}
                            />
                        </div>
                    </div>
                    {participated === true ? (
                        <div />
                    ) : (
                        <div className={style.referralcheck}>
                            <div
                                className={cx(style.referralcheck_ico, {
                                    reftrue: props.refcheck,
                                })}
                            />
                            <div className={style.referralcheck_title}>
                                {props.refcheck ? (
                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={"Connected through referral link"}
                                        size={12}
                                        color={"#55f559"}
                                    />
                                ) : (
                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={"Connected without referral link"}
                                        size={12}
                                        color={"rgba(255,255,255,0.5)"}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className={style.mobile_distribute}>
                <div className={style.title}>
                    <TitleLabel
                        fontWeight={"medium"}
                        text={"Total Distribution"}
                        size={14}
                        color={"#fff"}
                    />
                </div>
                <div className={style.amounts}>
                    <div className={style.amounts_now}>
                        <TitleLabel
                            fontWeight={"light"}
                            text={
                                getDecimal(
                                    multiply(amountsPercent, "1000000"),
                                    false,
                                    2
                                ) + "%"
                            }
                            size={14}
                            color={"#f29077"}
                        />
                    </div>
                    <div className={style.amounts_total}>
                        <div className={style.amounts_total_text}>
                            <TitleLabel
                                fontWeight={"light"}
                                text={
                                    getDecimal(amountsTotal, true, 6) + " MAX"
                                }
                                size={14}
                                color={"#c8c8c8"}
                            />
                        </div>
                    </div>
                </div>
                <div className={style.progressbar}>
                    <div
                        className={style.progressbar_now}
                        style={{ width: amountsPercent + "%" }}
                    />
                </div>
            </div>

            {participated === true ? (
                <div />
            ) : (
                <div className={style.mobile_referralcheck}>
                    <div
                        className={cx(style.referralcheck_ico, {
                            reftrue: props.refcheck,
                        })}
                    />
                    <div className={style.referralcheck_title}>
                        {props.refcheck ? (
                            <TitleLabel
                                fontWeight={"medium"}
                                text={"Connected through referral link"}
                                size={12}
                                color={"#55f559"}
                            />
                        ) : (
                            <TitleLabel
                                fontWeight={"medium"}
                                text={"Connected without referral link"}
                                size={12}
                                color={"rgba(255,255,255,0.5)"}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
