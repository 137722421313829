import { getDecimal } from "../../../../../libs/utils";
import style from "./CampaignParticipationFooter.module.scss";
import classNames from "classnames/bind";
import { environment } from "../../../../../libs/api/api";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import { useEffect, useState } from "react";
import ConfirmButton from "../../../../common/ConfirmButton/ConfirmButton";
import { useRecoilValue } from "recoil";
import { balanceState, walletState } from "../../../../../libs/contexts";
import LineDummy from "../../../../common/LineDummy/LineDummy";
import { ConditionType } from "./ConditionValue";
import { isZero, leftLessThanRight } from "../../../../../libs/Math";
import { ConditionView } from "./ConditionView";
import { queryTokenBalance } from "../../../../../libs/api/trade/query";

export default function CampaignParticipationFooter(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    txPassed: boolean;
    participatePressed: () => void;
}) {
    const balance = useRecoilValue(balanceState);
    const wallet = useRecoilValue(walletState);

    const [vpBalance, setVpBalance] = useState("0");

    const config = props.summary.config;
    const reward = props.summary.reward;

    const [satisfyDeposit, setSatisfyDeposit] = useState(false);
    const [satisfyParticipation, setSatisfyParticipation] = useState(false);
    const [satisfyVP, setSatisfyVP] = useState(false);

    useEffect(() => {
        if (wallet) {
            queryTokenBalance(
                environment().contracts.vp_token,
                wallet.terraAddress
            )
                .then((r) => {
                    setVpBalance(r.amount);
                })
                .catch((e) => {
                    setVpBalance("0");
                });
        }
    }, [wallet]);

    useEffect(() => {
        const config = props.summary.config;
        const participation = props.summary.participation;

        if (isZero(config.deposit.amount)) {
            setSatisfyDeposit(true);
        } else {
            if (leftLessThanRight(balance.token, config.deposit.amount)) {
                setSatisfyDeposit(false);
            } else {
                setSatisfyDeposit(true);
            }
        }

        let count = participation
            ? participation.participation_reward.count
            : 0;
        if (isZero(count)) {
            setSatisfyParticipation(true);
        } else {
            setSatisfyParticipation(false);
        }

        if (isZero(config.vp_burn_amount)) {
            setSatisfyVP(true);
        } else {
            if (leftLessThanRight(vpBalance, config.vp_burn_amount)) {
                setSatisfyVP(false);
            } else {
                setSatisfyVP(true);
            }
        }
    }, [props.summary, balance, vpBalance]);

    return (
        <div className={style.container}>
            <div className={style.dummy}>
                <LineDummy horizontal={true} />
            </div>

            <TitleLabel
                text="Participate Condition Check"
                size={16}
                fontWeight="light"
            />
            <div style={{ height: "10px" }} />

            <ConditionView
                passed={
                    !leftLessThanRight(balance.token, config.deposit.amount)
                }
                summary={props.summary}
                type={ConditionType.deposit}
            />

            {/* <ConditionView
                                passed={isZero(
                                    participation
                                        ? participation.participation_reward
                                              .count
                                        : 0
                                )}
                                summary={props.summary}
                                type={ConditionType.participation}
                            /> */}

            <ConditionView
                passed={!leftLessThanRight(vpBalance, config.vp_burn_amount)}
                summary={props.summary}
                type={ConditionType.vp}
            />

            <div className={style.dummy2}>
                <LineDummy horizontal={true} />
            </div>

            <div className={style.estimate_box}>
                <div className={style.value}>
                    {getDecimal(reward.participation.amount, true) +
                        " " +
                        reward.participation.symbol}
                </div>
                <div className={style.lock}>
                    <div className={style.dummy3} />
                    <div className={style.text}>
                        {reward.participation.lock_day + " Days"}
                    </div>
                    <div className={style.icon} />
                </div>
            </div>

            <ConfirmButton
                title={"Get Reward"}
                enable={
                    props.txPassed &&
                    satisfyDeposit &&
                    satisfyParticipation &&
                    satisfyVP
                }
                onClick={props.participatePressed}
            />
        </div>
    );
}
