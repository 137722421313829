import { useEffect, useState } from "react";
import * as Utils from "../../../../libs/utils";
import style from "./StakeLpLagacy.module.scss";
import { unstakeLpEstimate } from "../../../../libs/api/astroport_liquidity/query";
import { environment } from "../../../../libs/api/api";
import InputField from "../../../common/InputField/InputField";
import ConfirmButton from "../../../common/ConfirmButton/ConfirmButton";

import { isZero, plus } from "../../../../libs/Math";
import TxInfoView from "../../../common/TxInfoView/TxInfoView";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../libs/contexts";
import {
    queryLpEstimateParams,
    terraswapStakerInfo,
} from "../../../../libs/api/terraswap_liquidity/query";
import { queryTokenBalance } from "../../../../libs/api/trade/query";
import TitleLabel from "../../../common/Labels/TitleLabel";
import { getFeeNormal } from "../../../../libs/api/fee";

export default function StakeLpLagacyUnstake(props: {
    releasePressed: (balance: {
        staked: string;
        pending: string;
        lp: string;
    }) => void;
}) {
    const wallet = useRecoilValue(walletState);
    const [balance, setBalance] = useState({
        staked: "0",
        pending: "0",
        lp: "0",
    });

    const [lpEstimated, setLpEstimated] = useState({
        token: "",
        uusd: "",
    });

    const [estimateParams, setEstimateParams] = useState<
        ResponseLpEstimate | undefined
    >(undefined);

    useEffect(() => {
        if (wallet) {
            load(wallet.terraAddress)
                .then((r) => {
                    setBalance(r);
                })
                .catch((e) => {});
        }
    }, [wallet]);

    async function load(address: string) {
        const info = await terraswapStakerInfo(address);
        const balance = await queryTokenBalance(
            environment().contracts.terraswap.lp,
            address
        );
        return {
            staked: info.bond_amount,
            pending: info.pending_reward,
            lp: balance.amount,
        };
    }

    useEffect(() => {
        //staked lp 수량 + lp토큰수량 + claimable 리워드

        queryLpEstimateParams()
            .then((r) => {
                setEstimateParams(r);
            })
            .catch((e) => {});
    }, []);

    useEffect(() => {
        if (balance && estimateParams) {
            unstakeLpEstimate(
                {
                    token: estimateParams.pools.token,
                    uusd: estimateParams.pools.uusd,
                },
                plus(balance.staked, balance.lp),
                estimateParams.lp_total_supply
            )
                .then((r) => {
                    setLpEstimated(r);
                })
                .catch((e) => {});
        } else {
            setLpEstimated({
                token: "0",
                uusd: "0",
            });
        }
    }, [balance, estimateParams]);

    return (
        <div className={style.inner_container}>
            <div className={style.summary}>
                <TitleLabel
                    size={14}
                    color={"#786f64"}
                    text={"VKR-UST LP for Without Reward"}
                />
                <div className={style.text}>
                    <div>
                        {Utils.getDecimal(
                            plus(balance.lp, balance.staked),
                            true,
                            6
                        )}
                        &nbsp;<small>{"LP"}</small>
                        &nbsp;{"+"}&nbsp;
                    </div>
                    <div>
                        {Utils.getDecimal(balance.pending, true, 6)}&nbsp;
                        <small>{"VKR"}</small>
                    </div>
                </div>
            </div>

            <div className={style.exchangeicon} />

            <div className={style.row}>
                {/* <div style={{ flex: 1 }}> */}
                <InputField
                    title={"Output VKR"}
                    type="amount"
                    symbol={"VKR"}
                    disabled={true}
                    onChanged={(v: string) => {}}
                    value={Utils.getDecimal(
                        plus(lpEstimated.token, balance.pending),
                        true
                    )}
                    error={undefined}
                />
                {/* </div> */}

                <div className={style.icon} />

                {/* <div style={{ flex: 1 }}> */}
                <InputField
                    title={"Output UST"}
                    type="amount"
                    symbol={"UST"}
                    disabled={true}
                    onChanged={(v: string) => {}}
                    value={Utils.getDecimal(lpEstimated.uusd, true, 3)}
                    error={undefined}
                />
                {/* </div> */}
            </div>

            <InfoView />

            <ConfirmButton
                title="Withdraw"
                enable={
                    !isZero(balance.lp) ||
                    !isZero(balance.staked) ||
                    !isZero(balance.pending)
                }
                onClick={() => {
                    props.releasePressed(balance);
                }}
                marginTop={30}
                marginBottom={40}
            />
        </div>
    );
}

function InfoView() {
    const [fee, setFee] = useState("0");
    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => {});
    }, []);

    return <TxInfoView fee={fee} />;
}
