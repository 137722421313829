import style from "./CampaignParticipationAuto.module.scss";
import classNames from "classnames/bind";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import { useEffect, useRef, useState } from "react";
import InputFieldTxPaste from "../../../../common/InputField/InputFieldTxPaste";
import ConfirmButton from "../../../../common/ConfirmButton/ConfirmButton";
import { qualificationTxInfo } from "../../../../../libs/api/campaign/qualification/rest";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../../libs/contexts";
const cx = classNames.bind(style);

export default function CampaignParticipationAuto(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    txPassed: boolean;
    setTxPassed: (v: boolean) => void;
    txHash: string;
    setTxHash: (t: string) => void;
    hashResult: TxQualificationInfo | undefined;
    setHashResult: (v: TxQualificationInfo | undefined) => void;
}) {
    const retryCountDownMax = 20; // will retry 3seconds * 20times
    const wallet = useRecoilValue(walletState);

    const retryCountDown = useRef(0);
    const [loading, setLoading] = useState(false);
    const [error1, setError1] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!wallet) {
            props.setHashResult(undefined);
        }
    }, [wallet]);

    useEffect(() => {
        if (props.hashResult) {
            if (
                props.hashResult.campaign === props.campaignAddress &&
                props.hashResult.actor ===
                    (wallet ? wallet.terraAddress : "") &&
                props.hashResult.status === "SUCCESS"
            ) {
                setError1(undefined);
                props.setTxPassed(true);
            } else if (props.hashResult.reason) {
                setError1(props.hashResult.reason);
                props.setTxPassed(false);
            } else {
                setError1("Invalid Tx");
                props.setTxPassed(false);
            }
        } else {
            props.setTxHash("");
            props.setTxPassed(false);
        }
    }, [props.hashResult, wallet, props.campaignAddress]);

    useEffect(() => {
        if (error1 !== undefined) {
            setError1(undefined);
        }
    }, [props.txHash]);

    function startCheck() {
        retryCountDown.current -= 1;

        qualificationTxInfo(
            wallet!.terraAddress,
            props.txHash,
            props.campaignAddress
        )
            .then((r) => {
                if (r.status === "SUCCESS") {
                    retryCountDown.current = 0;
                    setLoading(false);
                    props.setHashResult(r);
                } else {
                    if (retryCountDown.current > 0) {
                        setTimeout(() => {
                            startCheck();
                        }, 3000);
                    } else {
                        retryCountDown.current = 0;
                        setLoading(false);
                        props.setHashResult(r);
                    }
                }
            })
            .catch((e) => {
                if (retryCountDown.current > 0) {
                    setTimeout(() => {
                        startCheck();
                    }, 3000);
                } else {
                    setLoading(false);
                    setError1("Invalid Tx");
                }
            });
    }

    return (
        <div className={style.container}>
            <TitleLabel text={"Input TX"} fontWeight="light" size={16} />

            <div style={{ height: "6px" }} />

            <InputFieldTxPaste
                value={props.txHash}
                passed={props.txPassed}
                disabled={loading}
                setPassed={(v) => {
                    props.setHashResult(undefined);
                }}
                onChanged={props.setTxHash}
                error={error1}
            />

            {props.hashResult ? (
                <div className={style.signature_box}>
                    <div className={style.box1}>
                        <div
                            className={cx(style.icon, {
                                referrer:
                                    props.hashResult.referrer !== undefined,
                            })}
                        />
                        <div className={style.text}>
                            {props.hashResult.referrer
                                ? "Connected through referral link"
                                : "Connected without referral link"}
                        </div>
                    </div>
                    <div className={style.box2} />
                    <div className={style.box3}>
                        <div className={style.text1}>{"Signature"}</div>
                        <div className={style.text2}>
                            {props.hashResult.encodedSignature}
                        </div>
                    </div>
                </div>
            ) : (
                <div />
            )}

            {!props.txPassed ? (
                <ConfirmButton
                    title={"Check"}
                    loading={loading}
                    onClick={() => {
                        retryCountDown.current = retryCountDownMax;
                        setLoading(true);
                        setError1(undefined);
                        startCheck();
                    }}
                    enable={props.txHash !== ""}
                />
            ) : (
                <div />
            )}
        </div>
    );
}
