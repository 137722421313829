import { environment, wasmQuery } from "../api";
import { queryNativeBalance } from "../trade/query";

export async function terraswapStakerInfo(
    address: string
): Promise<ResponseLpStakerInfo> {
    return await wasmQuery(environment().contracts.terraswap.staking, {
        staker_info: {
            staker: address,
        },
    });
}

export async function queryLpEstimateParams(): Promise<ResponseLpEstimate> {
    const balances = await queryNativeBalance(
        "uusd",
        environment().contracts.terraswap.pair
    );
    const uusd = balances.amount;

    const response1 = await wasmQuery(environment().contracts.token, {
        balance: {
            address: environment().contracts.terraswap.pair,
        },
    });

    const response2 = await wasmQuery(environment().contracts.terraswap.lp, {
        token_info: {},
    });

    const token = response1.balance;
    const lp_total_supply = response2.total_supply;

    return {
        pools: {
            uusd: uusd,
            token: token,
        },
        lp_total_supply: lp_total_supply,
    };
}
