import { useEffect, useState } from "react";
import TitleLabel from "../common/Labels/TitleLabel";
import TxBoxView from "../common/TxBoxView/TxBoxView";
import style from "./Mypage.module.scss";
import MyValue from "./MyValue";
import MyClaimable from "./MyClaimable";
import MyRewards from "./MyRewards";
import {
    claimCampaignRewards,
    claimOtherRewards,
} from "../../libs/api/mypage/execution";
import { useRecoilValue } from "recoil";
import { walletState } from "../../libs/contexts";
import { useConnectedWallet, useWallet } from "@terra-money/wallet-provider";
import PageHeader from "../common/PageHeader/PageHeader";
import { campaignClaim } from "../../libs/api/campaign/execution";
import { campaignClaimableList } from "../../libs/api/rest";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../App";

import classNames from "classnames/bind";
const cx = classNames.bind(style);

export default function Mypage(props: any) {
    const history = useHistory();
    const connected = useConnectedWallet();
    const wallet = useRecoilValue(walletState);
    const { post } = useWallet();

    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    useEffect(() => {
        if (!connected) {
            history.replace(PATHS.home);
        }
    }, [connected]);

    function claimOtherRewardsPressed(hasVp: boolean) {
        setHash({
            type: "MyPageClaimOtherRewards",
            isPending: true,
        });

        claimOtherRewards(wallet!.terraAddress, hasVp)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "MyPageClaimOtherRewards",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                setHash({
                    type: "MyPageClaimOtherRewards",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    function claimCampaignRewardsPressed() {
        setHash({
            type: "MyPageClaimCampaignRewards",
            isPending: true,
        });

        claimCampaignRewards(wallet!.terraAddress)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "MyPageClaimCampaignRewards",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                setHash({
                    type: "MyPageClaimCampaignRewards",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={cx(style.container, {
            hash: hash,
        })}>
            {hash !== undefined ? (
                <PageHeader title="Claim Rewards" docs="" />
            ) : (
                <div />
            )}

            <TxBoxView
                transparent={true}
                hash={hash}
                clearHash={() => {
                    setHash(undefined);
                }}
            >
                <div className={style.myvalue_container}>
                    <MyValue />
                    <div style={{ width: "40px" }} />
                    <MyClaimable claimPressed={claimOtherRewardsPressed} />
                </div>
                <MyRewards claimPressed={claimCampaignRewardsPressed} />
            </TxBoxView>
        </div>
    );
}
