import { useConnectedWallet, useWallet } from "@terra-money/wallet-provider";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { environment, getTxInfo, isMainnet } from "../../libs/api/api";
import { queryTokenBalance } from "../../libs/api/trade/query";
import { postTx, VPBurn } from "../../libs/api/vp/execution";
import { getVPRatio } from "../../libs/api/vp/query";
import { balanceState } from "../../libs/contexts";
import {
    divide,
    equalTo,
    leftGreaterThanRight,
    leftLessThanRight,
    multiply,
} from "../../libs/Math";
import * as Utils from "../../libs/utils";
import TitleLabel from "../common/Labels/TitleLabel";
import TxBoxView from "../common/TxBoxView/TxBoxView";
import VPAlert from "./VPAlert";
import VPExchange from "./VPExchange";
import style from "./VPMain.module.scss";
import classNames from "classnames/bind";
import { getFeeNormal } from "../../libs/api/fee";
import Skeleton from "react-loading-skeleton";
import { TxInfo } from "@terra-money/terra.js";
import { campaignState, campaignSummary } from "../../libs/api/campaign/query";
import InputFieldCopy from "../common/InputField/InputFieldCopy";
import LineDummy from "../common/LineDummy/LineDummy";
import { NormalButton } from "../common/NormalButton/NormalButton";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../App";
import {
    getVpCampaignReferre,
    setVpCampaignReferrer,
} from "../../libs/storage";

const cx = classNames.bind(style);

export enum Boxcheck {
    checked,
    notchecked,
}

export enum VPamount {
    bronze,
    silver,
    gold,
    black,
}

export default function VPMain() {
    const history = useHistory();
    const wallet = useConnectedWallet();
    const { post } = useWallet();
    const balance = useRecoilValue(balanceState);
    const [boxchecked, setBoxchecked] = useState(Boxcheck.notchecked);
    const [vpratio, setVpratio] = useState("0");
    const [vpbalance, setVpbalance] = useState<string | undefined>(undefined);
    const [excheck, setExcheck] = useState(false);
    const [vkramount, setVkramount] = useState("0");
    const [hash, setHash] = useState<HashResult | undefined>(undefined);
    const [vpgrade, setVpgrade] = useState<VPamount | undefined>(undefined);

    const [fee, setFee] = useState("0");

    const [referrer, setReferrer] = useState("");

    const [txHash, setTxHash] = useState("");
    const timer = useRef<any>(null);

    const [mintedVp, setMintedVp] = useState("0");
    const [isCampaignActive, setIsCampaignActive] = useState(false);

    useEffect(() => {
        // const campaign = environment().contracts.vpburncampaign;
        // if (campaign) {
        //     campaignState(campaign)
        //         .then((r) => {
        //             setIsCampaignActive(r.is_active);
        //         })
        //         .catch((e) => {
        //             setIsCampaignActive(false);
        //         });
        // } else {
        setIsCampaignActive(false);
        // }
    }, []);

    useEffect(() => {
        getFeeNormal()
            .then((r) => {
                setFee(r.amount.get("uusd")!.amount.toString());
            })
            .catch((e) => {});
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const ref = params.get("vkr");
        const value = ref ?? "";

        let referrer = value.replaceAll(" ", "+");

        if (referrer === "") {
            const saved = getVpCampaignReferre();
            referrer = saved ? saved.referrer : "";
        }

        setVpCampaignReferrer({ referrer: referrer });

        setReferrer(referrer);
    }, []);

    useEffect(() => {
        if (hash === undefined) {
            if (wallet) {
                getVPRatio(wallet.terraAddress)
                    .then((r) => {
                        setVpratio(divide("1", r));
                    })
                    .catch((e) => {});

                queryTokenBalance(
                    environment().contracts.vp_token,
                    wallet.terraAddress
                )
                    .then((r) => {
                        setVpbalance(r.amount);
                    })
                    .catch((e) => {
                        setVpbalance("0");
                    });
            } else {
                setVpbalance("0");
            }
            setBoxchecked(Boxcheck.notchecked);
        }
    }, [hash, wallet]);

    useEffect(() => {
        if (txHash === "") {
            return;
        }

        if (timer.current !== null) {
            clear();
        }

        timer.current = setInterval(() => {
            polling();
        }, 1000);

        return () => {
            clear();
        };
    }, [txHash]);

    const clear = useCallback(() => {
        if (timer.current !== null) {
            clearInterval(timer.current);
            timer.current = null;
        }
    }, []);

    const polling = useCallback(() => {
        if (!txHash) {
            return;
        }

        getTxInfo(txHash)
            .then((r: TxInfo) => {
                if (r.code) {
                    // setTxHash("");
                } else {
                    setTimeout(() => {
                        postTx(
                            txHash,
                            wallet!.terraAddress,
                            referrer ? referrer : undefined
                        )
                            .then((r) => {
                                clear();
                            })
                            .catch((e) => {
                                retry();
                            });
                    }, 500);
                }
            })

            .catch((error) => {});
    }, [txHash]);

    function retry() {
        postTx(txHash, wallet!.terraAddress, referrer ? referrer : undefined)
            .then((r) => {
                clear();
            })
            .catch((e) => {
                // setTxHash("");
                setTimeout(() => {
                    retry();
                }, 1000);
            });
    }

    useEffect(() => {
        if (vpbalance) {
            // over 20000 -> black
            if (
                leftGreaterThanRight(vpbalance, "20000000000") ||
                equalTo(vpbalance, "20000000000")
            ) {
                setVpgrade(VPamount.black);
            }
            // 10000 ~ 20000 -> gold
            else if (
                leftGreaterThanRight(vpbalance, "10000000000") ||
                equalTo(vpbalance, "10000000000")
            ) {
                setVpgrade(VPamount.gold);
            }
            // 3000 ~ 10000 -> silver
            else if (
                leftGreaterThanRight(vpbalance, "3000000000") ||
                equalTo(vpbalance, "3000000000")
            ) {
                setVpgrade(VPamount.silver);
            }
            // under 3000 -> bronze
            else {
                setVpgrade(VPamount.bronze);
            }
        } else {
            // default -> bronze
            setVpgrade(VPamount.bronze);
        }
    }, [vpbalance, vpgrade]);

    function createVP() {
        if (wallet && excheck && boxchecked === Boxcheck.checked) {
            setHash({
                type: "VPBurn",
                isPending: true,
            });

            VPBurn(
                isCampaignActive,
                wallet.terraAddress,
                vkramount,
                referrer ? referrer : undefined
            )
                .then((r) => {
                    return post(r);
                })
                .then((response) => {
                    if (isCampaignActive) {
                        setMintedVp(divide(vkramount, "12.5"));
                        setTxHash(response.result.txhash);
                    }

                    setHash({
                        type: "VPBurn",
                        response: response,
                        redirect: undefined,
                    });
                })
                .catch((error) => {
                    setHash({
                        type: "VPBurn",
                        error: error,
                        redirect: undefined,
                    });
                });
        }
    }

    function toggleBox() {
        if (boxchecked === Boxcheck.checked) {
            setBoxchecked(Boxcheck.notchecked);
        } else {
            setBoxchecked(Boxcheck.checked);
        }
    }

    return (
        <div className={cx(style.wrap, { hash: hash !== undefined })}>
            <div className={cx(style.container, { hash: hash !== undefined })}>
                {isCampaignActive && hash === undefined ? (
                    <div className={style.campaign_container}>
                        {!txHash || leftLessThanRight(mintedVp, "40000000") ? (
                            <div className={style.before} />
                        ) : (
                            <div className={style.after}>
                                <TitleLabel
                                    text="Valkyrie V2 Campaign"
                                    fontWeight="bold"
                                    size={20}
                                    color={"white"}
                                />

                                <div style={{ height: "20px" }} />

                                <InputFieldCopy
                                    title={
                                        "Copy your TXid into V2 campaign page"
                                    }
                                    value={txHash}
                                    disabled={true}
                                    onChanged={() => {}}
                                />

                                <div className={style.referrerbox}>
                                    <div
                                        className={cx(style.reficon, {
                                            checked:
                                                referrer !== "" ? true : false,
                                        })}
                                    />
                                    <div className={style.text}>
                                        {referrer !== ""
                                            ? "Connected through referral link"
                                            : "Connected without referral link"}
                                    </div>
                                </div>

                                <div className={style.dummy1}>
                                    <LineDummy horizontal={true} />
                                </div>
                                <div className={style.button}>
                                    <NormalButton
                                        title={"Go Campaign"}
                                        onClick={() => {
                                            Utils.showUrl(
                                                PATHS.campaignsDetail +
                                                    environment().contracts
                                                        .vpburncampaign
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div />
                )}

                <div className={style.title}>
                    <TitleLabel
                        size={20}
                        fontWeight={"light"}
                        text={"Create Valkyrian Pass"}
                        color={"#fff"}
                    />
                    <a
                        className={style.docs_button}
                        href={
                            "https://medium.com/@valkyrie-protocol/90-day-permanent-burn-and-the-valkyrian-pass-vp-93d94ce54b9"
                        }
                        target="_blank"
                        rel={"noreferrer"}
                    >
                        <div className={style.icon} />
                        <div className={style.title}>{"What are VPs?"}</div>
                    </a>
                </div>

                <TxBoxView
                    hash={hash}
                    clearHash={() => {
                        setHash(undefined);
                    }}
                    transparent={true}
                >
                    <div className={style.vp_container}>
                        <div className={style.inner_container}>
                            <div className={style.myvp}>
                                <MyVP
                                    vpbalance={vpbalance}
                                    vpgrade={vpgrade}
                                    vpratio={vpratio}
                                />
                            </div>
                            <div className={style.changevp}>
                                <div className={style.vpexchange}>
                                    <VPExchange
                                        vkrbalance={balance.token}
                                        vpratio={vpratio}
                                        excheck={setExcheck}
                                        vkramountChange={setVkramount}
                                    />
                                </div>
                                <div className={style.vpinfo}>
                                    <div className={style.price_txfee}>
                                        <div className={style.txfee}>
                                            <div className={style.txfee_title}>
                                                <div
                                                    className={
                                                        style.txfee_title_ico
                                                    }
                                                />
                                                <div
                                                    className={
                                                        style.txfee_title_text
                                                    }
                                                >
                                                    {"Tx Fee"}
                                                </div>
                                            </div>
                                            <div className={style.txfee_amount}>
                                                <div
                                                    className={
                                                        style.txfee_amount_wrap
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            style.txfee_amount_text
                                                        }
                                                    >
                                                        <TitleLabel
                                                            size={16}
                                                            fontWeight={
                                                                "medium"
                                                            }
                                                            text={Utils.getDecimal(
                                                                fee,
                                                                true,
                                                                3
                                                            )}
                                                            color={"#fff"}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            style.txfee_amount_symbol
                                                        }
                                                    >
                                                        <TitleLabel
                                                            size={12}
                                                            fontWeight={
                                                                "medium"
                                                            }
                                                            text={"UST"}
                                                            color={"#fff"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.vpalert}>
                                        <div className={style.vpalert_wrap}>
                                            <VPAlert boxchecked={boxchecked} />
                                        </div>
                                        <div
                                            className={
                                                style.vpalert_checkbutton
                                            }
                                        >
                                            <Checkbox
                                                boxchecked={boxchecked}
                                                toggleBox={toggleBox}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={style.createbutton}>
                                    <div
                                        className={cx(style.button, {
                                            enable:
                                                excheck &&
                                                boxchecked === Boxcheck.checked,
                                        })}
                                        onClick={createVP}
                                    >
                                        {"Create VP"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TxBoxView>
            </div>
        </div>
    );
}

function MyVP(props: {
    vpbalance?: string;
    vpratio: string;
    vpgrade: VPamount | undefined;
}) {
    const [divide1, setDivide1] = useState("");
    const [divide2, setDivide2] = useState("");

    useEffect(() => {
        if (props.vpbalance !== undefined) {
            const divide_balance = Utils.getDecimal(props.vpbalance).split(".");

            setDivide1(divide_balance[0]);
            setDivide2(divide_balance[1]);
        }
    }, [props.vpbalance]);

    return (
        <div className={style.myvp_container}>
            <div className={style.myvp_amount}>
                <div className={style.myvp_amount_title}>
                    <TitleLabel
                        size={14}
                        fontWeight={"medium"}
                        text={"My VP"}
                    />
                </div>
                <div
                    className={cx(style.myvp_amount_card, {
                        silver: props.vpgrade === VPamount.silver,
                        gold: props.vpgrade === VPamount.gold,
                        black: props.vpgrade === VPamount.black,
                    })}
                >
                    {props.vpbalance === undefined ? (
                        <div className={style.myvp_amount_card_balance}>
                            <Skeleton width={100} height={24} />
                        </div>
                    ) : (
                        <div className={style.myvp_amount_card_balance}>
                            {props.vpgrade === VPamount.bronze ||
                            props.vpgrade === VPamount.black ? (
                                <TitleLabel
                                    size={20}
                                    fontWeight={"bold"}
                                    text={Utils.getDecimal(
                                        multiply(divide1, "1000000"),
                                        true,
                                        6
                                    )}
                                    color={"white"}
                                />
                            ) : (
                                <TitleLabel
                                    size={20}
                                    fontWeight={"bold"}
                                    text={Utils.getDecimal(
                                        multiply(divide1, "1000000"),
                                        true,
                                        6
                                    )}
                                    color={"black"}
                                />
                            )}

                            {divide2 === undefined ? (
                                <div />
                            ) : props.vpgrade === VPamount.bronze ||
                              props.vpgrade === VPamount.black ? (
                                <TitleLabel
                                    size={20}
                                    fontWeight={"bold"}
                                    text={"."}
                                    color={"white"}
                                />
                            ) : (
                                <TitleLabel
                                    size={20}
                                    fontWeight={"bold"}
                                    text={"."}
                                    color={"black"}
                                />
                            )}
                            {divide2 === undefined ? (
                                <div />
                            ) : props.vpgrade === VPamount.bronze ||
                              props.vpgrade === VPamount.black ? (
                                <div className={style.dividemargin}>
                                    <TitleLabel
                                        size={12}
                                        fontWeight={"bold"}
                                        text={divide2}
                                        color={"white"}
                                    />
                                </div>
                            ) : (
                                <div className={style.dividemargin}>
                                    <TitleLabel
                                        size={12}
                                        fontWeight={"bold"}
                                        text={divide2}
                                        color={"black"}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className={style.myvp_dummy} />
            <div className={style.myvp_rate}>
                <div className={style.myvp_rate_title}>
                    <TitleLabel
                        size={14}
                        fontWeight={"medium"}
                        text={"VP Rate"}
                        color={"#fff"}
                    />
                </div>
                <div className={style.myvp_rate_vkr}>
                    <div className={style.vkrico} />
                    <div className={style.vkr_amount}>
                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={props.vpratio}
                            color={"#fff"}
                        />
                    </div>
                    <div className={style.vkr_symbol}>
                        <TitleLabel
                            size={12}
                            fontWeight={"medium"}
                            text={"VKR"}
                            color={"#fff"}
                        />
                    </div>
                    <div className={style.vkr_equal} />
                    <div className={style.vp_amount}>
                        <TitleLabel
                            size={16}
                            fontWeight={"medium"}
                            text={"1"}
                            color={"#fff"}
                        />
                    </div>
                    <div className={style.vp_symbol}>
                        <TitleLabel
                            size={12}
                            fontWeight={"medium"}
                            text={"VP"}
                            color={"#fff"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function Checkbox(props: { boxchecked: Boxcheck; toggleBox: () => void }) {
    return (
        <div
            className={cx(style.checkbox, {
                checked: props.boxchecked === Boxcheck.checked,
            })}
            onClick={props.toggleBox}
        >
            <div
                className={cx(style.box, {
                    checked: props.boxchecked === Boxcheck.checked,
                })}
            >
                {/* <div className={style.boxcheckico} /> */}
            </div>
            <div
                className={cx(style.boxcontent, {
                    checked: props.boxchecked === Boxcheck.checked,
                })}
            >
                {"Checked it"}
            </div>
        </div>
    );
}
