import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getBlockTime } from "../../../../../../libs/api/api";
import { lunartCampaignParticipation } from "../../../../../../libs/api/campaign/query";
import { blockState, walletState } from "../../../../../../libs/contexts";
import {
    divide,
    leftGreaterThanRight,
    multiply,
    plus,
} from "../../../../../../libs/Math";
import { dateFormat8, getDecimal } from "../../../../../../libs/utils";
import TitleLabel from "../../../../../common/Labels/TitleLabel";
import ExplainPopup from "../../ExplainPopup";
import style from "./IPCReward.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export enum claimStatus {
    fail,
    true,
    end,
    zero,
}

export default function IPCReward(props: {
    campaignAddress: string;
    claimReward: (
        overfirstlock: claimStatus,
        participationInfo: LunartCampaignParticipation | undefined
    ) => void;
}) {
    const wallet = useRecoilValue(walletState);
    const [participationInfo, setParticipationInfo] = useState<
        LunartCampaignParticipation | undefined
    >(undefined);

    const block = useRecoilValue(blockState);
    const [blockdate, setBlockdate] = useState("");

    const [nowPercent, setNowPercent] = useState(0);
    const [nowClaimedPercent, setNowClaimedPercent] = useState(0);
    const [nowClaimablePercent, setNowClaimablePercent] = useState(0);

    const [divide1, setDivide1] = useState("");
    const [divide2, setDivide2] = useState("");

    const [overfirstlock, setOverfirstlock] = useState(claimStatus.fail);

    useEffect(() => {
        if (participationInfo) {
            let reward = participationInfo.participation_reward;
            // let end = reward.schedule[1].end;
            // let start = reward.participated_height;
            // let now = block.block;

            // let p = (now - start) / (end - start);

            // setNowPercent(Math.max(p * 100, 10));

            const divide_locked = getDecimal(reward.unlocked).split(".");

            setDivide1(divide_locked[0]);
            setDivide2(divide_locked[1]);

            if (
                // block over 10 % check
                leftGreaterThanRight(
                    divide(reward.total, "10"),
                    plus(reward.unlocked, reward.claimed)
                )
            ) {
                setOverfirstlock(claimStatus.fail);
                setNowPercent(0);
            } else {
                if (reward.claimed === reward.total) {
                    setOverfirstlock(claimStatus.end);
                } else {
                    if (reward.unlocked === "0") {
                        setOverfirstlock(claimStatus.zero);
                    } else {
                        setOverfirstlock(claimStatus.true);
                    }
                }

                setNowPercent(
                    Number(
                        multiply(
                            divide(
                                plus(reward.claimed, reward.unlocked),
                                reward.total
                            ),
                            "100"
                        )
                    )
                );

                setNowClaimedPercent(
                    Number(
                        multiply(
                            divide(
                                reward.claimed,
                                plus(reward.claimed, reward.unlocked)
                            ),
                            "100"
                        )
                    )
                );

                setNowClaimablePercent(
                    Number(
                        multiply(
                            divide(
                                reward.unlocked,
                                plus(reward.claimed, reward.unlocked)
                            ),
                            "100"
                        )
                    )
                );
            }
        }
    }, [participationInfo, block]);

    const [showExplainPopup, setShowExplainPopup] = useState(-1);

    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow = showExplainPopup !== -1 ? "hidden" : "auto";
    }, [showExplainPopup]);

    useEffect(() => {
        if (!participationInfo) {
            return;
        }

        getBlockTime(participationInfo.participation_reward.schedule[0].end)
            .then((r) => {
                setBlockdate(dateFormat8(r));
            })
            .catch((e) => {});
    }, [block, participationInfo]);

    useEffect(() => {
        if (props.campaignAddress && wallet) {
            lunartCampaignParticipation(
                props.campaignAddress,
                wallet.terraAddress
            )
                .then((r) => {
                    setParticipationInfo(r);
                })
                .catch((e) => {});
        }
    }, [props.campaignAddress, wallet]);

    if (!participationInfo) {
        return <div className={style.container}></div>;
    }

    return (
        <div className={style.container}>
            <div className={style.title}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"My LunArt"}
                    size={20}
                    color={"#fff"}
                />
            </div>
            <div className={style.contentswrap}>
                <div className={style.contentsbg_footer} />
                <div className={style.contents}>
                    <div className={style.contents_amount}>
                        <div className={style.contents_amount_text}>
                            <div className={style.contents_amount_text_title}>
                                <div className={style.totalreceive}>
                                    <TitleLabel
                                        size={14}
                                        color={"#fff"}
                                        text={"My Total Tokens"}
                                        fontWeight={"medium"}
                                    />
                                </div>
                                <div
                                    className={style.totalreceive_amount}
                                    onClick={() => {
                                        setShowExplainPopup(1);
                                    }}
                                >
                                    <TitleLabel
                                        size={14}
                                        color={"#f29077"}
                                        text={
                                            getDecimal(
                                                participationInfo
                                                    .participation_reward.total,
                                                true,
                                                6
                                            ) + " ARTS"
                                        }
                                        fontWeight={"light"}
                                        underline={true}
                                    />
                                </div>
                            </div>
                            <div className={style.contents_amount_text_sub}>
                                <TitleLabel
                                    size={12}
                                    color={"#fff"}
                                    text={"Claimable"}
                                    fontWeight={"light"}
                                />
                            </div>
                            {overfirstlock === claimStatus.true ? (
                                <div
                                    className={
                                        style.contents_amount_text_amount
                                    }
                                >
                                    <TitleLabel
                                        size={28}
                                        color={"#ffe646"}
                                        text={divide1}
                                        fontWeight={"bold"}
                                    />
                                    {divide2 === undefined ? (
                                        <div />
                                    ) : (
                                        <TitleLabel
                                            size={28}
                                            color={"#ffe646"}
                                            text={"."}
                                            fontWeight={"bold"}
                                        />
                                    )}

                                    {divide2 === undefined ? (
                                        <div />
                                    ) : (
                                        <div className={style.text_margin}>
                                            <TitleLabel
                                                size={12}
                                                color={"#ffe646"}
                                                text={divide2}
                                                fontWeight={"bold"}
                                            />
                                        </div>
                                    )}

                                    <div className={style.text_margin2}>
                                        <TitleLabel
                                            size={12}
                                            color={"#ffe646"}
                                            text={"ARTS"}
                                            fontWeight={"medium"}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={
                                        style.contents_amount_text_amount
                                    }
                                >
                                    <TitleLabel
                                        size={28}
                                        color={"rgba(255,255,255,0.3)"}
                                        text={"0"}
                                        fontWeight={"bold"}
                                    />
                                    <div className={style.text_margin2}>
                                        <TitleLabel
                                            size={12}
                                            color={"rgba(255,255,255,0.3)"}
                                            text={"ARTS"}
                                            fontWeight={"medium"}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={style.contents_amount_buttonfield}>
                            <div
                                className={cx(style.contents_amount_button, {
                                    enabled: overfirstlock === claimStatus.true,
                                    end:
                                        overfirstlock === claimStatus.end ||
                                        overfirstlock === claimStatus.zero,
                                })}
                                onClick={() => {
                                    props.claimReward(
                                        overfirstlock,
                                        participationInfo
                                    );
                                }}
                            >
                                {"Claim"}
                            </div>
                        </div>
                    </div>
                    <div className={style.contents_bar}>
                        {overfirstlock === claimStatus.fail ? (
                            <div className={style.contents_bar_date}>
                                <div className={style.contents_bar_date_text}>
                                    <TitleLabel
                                        size={12}
                                        color={"#fff"}
                                        text={
                                            blockdate +
                                            " (Block: " +
                                            participationInfo
                                                .participation_reward
                                                .schedule[0].end +
                                            ")"
                                        }
                                        fontWeight={"light"}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className={style.contents_bar_date}></div>
                        )}

                        <div className={style.contents_bar_progress}>
                            <div
                                className={cx(style.progressbar_now, {
                                    full: nowPercent === 100,
                                })}
                                style={{ width: nowPercent + "%" }}
                            >
                                <div
                                    className={cx(style.progressbar_claimed, {
                                        full: nowPercent === 100,
                                    })}
                                    style={{ width: nowClaimedPercent + "%" }}
                                />
                            </div>
                            {overfirstlock === claimStatus.fail ? (
                                <div
                                    className={style.progressbar_point}
                                    style={{ left: 10 + "%" }}
                                />
                            ) : (
                                <div />
                            )}
                        </div>
                        <div className={style.contents_bar_amount}>
                            <TitleLabel
                                size={12}
                                color={"#fff"}
                                text={
                                    "Claimed: " +
                                    getDecimal(
                                        participationInfo.participation_reward
                                            .claimed,
                                        true,
                                        6
                                    ) +
                                    " ARTS"
                                }
                                fontWeight={"light"}
                            />
                        </div>
                    </div>
                    <div className={style.myreward_dummy} />
                    <div
                        className={cx(style.mobile_button, {
                            enabled: overfirstlock === claimStatus.true,
                            end:
                                overfirstlock === claimStatus.end ||
                                overfirstlock === claimStatus.zero,
                        })}
                        onClick={() => {
                            props.claimReward(overfirstlock, participationInfo);
                        }}
                    >
                        {"Claim"}
                    </div>
                </div>
            </div>

            {showExplainPopup !== -1 ? (
                <ExplainPopup
                    show={showExplainPopup}
                    setShow={setShowExplainPopup}
                />
            ) : (
                <div />
            )}
        </div>
    );
}
