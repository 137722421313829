import { StakeLpStakeManualMode } from "../StakeLp";
import style from "./StakeLpStake.module.scss";
import StakeLpStakeManual1 from "./StakeLpStakeManual1";
import StakeLpStakeManual2 from "./StakeLpStakeManual2";

export default function StakeLpStakeManual(props: {
    mode: StakeLpStakeManualMode;
    estimateParams?: ResponseLpEstimate;
    makeLpPressed: (token: string, uusd: string) => void;
    stakeLpPressed: (amount: string) => void;
}) {
    return (
        <div className={style.inner_container}>
            {props.mode === StakeLpStakeManualMode.step1 ? (
                <StakeLpStakeManual1
                    estimateParams={props.estimateParams}
                    makeLpPressed={props.makeLpPressed}
                />
            ) : (
                <StakeLpStakeManual2 stakeLpPressed={props.stakeLpPressed} />
            )}
        </div>
    );
}
