import style from "./MainSection4.module.scss";
import * as Utils from "../../libs/utils";
import TitleLabel from "../common/Labels/TitleLabel";
import { multiply } from "../../libs/Math";
import { useEffect, useRef } from "react";
import { pCountAndDistributed } from "../../libs/api/rest";
import { useState } from "react";
import { refreshState } from "../../libs/contexts";
import { useRecoilValue } from "recoil";

export default function MainSection4() {
    const refresh = useRecoilValue(refreshState);
    const isLoading = useRef(false);
    const [value, setValue] = useState({
        participationCount: {
            total: "0",
            participation: "0",
            referral: "0",
        },
        totalDistributed: {
            total: "0",
            participation: "0",
            referral: "0",
        },
    });

    useEffect(() => {
        if (isLoading.current) {
            return;
        }
        isLoading.current = true;
        pCountAndDistributed()
            .then((r) => {
                setValue(r);

                isLoading.current = false;
            })
            .catch((e) => {
                isLoading.current = false;
            });
    }, [refresh]);

    return (
        <div className={style.container}>
            <Card
                title={"Cumulative Campaign Interactions"}
                tooltip={"Total all time cumulative number of interactions"}
                value={multiply(value.participationCount.total, 1000000)}
                chart={{
                    title1: "Direct participation",
                    value1: multiply(
                        value.participationCount.participation,
                        1000000
                    ),
                    title2: "Referral participation",
                    value2: multiply(
                        value.participationCount.referral,
                        1000000
                    ),
                }}
            />
            <div className={style.dummy} />
            <Card
                title={"Cumulative Distributed Rewards"}
                tooltip={
                    "Total all time cumulative rewards that were distributed"
                }
                value={value.totalDistributed.total}
                symbol={"UST"}
                chart={{
                    title1: "Participation",
                    value1: value.totalDistributed.participation,
                    title2: "Sharing",
                    value2: value.totalDistributed.referral,
                }}
            />
        </div>
    );
}

function Card(props: {
    title: string;
    tooltip: string;
    value: string;
    symbol?: string;
    chart: {
        title1: string;
        value1: string;
        title2: string;
        value2: string;
    };
}) {
    const color1 = "#ffe646";
    const color2 = "#a37e30";

    const rate1 = parseFloat(props.chart.value1);
    const rate2 = parseFloat(props.chart.value2);
    let sum = rate1 + rate2;

    if (sum === 0) {
        sum = 1;
    }

    if (isNaN(rate1) || isNaN(rate2)) {
        return <div />;
    }

    return (
        <div className={style.inner_container}>
            <TitleLabel
                tooltip={props.tooltip}
                text={props.title}
                color={"#fff"}
            />
            <div style={{ height: "8px" }} />

            <TitleLabel
                size={30}
                text={Utils.getFormat(props.value)}
                color={"white"}
                symbol={props.symbol}
            />
            <div style={{ height: "10px" }} />

            <div className={style.chart}>
                <div className={style.info_container}>
                    <div className={style.info}>
                        <div className={style.info_title}>
                            <div
                                className={style.icon}
                                style={{ backgroundColor: color1 }}
                            />
                            <div style={{ width: "4px" }} />
                            <TitleLabel
                                text={props.chart.title1}
                                size={12}
                                fontWeight="light"
                                color={"#fff"}
                            />
                        </div>
                        <div className={style.spacer} />
                        <TitleLabel
                            text={Utils.getFormat(props.chart.value1)}
                            size={12}
                            fontWeight="bold"
                            color="#fff"
                            symbol={props.symbol}
                        />
                    </div>

                    <div className={style.infodummy} />

                    <div className={style.info}>
                        <div className={style.info_title}>
                            <div
                                className={style.icon}
                                style={{ backgroundColor: color2 }}
                            />
                            <div style={{ width: "4px" }} />
                            <TitleLabel
                                text={props.chart.title2}
                                size={12}
                                fontWeight="light"
                                color={"#fff"}
                            />
                        </div>
                        <div className={style.spacer} />
                        <TitleLabel
                            text={Utils.getFormat(props.chart.value2)}
                            size={12}
                            fontWeight="bold"
                            color="#fff"
                            symbol={props.symbol}
                        />
                    </div>
                </div>
                <div
                    className={style.bar}
                    style={{ backgroundColor: "#2d2d2d" }}
                >
                    <div
                        style={{ flex: rate1 / sum, backgroundColor: color1 }}
                    />
                    <div
                        style={{ flex: rate2 / sum, backgroundColor: color2 }}
                    />
                </div>
            </div>
        </div>
    );
}
