import { atom } from "recoil";
import { loadSpread } from "./storage";

export const walletState = atom<{ terraAddress: string } | undefined>({
    key: "wallets", // unique ID (with respect to other atoms/selectors)
    default: undefined, //loadWallet(), // default value (aka initial value)
});

export const vpAirdropState = atom<AirdropItem[]>({
    key: "vpairdrop",
    default: [],
});

export const airdropState = atom<AirdropItem[]>({
    key: "airdrop",
    default: [],
});

export const balanceState = atom({
    key: "balances",
    default: {
        uusd: "0",
        token: "0",
    },
});

export const spreadState = atom({
    key: "spread",
    default: loadSpread(),
});

export const blockState = atom({
    key: "block",
    default: {
        block: 0,
    },
});

export const refreshState = atom({
    key: "refresh",
    default: 0,
});
