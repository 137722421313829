import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { participationCampaignList } from "../../libs/api/rest";
import { walletState } from "../../libs/contexts";
import TitleLabel from "../common/Labels/TitleLabel";
import style from "./MyRewards.module.scss";
import * as Utils from "../../libs/utils";
import { isZero, plus } from "../../libs/Math";
import MyRewardsCampaignItem from "./MyRewardsCampaignItem";
import { ClaimButton } from "../common/NormalButton/NormalButton";

export default function MyRewards(props: { claimPressed: () => void }) {
    const wallet = useRecoilValue(walletState);

    const [list, setList] = useState<
        { ipc: string[]; campaign: string[] } | undefined
    >(undefined);

    const totalInfo = useRef<
        {
            campaign: string;
            claimed: string;
            locked: string;
            claimable: string;
        }[]
    >([]);

    const [totalSummary, setTotalSummary] = useState<
        | {
              totalRewardValue: string;
              totalParticipatedCampaigns: number;
              claimed: string;
              locked: string;
              claimable: string;
          }
        | undefined
    >(undefined);

    const [totalRewardValue, setTotalRewardValue] = useState<
        AmountSplitModel | undefined
    >(undefined);
    const [claimedNum, setClaimedNum] = useState<AmountSplitModel | undefined>(
        undefined
    );
    const [lockedNum, setLockedNum] = useState<AmountSplitModel | undefined>(
        undefined
    );
    const [claimableNum, setClaimableNum] = useState<
        AmountSplitModel | undefined
    >(undefined);

    useEffect(() => {
        // true : ipc
        if (wallet) {
            loadList()
                .then((r) => {
                    setList(r);
                })
                .catch((e) => {});
        }
    }, [wallet]);

    useEffect(() => {
        if (list) {
            if (list.ipc.length === 0 && list.campaign.length === 0) {
                updateTotalInfo();
            }
        }
    }, [list]);

    async function loadList() {
        const ipc = await participationCampaignList(
            wallet ? wallet.terraAddress : undefined,
            true
        );
        const campaign = await participationCampaignList(
            wallet ? wallet.terraAddress : undefined,
            false
        );

        return {
            ipc: ipc,
            campaign: campaign,
        };
    }

    useEffect(() => {
        if (totalSummary) {
            setTotalRewardValue(
                Utils.splitAmountByDot(totalSummary.totalRewardValue, 3)
            );
            setClaimedNum(Utils.splitAmountByDot(totalSummary.claimed, 3));
            setLockedNum(Utils.splitAmountByDot(totalSummary.locked, 3));
            setClaimableNum(Utils.splitAmountByDot(totalSummary.claimable, 3));
        }
    }, [totalSummary]);

    function addTotalInfo(
        campaign: string,
        claimed: string,
        claimable: string,
        locked: string
    ) {
        let exist =
            totalInfo.current.filter((item) => {
                return item.campaign === campaign;
            }).length > 0;

        if (!exist) {
            totalInfo.current.push({
                campaign: campaign,
                claimable: claimable,
                claimed: claimed,
                locked: locked,
            });
        }

        if (
            list &&
            totalInfo.current.length === list.ipc.length + list.campaign.length
        ) {
            updateTotalInfo();
        }
    }

    function updateTotalInfo() {
        if (
            list &&
            totalInfo.current.length === list.ipc.length + list.campaign.length
        ) {
            let totalRewardValue = "0";
            let totalClaimed = "0";
            let totalLocked = "0";
            let totalClaimable = "0";

            totalInfo.current.forEach((item) => {
                totalClaimed = plus(totalClaimed, item.claimed);
                totalLocked = plus(totalLocked, item.locked);
                totalClaimable = plus(totalClaimable, item.claimable);
            });
            totalRewardValue = plus(
                plus(totalClaimed, totalLocked),
                totalClaimable
            );

            setTotalSummary({
                totalParticipatedCampaigns:
                    list.ipc.length + list.campaign.length,
                totalRewardValue: totalRewardValue,
                claimed: totalClaimed,
                locked: totalLocked,
                claimable: totalClaimable,
            });
        }
    }

    return (
        <div className={style.container}>
            <TitleLabel
                text={"Campaign Rewards"}
                size={20}
                color={"#fff"}
                fontWeight={"light"}
            />

            <div className={style.reward_total_header}>
                <div className={style.total_reward}>
                    <TitleLabel
                        tooltip={
                            "Total Value of Campaign Rewards (Claimed Rewards + Lock-up Rewards + Claimable Rewards, IPC Campaigns + Campaign)"
                        }
                        text={"Total Reward Value"}
                        color={"#fff"}
                        fontWeight={"light"}
                    />
                    <div style={{ height: 6 }} />
                    <TitleLabel
                        text={
                            totalRewardValue ? totalRewardValue.num1 : undefined
                        }
                        symbol={
                            totalRewardValue && totalRewardValue.num2
                                ? "." + totalRewardValue.num2 + " UST"
                                : "UST"
                        }
                        skeleton={{ width: 100 }}
                        size={30}
                        color={"#fff"}
                    />
                </div>
                <div className={style.line_dummy} />
                <div className={style.line_dummy2} />
                <div className={style.total_participate}>
                    <TitleLabel
                        text={"Total Participated Campaigns"}
                        color={"#fff"}
                        fontWeight={"light"}
                    />
                    <div style={{ height: 6 }} />
                    <TitleLabel
                        text={
                            totalSummary
                                ? totalSummary.totalParticipatedCampaigns.toString()
                                : undefined
                        }
                        color={"#fff"}
                        size={30}
                        skeleton={{ width: 100 }}
                    />
                </div>
            </div>

            <RewardHeader
                claimed={claimedNum}
                locked={lockedNum}
                claimable={claimableNum}
                isClaimable={
                    totalSummary ? !isZero(totalSummary.claimable) : false
                }
                claimPressed={props.claimPressed}
            />

            <MyRewardsCampaignItem
                title={"IPC"}
                ipc={true}
                list={list ? list.ipc : []}
                addTotalInfo={addTotalInfo}
            />

            <MyRewardsCampaignItem
                title={"Campaigns"}
                ipc={false}
                list={list ? list.campaign : []}
                addTotalInfo={addTotalInfo}
            />
        </div>
    );
}

function RewardHeader(props: {
    claimed: AmountSplitModel | undefined;
    locked: AmountSplitModel | undefined;
    claimable: AmountSplitModel | undefined;
    isClaimable: boolean;
    claimPressed: () => void;
}) {
    const wallet = useRecoilValue(walletState);

    return (
        <div className={style.reward_value_header}>
            <div className={style.claimed}>
                <TitleLabel
                    text={"Claimed"}
                    color={"#fff"}
                    fontWeight={"light"}
                />
                <div style={{ height: "6px" }} />
                <TitleLabel
                    text={props.claimed ? props.claimed.num1 : undefined}
                    symbol={
                        props.claimed && props.claimed.num2
                            ? "." + props.claimed.num2 + " UST"
                            : "UST"
                    }
                    skeleton={{ width: 100 }}
                    size={16}
                    color={"#fff"}
                />
            </div>
            <div className={style.line_dummy} />
            <div className={style.line_dummy2} />
            <div className={style.locked}>
                <div className={style.title}>
                    <TitleLabel
                        tooltip="Total Value of all locked campaigns rewards(excluding deposit locked)"
                        text={"Locked"}
                        color={"#fff"}
                        fontWeight={"light"}
                    />
                </div>
                <TitleLabel
                    text={props.locked ? props.locked.num1 : undefined}
                    symbol={
                        props.locked && props.locked.num2
                            ? "." + props.locked.num2 + " UST"
                            : "UST"
                    }
                    skeleton={{ width: 100 }}
                    size={16}
                    color={"#f93660"}
                />
            </div>
            <div className={style.line_dummy} />
            <div className={style.line_dummy2} />
            <div className={style.claimable}>
                <div className={style.text}>
                    <TitleLabel
                        text={"Claimable"}
                        color={"#fff"}
                        fontWeight={"light"}
                    />
                    <div style={{ height: "6px" }} />
                    <TitleLabel
                        text={
                            props.claimable ? props.claimable.num1 : undefined
                        }
                        symbol={
                            props.claimable && props.claimable.num2
                                ? "." + props.claimable.num2 + " UST"
                                : "UST"
                        }
                        skeleton={{ width: 100 }}
                        size={16}
                        color={"#ffe646"}
                    />
                </div>
                <div className={style.button}>
                    <ClaimButton
                        title={"Claim All"}
                        disabled={!props.isClaimable}
                        onClick={() => {
                            if (wallet) {
                                props.claimPressed();
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
