import { useEffect, useState } from "react";
import { environment } from "../../../../../libs/api/api";
import {
    divide,
    isZero,
    leftGreaterThanRight,
    minus,
    multiply,
} from "../../../../../libs/Math";
import { getDecimal } from "../../../../../libs/utils";
import TitleLabel from "../../../../common/Labels/TitleLabel";
import style from "./TalisGuide.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function TalisGuide(props: {
    earlybird: boolean;
    requirement?: TalisRequirement;
    summary: CampaignSummary;
}) {
    const state = props.summary.state;
    const reward = props.summary.reward;

    const pFiltered = state.pool_balances.filter((item) => {
        return item.denom === reward.participation.denom;
    });
    const pReward = pFiltered.length > 0 ? pFiltered[0].amount : "0";

    const [amountsTotal, setAmountsTotal] = useState("0");
    const [amountsNow, setAmountsNow] = useState("0");
    const [amountsPercent, setAmountsPercent] = useState("0");

    useEffect(() => {
        let total = "0";
        if (props.earlybird) {
            total = environment().contracts.talis_early.total_distribution;
        } else {
            total = environment().contracts.talis_public.total_distribution;
        }

        setAmountsTotal(total);
    }, [props.earlybird]);

    useEffect(() => {
        setAmountsNow(minus(amountsTotal, pReward));
    }, [pReward, amountsTotal]);

    useEffect(() => {
        if (!isZero(amountsTotal)) {
            setAmountsPercent(
                multiply(divide(amountsNow, amountsTotal), "100")
            );
        }
    }, [amountsNow, amountsTotal]);

    return (
        <div
            className={cx(style.container, {
                disabled: !leftGreaterThanRight(amountsPercent, "80"),
            })}
        >
            <div className={style.title}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Guide"}
                    size={20}
                    color={"#fff"}
                />
            </div>
            <div className={style.guidewrap}>
                <div className={style.box}>
                    <div className={style.boxwrap}>
                        <div className={style.boxwrap_title}>
                            <TitleLabel
                                fontWeight={"medium"}
                                text={"Talis IPC Key Notice"}
                                size={18}
                                color={"#fff"}
                            />
                        </div>
                        <div className={style.boxwrap_contents_text}>
                            <div className={style.boxwrap_contetns_text1}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "- Input valid Talis app participation TXID in order to participate the IPC"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />
                            </div>

                            <div className={style.boxwrap_contetns_text1}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "- Purchased $TALIS token lock-up period (4 months)"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />
                            </div>
                            <div className={style.boxwrap_contetns_text1}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        "- The purchased $TALIS will be released with 14 months linear vesting"
                                    }
                                    size={12}
                                    color={"#fff"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.boxwrap_contents_more}>
                        <a
                            href="https://medium.com/@valkyrie-protocol/lunart-ipc-details-dfe652b0172e"
                            target="_blank"
                            className={style.boxwrap_contents_more_text}
                        >
                            <TitleLabel
                                fontWeight={"light"}
                                text={"more"}
                                size={12}
                                color={"#fff"}
                            />
                        </a>
                    </div>
                </div>
                <div className={style.detail}>
                    <div className={style.detail_swap}>
                        <div className={style.detail_swap_title}>
                            <div className={style.detail_swap_title_ico} />
                            <div className={style.detail_swap_title_text}>
                                <TitleLabel
                                    fontWeight={"medium"}
                                    text={"Swap"}
                                    size={16}
                                    color={"#fff"}
                                />
                            </div>
                        </div>
                        <div className={style.detailwrap}>
                            <div className={style.detail_swap_rate}>
                                <div className={style.detail_swap_rate_title}>
                                    <TitleLabel
                                        fontWeight={"light"}
                                        text={"Rate"}
                                        size={14}
                                        color={"#fff"}
                                    />
                                </div>
                                <div
                                    className={style.detail_swap_rate_contents}
                                >
                                    <div className={style.rate_ust}>
                                        <div className={style.rate_ust_ico} />
                                        <div className={style.rate_ust_amount}>
                                            <TitleLabel
                                                fontWeight={"medium"}
                                                text={"1"}
                                                size={18}
                                                color={"#13ea5b"}
                                                symbol={"TALIS"}
                                            />
                                        </div>
                                    </div>
                                    <div className={style.rate_dummy} />
                                    <div className={style.rate_token}>
                                        <div className={style.rate_token_ico} />
                                        <div
                                            className={style.rate_token_amount}
                                        >
                                            <TitleLabel
                                                fontWeight={"medium"}
                                                text={
                                                    props.earlybird
                                                        ? environment()
                                                              .contracts
                                                              .talis_early
                                                              .ustprice
                                                        : environment()
                                                              .contracts
                                                              .talis_public
                                                              .ustprice
                                                }
                                                size={18}
                                                color={"#fff"}
                                                symbol={"UST"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.detail_swap_available}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={"Available"}
                                    size={14}
                                    color={"#fff"}
                                />
                                <div
                                    className={style.detail_swap_available_wrap}
                                >
                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={"Min"}
                                        size={16}
                                        color={"#13ea5b"}
                                    />
                                    <div style={{ width: "4px" }} />
                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={
                                            props.requirement
                                                ? getDecimal(
                                                      props.requirement
                                                          .min_uusd_amount,
                                                      true
                                                  )
                                                : "0"
                                        }
                                        size={16}
                                        color={"#fff"}
                                        symbol={"UST"}
                                    />
                                    <div style={{ width: "4px" }} />
                                    <div style={{ width: "4px" }} />
                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={"~"}
                                        size={16}
                                        color={"#fff"}
                                    />
                                    <div style={{ width: "4px" }} />

                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={"Max"}
                                        size={16}
                                        color={"#13ea5b"}
                                    />
                                    <div style={{ width: "4px" }} />
                                    <TitleLabel
                                        fontWeight={"medium"}
                                        text={
                                            props.requirement
                                                ? getDecimal(
                                                      props.requirement
                                                          .max_uusd_amount,
                                                      true
                                                  )
                                                : "0"
                                        }
                                        size={16}
                                        color={"#fff"}
                                        symbol={"UST"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
