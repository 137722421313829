import { useHistory, useLocation } from "react-router-dom";
import { PATHS } from "../../App";
import {
    airdropState,
    balanceState,
    vpAirdropState,
    walletState,
} from "../../libs/contexts";

import style from "./Header.module.scss";
import HeaderPc from "./HeaderPc";
import HeaderMobile from "./HeaderMobile";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { queryBalances } from "../../libs/api/trade/query";

import {
    airdropList,
    airdropVpList,
    isWhitelistedCreator,
} from "../../libs/api/rest";
import { useWallet } from "@terra-money/wallet-provider";
import { plus } from "../../libs/Math";

export default function Header() {
    const setBalance = useSetRecoilState(balanceState);
    const setAirdropList = useSetRecoilState(airdropState);
    const setVpAirdropList = useSetRecoilState(vpAirdropState);
    const history = useHistory();
    const location = useLocation();

    const wallet = useRecoilValue(walletState);
    const { disconnect } = useWallet();

    const [isCreator, setCreator] = useState(false);

    const [menus, setMenus] = useState<{ title: string; path: string }[]>([]);

    useEffect(() => {
        if (wallet) {
            setMenus([
                { title: "My Page", path: PATHS.mypage },
                { title: "Campaigns", path: PATHS.campaigns },
                { title: "Trade", path: PATHS.trade },
                { title: "Governance", path: PATHS.governance },
                { title: "Creator", path: PATHS.producer },
            ]);
        } else {
            setMenus([
                { title: "Campaigns", path: PATHS.campaigns },
                { title: "Trade", path: PATHS.trade },
                { title: "Governance", path: PATHS.governance },
                { title: "Creator", path: PATHS.producer },
            ]);
        }
    }, [wallet]);

    useEffect(() => {
        if (wallet) {
            isWhitelistedCreator(wallet.terraAddress).then((r) => {
                setCreator(r);
            });

            airdropList(wallet.terraAddress)
                .then((r) => {
                    setAirdropList(r);
                })
                .catch((e) => {
                    setAirdropList([]);
                });

            airdropVpList(wallet.terraAddress)
                .then((r) => {
                    setVpAirdropList(r);
                })
                .catch((e) => {
                    setVpAirdropList([]);
                });
        } else {
            setCreator(false);
            setAirdropList([]);
        }
    }, [wallet]);

    useEffect(() => {
        if (wallet) {
            queryBalances(wallet.terraAddress)
                .then((r) => {
                    setBalance(r);
                })
                .catch((e) => {});
        } else {
            setBalance({
                uusd: "0",
                token: "0",
            });
        }
    }, [location, wallet]);

    function connectPressed() {
        history.push(PATHS.connect);
    }

    function disconnectPressed() {
        disconnect();
    }

    return (
        <div className={style.container}>
            <HeaderPc
                menus={menus}
                connect={connectPressed}
                isCreator={isCreator}
                disconnect={disconnectPressed}
            />
            <HeaderMobile
                menus={menus}
                connect={connectPressed}
                isCreator={isCreator}
                disconnect={disconnectPressed}
            />
        </div>
    );
}
