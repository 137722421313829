import style from "./BaseCampaignDetail.module.scss";

import { NormalButton } from "../../../common/NormalButton/NormalButton";

import { useEffect, useState } from "react";
import { campaignClaim } from "../../../../libs/api/campaign/execution";
import {
    calcRefRewardReceivableInfo,
    campaignSummary,
} from "../../../../libs/api/campaign/query";
import * as Utils from "../../../../libs/utils";

import CampaignDetailPoolHistory from "../../../common/CampaignComponents/CampaignDetailPoolHistory";
import TitleLabel from "../../../common/Labels/TitleLabel";
import {
    divide,
    equalTo,
    isZero,
    leftGreaterThanRight,
    minus,
} from "../../../../libs/Math";
import TxBoxView from "../../../common/TxBoxView/TxBoxView";
import LockedDepositListPopup from "../Common/LockedDepositListPopup";
import { useWallet } from "@terra-money/wallet-provider";

import ReceivablePopup from "../Common/ReceivablePopup";
import { useRecoilValue } from "recoil";
import { walletState } from "../../../../libs/contexts";
import classNames from "classnames/bind";
import BaseCampaignGuide from "./Guide/BaseCampaignGuide";
import CampaignParticipation from "../Common/Participation/CampaignParticipation";
import CampaignDetailTitle from "../Common/CampaignDetailTitle";
import CampaignDescription from "../Common/CampaignDescription";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../../App";
import CopyButton from "../../../common/CopyButton/CopyButton";

const cx = classNames.bind(style);

export const parameter = "vkr";

export default function BaseCampaignDetail(props: {
    campaignAddress: string;
    summary: CampaignSummary;
}) {
    const { post } = useWallet();
    const history = useHistory();
    const wallet = useRecoilValue(walletState);
    const [hash, setHash] = useState<HashResult | undefined>(undefined);
    const [txHash, setTxHash] = useState("");

    const [summary, setSummary] = useState<CampaignSummary>(props.summary);

    useEffect(() => {
        if (!hash && txHash && wallet) {
            campaignSummary(
                props.campaignAddress,
                wallet ? wallet.terraAddress : undefined
            )
                .then((r) => {
                    setSummary(r);
                })

                .catch((error) => {
                    history.replace(PATHS.campaigns);
                });
        }
    }, [hash, txHash, wallet, props.campaignAddress]);

    return (
        <div className={style.wrap}>
            <div className={cx(style.container, { hash: hash !== undefined })}>
                <TxBoxView
                    hash={hash}
                    clearHash={() => {
                        setHash(undefined);
                    }}
                    transparent={true}
                >
                    <CampaignDetailTitle summary={summary} />

                    <MyLink
                        summary={summary}
                        campaignAddress={props.campaignAddress}
                        claimPressed={(address: string) => {
                            setHash({
                                type: "CampaignClaimOne",
                                isPending: true,
                            });

                            campaignClaim(props.campaignAddress, address)
                                .then((r) => {
                                    return post(r);
                                })
                                .then((response) => {
                                    setHash({
                                        type: "CampaignClaimOne",
                                        response: response,
                                        redirect: undefined,
                                    });
                                })
                                .catch((error) => {
                                    setHash({
                                        type: "CampaignClaimOne",
                                        error: error,
                                        redirect: undefined,
                                    });
                                });
                        }}
                    />

                    <BaseCampaignGuide
                        campaignAddress={props.campaignAddress}
                        summary={summary}
                    />

                    <CampaignParticipation
                        campaignAddress={props.campaignAddress}
                        summary={summary}
                        participatePressed={(t) => {
                            setHash({
                                type: "CampaignClaimOne",
                                isPending: true,
                            });

                            post(t)
                                .then((response) => {
                                    setTxHash(response.result.txhash);
                                    setHash({
                                        type: "CampaignClaimOne",
                                        response: response,
                                        redirect: undefined,
                                    });
                                })
                                .catch((error) => {
                                    setHash({
                                        type: "CampaignClaimOne",
                                        error: error,
                                        redirect: undefined,
                                    });
                                });
                        }}
                    />

                    <CampaignDescription
                        summary={summary}
                        isIpc={false}
                        title={"Description"}
                    />

                    <CampaignDetailPoolHistory
                        campaignAddress={props.campaignAddress}
                    />
                </TxBoxView>
            </div>
        </div>
    );
}

function MyLink(props: {
    summary: CampaignSummary;
    campaignAddress: string;
    claimPressed: (address: string) => void;
}) {
    const wallet = useRecoilValue(walletState);
    const [showLockedDepositPopup, setShowLockedDepositPopup] = useState(-1);
    const [showReceivablePopup, setShowReceivablePopup] = useState<
        ReceivableReward | undefined
    >(undefined);
    const [shareLink, setShareLink] = useState("");
    const [claimable, setClaimable] = useState(false);
    const p = props.summary.participation!;

    const claimable_deposit = props.summary.deposit
        ? props.summary.deposit.claimable
        : "0";
    const total = props.summary.deposit
        ? props.summary.deposit.total_deposited
        : "0";

    const [receivableInfo, setReceivableInfo] = useState<
        ReceivableReward | undefined
    >(undefined);

    const rate = isZero(receivableInfo ? receivableInfo.receivable : "0")
        ? 0
        : parseFloat(
              divide(
                  receivableInfo ? receivableInfo.received : "0",
                  receivableInfo ? receivableInfo.receivable : "1"
              )
          ) * 100;

    const isOver = receivableInfo
        ? leftGreaterThanRight(
              receivableInfo.received,
              receivableInfo.receivable
          )
            ? true
            : false
        : false;

    useEffect(() => {
        if (props.campaignAddress && wallet) {
            calcRefRewardReceivableInfo(
                props.campaignAddress,
                wallet.terraAddress
            )
                .then((r) => {
                    setReceivableInfo(r);
                })
                .catch((e) => {});
        }
    }, [props.campaignAddress, wallet]);

    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow =
            showLockedDepositPopup !== -1 || showReceivablePopup
                ? "hidden"
                : "auto";
    }, [showLockedDepositPopup, showReceivablePopup]);

    useEffect(() => {
        if (props.summary.shareLink) {
            setShareLink(props.summary.shareLink.url);
        }

        if (props.summary.participation) {
            const deposit = props.summary.deposit
                ? props.summary.deposit.claimable
                : "0";

            const p = props.summary.participation;
            setClaimable(
                leftGreaterThanRight(p.participation_reward.unlocked, 0) ||
                    leftGreaterThanRight(p.referral_reward.unlocked, 0) ||
                    leftGreaterThanRight(deposit, 0)
            );
        } else {
            setClaimable(false);
        }
    }, [props.summary]);

    if (
        !props.summary.participation ||
        wallet === undefined ||
        props.summary.participation.last_participated_at <= 0
    ) {
        return <div />;
    }

    return (
        <div className={style.header_container}>
            <div className={style.header_myrewards}>
                <div className={style.header_myrewards_title}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"My Rewards"}
                        size={20}
                        color={"#fff"}
                    />
                </div>
                <div className={style.header_myrewards_wrap}>
                    <div className={style.header_myrewards_bg_footer} />
                    {/* pc version */}
                    <div className={style.header_myrewards_contents}>
                        <div className={style.myrewards_contents_titles}>
                            <ParticipationTitle />
                        </div>
                        <div className={style.myrewards_contents_dummy} />

                        <div className={style.myrewards_contents_titles}>
                            <ParticipationDetail
                                title={"Participation"}
                                claimable={p.participation_reward.unlocked}
                                locked={p.participation_reward.locked}
                                decimals={
                                    props.summary.reward.participation.decimals
                                }
                                symbol={
                                    props.summary.reward.participation.symbol
                                }
                                pressed={() => {
                                    setShowLockedDepositPopup(0);
                                }}
                            />
                        </div>

                        <div className={style.myrewards_contents_dummy} />

                        <div className={style.myrewards_contents_titles}>
                            <ParticipationDetail
                                title={"Sharing"}
                                claimable={p.referral_reward.unlocked}
                                locked={p.referral_reward.locked}
                                decimals={6}
                                symbol={"VKR"}
                                pressed={() => {
                                    setShowLockedDepositPopup(2);
                                }}
                            />
                        </div>
                        <div className={style.myrewards_contents_dummy} />
                        <div className={style.myrewards_contents_titles}>
                            <ParticipationDetail
                                title={"Campaign Deposit"}
                                tooltip={
                                    "Current Claimable deposit collateral/Currently locked deposit collateral"
                                }
                                claimable={claimable_deposit}
                                locked={minus(total, claimable_deposit)}
                                decimals={6}
                                symbol={"VKR"}
                                pressed={() => {
                                    setShowLockedDepositPopup(1);
                                }}
                            />
                        </div>
                        <div className={style.myrewards_contents_dummy} />
                        <div className={style.myrewards_claim}>
                            <div className={style.myrewards_claimbutton}>
                                <NormalButton
                                    title={"Claim All Rewards"}
                                    onClick={() => {
                                        if (wallet) {
                                            props.claimPressed(
                                                wallet.terraAddress
                                            );
                                        }
                                    }}
                                    disabled={!claimable}
                                />
                            </div>
                        </div>
                    </div>

                    {/* mobile version */}
                    <div className={style.mobile_header_myrewards_contents}>
                        <div className={style.mobile_myrewards_contents_titles}>
                            <MobileParticipationDetail
                                title={"Participation"}
                                claimable={p.participation_reward.unlocked}
                                locked={p.participation_reward.locked}
                                decimals={
                                    props.summary.reward.participation.decimals
                                }
                                symbol={
                                    props.summary.reward.participation.symbol
                                }
                                pressed={() => {
                                    setShowLockedDepositPopup(0);
                                }}
                            />
                        </div>

                        <div
                            className={style.mobile_myrewards_contents_dummy}
                        />

                        <div className={style.mobile_myrewards_contents_titles}>
                            <MobileParticipationDetail
                                title={"Sharing"}
                                claimable={p.referral_reward.unlocked}
                                locked={p.referral_reward.locked}
                                decimals={6}
                                symbol={"VKR"}
                                pressed={() => {
                                    setShowLockedDepositPopup(2);
                                }}
                            />
                        </div>
                        <div
                            className={style.mobile_myrewards_contents_dummy}
                        />
                        <div className={style.mobile_myrewards_contents_titles}>
                            <MobileParticipationDetail
                                title={"Campaign Deposit"}
                                claimable={claimable_deposit}
                                locked={minus(total, claimable_deposit)}
                                decimals={6}
                                symbol={"VKR"}
                                pressed={() => {
                                    setShowLockedDepositPopup(1);
                                }}
                            />
                        </div>
                        <div
                            className={style.mobile_myrewards_contents_dummy}
                        />
                        <div className={style.mobile_myrewards_claim}>
                            <div className={style.mobile_myrewards_claimbutton}>
                                <NormalButton
                                    title={"Claim All Rewards"}
                                    onClick={() => {
                                        if (wallet) {
                                            props.claimPressed(
                                                wallet.terraAddress
                                            );
                                        }
                                    }}
                                    disabled={!claimable}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.header_mysharing}>
                <div className={style.header_mysharing_title}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"My Sharing"}
                        size={20}
                        color={"#fff"}
                    />
                </div>
                <div className={style.header_mysharing_wrap}>
                    <div className={style.header_mysharing_bg_footer} />
                    <div className={style.header_mysharing_contents}>
                        <div className={style.header_mysharing_link}>
                            <div className={style.link_ico} />
                            <div className={style.link_title}>
                                <div className={style.link_title_name}>
                                    <TitleLabel
                                        fontWeight={"light"}
                                        text={"Referral Link"}
                                        size={16}
                                        color={"#fff"}
                                        tooltip={shareLink}
                                    />
                                </div>
                            </div>
                            <div className={style.link_button}>
                                <CopyButton copyString={shareLink} />
                            </div>
                        </div>
                        <div className={style.header_mysharing_dummy} />
                        <div className={style.header_mysharing_cap}>
                            <div className={style.mysharing_rewardcap}>
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={"Sharing Reward Cap"}
                                    size={16}
                                    color={"#fff"}
                                    tooltip={"Received + Claimable"}
                                />
                            </div>
                            <div
                                className={style.mysharing_max}
                                onClick={() => {
                                    if (receivableInfo) {
                                        setShowReceivablePopup(receivableInfo);
                                    }
                                }}
                            >
                                <TitleLabel
                                    fontWeight={"light"}
                                    text={
                                        receivableInfo
                                            ? Utils.getDecimal(
                                                  receivableInfo.receivable,
                                                  true,
                                                  props.summary.reward.referral
                                                      .denom === "uusd"
                                                      ? 3
                                                      : 6
                                              )
                                            : undefined
                                    }
                                    size={12}
                                    color={"rgba(255,255,255,0.7)"}
                                    symbol={
                                        props.summary.reward.referral.symbol
                                    }
                                    underline={true}
                                    skeleton={{ width: 100 }}
                                />
                            </div>
                            <div className={style.mysharing_percentage}>
                                <div className={style.bar_title}>
                                    {leftGreaterThanRight(rate, "80") ||
                                    equalTo(rate, "80") ? (
                                        <div className={style.barover}>
                                            <div
                                                className={style.barover_ico}
                                            />
                                            <TitleLabel
                                                fontWeight={"medium"}
                                                text={rate + "%"}
                                                size={10}
                                                color={"#e94f4f"}
                                            />
                                        </div>
                                    ) : (
                                        <TitleLabel
                                            fontWeight={"medium"}
                                            text={
                                                Utils.getPercentFormat(
                                                    (rate / 100).toString()
                                                ) + "%"
                                            }
                                            size={10}
                                            color={"#ffe646"}
                                        />
                                    )}
                                </div>
                                <div className={style.defaultbar} />
                                {leftGreaterThanRight(rate, "80") ||
                                equalTo(rate, "80") ? (
                                    <div
                                        className={style.bar}
                                        style={{
                                            width: rate + "%",
                                            backgroundColor: isOver
                                                ? "rgba(255,255,255,0.5)"
                                                : "#e94f4f",
                                        }}
                                    />
                                ) : (
                                    <div
                                        className={style.bar}
                                        style={{
                                            width: rate + "%",
                                            backgroundColor: isOver
                                                ? "rgba(255,255,255,0.5)"
                                                : "#ffe646",
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showLockedDepositPopup !== -1 ? (
                <LockedDepositListPopup
                    show={showLockedDepositPopup}
                    setShow={setShowLockedDepositPopup}
                    summary={props.summary}
                />
            ) : (
                <div />
            )}

            {showReceivablePopup !== undefined ? (
                <ReceivablePopup
                    show={showReceivablePopup !== undefined}
                    setShow={() => {
                        setShowReceivablePopup(undefined);
                    }}
                    info={showReceivablePopup}
                    summary={props.summary}
                />
            ) : (
                <div />
            )}
        </div>
    );
}

export function ParticipationTitle() {
    return (
        <div className={style.ptitle_container}>
            <div className={style.ptitle_info}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Participation Info"}
                    size={12}
                    color={"rgba(255,255,255,0.7)"}
                />
            </div>
            <div className={style.ptitle_locked}>
                <div className={style.ptitle_locked_title}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={"Locked"}
                        size={12}
                        color={"rgba(255,255,255,0.7)"}
                    />
                </div>
                <div className={style.ptitle_locked_ico} />
            </div>
            <div className={style.ptitle_claimable}>
                <TitleLabel
                    fontWeight={"light"}
                    text={"Claimable"}
                    size={12}
                    color={"rgba(255,255,255,0.7)"}
                />
            </div>
        </div>
    );
}

export function ParticipationDetail(props: {
    title: string;
    tooltip?: string;
    claimable: string;
    locked: string;
    decimals: number;
    symbol: string;
    pressed?: () => void;
}) {
    return (
        <div className={style.pdetail_container}>
            <div className={style.pdetail_info}>
                <div
                    className={cx(style.pdetail_info_ico, {
                        sharing: props.title === "Sharing",
                        deposit: props.title === "Campaign Deposit",
                    })}
                />
                <div className={style.pdetail_info_title}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={props.title}
                        size={16}
                        color={"#fff"}
                        tooltip={props.tooltip}
                    />
                </div>
            </div>
            <div className={style.pdetail_locked}>
                <div className={style.pdetail_locked_wrap}>
                    <div
                        style={{
                            cursor: props.pressed ? "pointer" : "auto",
                        }}
                        onClick={() => {
                            if (props.pressed) {
                                props.pressed();
                            }
                        }}
                    >
                        <TitleLabel
                            size={16}
                            text={Utils.getDecimal(
                                props.locked,
                                true,
                                props.decimals
                            )}
                            color={"#f93660"}
                            underline={props.pressed ? true : false}
                            symbol={props.symbol}
                        />
                    </div>
                </div>
            </div>
            <div className={style.pdetail_claimable}>
                <TitleLabel
                    fontWeight={"light"}
                    text={Utils.getDecimal(
                        props.claimable,
                        true,
                        props.decimals
                    )}
                    size={16}
                    color={"#ffe646"}
                    symbolFontWeight="medium"
                    symbol={props.symbol}
                />
            </div>
        </div>
    );
}

function MobileParticipationDetail(props: {
    title: string;
    claimable: string;
    decimals: number;
    locked: string;
    symbol: string;
    pressed?: () => void;
}) {
    return (
        <div className={style.mobile_pdetail_container}>
            <div className={style.mobile_title}>
                <div
                    className={cx(style.mobile_title_ico, {
                        sharing: props.title === "Sharing",
                        deposit: props.title === "Campaign Deposit",
                    })}
                />
                <div className={style.mobile_title_text}>
                    <TitleLabel
                        fontWeight={"light"}
                        text={props.title}
                        size={16}
                        color={"#fff"}
                    />
                </div>
            </div>
            <div className={style.mobile_contents}>
                <div className={style.mobile_contents_locked}>
                    <div className={style.mobile_contents_locked_title}>
                        <div className={style.locked_title_text}>
                            <TitleLabel
                                fontWeight={"light"}
                                text={"Locked"}
                                size={12}
                                color={"rgba(255,255,255,0.5)"}
                            />
                        </div>
                        <div className={style.locked_title_ico} />
                    </div>
                    <div className={style.mobile_contents_locked_amount}>
                        <div className={style.pdetail_locked_wrap}>
                            <div className={style.pdetail_locked_amount}>
                                <div
                                    style={{
                                        cursor: props.pressed
                                            ? "pointer"
                                            : "auto",
                                    }}
                                    onClick={() => {
                                        if (props.pressed) {
                                            props.pressed();
                                        }
                                    }}
                                >
                                    <TitleLabel
                                        size={16}
                                        text={Utils.getDecimal(
                                            props.locked,
                                            true,
                                            props.decimals
                                        )}
                                        color={"#f93660"}
                                        underline={props.pressed ? true : false}
                                        symbol={props.symbol}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.mobile_contents_dummy} />
                <div className={style.mobile_contents_claimable}>
                    <div className={style.mobile_contents_claimable_title}>
                        <TitleLabel
                            fontWeight={"light"}
                            text={"Claimable"}
                            size={12}
                            color={"rgba(255,255,255,0.5)"}
                        />
                    </div>
                    <div className={style.mobile_contents_claimable_amount}>
                        <TitleLabel
                            fontWeight={"light"}
                            text={Utils.getDecimal(
                                props.claimable,
                                true,
                                props.decimals
                            )}
                            size={16}
                            color={"#ffe646"}
                            symbol={props.symbol}
                            symbolFontWeight="medium"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
