import { Coin, Coins, TxInfo } from "@terra-money/terra.js";
import { isZero, leftGreaterThanRight, plus } from "../../../libs/Math";
import { campaignSummary } from "../../../libs/api/campaign/query";
import { environment, getSymbol, tokenInfo } from "../../../libs/api/api";
import * as Utils from "../../../libs/utils";

interface Message {
    execute_msg: any;
    coins: Coins;
    contract: string;
    sender: string;
}

export async function txParse(
    hash: HashResult,
    txInfo: TxInfo
): Promise<TxMsgParsed[]> {
    let list: TxMsgParsed[] = [];

    const txMsgs = txInfo.tx.body.messages;
    const logs = txInfo.logs ?? [];

    if (hash.type === "AstroStakeAuto") {
        const msg: Message = txMsgs[1] as Message;

        const fromToken = msg.execute_msg.provide_liquidity.assets[0].amount;
        const coins: Coins = msg.coins;

        const fromUusd = coins.toArray()[0].amount.toString();
        let to = "0";
        for (let i = 0; i < logs[1].events.length; i++) {
            const event = logs[1].events[i];
            if (event.type === "from_contract") {
                for (let j = 0; j < event.attributes.length; j++) {
                    const item = event.attributes[j];
                    if (item.key === "amount") {
                        to = item.value;
                    }
                }
            }
        }

        list.push({
            title: "Deposited",
            values: [
                {
                    value: Utils.getDecimal(fromToken, true),
                    denom: "VKR",
                },
                {
                    value: Utils.getDecimal(fromUusd, true, 3),
                    denom: "UST",
                },
            ],
        });

        list.push({
            title: "Received",
            values: [
                {
                    value: Utils.getDecimal(to, true),
                    denom: "VKR-UST LP",
                },
            ],
        });
    } else if (hash.type === "AstroMakeLp") {
        const msg: Message = txMsgs[1] as Message;

        const fromToken = msg.execute_msg.provide_liquidity.assets[0].amount;
        const coins: Coins = msg.coins;

        const fromUusd = coins.toArray()[0].amount.toString();
        let to = "0";
        for (let i = 0; i < logs[1].events.length; i++) {
            const event = logs[1].events[i];
            if (event.type === "from_contract") {
                for (let j = 0; j < event.attributes.length; j++) {
                    const item = event.attributes[j];
                    if (item.key === "amount") {
                        to = item.value;
                    }
                }
            }
        }

        list.push({
            title: "Deposited",
            values: [
                {
                    value: Utils.getDecimal(fromToken, true),
                    denom: "VKR",
                },
                {
                    value: Utils.getDecimal(fromUusd, true, 3),
                    denom: "UST",
                },
            ],
        });

        list.push({
            title: "Received",
            values: [
                {
                    value: Utils.getDecimal(to, true),
                    denom: "VKR-UST LP",
                },
            ],
        });
    } else if (hash.type === "AstroStakeLp") {
        const msg: Message = txMsgs[0] as Message;

        const fromToken = msg.execute_msg.send.amount;

        list.push({
            title: "Staked",
            values: [
                {
                    value: Utils.getDecimal(fromToken, true),
                    denom: "LP",
                },
            ],
        });
    } else if (hash.type === "LpAutoUnstake") {
        const msg: Message = txMsgs[0] as Message;
        const fromLp = msg.execute_msg.unbond.amount;
        let toToken = "0";
        let toUusd = "0";

        for (let i = 0; i < logs[1].events.length; i++) {
            const event = logs[1].events[i];
            if (event.type === "coin_received") {
                for (let j = 0; j < event.attributes.length; j++) {
                    const item = event.attributes[j];
                    if (item.key === "amount") {
                        toUusd = item.value.replaceAll("uusd", "");
                    }
                }
            } else if (event.type === "from_contract") {
                toToken = event.attributes[14].value;
                // event.attributes.forEach((item) => {
                //     if (item.key === "share") {
                //         // to = item.value;
                //     }
                // });
            }
        }

        list.push({
            title: "Burned",
            values: [
                {
                    value: Utils.getDecimal(fromLp, true),
                    denom: "VKR-UST LP",
                },
            ],
        });

        list.push({
            title: "Received",
            values: [
                {
                    value: Utils.getDecimal(toToken, true),
                    denom: "VKR",
                },
                {
                    value: Utils.getDecimal(toUusd, true, 3),
                    denom: "UST",
                },
            ],
        });
    } else if (hash.type === "AstroUnstakeLp") {
        const msg: Message = txMsgs[0] as Message;
        const fromLp = msg.execute_msg.withdraw.amount;

        list.push({
            title: "Unstaked",
            values: [
                {
                    value: Utils.getDecimal(fromLp, true),
                    denom: "LP",
                },
            ],
        });
    } else if (hash.type === "AstroDivideLp") {
        const msg: Message = txMsgs[0] as Message;
        const fromLp = msg.execute_msg.send.amount;
        let toToken = "0";
        let toUusd = "0";

        for (let i = 0; i < logs[0].events.length; i++) {
            const event = logs[0].events[i];
            if (event.type === "coin_received") {
                toUusd = event.attributes[1].value.replaceAll("uusd", "");
            } else if (event.type === "from_contract") {
                toToken = event.attributes[14].value;
            }
        }

        list.push({
            title: "Burned",
            values: [
                {
                    value: Utils.getDecimal(fromLp, true),
                    denom: "LP",
                },
            ],
        });

        list.push({
            title: "Received",
            values: [
                {
                    value: Utils.getDecimal(toToken, true),
                    denom: "VKR",
                },
                {
                    value: Utils.getDecimal(toUusd, true, 3),
                    denom: "UST",
                },
            ],
        });
    } else if (hash.type === "AstroUnstakeAuto") {
        const msg: Message = txMsgs[0] as Message;

        const fromLp = msg.execute_msg.withdraw.amount;
        let toToken = "0";
        let toUusd = "0";

        for (let i = 0; i < logs[1].events.length; i++) {
            const event = logs[1].events[i];
            if (event.type === "coin_received") {
                toUusd = event.attributes[1].value.replaceAll("uusd", "");
            } else if (event.type === "from_contract") {
                toToken = event.attributes[14].value;
            }
        }

        list.push({
            title: "Burned",
            values: [
                {
                    value: Utils.getDecimal(fromLp, true),
                    denom: "LP",
                },
            ],
        });

        list.push({
            title: "Received",
            values: [
                {
                    value: Utils.getDecimal(toToken, true),
                    denom: "VKR",
                },
                {
                    value: Utils.getDecimal(toUusd, true, 3),
                    denom: "UST",
                },
            ],
        });
        // } else if (hash.type === "LpUnstake") {
        //     const msg: Message = txMsgs[0] as Message;
        //     const fromLp = msg.execute_msg.unbond.amount;

        //     list.push({
        //         title: "Unstaked",
        //         values: [
        //             {
        //                 value: Utils.getDecimal(fromLp, true),
        //                 denom: "VKR-UST LP",
        //             },
        //         ],
        //     });
    } else if (hash.type === "LpClaim") {
        const msg: Message = txMsgs[txMsgs.length - 1] as Message;

        let claimedVkr = "0";
        let claimedAstro = "0";

        for (let i = 0; i < logs[0].events.length; i++) {
            const event = logs[0].events[i];

            if (event.type === "from_contract") {
                for (let j = 0; j < event.attributes.length; j++) {
                    let attr = event.attributes[j];
                    if (
                        attr.key === "contract_address" &&
                        attr.value ===
                            environment().contracts.astroport.astroToken &&
                        event.attributes[j + 1].key === "action" &&
                        event.attributes[j + 1].value === "transfer" &&
                        event.attributes[j + 3].key === "to" &&
                        event.attributes[j + 3].value === msg.sender
                    ) {
                        claimedAstro = event.attributes[j + 4].value;
                    }

                    if (
                        attr.key === "contract_address" &&
                        attr.value === environment().contracts.token &&
                        event.attributes[j + 1].key === "action" &&
                        event.attributes[j + 1].value === "transfer" &&
                        event.attributes[j + 3].key === "to" &&
                        event.attributes[j + 3].value === msg.sender
                    ) {
                        claimedVkr = event.attributes[j + 4].value;
                    }
                }
            }
        }

        list.push({
            title: "Claimed",
            values: [
                {
                    value: Utils.getDecimal(claimedVkr, true),
                    denom: "VKR",
                },
                {
                    value: Utils.getDecimal(claimedAstro, true),
                    denom: "ASTRO",
                },
            ],
        });
    } else if (hash.type === "TerraswapUnstake") {
        // let claimed = "0";
        // for (let i = 0; i < logs[0].events.length; i++) {
        //     const event = logs[0].events[i];
        //     if (event.type === "from_contract") {
        //         claimed = event.attributes[3].value;
        //     }
        // }
        // list.push({
        //     title: "Claimed",
        //     values: [
        //         {
        //             value: Utils.getDecimal(claimed, true),
        //             denom: "VKR",
        //         },
        //     ],
        // });
    } else if (hash.type === "Buy") {
        const msg: Message = txMsgs[0] as Message;
        const coins: Coins = msg.coins;
        const fromUusd = coins.toArray()[0].amount.toString();

        let toToken = "0";

        for (let i = 0; i < logs[0].events.length; i++) {
            const event = logs[0].events[i];
            if (event.type === "from_contract") {
                for (let j = 0; j < event.attributes.length; j++) {
                    const item = event.attributes[j];
                    if (item.key === "return_amount") {
                        toToken = item.value;
                    }
                }
            }
        }

        list.push({
            title: "Paid",
            values: [
                {
                    value: Utils.getDecimal(fromUusd, true, 3),
                    denom: "UST",
                },
            ],
        });

        list.push({
            title: "Received",
            values: [
                {
                    value: Utils.getDecimal(toToken, true),
                    denom: "VKR",
                },
            ],
        });
    } else if (hash.type === "Sell") {
        let fromToken = "0";
        let toUusd = "0";
        for (let i = 0; i < logs[0].events.length; i++) {
            const event = logs[0].events[i];
            if (event.type === "from_contract") {
                for (let j = 0; j < event.attributes.length; j++) {
                    const item = event.attributes[j];
                    if (item.key === "offer_amount") {
                        fromToken = item.value;
                    } else if (item.key === "return_amount") {
                        toUusd = item.value;
                    }
                }
            }
        }

        list.push({
            title: "Sold",
            values: [
                {
                    value: Utils.getDecimal(fromToken, true),
                    denom: "VKR",
                },
            ],
        });

        list.push({
            title: "Earned",
            values: [
                {
                    value: Utils.getDecimal(toUusd, true, 3),
                    denom: "UST",
                },
            ],
        });
    } else if (hash.type === "CreateCampaign") {
        let contract = "";

        for (let i = 0; i < logs[0].events.length; i++) {
            const event = logs[0].events[i];
            if (event.type === "instantiate_contract") {
                contract = event.attributes[3].value;
            }
        }

        list.push({
            title: "Created",
            values: [
                {
                    value: contract,
                    denom: "",
                },
            ],
        });
    } else if (hash.type === "ChangeCampaignDesc") {
        const msg: Message = txMsgs[0] as Message;

        list.push({
            title: "Campaign",
            values: [
                {
                    value: msg.contract,
                    denom: "",
                },
            ],
        });
        // list.push({
        //     title: "URL",
        //     values: [
        //         {
        //             value: url,
        //             denom: "",
        //         },
        //     ],
        // });
        // list.push({
        //     title: "Description",
        //     values: [
        //         {
        //             value: desc,
        //             denom: "",
        //         },
        //     ],
        // });
    } else if (hash.type === "ChangeCampaignActivation") {
        const msg: Message = txMsgs[0] as Message;
        const toActive: boolean = msg.execute_msg.update_activation.active;

        list.push({
            title: "Activation",
            values: [
                {
                    value: toActive ? "Active" : "Inactive",
                    denom: "",
                },
            ],
        });
    } else if (hash.type === "GovStake") {
        const msg: Message = txMsgs[0] as Message;
        const fromToken = msg.execute_msg.send.amount;

        list.push({
            title: "Deposited",
            values: [
                {
                    value: Utils.getDecimal(fromToken, true),
                    denom: "VKR",
                },
            ],
        });
    } else if (hash.type === "GovUnstake") {
        const msg: Message = txMsgs[0] as Message;
        const fromToken = msg.execute_msg.unstake_governance_token.amount;

        list.push({
            title: "Unstake requested",
            values: [
                {
                    value: Utils.getDecimal(fromToken, true),
                    denom: "VKR",
                },
            ],
        });
    } else if (
        hash.type === "GovPoll2" ||
        hash.type === "GovPoll3" ||
        hash.type === "GovPoll5" ||
        hash.type === "GovPoll6"
    ) {
        const id = logs[0].events[1].attributes[8].value;

        list.push({
            title: "Created a poll",
            values: [
                {
                    value: "#" + id,
                    denom: "",
                },
            ],
        });
    } else if (hash.type === "GovVote") {
        const msg: Message = txMsgs[0] as Message;

        const id = msg.execute_msg.cast_vote.poll_id;
        const vote = msg.execute_msg.cast_vote.vote;
        const amount = msg.execute_msg.cast_vote.amount;

        list.push({
            title: "Poll",
            values: [
                {
                    value: "#" + id,
                    denom: "",
                },
            ],
        });
        list.push({
            title: "Vote",
            values: [
                {
                    value: vote.toUpperCase(),
                    denom: "",
                },
            ],
        });
        list.push({
            title: "Amount",
            values: [
                {
                    value: Utils.getDecimal(amount, true),
                    denom: "VKR",
                },
            ],
        });
    } else if (hash.type === "CampaignDeposit") {
        const msg: Message = txMsgs[txMsgs.length - 1] as Message;
        const to = msg.contract;

        list.push({
            title: "Campaign",
            values: [
                {
                    value: to,
                    denom: "",
                },
            ],
        });

        if (txMsgs.length === 3) {
            const msg2: any = txMsgs[2];

            const participation =
                msg.execute_msg.add_reward_pool.participation_reward_amount;
            const referral =
                msg.execute_msg.add_reward_pool.referral_reward_amount;

            const summary = await campaignSummary(msg2.contract, undefined);

            list.push(
                {
                    title: "Participation",
                    values: [
                        {
                            value: Utils.getDecimal(
                                participation,
                                true,
                                summary.reward.participation.denom === "uusd"
                                    ? 3
                                    : 6
                            ),
                            denom: summary.reward.participation.symbol,
                        },
                    ],
                },
                {
                    title: "Sharing",
                    values: [
                        {
                            value: Utils.getDecimal(
                                referral,
                                true,
                                summary.reward.referral.denom === "uusd" ? 3 : 6
                            ),
                            denom: summary.reward.referral.symbol,
                        },
                    ],
                }
            );
        } else {
        }
    } else if (hash.type === "CampaignWithdraw") {
        const msg: Message = txMsgs[0] as Message;
        const exe = msg.execute_msg.remove_reward_pool;
        const denom = exe.denom.native ? exe.denom.native : exe.denom.token;

        let received = "0";
        let fee = "0";
        // let tax = new BigNumber("0");
        if (Utils.isNativeToken(denom)) {
            const user = msg.sender;
            // const campaign = msg.contract;

            for (let i = 0; i < logs[0].events.length; i++) {
                const event = logs[0].events[i];
                if (event.type === "transfer") {
                    for (let j = 0; j < event.attributes.length; j++) {
                        const attr = event.attributes[j];
                        if (attr.key === "recipient") {
                            if (attr.value === user) {
                                received = plus(
                                    received,
                                    event.attributes[j + 2].value.replaceAll(
                                        denom,
                                        ""
                                    )
                                );
                            } else {
                                fee = plus(
                                    fee,
                                    event.attributes[j + 2].value.replaceAll(
                                        denom,
                                        ""
                                    )
                                );
                                // tax = tax.plus(
                                //     event.attributes[j + 2].value.replaceAll(
                                //         denom,
                                //         ""
                                //     )
                                // );
                            }
                        } else {
                            continue;
                        }
                    }
                }
            }
        } else {
            for (let i = 0; i < logs[0].events.length; i++) {
                const event = logs[0].events[i];

                if (event.type === "from_contract") {
                    fee = plus(
                        fee,
                        event.attributes[5].value.replaceAll(denom, "")
                    );
                    received = plus(
                        received,
                        event.attributes[4].value.replaceAll(denom, "")
                    );
                }
            }
        }
        // let fromUusd = msg.coins[0].amount;
        // let toToken = "0";
        // for (let i = 0; i < logs[0].events.length; i++) {
        //     const event = logs[0].events[i];
        //     if (event.type === "from_contract") {
        //         toToken = event.attributes[5].value;
        //     }
        // }

        const symbol = await getSymbol(denom);

        list.push({
            title: "Amount",
            values: [
                {
                    value: Utils.getDecimal(
                        received.toString(),
                        true,
                        denom === "uusd" ? 3 : 6
                    ),
                    denom: symbol,
                },
            ],
        });
        // if (tax.isGreaterThan(0)) {
        //     list.push({
        //         title: "Tax",
        //         values: [
        //             {
        //                 value: Utils.getDecimal(
        //                     tax.toString(),
        //                     false,
        //                     true,
        //                 ),
        //                 denom: symbol,
        //             },
        //         ],
        //     });
        // }

        if (leftGreaterThanRight(fee, 0)) {
            list.push({
                title: "Fee",
                values: [
                    {
                        value: Utils.getDecimal(
                            fee.toString(),
                            true,
                            denom === "uusd" ? 3 : 6
                        ),
                        denom: symbol,
                    },
                ],
            });
        }
    } else if (hash.type === "VPBurn") {
        const msg: Message = txMsgs[0] as Message;
        let burned = "0";
        let minted = "0";

        for (let i = 0; i < logs[0].events.length; i++) {
            const event = logs[0].events[i];
            if (event.type === "from_contract") {
                for (let j = 0; j < event.attributes.length; j++) {
                    const item = event.attributes[j];
                    if (item.key === "mint_amount") {
                        minted = item.value;
                    }
                    if (item.key === "burn_amount") {
                        burned = item.value;
                    }
                }
            }
        }

        list.push(
            {
                title: "Burned",
                values: [
                    {
                        value: Utils.getDecimal(burned, true, 6),
                        denom: "VKR",
                    },
                ],
            },
            {
                title: "Minted",
                values: [
                    {
                        value: Utils.getDecimal(minted, true, 6),
                        denom: "VP",
                    },
                ],
            }
        );
    } else if (hash.type === "ProducerQualificationConnect") {
        const msg: Message = txMsgs[0] as Message;
        const campaign = msg.contract;
        const contract = msg.execute_msg.update_campaign_config.qualifier;

        list.push(
            {
                title: "Campaign Address",
                values: [
                    {
                        value: campaign,
                        denom: "",
                    },
                ],
            },
            {
                title: "Qualification Address",
                values: [
                    {
                        value: contract,
                        denom: "",
                    },
                ],
            }
        );
    } else if (hash.type === "TicketClaim") {
        let claimed = "0";
        let recipient = "";
        logs.forEach((txlog) => {
            txlog.events.forEach((event) => {
                if (event.type === "from_contract") {
                    event.attributes.forEach((attribute) => {
                        if (attribute.key === "recipient") {
                            recipient = attribute.value;
                        } else if (attribute.key === "amount") {
                            claimed = attribute.value;
                        }
                    });
                }
            });
        });

        list.push(
            {
                title: "Claimed",
                values: [
                    {
                        value: Utils.getDecimal(claimed, true, 6),
                        denom: "VP",
                    },
                ],
            },
            {
                title: "Recipient",
                values: [
                    {
                        value: recipient,
                        denom: "",
                    },
                ],
            }
        );
    } else if (
        hash.type === "CampaignClaimOne" ||
        hash.type === "CampaignAllClaim"
    ) {
        let parsed: {
            campaignAddress: string;
            pReward: string;
            pDenom: string;
            rReward: string;
            deposit: string;
        }[] = [];

        logs.forEach((txlog) => {
            txlog.events.forEach((event) => {
                if (event.type === "from_contract") {
                    const campaignAddress = event.attributes[0].value;

                    const action = event.attributes[1].value;
                    if (action === "claim_participation_reward") {
                        const amount = event.attributes[2].value;

                        const p = Utils.amountDivide(amount);

                        const pReward = p.amount;
                        const pDenom = p.denom;

                        let filtered = parsed.filter((item) => {
                            return item.campaignAddress === campaignAddress;
                        });

                        if (filtered.length > 0) {
                            filtered[0].pReward = pReward;
                            filtered[0].pDenom = pDenom;
                        } else {
                            parsed.push({
                                campaignAddress: campaignAddress,
                                pReward: pReward,
                                pDenom: pDenom,
                                rReward: "0",
                                deposit: "0",
                            });
                        }

                        // console.log(event);
                        // console.log(amount);
                    } else if (action === "claim_referral_reward") {
                        const rReward = event.attributes[2].value;
                        let filtered = parsed.filter((item) => {
                            return item.campaignAddress === campaignAddress;
                        });

                        if (filtered.length > 0) {
                            filtered[0].rReward = rReward;
                        } else {
                            parsed.push({
                                campaignAddress: campaignAddress,
                                pReward: "0",
                                pDenom: "",
                                rReward: rReward,
                                deposit: "0",
                            });
                        }
                    } else if (action === "withdraw") {
                        const deposit = event.attributes[8].value;
                        let filtered = parsed.filter((item) => {
                            return item.campaignAddress === campaignAddress;
                        });

                        if (filtered.length > 0) {
                            filtered[0].deposit = deposit;
                        } else {
                            parsed.push({
                                campaignAddress: campaignAddress,
                                pReward: "0",
                                pDenom: "",
                                rReward: "0",
                                deposit: deposit,
                            });
                        }
                    }
                }
            });
        });

        for (let i = 0; i < parsed.length; i++) {
            const item = parsed[i];
            list.push({
                title: "Campaign",
                values: [
                    {
                        value: item.campaignAddress,
                        denom: "",
                    },
                ],
            });

            if (!isZero(item.pReward)) {
                let t = await tokenInfo(item.pDenom);

                list.push({
                    title: "- Participation Reward",
                    values: [
                        {
                            value: Utils.getDecimal(
                                item.pReward,
                                true,
                                item.pDenom.startsWith("t") ? t.decimals : 3
                            ),
                            denom: await getSymbol(item.pDenom),
                        },
                    ],
                });
            }
            if (!isZero(item.rReward)) {
                list.push({
                    title: "- Sharing Reward",
                    values: [
                        {
                            value: Utils.getDecimal(item.rReward, true),
                            denom: "VKR",
                        },
                    ],
                });
            }
            if (!isZero(item.deposit)) {
                list.push({
                    title: "- Deposit",
                    values: [
                        {
                            value: Utils.getDecimal(item.deposit, true),
                            denom: "VKR",
                        },
                    ],
                });
            }
        }
    } else if (hash.type === "Airdrop") {
        const msg: Message = txMsgs[0] as Message;

        const amount = msg.execute_msg.claim.amount;
        list.push({
            title: "Claimed",
            values: [
                {
                    value: Utils.getDecimal(amount, true),
                    denom: "VKR",
                },
            ],
        });
    } else if (hash.type === "VPAirdrop") {
        const msg: Message = txMsgs[0] as Message;

        const amount = msg.execute_msg.claim.amount;
        list.push({
            title: "Claimed",
            values: [
                {
                    value: Utils.getDecimal(amount, true),
                    denom: "VP",
                },
            ],
        });
    }

    const txHash = txInfo.txhash;
    list.push({
        title: "Tx Hash",
        values: [
            {
                value: txHash,
                denom: "hash",
            },
        ],
    });

    // const fee: Coin | undefined = txInfo.tx.fee.amount.get("uusd");
    // const feeAmount = fee ? fee.amount.toString() : "0";
    // const feeDenom = "uusd";

    const fee: Coin | undefined = txInfo.tx.auth_info.fee.amount.get("uusd");
    const feeAmount = fee ? fee.amount.toString() : "0";
    const feeDenom = "uusd";

    list.push({
        title: "Tx Fee",
        values: [
            {
                value: Utils.getDecimal(feeAmount, true, 3),
                denom: await getSymbol(feeDenom),
            },
        ],
    });

    return list;
}
