import style from "./FanfuryCampaignDetail.module.scss";
import { useEffect, useState } from "react";
import {
    ipcClaimReferralDeposit,
    ipcClaimParticipationReward,
} from "../../../../libs/api/campaign/execution";
import { fanfuryRequirement } from "../../../../libs/api/campaign/query";
import CampaignDetailPoolHistory from "../../../common/CampaignComponents/CampaignDetailPoolHistory";

import { multiply } from "../../../../libs/Math";
import TxBoxView from "../../../common/TxBoxView/TxBoxView";
import { useWallet } from "@terra-money/wallet-provider";
import { useRecoilValue } from "recoil";
import { blockState, walletState } from "../../../../libs/contexts";
import classNames from "classnames/bind";
import FanfuryInfo from "./Info/FanfuryInfo";
import FanfuryParticipation from "./Participation/FanfuryParticipation";
import FanfuryCampaignAbout from "./About/FanfuryCampaignAbout";
import IPCDetailTitle from "./IPC/Title/IPCDetailTitle";
import FanfuryGuide from "./Guide/FanfuryGuide";
import FanfuryReward from "./Reward/FanfuryReward";
import FanfuryStandbyBanner from "./StandbyBanner/FanfuryStandbyBanner";
import CampaignDescription from "../Common/CampaignDescription";
import MySharing from "./MySharing";
import MyRewards from "./MyRewards";
import { joinCampaignV2 } from "../../../../libs/api/campaign/qualification/execution";
import { environment } from "../../../../libs/api/api";

const cx = classNames.bind(style);

export default function FanfuryCampaignDetail(props: {
    campaignAddress: string;
    summary: CampaignSummary;
    earlybird: boolean;
    refresh: () => void;
}) {
    const { post } = useWallet();
    const wallet = useRecoilValue(walletState);
    const block = useRecoilValue(blockState);

    const [hash, setHash] = useState<HashResult | undefined>(undefined);

    const [participated, setParticipated] = useState(false);
    const [requirement, setRequirement] = useState<
        FanfuryRequirement | undefined
    >(undefined);
    const [started, setStarted] = useState(false);

    useEffect(() => {
        if (
            props.summary &&
            props.summary.participation &&
            props.summary.participation.last_participated_at > 0
        ) {
            setParticipated(true);
        } else {
            setParticipated(false);
        }
    }, [props.summary]);

    useEffect(() => {
        fanfuryRequirement(props.earlybird)
            .then((r) => {
                setRequirement(r);
            })
            .catch((e) => {});
    }, [props.earlybird]);

    useEffect(() => {
        if (requirement) {
            if (block.block >= requirement.start_block) {
                setStarted(true);
            }
        }
    }, [block, requirement]);

    function ipcParticipate(
        token: string,
        referrer: string | undefined,
        txHash: string,
        signature: string,
        ruleId: string
    ) {
        if (wallet) {
            setHash({
                type: "IPCParticipate",
                isPending: true,
            });

            const qualifier = props.earlybird
                ? environment().contracts.fanfury_early.qualifier
                : environment().contracts.fanfury_public.qualifier;

            joinCampaignV2(
                props.campaignAddress,
                qualifier,
                wallet!.terraAddress,
                multiply(token, 1000000),
                txHash,
                signature,
                ruleId,
                referrer
            )
                .then((r) => {
                    return post(r);
                })
                .then((r) => {
                    setHash({
                        type: "IPCParticipate",
                        response: r,
                        redirect: undefined,
                        callback: (success: boolean) => {
                            if (success) {
                                props.refresh();
                            }
                        },
                    });
                })
                .catch((e) => {
                    setHash({
                        type: "IPCParticipate",
                        error: e,
                        redirect: undefined,
                    });
                });
        }
    }

    function claimParticipationReward() {
        if (props.campaignAddress && wallet) {
            setHash({
                type: "IPCClaim",
                isPending: true,
            });

            ipcClaimParticipationReward(
                props.campaignAddress,
                wallet.terraAddress
            )
                .then((r) => {
                    return post(r);
                })
                .then((r) => {
                    setHash({
                        type: "IPCClaim",
                        response: r,
                        redirect: undefined,
                    });
                })
                .catch((e) => {
                    setHash({
                        type: "IPCClaim",
                        error: e,
                        redirect: undefined,
                    });
                });
        }
    }

    function claimReferralDepositReward() {
        setHash({
            type: "CampaignClaimOne",
            isPending: true,
        });

        ipcClaimReferralDeposit(props.campaignAddress, wallet!.terraAddress)
            .then((r) => {
                return post(r);
            })
            .then((response) => {
                setHash({
                    type: "CampaignClaimOne",
                    response: response,
                    redirect: undefined,
                });
            })
            .catch((error) => {
                setHash({
                    type: "CampaignClaimOne",
                    error: error,
                    redirect: undefined,
                });
            });
    }

    return (
        <div className={style.wrap}>
            {hash === undefined ? <div className={style.bg_footer} /> : <div />}

            <div className={cx(style.container, { hash: hash !== undefined })}>
                <TxBoxView
                    hash={hash}
                    clearHash={() => {
                        setHash(undefined);
                    }}
                    transparent={true}
                >
                    {!started ? (
                        <div className={style.ipcbeforeopen}>
                            <FanfuryStandbyBanner
                                earlybird={props.earlybird}
                                requirement={requirement}
                            />
                        </div>
                    ) : (
                        <div />
                    )}

                    <IPCDetailTitle
                        earlybird={props.earlybird}
                        summary={props.summary}
                        started={started}
                    />

                    {participated === true ? (
                        <FanfuryReward
                            summary={props.summary}
                            claimParticipationReward={claimParticipationReward}
                        />
                    ) : (
                        <div />
                    )}

                    {participated ? (
                        <div className={style.header_container}>
                            <MyRewards
                                summary={props.summary}
                                campaignAddress={props.campaignAddress}
                                claimPressed={claimReferralDepositReward}
                            />
                            <MySharing
                                summary={props.summary}
                                campaignAddress={props.campaignAddress}
                            />
                        </div>
                    ) : (
                        <div />
                    )}
                    <FanfuryGuide
                        earlybird={props.earlybird}
                        requirement={requirement}
                        summary={props.summary}
                    />
                    {participated === true ? (
                        <div />
                    ) : (
                        <FanfuryParticipation
                            summary={props.summary}
                            earlybird={props.earlybird}
                            campaignAddress={props.campaignAddress}
                            requirement={requirement}
                            started={started}
                            ipcParticipate={ipcParticipate}
                        />
                    )}
                    {participated === true ? (
                        <div style={{ marginTop: 40 }} />
                    ) : (
                        <div />
                    )}
                    <FanfuryInfo
                        campaignAddress={props.campaignAddress}
                        summary={props.summary}
                    />

                    <FanfuryCampaignAbout />

                    <CampaignDescription
                        summary={props.summary}
                        isIpc={true}
                        title={"Fanfury"}
                    />
                    <CampaignDetailPoolHistory
                        campaignAddress={props.campaignAddress}
                    />
                </TxBoxView>
            </div>
        </div>
    );
}
